import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RideTags from '../RideTags';
import DriverWidget from '../DriverWidget';
import RideViewInfo from './RideViewInfo';
import RideInfo from './RideInfo';
import RideSubNavigation from './RideSubNavigation';
import BusAssignmentWidget from '../BusAssignmentWidget';
import TrainAssignmentWidget from '../TrainAssignmentWidget';
import BarBox from '../UI/BarBox';
import { pageTitleProp } from '../System/PageTitle';
import { ScrollToTop } from '../ScrollToTop';
import SearchResultWidget from './SearchResultWidget';
import { formatDate } from '../../util/date';
import './RideView.scss';
import usePIICompliance from '../../auth/usePIICompliance';
import { pIIRestrictedDDAction } from '../../DataDog/actions';

const TRAIN_DATA_DATE = '2024-06-01T00:00:00+01:00'; // (1st June 2024) date when train data appear on locomotion
const SHOW_AFTER = '2024-08-07T00:00:00+01:00'; // enable after UTC 01:00 on 7th August 2024
const CURRENT_DATE = formatDate(new Date(), 'YYYY-MM-DDTHH:mm:ssZ');

export default function RideView(props) {
    const { match, meta, pageTitle, translate, printView } = props;

    const isPII = usePIICompliance({
        isTurkey: meta.should_print_pax_list,
    });

    useEffect(() => {
        // log all restricted PII data access in DD
        if (isPII === false) {
            pIIRestrictedDDAction();
        }
    }, [isPII]);

    const shouldShowNewTrainWidget =
        meta.ride.departure > TRAIN_DATA_DATE &&
        meta.ride.line_variation.transfer_type === 'train' &&
        !meta.ride.line.is_cooperation &&
        CURRENT_DATE > SHOW_AFTER;

    return (
        <React.Fragment>
            <RideSubNavigation
                match={match}
                rideId={meta.ride.id}
                rideUuid={meta.ride.uuid}
                translate={translate}
            />

            <div className="ride-view__context-block--narrow ride-view__context-block--on-timeline">
                <BarBox color={meta.ride.line.color}>
                    <RideInfo
                        rideMeta={meta}
                        pageTitle={pageTitle}
                        printView={printView}
                    />
                </BarBox>
            </div>

            <RideViewInfo
                match={match}
                meta={meta}
                rideId={meta.ride.id}
                rideUuid={meta.ride.uuid}
                rideIsDelayProcessEnabled={
                    meta.ride.is_delay_processing_enabled
                }
                rideStatus={meta.ride.status}
                withBoosterBuses={meta.with_booster_buses}
                pax2busDistributionEnabled={meta.pax2bus_distribution_enabled}
                translate={translate}
                printView={printView}
                rideType={meta.transfer_type}
            />

            <div className="ride-view__context-block--narrow ride-view__context-block--on-timeline">
                <div className="content-box">
                    <div className="timeline-bottom-wrapper">
                        <RideTags
                            rideId={meta.ride.id}
                            rideUuid={meta.ride.uuid}
                        />
                        {isPII && (
                            <DriverWidget
                                translate={translate}
                                rideUuid={meta.ride.uuid}
                            />
                        )}
                    </div>
                    <div className="rideview-taurus-widget-wrapper">
                        {shouldShowNewTrainWidget ? (
                            <TrainAssignmentWidget
                                rideId={meta.ride.id}
                                rideUuid={meta.ride.uuid}
                            />
                        ) : (
                            <BusAssignmentWidget
                                rideId={meta.ride.id}
                                rideUuid={meta.ride.uuid}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ScrollToTop />
            <SearchResultWidget />
        </React.Fragment>
    );
}

RideView.propTypes = {
    match: PropTypes.object,
    meta: PropTypes.object.isRequired,
    pageTitle: pageTitleProp.types,
    translate: PropTypes.func,
    printView: PropTypes.bool,
};

RideView.defaultProps = {
    match: {},
    pageTitle: pageTitleProp.default,
    translate: (key) => key,
    printView: false,
};
