import { useState } from 'react';
import api from '../../api/CommentsBankApi';

export default function useSaveComments(referenceId, referenceType) {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState(null);

    const saveComment = (content, files) => {
        const formData = new FormData();
        formData.append('content', content);
        formData.append('referenceType', referenceType);
        if (files && files.length) {
            files.forEach((file) => {
                formData.append('files', file);
            });
        }
        setComment(null);
        setSaving(true);
        return api
            .post(`/add/${referenceId}`, formData)
            .then((response) => {
                setError(null);
                setComment(response);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setSaving(false);
            });
    };
    return {
        saving,
        error,
        comment,
        saveComment,
    };
}
