import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectGroup } from '@flixbus/honeycomb-react';

export default function SelectGroupInput(props) {
    const {
        label, options: initOptions, onSelect, onUnselect, value, ...restProps
    } = props;
    const [options, setOptions] = useState(initOptions);

    useEffect(() => {
        function clickHandler(e) {
            if (e.target.checked) {
                onSelect(e.target.value);
            } else {
                onUnselect(e.target.value);
            }
        }
        const opt = initOptions.map((o) => {
            o.onChange = clickHandler;
            if (value && value.includes(o.value)) {
                o.checked = true;
            } else {
                o.checked = false;
            }
            return o;
        });
        setOptions(opt);
    }, [value, value.length, onSelect, onUnselect, initOptions]);

    return <SelectGroup {...restProps} multi label={label} options={options} />;
}
SelectGroupInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func,
    value: PropTypes.array,
};
SelectGroupInput.defaultProps = {
    label: '',
    onSelect: () => {},
    onUnselect: () => {},
    value: [],
};
