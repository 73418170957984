import React from 'react';
import { Link } from 'react-router-dom';
import {
    ListWrapper,
    ListWrapperItem,
    Grid,
    GridCol,
    FormRow,
    Input,
    Heading,
    Text,
} from '@flixbus/honeycomb-react';
import { TranslateContext } from '../System/Translations';

export default class IndexPage extends React.Component {
    static contextType = TranslateContext;

    state = {
        rideId: '',
    };

    setRideId = (e) => {
        const { value } = e.target;
        this.setState({ rideId: value });
    };

    render() {
        const { rideId } = this.state;
        const translate = this.context;
        return (
            <div className="ride-view__context-block--narrow">
                <Heading size={2}>{translate('index-page.heading')}</Heading>
                <Text>{translate('index-page.welcome')}</Text>
                <Text>{translate('index-page.welcome-msg')}</Text>
                <Grid>
                    <GridCol size={4}>
                        <FormRow>
                            <Input
                                id="ride-id"
                                label={translate('index-page.ride-id')}
                                placeholder=""
                                onChange={this.setRideId}
                                value={rideId}
                            />
                        </FormRow>
                        {rideId !== '' && (
                            <ListWrapper appearance="linked">
                                <ListWrapperItem
                                    RouterLink={Link}
                                    to={`/ride-view/${rideId}/timeline`}
                                >
                                    {translate('content_box.side_nav.timeline')}
                                </ListWrapperItem>
                                <ListWrapperItem
                                    RouterLink={Link}
                                    to={`/ride-view/${rideId}/reservations`}
                                >
                                    {translate(
                                        'content_box.side_nav.reservations'
                                    )}
                                </ListWrapperItem>
                                <ListWrapperItem
                                    RouterLink={Link}
                                    to={`/ride-view/${rideId}/comments`}
                                >
                                    {translate('content_box.side_nav.comments')}
                                </ListWrapperItem>
                                <ListWrapperItem
                                    RouterLink={Link}
                                    to={`/ride-view/${rideId}/notifications`}
                                >
                                    {translate(
                                        'content_box.side_nav.notifications_log'
                                    )}
                                </ListWrapperItem>
                                <ListWrapperItem
                                    RouterLink={Link}
                                    to={`/ride-view/${rideId}/actions-log`}
                                >
                                    {translate(
                                        'content_box.side_nav.action_log'
                                    )}
                                </ListWrapperItem>
                            </ListWrapper>
                        )}
                    </GridCol>
                </Grid>
            </div>
        );
    }
}
