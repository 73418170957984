// import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { addTime } from '../../util/date';
// import { TranslateContext } from '../System/Translations';

export default function RebookingRideStatus(props) {
    return null;

    // const { status } = props;
    // const barStyle = {
    //     width: `${status.progress}%`,
    // };
    // const translate = useContext(TranslateContext);

    // return (
    //     <div className="rebooking-status flix-box">
    //         {status.is_pending ? (
    //             <React.Fragment>
    //                 <div>
    //                     {status.priority_queue ? (
    //                         <span className="rebooking-status--priority__queue">{translate('rebooking-ride-status.priority-rebooking-progress')}</span>
    //                     ) : (
    //                         <span>{translate('rebooking-ride-status.rebooking-progress')}</span>
    //                     )}
    //                     <span className="rebooking-status-progress">
    //                         <span className="rebooking-status-progress__bar" style={barStyle}>
    //                             {`${status.progress}%`}
    //                         </span>
    //                     </span>
    //                 </div>
    //                 <div>
    //                     {translate('rebooking-ride-status.rebooking-will-complete')}
    //                     <strong>{addTime(status.expiration, 's', 'HH:mm:ss')}</strong>
    //                 </div>
    //                 <div>
    //                     {translate('rebooking-ride-status.new-rides')}
    //                     {status.new_rides.map((ride) => (
    //                         <Link className="flix-link" key={ride} to={`/ride-view/${ride}/`} href="/" target="_blank">
    //                             {ride}
    //                             &nbsp;
    //                         </Link>
    //                     ))}
    //                 </div>
    //             </React.Fragment>
    //         ) : (
    //             <span>{translate('rebooking-ride-status.no-pending-rebooking')}</span>
    //         )}
    //     </div>
    // );
}

// RebookingRideStatus.propTypes = {
//     status: PropTypes.shape({
//         isPending: PropTypes.bool,
//         progress: PropTypes.number,
//     }).isRequired,
// };
