import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { NavHorizontal, NavItem } from '@flixbus/honeycomb-react';
import PropTypes from 'prop-types';
import api from '../../api/Client';
import './RideSubNavigation.scss';
import RideMetaContext from '../App/RideMeta/context';
import { CONFIG as CONF } from '../../config';
import useCommentsCount from '../CommentsHooks/useCommentsCount';
import usePIICompliance from '../../auth/usePIICompliance';
// comment out until next NY
// import hatBase64 from './hatBase64';

export default function RideSubNavigation(props) {
    const { match, rideUuid, translate } = props;
    const { rideMeta } = useContext(RideMetaContext);
    const { config: CONFIG } = api;
    const isPII = usePIICompliance({
        isTurkey: rideMeta.should_print_pax_list,
    });
    const radarUrl = `${CONFIG.radar_host}?rideId=${rideUuid}`;
    const driverAppMessageUrl = CONF('DRIVER_APP_URL');
    const commentsCount = useCommentsCount(rideUuid);

    const handleClick = () => {
        window.scrollTo(0, 40); // sub navigation height
    };

    const PIIComplianceList = [
        'reservations',
        'comments',
        'notifications',
        'actions-log',
        'actions-log-beta',
        'notifications-log-beta',
    ];
    const PIIEntriesRegex = new RegExp(PIIComplianceList.join('|'), 'ig');

    const content = [
        {
            text: translate('content_box.side_nav.timeline'),
            href: `${match.url}/timeline`,
            key: 'one',
            RouterLink: NavLink,
            onClick: handleClick,
        },
        {
            text:
                translate('content_box.side_nav.reservations') +
                (rideMeta.paxCount !== undefined
                    ? `(${rideMeta.paxCount})`
                    : ''),
            href: `${match.url}/reservations`,
            key: 'two',
            RouterLink: NavLink,
            onClick: handleClick,
        },
        {
            text: (
                <span className="hat-wrapper">
                    {translate('content_box.side_nav.comments')}
                    {commentsCount !== null ? (
                        <>
                            ({commentsCount})
                            {/*
                                Commented until next NY :)
                                <img src={hatBase64} alt="" />
                            */}
                        </>
                    ) : (
                        ''
                    )}
                </span>
            ),
            href: `${match.url}/comments`,
            key: 'three',
            RouterLink: NavLink,
            onClick: handleClick,
        },
        {
            text: translate('content_box.side_nav.notifications_log'),
            href: `${match.url}/notifications`,
            key: 'four',
            RouterLink: NavLink,
            onClick: handleClick,
        },
        {
            text: translate('content_box.side_nav.action_log'),
            href: `${match.url}/actions-log`,
            key: 'five',
            RouterLink: NavLink,
            onClick: handleClick,
        },
        {
            text: translate('content_box.side_nav.capacity'),
            href: `${match.url}/capacity`,
            key: 'seven',
            RouterLink: NavLink,
            onClick: handleClick,
        },
        {
            text: translate('toolbar.driver_app_message.title'),
            href: driverAppMessageUrl,
            key: 'eight',
            target: '_blank',
        },
        {
            text: translate('content_box.side_nav.radar'),
            href: radarUrl,
            key: 'nine',
            target: '_blank',
            rel: 'noreferrer noopener',
        },
        {
            text: translate('action-log-beta'),
            href: `${match.url}/actions-log-beta`,
            key: 'ten',
            RouterLink: NavLink,
            onClick: handleClick,
        },
        {
            text: translate('notifications-log-beta'),
            href: `${match.url}/notifications-log-beta`,
            key: 'eleven',
            RouterLink: NavLink,
            onClick: handleClick,
        },
    ];

    return (
        <NavHorizontal
            aria-label="sub navigation"
            extraClasses="sub-navigation"
        >
            {content
                .filter((i) => isPII || i.href.search(PIIEntriesRegex) === -1)
                .map((item, idx) => {
                    const { text, key, ...rest } = item;
                    return (
                        <NavItem key={text + key + idx} {...rest}>
                            {text}
                        </NavItem>
                    );
                })}
        </NavHorizontal>
    );
}

RideSubNavigation.propTypes = {
    match: PropTypes.shape({ url: PropTypes.string, path: PropTypes.string })
        .isRequired,
    rideUuid: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
};
