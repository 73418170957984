import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Spinner, Text } from '@flixbus/honeycomb-react';
import { Icon, IconArrowDown } from '@flixbus/honeycomb-icons-react';
import api from '../../../../api/Client';
import { TranslateContext } from '../../../System/Translations';
import { NotifyContext } from '../../../NotificationSystem/context';
import useUserCan from '../../../../auth/useUserCan';
import MassAssignment from './MassAssignment';
import BackendRedirectForm from './BackendRedirectForm';
import TagsForm from '../../TagsForm/TagsForm';
import exportCsv from './exportCsv';
import BatchStopStatus from '../../BatchStopStatus/BatchStopStatus';
import { RideStatusPopup } from "../../../RideStatusPopup";
import {
    MASS_ASSIGNMENT,
    LOCK_ALL_CAPACITY,
    UNLOCK_ALL_CAPACITY,
    EXPORT_CSV,
    LOCK_CANCEL_TOKEN,
    UNLOCK_CANCEL_TOKEN,
    CHANGE_STATUS,
    ADD_REMOVE_TAGS,
    BATCH_STATION_STATUS,
    MARK_AS_BOOSTER,
    CAPACITY_LOCKING,
} from './constants';
import localStorage from '../../../../util/localStorage';

const FLEET_MNGMNT_URL = 'https://management.fleet.flixbus.com';
export default function RideActions(props) {
    const { selected, searchParams } = props;
    const translate = useContext(TranslateContext);
    const notify = useContext(NotifyContext);
    const lang = translate.language;

    const [action, setAction] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [mrkAsBooster, setMrkAsBooster] = useState(false);
    const [capacityAllLoading, setCapacityAllLoading] = useState(false);
    const [tagsFormActive, setTagsForm] = useState(false);
    const [batchStatus, setBatchStatus] = useState(false);

    const massAssignmentForm = useRef(null);
    const backendRedirectForm = useRef(null);
    const componentRef = useRef();

    const isUserCanLock = useUserCan('lock_all_seats');
    const isUserCanTags = useUserCan('adding_tag');
    const isUserCanStopsStatus = useUserCan('reopen_close_station');
    const isUserCanChangeRideStatus = useUserCan('change_ride_status');

    const [rideStatusPopupActive, setRideStatusPopupActive] = useState(false);

    // Fleet Management form params
    const paramString =
        searchParams &&
        searchParams.split('?s=').length &&
        `rv=${searchParams.split('?s=')[1]}`;
    const massAssignmentActionUrl = `${FLEET_MNGMNT_URL}/mass-assignment/${lang}?${paramString}`;
    const markAsBoosterActionUrl = `${FLEET_MNGMNT_URL}/mark-as-booster/${lang}?rideIds=${selectedIds.toString()}&${paramString}`;

    function executeAction() {
        if (action) {
            actions[action](selected);
        }
    }

    function massAssignment() {
        if (selectedIds.length && massAssignmentForm.current) {
            massAssignmentForm.current.submit();
        }
    }

    function changeStatus() {
        setRideStatusPopupActive(true);
    }

    function lockUnlockAllCapacity(param) {
        setCapacityAllLoading(true);

        const URL = param ? `/seat-lock/unlock-all` : `/seat-lock/lock-all`;
        const TOKEN = param ? UNLOCK_CANCEL_TOKEN : LOCK_CANCEL_TOKEN;
        const message = param
            ? translate('notify.unlock_all_capacity_success')
            : translate('notify.lock_all_capacity_success');

        // perfrom request
        api.post(URL, {
            cancelTokenId: TOKEN,
            rideIds: selectedIds,
        })
            .then(() => {
                setCapacityAllLoading(false);

                notify({
                    type: 'success',
                    message,
                });
            })
            .catch((e) => {
                if (!api.isCancel(e)) {
                    notify({
                        type: 'danger',
                        message: e.toString(),
                    });
                }

                setCapacityAllLoading(false);
            });
    }

    function tagsFormControl(active = false) {
        setTagsForm(active);
    }

    function capacityBatch() {
        localStorage.setItem(
            localStorage.BATCH_CAPACITY,
            JSON.stringify({
                ids: selectedIds.join(','),
                expire: new Date().getTime() + 600000,
            })
        );
        const url =
            selectedIds.length > 1
                ? '/capacity/batch'
                : `/ride-view/${selectedIds[0]}/capacity`;

        window.open(url, '_blank');
    }

    function markAsBooster() {
        const url = mrkAsBooster ? markAsBoosterActionUrl : '';
        window.open(url, '_blank');
    }

    const actions = {
        MASS_ASSIGNMENT: massAssignment,
        LOCK_ALL_CAPACITY: () => lockUnlockAllCapacity(false),
        UNLOCK_ALL_CAPACITY: () => lockUnlockAllCapacity(true),
        EXPORT_CSV: exportCsv(notify),
        CHANGE_STATUS: changeStatus,
        ADD_REMOVE_TAGS: () => tagsFormControl(true),
        BATCH_STATION_STATUS: () => setBatchStatus(true),
        MARK_AS_BOOSTER: markAsBooster,
        CAPACITY_LOCKING: capacityBatch,
    };

    const mapActionsNames = {
        MASS_ASSIGNMENT: translate('ride-search.ride-actions.mass-assignment'),
        LOCK_ALL_CAPACITY: translate('toolbar.lock_all_capacity'),
        UNLOCK_ALL_CAPACITY: translate('toolbar.unlock_all_capacity'),
        EXPORT_CSV: translate('ride-search.ride-actions.export-csv'),
        CHANGE_STATUS: translate('ride-search.ride-actions.change-status'),
        ADD_REMOVE_TAGS: translate('search.actions.add-remove-tags'),
        BATCH_STATION_STATUS: translate('search.actions.batch-stops-status'),
        MARK_AS_BOOSTER: translate('ride-search.ride-actions.mark-as-booster'),
        CAPACITY_LOCKING: translate(
            'ride-search.ride-actions.capacity-locking'
        ),
    };

    const onActionClickHandler = (e, name) => {
        e.preventDefault();
        setAction(name);
        handleClickOutside();
    };

    let ridesActions = [
        {
            text: translate('ride-search.ride-actions.mass-assignment'),
            href: '#',
            onClick: (e) => onActionClickHandler(e, MASS_ASSIGNMENT),
        },
        {
            text: translate('ride-search.ride-actions.mark-as-booster'),
            href: '#',
            onClick: (e) => onActionClickHandler(e, MARK_AS_BOOSTER),
        },
        {
            text: translate('ride-search.ride-actions.export-csv'),
            href: '#',
            onClick: (e) => onActionClickHandler(e, EXPORT_CSV),
        },
        {
            text: translate('ride-search.ride-actions.capacity-locking'),
            href: '#',
            onClick: (e) => onActionClickHandler(e, CAPACITY_LOCKING),
        },
    ];

    const capacityActions = [
        {
            text: translate('toolbar.lock_all_capacity'),
            href: '#',
            onClick: (e) => onActionClickHandler(e, LOCK_ALL_CAPACITY),
        },
        {
            text: translate('toolbar.unlock_all_capacity'),
            href: '#',
            onClick: (e) => onActionClickHandler(e, UNLOCK_ALL_CAPACITY),
        },
    ];

    if (isUserCanLock) {
        ridesActions = ridesActions.concat(capacityActions);
    }

    if (isUserCanTags) {
        ridesActions = ridesActions.concat({
            text: mapActionsNames.ADD_REMOVE_TAGS,
            href: '#',
            onClick: (e) => onActionClickHandler(e, ADD_REMOVE_TAGS),
        });
    }

    if (isUserCanStopsStatus) {
        ridesActions.push({
            text: mapActionsNames.BATCH_STATION_STATUS,
            href: '#',
            onClick: (e) => onActionClickHandler(e, BATCH_STATION_STATUS),
        });
    }

    if(isUserCanChangeRideStatus) {
        ridesActions.push({
            text: translate('ride-search.ride-actions.change-status'),
            href: '#',
            onClick: (e) => onActionClickHandler(e, CHANGE_STATUS),
        })
    }

    const fakeSelect = () => {
        return (
            <div className="fake-select">
                {action !== ''
                    ? mapActionsNames[action]
                    : translate('ride-search.ride-actions.select-an-action')}
                <Icon InlineIcon={IconArrowDown} />
            </div>
        );
    };

    const handleClickOutside = () => {
        componentRef.current.focus();
    };

    const hiddenInputStyle = {
        opacity: 0,
        position: 'absolute',
        top: -10000,
    };

    useEffect(() => {
        setSelectedIds(selected.map((item) => item.uuid));
    }, [selected]);

    useEffect(() => {
        setMrkAsBooster(Boolean(selectedIds.length));
    }, [selectedIds]);

    useEffect(
        () => () => {
            api.cancel(LOCK_CANCEL_TOKEN);
            api.cancel(UNLOCK_CANCEL_TOKEN);
        },
        []
    );

    return (
        <div className="ride-search-results-actions">
            {capacityAllLoading && (
                <div
                    aria-live="polite"
                    aria-busy="true"
                    className="ride-search-results-actions__spinner"
                >
                    <Text extraClasses="flix-space-flush-bottom flix-space-xs-right">
                        {translate(
                            'ride-search.ride-actions.all-capacity-request-is-performing'
                        )}
                    </Text>
                    <Spinner size="md" />
                </div>
            )}
            {!capacityAllLoading && (
                <>
                    {selectedIds.length > 0 ? (
                        <>
                            <Dropdown
                                links={ridesActions}
                                extraClasses="ride-search-results-actions__dropdown"
                            >
                                <Button extraClasses="fake-select">
                                    {action !== ''
                                        ? mapActionsNames[action]
                                        : translate(
                                              'ride-search.ride-actions.select-an-action'
                                          )}
                                    <Icon InlineIcon={IconArrowDown} />
                                </Button>
                            </Dropdown>
                            {/* force click outside on dropdown selection */}
                            <input
                                ref={componentRef}
                                style={hiddenInputStyle}
                            />
                        </>
                    ) : (
                        fakeSelect()
                    )}

                    <Button
                        appearance="secondary"
                        onClick={executeAction}
                        disabled={action === '' || !selectedIds.length > 0}
                    >
                        {translate('ride-search.ride-actions.execute')}
                    </Button>

                    <MassAssignment
                        selectedIds={selectedIds}
                        refEl={massAssignmentForm}
                        actionUrl={massAssignmentActionUrl}
                    />

                    <BackendRedirectForm
                        selectedIds={selectedIds}
                        refEl={backendRedirectForm}
                        action={action}
                    />

                    {tagsFormActive && (
                        <TagsForm
                            active={tagsFormActive}
                            onClose={tagsFormControl}
                            rides={selectedIds}
                        />
                    )}

                    {batchStatus && (
                        <BatchStopStatus
                            active={batchStatus}
                            rides={selectedIds}
                            onClose={() => {
                                setBatchStatus(false);
                            }}
                        />
                    )}
                </>
            )}
            <RideStatusPopup
                rides={[...selectedIds]}
                isOpened={rideStatusPopupActive}
                handleClose={() => setRideStatusPopupActive(false)}
            />
        </div>
    );
}

RideActions.propTypes = {
    selected: PropTypes.array,
    searchParams: PropTypes.string,
};
RideActions.defaultProps = {
    selected: [],
    searchParams: '',
};
