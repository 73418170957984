import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Checkbox } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconSeatSolid,
    IconTrainSolid,
} from '@flixbus/honeycomb-icons-react';
import SortHandler from './SortHandler';
import { DataRow, DataCol, DataHeaderGroup } from '../../UI/DataGrid';
import Translate from '../../System/Translations';

export default function TableHeader(props) {
    const {
        orders,
        toggleBatch,
        selectedPax,
        sortBy,
        activeSort,
        rideMeta,
        printView,
        columns,
    } = props;
    const ordersIds = orders.map((order) => order.id);
    const allChecked = ordersIds.length === selectedPax.length;
    return (
        <Translate>
            {(translate) => (
                <DataHeaderGroup>
                    <DataRow header>
                        {columns.passengers.checked && (
                            <DataCol>
                                <Checkbox
                                    id="select-all-pax"
                                    label={translate(
                                        'reservations.table.passengers'
                                    )}
                                    value={String(allChecked)}
                                    onChange={toggleBatch(ordersIds)}
                                    checked={allChecked}
                                    small
                                />
                            </DataCol>
                        )}

                        {!printView && columns.status.checked && (
                            <DataCol>
                                {translate('reservations.table.status')}
                                <SortHandler
                                    sortMethod="order.passenger.checkin_status"
                                    sortBy={sortBy}
                                    activeSort={activeSort}
                                />
                            </DataCol>
                        )}

                        {rideMeta.should_print_pax_list &&
                            columns.revenue.checked && (
                                <DataCol>
                                    {translate('reservations.table.revenue')}
                                </DataCol>
                            )}

                        {columns.seats.checked && (
                            <DataCol>
                                <div className="sort-handler-wrapper">
                                    <span>
                                        <Tooltip
                                            id="reservations.table.seats-tooltip"
                                            position="top"
                                            content={translate(
                                                'reservations.table.seats'
                                            )}
                                            openOnHover
                                            size="content-fit"
                                        >
                                            <Icon InlineIcon={IconSeatSolid} />
                                        </Tooltip>
                                        <SortHandler
                                            sortMethod="order.passenger.seats_number"
                                            sortBy={sortBy}
                                            activeSort={activeSort}
                                        />
                                    </span>
                                    <span>
                                        <Tooltip
                                            id="reservations.table.deck-tooltip"
                                            position="top"
                                            content={translate(
                                                'reservations.table.deck'
                                            )}
                                            openOnHover
                                            size="content-fit"
                                        >
                                            <Icon InlineIcon={IconTrainSolid} />
                                        </Tooltip>
                                        <SortHandler
                                            sortMethod="order.passenger.deck_number"
                                            sortBy={sortBy}
                                            activeSort={activeSort}
                                        />
                                    </span>
                                </div>
                            </DataCol>
                        )}

                        {!printView && columns.order.checked && (
                            <DataCol>
                                {translate('reservations.table.order')}
                                <SortHandler
                                    sortMethod="order.created_at"
                                    sortBy={sortBy}
                                    activeSort={activeSort}
                                />
                            </DataCol>
                        )}

                        {columns.route.checked && (
                            <DataCol>
                                <div className="sort-handler-wrapper">
                                    {translate('reservations.table.route')}
                                    <SortHandler
                                        sortMethod="order.route"
                                        sortBy={sortBy}
                                        activeSort={activeSort}
                                    />
                                </div>
                            </DataCol>
                        )}

                        {columns.extras.checked && (
                            <DataCol>
                                <div className="sort-handler-wrapper">
                                    {translate('reservations.table.extras')}
                                    <SortHandler
                                        sortMethod="order.extras"
                                        sortBy={sortBy}
                                        activeSort={activeSort}
                                    />
                                </div>
                            </DataCol>
                        )}

                        {columns.language.checked && (
                            <DataCol>
                                <div className="sort-handler-wrapper">
                                    {translate('reservations.table.language')}
                                    <SortHandler
                                        sortMethod="order.language"
                                        sortBy={sortBy}
                                        activeSort={activeSort}
                                    />
                                </div>
                            </DataCol>
                        )}

                        {!printView && columns.phone.checked && (
                            <DataCol>
                                {translate('reservations.table.phone')}
                            </DataCol>
                        )}

                        {columns.shop.checked && (
                            <DataCol>
                                <div className="sort-handler-wrapper">
                                    {translate('reservations.table.shop')}
                                    <SortHandler
                                        sortMethod="order.shop"
                                        sortBy={sortBy}
                                        activeSort={activeSort}
                                    />
                                </div>
                            </DataCol>
                        )}

                        {!printView && columns.transfer.checked && (
                            <DataCol>
                                {translate('reservations.table.transfer')}
                                <SortHandler
                                    sortMethod="order.passengers.interconnection"
                                    sortBy={sortBy}
                                    activeSort={activeSort}
                                />
                            </DataCol>
                        )}
                    </DataRow>
                </DataHeaderGroup>
            )}
        </Translate>
    );
}

TableHeader.propTypes = {
    orders: PropTypes.array.isRequired,
    toggleBatch: PropTypes.func.isRequired,
    selectedPax: PropTypes.array.isRequired,
    sortBy: PropTypes.func.isRequired,
    activeSort: PropTypes.object.isRequired,
    rideMeta: PropTypes.object.isRequired,
    printView: PropTypes.bool,
};
TableHeader.defaultProps = {
    printView: false,
};
