import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from '@flixbus/honeycomb-react';

export default function Passenger(props) {
    const { passenger, togglePax, isChecked, push } = props;
    const paxItemCssClass = classNames('reservation-passenger-item', {
        'reservation-passenger-item--push': push,
    });
    const { name } = passenger;
    return (
        <div className={paxItemCssClass}>
            {push ? (
                <label>{name}</label>
            ) : (
                <Checkbox
                    label={name}
                    id={passenger.ticket_id || passenger.id}
                    value={String(isChecked)}
                    onChange={togglePax(passenger.order_id)}
                    checked={isChecked}
                    small
                />
            )}
        </div>
    );
}

Passenger.propTypes = {
    passenger: PropTypes.object.isRequired,
    togglePax: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    push: PropTypes.bool.isRequired,
};
