import React from 'react';

/**
 * React Context to share Page Title handler
 * across application components
 * @constant PageTitleContext
 */
const PageTitleContext = React.createContext();

export default PageTitleContext;
