import React from 'react';
import PropTypes from 'prop-types';
import WsSubscriber from '../../../../../../ws/WsSubscriber';
import api from '../../../../../../api/Client';
import {
    STATION_STATUS_OPEN,
    STATION_STATUS_CLOSED,
    STATION_STATUS_CANCELLED,
} from '../../../constants';

const supportedStatuses = [
    STATION_STATUS_OPEN,
    STATION_STATUS_CLOSED,
    STATION_STATUS_CANCELLED,
];

export default function withStationStatusWsSubscriber(Component) {
    return class WithStationStatusWsSubscriber extends React.Component {
        static propTypes = {
            rideUuid: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            uuid: PropTypes.string,
            status: PropTypes.string,
            toggleSaving: PropTypes.func.isRequired,
            notify: PropTypes.func,
            onStatusChanged: PropTypes.func,
        };
        static defaultProps = {
            notify: () => {},
            onStatusChanged: () => {},
        };
        constructor(props) {
            super(props);
            this.state = {
                status: props.status,
            };
        }
        async componentDidMount() {
            this.config = await api.getAppConfig();
            WsSubscriber().subscribe(
                this.config.wamp.topic.station_status,
                this.onStationStatusChange
            );
        }
        componentWillUnmount() {
            WsSubscriber().unsubscribe(
                this.config.wamp.topic.station_status,
                this.onStationStatusChange
            );
        }
        onStationStatusChange = (data) => {
            const {
                uuid,
                rideUuid,
                notify,
                confirmStatusChange,
                onStatusChanged,
            } = this.props;

            const stopUuid = uuid;
            const consumedInfo = data[0];

            if (
                rideUuid === consumedInfo.rideId &&
                stopUuid === consumedInfo.stopId &&
                supportedStatuses.indexOf(consumedInfo.status) !== -1
            ) {
                onStatusChanged(consumedInfo.status);
                confirmStatusChange(consumedInfo.status);
                notify({
                    type: 'success',
                    message: `Station ${consumedInfo.status}`,
                });
            }
        };
        render() {
            return <Component {...this.props} />;
        }
    };
}
