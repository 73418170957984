import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconBikeSolid,
    IconLuggageAdditionalSolid,
    IconWheelchairSolid,
    IconLuggageBulky,
    IconAttention,
    IconSeat,
} from '@flixbus/honeycomb-icons-react';
import { ReactComponent as GhostIcon } from './ghost-icon.svg';
import Translate from '../../System/Translations';

export default function ExtrasInfo({ extras }) {
    const {
        luggage_additional: luggageAdditional,
        bike_slot: bikeSlot,
        wheelchair,
        empty_seat: emptySeat,
        luggage_bulky: bulkyLuggage,
        premium_seat: premiumSeat,
        ghosted,
        ghost_reason: ghostReason,
        ...restExtras
    } = extras;

    return (
        <Translate>
            {(translate) => (
                <div>
                    {bikeSlot > 0 && (
                        <span
                            title={`${translate(
                                'icon_hint.extras.bikes'
                            )}: ${bikeSlot}`}
                        >
                            <Icon
                                extraClasses="extra-icon"
                                InlineIcon={IconBikeSolid}
                            />
                        </span>
                    )}
                    {luggageAdditional > 0 && (
                        <span
                            title={`${translate(
                                'icon_hint.extras.luggage'
                            )}: ${luggageAdditional}`}
                        >
                            <Icon
                                extraClasses="extra-icon"
                                InlineIcon={IconLuggageAdditionalSolid}
                            />
                        </span>
                    )}
                    {wheelchair > 0 && (
                        <span
                            title={`${translate(
                                'icon_hint.extras.wheelchair'
                            )}: ${luggageAdditional}`}
                        >
                            <Icon
                                extraClasses="extra-icon"
                                InlineIcon={IconWheelchairSolid}
                            />
                        </span>
                    )}
                    {emptySeat > 0 && (
                        <Tooltip
                            openOnHover
                            smartPosition
                            id="empty-seats"
                            content={`${translate(
                                'reservations.table.empty_seat'
                            )}: ${emptySeat}`}
                        >
                            <span className="reservation-passenger-seat-badge reservation-passenger-seat-badge--empty" />
                        </Tooltip>
                    )}
                    {bulkyLuggage > 0 && (
                        <span
                            title={`${translate(
                                'icon_hint.extras.luggage_bulky'
                            )}: ${bulkyLuggage}`}
                        >
                            <Icon
                                extraClasses="extra-icon"
                                InlineIcon={IconLuggageBulky}
                            />
                        </span>
                    )}
                    {premiumSeat > 0 && (
                        <span
                            title={`${translate(
                                'icon_hint.extras.premium_seat'
                            )}: ${premiumSeat}`}
                        >
                            <Icon
                                extraClasses="extra-icon"
                                InlineIcon={IconSeat}
                            />
                        </span>
                    )}
                    {ghosted > 0 && (
                        <Tooltip
                            openOnHover
                            smartPosition
                            id="ghost-icon"
                            content={`${translate(
                                'icon_hint.extras.ghost_icon'
                            )}: ${ghostReason}`}
                        >
                            <Icon
                                extraClasses="extra-icon"
                                InlineIcon={() => <GhostIcon />}
                            />
                        </Tooltip>
                    )}
                    {Object.keys(restExtras).length > 0 &&
                        Object.entries(restExtras).map(([key, value]) => (
                            <span
                                title={`${key}: ${value}`}
                                key={`${key}: ${value} - extras`}
                            >
                                <Icon
                                    key={key}
                                    extraClasses="extra-icon"
                                    InlineIcon={IconAttention}
                                />
                            </span>
                        ))}
                </div>
            )}
        </Translate>
    );
}

ExtrasInfo.propTypes = {
    extras: PropTypes.shape({
        luggage_additional: PropTypes.number,
        bike_slot: PropTypes.number,
    }).isRequired,
};
