import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Tag, Tooltip } from '@flixbus/honeycomb-react';
import { IconAttentionSolid } from '@flixbus/honeycomb-icons-react';

export default function InterconnectionItem(props) {
    const { color, type, delayStatus, code, id, count, hintText, isLast } =
        props;
    const statusCssClass = classNames(
        'timeline-interconnections-status',
        `timeline-interconnections-status--${delayStatus}`
    );
    const getIcon = () => (
        <IconAttentionSolid
            className="timeline-interconnections-tag-icon"
            style={{ fill: '#' + color }}
        />
    );
    const rideCode = () => {
        let res = '';

        if (code.startsWith('L')) {
            res = code.slice(1, code.length);
        } else if (code.startsWith('TR')) {
            res = code.slice(2, code.length);
        } else {
            res = code;
        }

        if (res.includes('TN')) {
            const regex = /TN/gi;
            return res.replaceAll(regex, '| ');
        }

        return res;
    };
    return (
        <div className="timeline-interconnections-item">
            <div className="timeline-interconnections-item-container">
                <span className={statusCssClass}></span>
                {count && (
                    <>&nbsp; {`${type === 'in' ? '+' : '-'}${count}`} &nbsp; </>
                )}
                <Tooltip
                    id={id}
                    content={hintText}
                    extraClasses="timeline-interconnections-hint"
                    position={isLast ? 'left' : null}
                    smartPosition
                    openOnHover
                >
                    <Tag
                        extraClasses="timeline-interconnections-tag"
                        style={{ borderColor: `#${color}` }}
                        small
                        outlined
                    >
                        {getIcon()}

                        <Link
                            className="timeline-interconnections-link"
                            title={code}
                            to={`/ride-view/${id}`}
                        >
                            {rideCode()}
                        </Link>
                    </Tag>
                </Tooltip>
            </div>
        </div>
    );
}

InterconnectionItem.propTypes = {
    color: PropTypes.string,
    delayStatus: PropTypes.oneOf(['success', 'warning', 'danger', 'default']),
    code: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isLast: PropTypes.bool,
};
InterconnectionItem.defaultProps = {
    color: '#000000',
    delayStatus: 'success',
    isLast: false,
};
