import React from 'react';
import PropTypes from 'prop-types';

export default function Pax2Bus(props) {
    const { item } = props;

    return (
        <div>
            Pax2bus distribution {item.parameters.enabled ? 'enabled' : 'disabled'}
        </div>
    );
}
Pax2Bus.propTypes = {
    item: PropTypes.object.isRequired,
};
