import React from 'react';
import { Grid, GridCol, Skeleton } from '@flixbus/honeycomb-react';

export default function EmptyItem() {
    return (
        <Grid>
            <GridCol size={2}>
                <Skeleton
                    Elem="div"
                    width="sm"
                    height="sm"
                    extraClasses="skeleton-add-m"
                />
                <Skeleton Elem="div" width="sm" height="sm" flushBottom />
            </GridCol>
            <GridCol size={4}>
                <Skeleton
                    Elem="div"
                    width="md"
                    height="sm"
                    extraClasses="skeleton-add-m"
                />
                <Skeleton Elem="div" width="lg" height="sm" flushBottom />
            </GridCol>
            <GridCol size={4}>
                <Skeleton
                    Elem="div"
                    width="sm"
                    height="sm"
                    extraClasses="skeleton-add-m"
                />
                <Skeleton Elem="div" width="lg" height="sm" flushBottom />
            </GridCol>
            <GridCol size={2}>
                <Skeleton width="lg" height="lg" />
            </GridCol>
        </Grid>
    );
}
