import React from 'react';
import Translate from '../context';

/**
 * HOC to add context consumer for i18n control
 * @func withTranslations
 * @param {object} Component - react component
 */

export default function withTranslations(Component) {
    return function WithTranslations(props) {
        return <Translate.Consumer>{(translate) => <Component {...props} translate={translate} />}</Translate.Consumer>;
    };
}
