import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function RebookingStartedBy(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('rebooking-started-by.order')} #{ item.parameters.source_order_id }
        </div>
    );
}

RebookingStartedBy.propTypes = {
    item: PropTypes.object.isRequired,
};
