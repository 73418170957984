import React, {
    useContext,
    useState,
    useRef,
    useEffect,
    useCallback,
} from 'react';
import {
    Popup,
    PopupSection,
    Button,
    Heading,
    Link,
    Tooltip,
    Spinner,
} from '@flixbus/honeycomb-react';
import { Icon, IconSeatFree, IconNewTab } from '@flixbus/honeycomb-icons-react';
import SeatAssignmentPaxList from './SeatAssignmentPaxList';
import { TranslateContext } from '../System/Translations';
import { NotifyContext } from '../NotificationSystem/context';
import apiPaxBank from '../../api/PaxBankApi';
import apiRebookr from '../../api/RebookrApi';

import './SeatAssignmentDialog.scss';

const CANCEL_TOKEN = 'SEAT_ASSIGNMNET_REQUEST';
const SEAT_MAP_URL = 'https://anthill.ant.flix.tech/vehicle-layout/{rideId}';
const SEAT_REASSIGN_OBJ = {
    orderId: 0,
    rideUuid: '',
    fromUuid: '',
    toUuid: '',
    ticketUuid: '',
    seatReservationProductUuid: '',
    seatCategory: '',
    newSeatUuid: '',
    label: '',
    coach: '',
    seatReservations: [],
};
const ALLOWED_CATEGORIES = ['empty_seat', 'premium_seat'];
export const SORT_BY_SCOPE = 3;

export default function SeatAssignmentDialog(props) {
    const { rideUuid, selectedPax, orders, ordersReFetch, resetPax, isTr } =
        props;

    const t = useContext(TranslateContext);
    const notify = useContext(NotifyContext);
    const isOnlyOnePaxSelected = selectedPax ? selectedPax.length === 1 : false;

    const [isOpen, setOpen] = useState(false);
    const [isDecks, setIsHasDecks] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [currentOrder, setCurrentOrder] = useState();
    const [passengers, setPassengers] = useState([]);
    const [seatsRequest, setSeatsRequest] = useState([]);
    const [seatSelection, setSeatSelection] = useState([]);

    const [availableSeats, setAvailableSeats] = useState([]);
    const [availableCoaches, setAvailableCoaches] = useState([]);

    const cachedSeats = useRef([]);
    const cachedCoaches = useRef([]);

    const [coachesMap, setCoachesMap] = useState({});

    const [message, setMessage] = useState('');

    function onClose() {
        setMessage('');
        setAvailableSeats([]);
        setSeatSelection([]);
        cachedSeats.current = [];
        cachedCoaches.current = [];
        setOpen(false);
    }

    function onSelect(
        seatId,
        ticketId,
        seatReservations,
        selectedCoach,
        isRemove
    ) {
        const seats = selectedCoach
            ? availableCoaches[selectedCoach]
            : availableSeats;
        const newSeat = seats.find((seat) => seat.id === seatId);

        let productId = seatReservations.find(
            (seatRes) => seatRes.ticket_id === ticketId
        )?.seat_reservation_product_uuid;

        let noProductId = false;
        const newSeatSelection = {
            ...seatSelection,
        };

        if (!productId) {
            noProductId = true;
            productId = ticketId;
        }

        // do not update if previously selected
        if (!newSeatSelection[productId]) {
            newSeatSelection[productId] = {};
        }

        if (isRemove) {
            delete newSeatSelection[productId];
        } else if (newSeat) {
            newSeatSelection[productId] = {
                ...newSeat,
                ticketId,
                noProductId,
            };
        }

        setSeatSelection(newSeatSelection);

        if (seats?.length) {
            // mark seat disabled on selection
            const filteredSeats = [...seats].map((availableSeat) => {
                const selectedSeats = Object.keys(newSeatSelection);
                if (
                    selectedSeats.find(
                        (id) => newSeatSelection[id]?.id === availableSeat?.id
                    )
                ) {
                    return { ...availableSeat, disabled: true };
                } else {
                    return { ...availableSeat, disabled: false };
                }
            });

            // update seats dropdowns options
            if (selectedCoach) {
                const filteredCoaches = [...availableCoaches];
                filteredCoaches[selectedCoach] = filteredSeats;
                setAvailableCoaches(filteredCoaches);
            } else {
                setAvailableSeats(filteredSeats);
            }
        }
    }

    function confirmSelection() {
        const reassignment = [];

        Object.keys(seatSelection).forEach((productId) => {
            const { id, label, position, ticketId, category, noProductId } =
                seatSelection[productId];
            const requestObj = { ...seatsRequest };

            requestObj.newSeatUuid = id;
            requestObj.seatReservationProductUuid = noProductId
                ? null
                : productId;
            requestObj.label = label;
            requestObj.coach = position.deck || '';
            requestObj.ticketUuid = ticketId;
            requestObj.seatCategory = ALLOWED_CATEGORIES.includes(category)
                ? category
                : isTr
                ? ALLOWED_CATEGORIES[1]
                : ALLOWED_CATEGORIES[0]; // For TR rides default is premium_seat

            delete requestObj.seatReservations;

            reassignment.push(requestObj);
        });

        if (reassignment.length > 0) {
            setIsLoading(true);
            apiRebookr
                .post(`api/seat/reassignment`, reassignment, {
                    cancelTokenId: CANCEL_TOKEN,
                })
                .then((res) => {
                    if (res.body.length > 0) {
                        res.body.forEach((item) => {
                            if (item.status !== 'OK') {
                                notify({
                                    type: 'danger',
                                    message: item.message || t('common.error'),
                                });
                                return;
                            }
                            notify({
                                type: 'success',
                                message: t(
                                    'reservations.table.seat_reassignment_success'
                                ),
                            });
                        });
                    }

                    setIsLoading(false);
                    resetPax();
                    ordersReFetch(600);
                    onClose();
                })
                .catch((e) => {
                    setIsLoading(false);
                    notify({
                        type: 'danger',
                        message:
                            e?.response?.data?.error +
                                ' - ' +
                                e?.response?.data?.errorMsg ||
                            e?.message ||
                            'Something goes wrong',
                    });
                });
        }
    }

    function sortTopByScore(arr, amount) {
        const sorted = [...arr].sort((a, b) => b.score - a.score);
        const topByAmount = sorted.slice(0, amount);

        // Combine the top "amount" items with the rest of the original array
        const rest = arr.filter((item) => !topByAmount.includes(item));

        return [...topByAmount, ...rest];
    }

    function seatsSort(a, b) {
        // Extract numeric part
        const numA = parseInt(a.label);
        const numB = parseInt(b.label);

        // Extract alphabetical part
        const alphaA = a.label.replace(/[0-9]/g, '');
        const alphaB = b.label.replace(/[0-9]/g, '');

        if (numA !== numB) {
            return numA - numB;
        }

        if (alphaA !== alphaB) {
            return alphaA.localeCompare(alphaB);
        }

        return 0;
    }

    const groupByCoach = useCallback((array, reservations) => {
        let grouped = {};
        const coachMap = {};

        array.forEach((obj) => {
            const coachNumber = obj.seat_details.position.deck;
            const coachLabel = obj.seat_details.vehicleLabel;

            if (!grouped[coachNumber]) {
                grouped[coachNumber] = [];
            }

            const seat = {
                label: obj.seat_details.label,
                id: obj.seat_id,
                category: obj.seat_details.category,
                gender: obj.gender_allowance,
                position: obj.seat_details.position,
                score: obj.score,
                reservations,
            };

            coachMap[coachNumber] = coachLabel;
            grouped[coachNumber].push(seat);
        });

        setCoachesMap(coachMap);

        grouped = Object.keys(grouped).map((coach) => {
            const sorted = grouped[coach].sort(seatsSort);
            grouped[coach] = sortTopByScore(sorted, SORT_BY_SCOPE);

            return grouped[coach];
        });

        return grouped;
    }, []);

    // Select passengers to show in seat selections
    useEffect(() => {
        if (isOnlyOnePaxSelected) {
            const order = orders.find((order) => selectedPax[0] === order.id);
            setCurrentOrder(order);
            const paxs = order?.passengers;
            if (paxs?.length > 0) {
                setIsHasDecks(paxs.some((pax) => pax.seat_type.deck));
                setPassengers(paxs);
            }
        }
    }, [isOnlyOnePaxSelected, selectedPax, orders]);

    // Get order seats
    useEffect(() => {
        function getOrderSeats(orderId) {
            apiPaxBank
                .get(
                    `api/rideviewer/ride/${rideUuid}/reservations/availableSeats/${orderId}`,
                    {
                        cancelTokenId: CANCEL_TOKEN,
                    }
                )
                .then((res) => {
                    if (res.seat_availability_list.length <= 0) {
                        notify({
                            type: 'warning',
                            message: t(
                                'reservations.table.seat_assignment_no_seat_res'
                            ),
                        });
                        setMessage(
                            t('reservations.table.seat_assignment_no_seat_res')
                        );
                    } else {
                        const seatReassign = { ...SEAT_REASSIGN_OBJ };

                        seatReassign.orderId = res.order_id;
                        seatReassign.fromUuid = res.from_uuid;
                        seatReassign.rideUuid = res.ride_uuid;
                        seatReassign.toUuid = res.to_uuid;
                        seatReassign.seatReservations = res.seat_reservations;

                        if (isDecks) {
                            // Grouping the array by the "deck" property
                            const groupedByCoach = groupByCoach(
                                res.seat_availability_list,
                                res.seat_reservations
                            );

                            cachedCoaches.current = groupedByCoach;
                            setAvailableCoaches(groupedByCoach);
                        } else {
                            // Create array of seats if there is no decks
                            const seats = [];

                            res.seat_availability_list.map((seat) => {
                                seats.push({
                                    label: seat.seat_details.label,
                                    id: seat.seat_id,
                                    category: seat.seat_details.category,
                                    gender: seat.gender_allowance,
                                    position: seat.seat_details.position,
                                    score: seat.score,
                                    reservations: res.seat_reservations,
                                    disabled: false,
                                });

                                return null;
                            });

                            const sorted = seats.sort(seatsSort);
                            const sortedByScore = sortTopByScore(
                                sorted,
                                SORT_BY_SCOPE
                            );

                            cachedSeats.current = sortedByScore;
                            setAvailableSeats(sortedByScore);
                        }

                        setSeatsRequest(seatReassign);
                    }
                })
                .catch((e) => {
                    if (!apiPaxBank.isCancel(e)) {
                        notify({
                            type: 'danger',
                            message: e.toString(),
                        });
                    }
                });
        }

        if (currentOrder && currentOrder.extras.ghosted === true) {
            setMessage(
                t('reservations.table.seat_assignment_no_for_ghost_orders')
            );

            return;
        }

        if (isOnlyOnePaxSelected && isOpen) {
            setMessage('');
            setAvailableSeats([]);
            setSeatSelection([]);
            getOrderSeats(selectedPax[0]);
        }
    }, [
        rideUuid,
        isOpen,
        isOnlyOnePaxSelected,
        selectedPax,
        passengers,
        notify,
        t,
        groupByCoach,
        isDecks,
        currentOrder,
    ]);

    // track state if value changed abort pending request
    useEffect(() => {
        apiPaxBank.cancel(CANCEL_TOKEN);
        apiRebookr.cancel(CANCEL_TOKEN);
    }, [isOpen]);

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
        condition ? wrapper(children) : children;

    return (
        <React.Fragment>
            <span
                className={'timeline-toolbar-icon-button'}
                onClick={() => {
                    if (isOnlyOnePaxSelected) {
                        setOpen(true);
                    }
                }}
            >
                <ConditionalWrapper
                    condition={
                        selectedPax.length <= 0 || selectedPax.length > 1
                    }
                    wrapper={(children) => (
                        <Tooltip
                            id="seat_assignment__tooltip"
                            content={
                                selectedPax?.length === 0
                                    ? t('reservations.table.select_pax')
                                    : selectedPax.length > 1
                                    ? t(
                                          'reservations.table.seat_assignment_only_one'
                                      )
                                    : null
                            }
                            hasClose
                            position={'bottom'}
                            openOnHover={true}
                            closeBtn={{ 'aria-label': 'Close me' }}
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    <Icon
                        appearance="primary"
                        InlineIcon={IconSeatFree}
                        title={t('reservations.table.seat_assignment')}
                    />
                    &nbsp;
                    <span>{t('reservations.table.seat_assignment')}</span>
                </ConditionalWrapper>
            </span>

            <Popup active={isOpen} extraClasses="seats-popup">
                {isLoading ? (
                    <PopupSection>
                        <Spinner />
                    </PopupSection>
                ) : (
                    <>
                        <PopupSection type="icon">
                            <Icon
                                InlineIcon={IconSeatFree}
                                size={12}
                                appearance="primary"
                                title="It's beer-o-clock"
                            />
                        </PopupSection>
                        <PopupSection type="title">
                            <Heading id="accessible-popup-title" size={3}>
                                {t('reservations.table.edit_seat_assignment')}
                            </Heading>
                            <Link
                                target="_blank"
                                extraClasses="seat-assignment-dialog__link"
                                href={SEAT_MAP_URL.replace(
                                    '{rideId}',
                                    rideUuid
                                )}
                            >
                                {t(
                                    'reservations.table.seat_assignment_open_map'
                                )}{' '}
                                <Icon
                                    InlineIcon={IconNewTab}
                                    aria-label="Opens in a New Tab"
                                    extraClasses="seat-assignment-dialog__link-icon"
                                />
                            </Link>
                        </PopupSection>
                        <PopupSection type="content">
                            <div
                                className={
                                    coachesMap &&
                                    Object.keys(coachesMap).length > 0
                                        ? 'seat-assignment-dialog__seats'
                                        : 'seat-assignment-dialog__seats seat-assignment-dialog__seats--not-train'
                                }
                            >
                                {Object.entries(availableCoaches).length > 0 ||
                                availableSeats.length > 0 ? (
                                    <SeatAssignmentPaxList
                                        passengers={passengers}
                                        availableCoaches={availableCoaches}
                                        availableSeats={availableSeats}
                                        coachesMap={coachesMap}
                                        onSelect={onSelect}
                                        seatSelection={seatSelection}
                                    />
                                ) : (
                                    message || 'Loading...'
                                )}
                            </div>
                        </PopupSection>
                        <PopupSection type="actions">
                            <Button onClick={onClose}>
                                {t('reservations.table.seat_assignment_close')}
                            </Button>
                            <Button
                                appearance="primary"
                                onClick={confirmSelection}
                                disabled={
                                    !Object.keys(seatSelection).length > 0
                                }
                            >
                                {t(
                                    'reservations.table.seat_assignment_confirm'
                                )}
                            </Button>
                        </PopupSection>
                    </>
                )}
            </Popup>
        </React.Fragment>
    );
}
