const USER_RULES = {
    cc_agent_internal: [
        'ride_cancelation',
        'settings_panel',
        'lock_all_seats',
        'adding_had',
        'driver_app_message',
        'reopen_close_station',
        'adding_tag',
        'send_email',
        'send_sms',
        'change_ride_status',
    ],
    cc_agent_external: [],
    tc_agent_internal: [
        'ride_cancelation',
        'settings_panel',
        'lock_all_seats',
        'adding_had',
        'driver_app_message',
        'reopen_close_station',
        'adding_tag',
        'send_email',
        'send_sms',
        'enable_PAX_distribution',
        'change_ride_status',
    ],
    shop_station_employee: [
        'adding_had',
        'driver_app_message',
        'adding_tag',
        'reopen_close_station',
    ],
    kk_shop_station_employee: [
        'adding_had',
        'driver_app_message',
        'adding_tag',
        'reopen_close_station',
    ],
};

const USER_ROLE_TITLES = {
    cc_agent_internal: 'CC agent int',
    cc_agent_external: 'CC agent ext',
    tc_agent_internal: 'TC agent',
    shop_station_employee: 'Shop/station agent',
    kk_shop_station_employee: 'KK shop/station agent',
};

const USER_ACTION_TITLES = {
    ride_cancelation: 'Cancel ride',
    settings_panel: 'Change settings',
    lock_all_seats: 'Lock seats',
    adding_had: 'HAD',
    driver_app_message: 'Driver app message',
    reopen_close_station: 'Change station status',
    adding_tag: 'Tags management',
    send_email: 'Send email',
    send_sms: 'Send sms',
    enable_PAX_distribution: 'Distribute PAX',
    change_ride_status: 'Change ride status',
};

export default USER_RULES;
export { USER_ROLE_TITLES, USER_ACTION_TITLES };
