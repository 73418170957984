import React, { useMemo, useState } from 'react';
import HadFormContext from './HadFormContext';

class HadForm {
    constructor(timeline) {
        this.timeline = timeline;
        this.setStops(timeline);
    }

    updateTimeline(timeline) {
        this.timeline = timeline;
        this.setStops(timeline);
    }

    setStops(timeline) {
        this.stops = timeline.reduce((acc, item) => {
            const result = { ...acc };
            if (item.entry_type === 'stop') {
                result[item.uuid] = {
                    arrival_delay: item.arrival_delay,
                    departure_delay: item.departure_delay,
                    sequence: item.sequence,
                    arrival: item.arrival,
                    departure: item.departure,
                    time_zone: item.time_zone,
                    incoming_segment_id: item.incoming_segment_id,
                    outgoing_segment_id: item.outgoing_segment_id,
                };
            }
            return result;
        }, {});
        return this;
    }

    setDelayArrival(id, value) {
        this.stops[id].arrival_delay = value;
    }

    setDelayDeparture(id, value) {
        this.stops[id].departure_delay = value;
    }
}

export default function HADProvider({ children, timeline }) {
    const [isOpen, setOpen] = useState(false);
    const had = useMemo(() => new HadForm(timeline), [timeline]);

    return <HadFormContext.Provider value={{ form: had, isOpen, setOpen }}>{children}</HadFormContext.Provider>;
}
