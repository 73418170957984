import { useState, useCallback } from 'react';
import api from '../../api/CommentsBankApi';

export default function useHighlightComment(referenceId, isHighlighted) {
    const [highlightUpdated, setHighlightUpdated] = useState(isHighlighted);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const highlightComment = useCallback(() => {
        setHighlightUpdated(null);
        setSaving(true);
        return api
            .put(`/highlight/${referenceId}?isHighlighted=${!highlightUpdated}`)
            .then(() => {
                setHighlightUpdated(!highlightUpdated);
                setError(null);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setSaving(false);
            });
    }, [referenceId, highlightUpdated]);

    return {
        saving,
        error,
        highlightUpdated,
        highlightComment,
    };
}
