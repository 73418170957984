import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@flixbus/honeycomb-react';
import { Icon, IconExpand, IconCollapse } from '@flixbus/honeycomb-icons-react';

export default function SelectionAndExpand(props) {
    const { selectRides, expandRides, expanded } = props;
    const [selectAll, setSelectAll] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const select = () => {
        setSelectAll(!selectAll);
        selectRides(!selectAll);
    };
    const expand = () => {
        setExpandAll(!expandAll);
        expandRides(!expandAll);
    };
    const expandIcon = expanded ? IconCollapse : IconExpand;

    return (
        <>
            <Icon
                InlineIcon={expandIcon}
                onClick={expand}
                extraClasses="expand-results-toggle"
            />
            <Checkbox
                extraClasses="select-results-checkbox"
                aria-label="select all"
                id="select-results-checkbox"
                onChange={select}
                value=""
                small
            />
        </>
    );
}

SelectionAndExpand.propTypes = {
    selectResults: PropTypes.func,
    expandRides: PropTypes.func,
    expanded: PropTypes.bool,
};
SelectionAndExpand.defaultProps = {
    selectResults: () => {},
    expandRides: () => {},
    expanded: false,
};
