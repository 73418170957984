import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, GridCol } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';

export default function TotalInfoTr(props) {
    const { orders } = props;
    const [revenueByCurrencies, setRevenueByCurrencies] = useState('');
    const [totalPax, setTotalPax] = useState(null);

    const translate = useContext(TranslateContext);

    useEffect(() => {
        const totalInfo = orders.reduce((acc, order) => {
            const result = { ...acc };

            result.pax += order.passengers.length;

            order.passengers.forEach(pax => {
                const currency = pax.currency;

                if(result.revenue[currency]) {
                    result.revenue[currency] += pax.revenue;
                } else {
                    result.revenue[currency] = pax.revenue;
                }
            })

            return result;
        }, { pax: 0, revenue: {} })

        setTotalPax(totalInfo.pax);

        const totalRevenueByCurrencies = Object.entries(totalInfo.revenue)
            .reduce((acc, [currency, value]) => {
                return `${acc} ${new Intl.NumberFormat(
                    'tr', 
                    { style: 'currency', currency: currency }
                ).format(value)},`
            }, '').slice(0, -1)

        setRevenueByCurrencies(totalRevenueByCurrencies);
    }, [orders])

    return (
        <Grid>
            <GridCol size={2}>
                <Box extraClasses="reservation-total-info">
                    <div className="reservation-total-info__item">
                        <strong>
                            {translate('reservations.total.passengers')}
                            :
                            &nbsp;
                        </strong>
                        {totalPax}
                    </div>
                    <div className="reservation-total-info__item">
                        <strong>
                            {translate('reservations.total.revenue')}
                            :
                            &nbsp;
                        </strong>
                        {revenueByCurrencies}
                    </div>
                </Box>
            </GridCol>
        </Grid>
    );
}

TotalInfoTr.propTypes = { orders: PropTypes.array };
TotalInfoTr.defaultProps = { orders: [] };
