/**
 * config for auth microsoft
 * @constant
 * @name msalConfig
 */

export const clientId = '1d5f07df-1aa5-4c06-8090-ec86c50e23ce';
export const uniqueId = 'd8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5';
export const msalConfig = {
    auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${uniqueId}`,
        redirectUri: '/blank',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
    system: {
        allowRedirectInIframe: true,
    },
};

export const scopes = [
    'https://einfachbusfahren.onmicrosoft.com/ac3d2430-f9a9-4c2d-9a82-7c0806da725c/user_impersonation',
];

export const msalPopupRequest = {
    scopes,
    authority: msalConfig.authority,
};

export const msalSilentRequest = {
    scopes,
    authority: msalConfig.authority,
};

export const megadriveNewApplication = {
    scopes: [
        'https://einfachbusfahren.onmicrosoft.com/3413a545-d0a9-4cc6-a929-2df63d4eb29f/API.ReadWrite',
    ],
    authority: msalConfig.authority,
};

export const actionLoggerApplication = {
    scopes: [
        'https://einfachbusfahren.onmicrosoft.com/e14ba164-a4e4-405e-8fe8-c8fb0cc702d0/API.ReadWrite',
    ],
    authority: msalConfig.authority,
};

export const customerNotifierV2Application = {
    scopes: [
        'https://customer-notifier2-ops-services-public.ew1p1.k8s.flix.tech/API.ReadWrite',
    ],
    authority: msalConfig.authority,
};

export const megadriveApplication = {
    scopes: [
        'https://einfachbusfahren.onmicrosoft.com/8879a802-8ae0-454b-8c5f-8531bd5b3b7c/API.ReadWrite',
    ],
    authority: msalConfig.authority,
};

export const commentsBankApplication = {
    scopes: [
        'api://fxt.ops.portal.commentsbank.prod.flix.tech/Perform.commentsbank',
    ],
};

export const paxBankApplication = {
    scopes: ['api://243aa2df-82d6-4b66-853d-01cd73471345/Perform.Paxbank'],
};

export const tagsBankApplication = {
    scopes: ['api://fxt.ops.portal.tagsbank.prod.flix.tech/Perform.Tagsbank'],
};

export const rebookrApplication = {
    scopes: ['api://e880ea1c-1789-487d-becf-51ba303d899e/Perform.Rebooking'],
};

export const fireflyScope = {
    scopes: ['api://fxt.ops.train.firefly/API.ReadWrite'],
};
