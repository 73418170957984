/**
 * Matches search entry with value of key in an object of array item
 * @func autocompleteProcessor
 * @param {string} search string to search matches with
 * @param {string} key key of object to match in
 * @param {array} data the list filters and sort matches
 * @returns {array} array with items
 */

function accentedNormalize(input) {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
function regExpNormalize(input) {
    return input.replace(/(\[|\]|\(|\)|\*|\.|\^|\{|\}|\+|\?|\\)/g, '\\$1');
}

const defaultKey = 'title';

const defaultOptions = {
    regExpFn: (search) => `(?:\\b|\\s)${search}`,
    sortByIndex: false,
};

export default function autocompleteProcessor(search, key, data, options = {}) {
    const opt = { ...defaultOptions, ...options };
    const searchNormalize = regExpNormalize(search);
    const accentedFreeSearch = accentedNormalize(searchNormalize);
    const expNorm = new RegExp(opt.regExpFn(accentedFreeSearch), 'i');

    const result = data.reduce((acc, item) => {
        const result = [...acc];
        const valueToSearch =
            typeof item[key] === 'undefined' ? item[defaultKey] : item[key];
        const searchValueNorm = accentedNormalize(valueToSearch);
        const searchIndexNorm = searchValueNorm.search(expNorm);

        if (searchIndexNorm !== -1) {
            const patchItem = { ...item, index: searchIndexNorm };
            result.push(patchItem);
        }

        return result;
    }, []);
    let sortResult = result.sort((a, b) => {
        const res = b.sortTitle > a.sortTitle;
        return res ? -1 : 1;
    });
    if (opt.sortByIndex) {
        return sortResult.sort((a, b) => a.index - b.index);
    }
    return sortResult;
}
