import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import { Button, Text } from "@flixbus/honeycomb-react";
import { TranslateContext } from "../System/Translations";
import { CUSTOMER_NOTIFIER_V2_SCOPE, getToken } from "../../auth";

const CUSTOMER_NOTIFIER_V2_PCR_URL = 'https://customer-notifier.bruteforce-prod.flixtech.io/api/custom-pcr';
const CUSTOMER_NOTIFIER_WHATSAPP_URL = 'https://customer-notifier.flixbus.com/send-whatsapp-template';

const openTemplateManagerWithToken = (token) => {
    const url = `${CUSTOMER_NOTIFIER_WHATSAPP_URL}?token=${token}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
        newTab.focus();
    }
}

const SendWhatsappTemplates = ({ selectedPax, rideUuid }) => {
    const translate = useContext(TranslateContext);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const requestToken = async () => {
            const token = await getToken(CUSTOMER_NOTIFIER_V2_SCOPE);
            setToken(token);
        };

        requestToken();
    }, []);

    const handleOpenWhatsappClick = () => {
        const preparedData = {
            rideId: rideUuid,
            orders: selectedPax.map(pax => String(pax)),
        }

        if(token) {
            axios.post(CUSTOMER_NOTIFIER_V2_PCR_URL, preparedData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    openTemplateManagerWithToken(response.data.id);
                })
                .catch(err => {
                    console.log('Error happened while saving orders:', err)
                })
        }
    }

    return (
        <>
            <Text>{translate('send_whatsapp.description')}</Text>
            <Button
                disabled={!selectedPax.length}
                appearance="primary"
                onClick={handleOpenWhatsappClick}
            >
                {translate('send_whatsapp.cta-button')}
            </Button>
        </>
    )
}

export default SendWhatsappTemplates;