import React, { useContext, useState } from 'react';
import {
    Autocomplete,
    AutocompleteOptions,
    Input,
} from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';
import cancelationReasons from './cancellationReasons';
import { queryNormalize } from '../../../util/queryNormalize';

export default function ReasonsSelector(props) {
    const { onSelect: onSelectProp } = props;
    const translate = useContext(TranslateContext);
    const [value, setValue] = useState('');

    const reasons = Object.entries(cancelationReasons)
        .map(([id, reason]) => ({
            value: id,
            title: translate(`ride-cancellation.reasons.${reason}`),
        }))
        .sort((a, b) => a.title.localeCompare(b.title));
    const [options, setOptions] = useState([]);

    function onFocus() {
        if (value === '') {
            setOptions(reasons);
        }
    }
    function onSelect(option) {
        setOptions([]);
        setValue(option.title);
        onSelectProp(Number(option.value));
    }
    function onSearch(e) {
        const query = e.target.value;
        const filteredOptions = reasons.filter((option) => {
            const normalizedQuery = queryNormalize(query);
            const normalizedOption = queryNormalize(option.title);
            const regExp = new RegExp(normalizedQuery, 'i');
            return normalizedOption.search(regExp) === 0;
        });
        setOptions(filteredOptions);

        if (query === '') {
            onSelectProp(null);
        }
    }
    function onReset() {
        setValue('');
        setOptions([]);
        onSelectProp(null);
    }
    return (
        <Autocomplete
            options={options}
            onDebounce={onSearch}
            onSelect={onSelect}
            onFocus={onFocus}
            onReset={onReset}
            value={value}
        >
            <Input
                label={translate('ride-cancellation.reasons.label')}
                id="ride-cancellation-autocomplete-input"
                placeholder={translate('ride-cancellation.reasons.placeholder')}
                type="search"
                autoComplete="off"
                info={translate('ride-cancellation.reasons.required')}
            />
            <AutocompleteOptions
                label={translate('ride-cancellation.reasons.label-options')}
                optionHasSubtitle={false}
            />
        </Autocomplete>
    );
}
