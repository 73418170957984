import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function TemplateItem(props) {
    const { title, content, uid, onSelect } = props;
    const translate = useContext(TranslateContext);
    return (
        <div className="template-item">
            {title ? title : `No title - ${content.slice(0, 50)}...`}
            <button
                type="button"
                className="use-button"
                onClick={() => onSelect({ title, content, uid })}
            >
                {translate('notifier.dialog.use_template')}
            </button>
        </div>
    );
}
TemplateItem.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    uid: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
};
TemplateItem.defaultProps = {
    title: '',
    content: 'No content',
    uid: '',
};
