import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@flixbus/honeycomb-react';
import { Icon, IconPersonSolid } from '@flixbus/honeycomb-icons-react';

export default function StationPaxDiff(props) {
    const { paxIn, paxOut } = props;
    return (
        <div className="timeline-station-pax-diff">
            <Tag small outlined>
                -{paxOut}
                &nbsp;
                <Icon
                    size={2}
                    appearance="primary"
                    InlineIcon={IconPersonSolid}
                />
            </Tag>
            <Tag extraClasses="timeline-station-pax-diff__in" small outlined>
                +{paxIn}
                &nbsp;
                <Icon
                    size={2}
                    appearance="primary"
                    InlineIcon={IconPersonSolid}
                />
            </Tag>
        </div>
    );
}
StationPaxDiff.propTypes = {
    paxIn: PropTypes.number.isRequired,
    paxOut: PropTypes.number.isRequired,
};
