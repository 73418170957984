import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tag, Button, Grid, GridCol } from '@flixbus/honeycomb-react';
import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../System/Translations';

export default function FormDataCloud(props) {
    const { tags, reset } = props;
    const translate = useContext(TranslateContext);
    return (
        <Grid align="top">
            <GridCol size={12}>
                {tags.map((tag) => (
                    <Tag
                        type="button"
                        Elem="button"
                        outlined
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                        key={`${tag.key}-${tag.type}`}
                        onClick={() => {
                            tag.delete(tag.value);
                        }}
                    >
                        {tag.title}
                        &nbsp;
                        <Icon appearance="primary" InlineIcon={IconClose} />
                    </Tag>
                ))}
                {tags.length > 0 && (
                    <Button
                        link
                        style={{ marginBottom: '10px' }}
                        onClick={reset}
                    >
                        {translate('ride-search.form.reset-all')}
                    </Button>
                )}
            </GridCol>
        </Grid>
    );
}
FormDataCloud.propTypes = {
    tags: PropTypes.array,
    onDelete: PropTypes.func,
    onReset: PropTypes.func,
};
FormDataCloud.defaultProps = {
    tags: [],
    onDelete: () => {},
    onReset: () => {},
};
