import { useContext, useEffect, useState } from 'react';
import TranslateContext from '../../System/Translations/context';
import * as FILTERS from '../SearchForm/formMap';

function generateMap(t) {
    return {
        [FILTERS.LINE]: t('ride-search.line-input-label'),
        [FILTERS.TAGS]: t('ride-search.tag-input-label'),
        [FILTERS.EXCLUDE_TAGS]: t('ride-search.tag-exclude-input-label'),
        [FILTERS.TRIP_NUMBER]: t('ride-search.trip-number-input-label'),
        [FILTERS.PARTNERS]: t('ride-search.partners-input-label'),
        [FILTERS.CONCESSION_OWNERS]: t('ride-search.concession-owner-label'),
        [FILTERS.DATE]: t('ride-search.date-input-label'),
        [FILTERS.STOP_DEPARTURE]: t('ride-search.departure-station.label'),
        [FILTERS.STOP_ARRIVAL]: t('ride-search.arrival-station.label'),
        [FILTERS.STOP_PASSING_THROUGH]: t(
            'ride-search.stop-passing-through-input-label'
        ),
        [FILTERS.PLATE_NUMBER]: t('ride-search.license-plate.label'),
        [FILTERS.DEPTIME]: t('ride-search.time-dep-input-label'),
        [FILTERS.ARRTIME]: t('ride-search.time-arr-input-label'),
        [FILTERS.STATUSES]: t('ride-search.status.label'),
        [FILTERS.WEEKDAYS]: t('ride-search.weekdays.label'),
        [FILTERS.TIMEZONE]: t('ride-search.timezone.label'),
        [FILTERS.BUS_NUMBER]: t('ride-search.busnumber.label'),
        [FILTERS.BUS_IDS]: t('ride-search.busid.label'),
    };
}
/**
 * Hook that provides mapping filters name with translated labels
 * @function useFiltersMap
 * @returns {object} map of filters {[name]: label} list
 */
export default function useFiltersMap() {
    const t = useContext(TranslateContext);
    const [fm, setFm] = useState(generateMap(t));

    useEffect(() => {
        // we have to wait for download an update translations
        /**
         * @TODO
         * update translate system that will be handle it
         * out of box or with event modeling
         */
        const timeout = setTimeout(() => {
            setFm(generateMap(t));
        }, 250);
        return () => {
            clearTimeout(timeout);
        };
    }, [t, t.language, setFm]);

    return fm;
}
