export const DEFAULT_FILTERS = Object.freeze({
    delays: ['big_delay', 'delay_log', 'manual_delay_log'],
    station_status: ['close_station', 'open_station', 'cancel_station'],
    ride_status: [
        'ride_status_changed',
        'ride_bus_paired',
        'ride_cancellation',
    ],
    tickets_actions: [
        'open_tickets',
        'close_tickets',
        'open_ticket_rebook_and_checkin',
    ],
    passengers: ['checkin'],
    drivers: ['driver_added', 'driver_removed'],
    rebookings: [
        'rebooking_started_by',
        'rebookr_rebooking_from_ride',
        'rebookr_rebooking_to_ride',
        'rebookr_rebooking_error',
        'seat_refund',
        'rebookr2_rebooking_error',
        'rebookr2_rebooking_from_ride',
        'rebookr2_rebooking_to_ride',
    ],
    notifications: [
        'deferred_notification_queued',
        'deferred_notification_processed',
        'delay_notification_scheduled',
        'delay_notification_triggered',
        'notification_antispam',
        'ride_station_time_changed',
        'stop_relocation',
    ],
    ride_tags: [
        'ride_tag_added',
        'ride_tag_removed'
    ],
    ride_concept_change: [
        'ride_concept_change',
        'ghost_order_detected'
    ]
});
