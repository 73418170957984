export class LocalStorage {
    constructor(storage) {
        this.storage = storage || window.localStorage;
    }

    LANGUAGE = 'rideviewer.language';
    BATCH_CAPACITY = 'rideviewer.batch-capacity';

    setItem(item, value) {
        try {
            return this.storage.setItem(item, value);
        } catch {
            return undefined;
        }
    }

    getItem(item) {
        try {
            return this.storage.getItem(item);
        } catch {
            return undefined;
        }
    }

    removeItem(item) {
        try {
            return this.storage.removeItem(item);
        } catch {
            return undefined;
        }
    }

    clear() {
        try {
            return this.storage.clear();
        } catch {
            return undefined;
        }
    }
}

export default new LocalStorage();
