import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function LeaveTheRide(props) {
    const { item } = props;
    const user = item.user ? item.user.fullName : item.parameters.user;
    const content = (user !== '') ? <strong>{ user }</strong> : [];
    const translate = useContext(TranslateContext);

    return (
        <div>
            {translate('leave-the-ride')} { content }
        </div>
    );
}

LeaveTheRide.propTypes = {
    item: PropTypes.object.isRequired,
};
