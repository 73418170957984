import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function Checkin(props) {
    const { item } = props;
    let content;

    const translate = useContext(TranslateContext);

    const openTicketAddon = (item.action === 'open_ticket_rebook_and_checkin')
        ? <strong><br /> {translate('checkin.open-ticket-rule-was-applied')}</strong> : '';

    if (item.parameters.passenger) {
        content = (
            <div>
                {translate('checkin.passenger-status')}{' '}
                <strong>{ item.parameters.status }</strong> for{' '}
                <strong>{ item.parameters.passenger.first_name } { item.parameters.passenger.last_name }</strong>
                { openTicketAddon }
            </div>);
    } else {
        content = (
            <div>
                {translate('checkin.passenger-status')}{' '}
                <strong>{ item.parameters.status }</strong> for{' '}
                <strong>{translate('checkin.passenger-not-found')} [{ item.parameters.id }]</strong>
                { openTicketAddon }
            </div>);
    }

    return content;
}

Checkin.propTypes = {
    item: PropTypes.object.isRequired,
};
