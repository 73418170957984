import React, { useContext, useEffect, useState } from "react";
import { ACTION_LOGGER_SCOPE, getToken } from "../../auth";
import { TranslateContext } from "../System/Translations";

const ActionLoggerBeta = ({ rideId }) => {
    const [token, setToken] = useState();
    const { language: initialLanguage } = useContext(TranslateContext);

    useEffect(() => {
        const requestToken = async () => {
                const token = await getToken(ACTION_LOGGER_SCOPE);
                setToken(token);
        };

        requestToken();
    }, []);

    useEffect(() => {
        console.log('token', token)
    }, [token])

    return token ? (
        <action-logger props={JSON.stringify({ token, rideId, initialLanguage })} />
    ) : null;
}

export default ActionLoggerBeta;