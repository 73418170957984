import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DelayTimesetChanges from '../DelayTimesetChanges';
import { TranslateContext } from '../../../System/Translations';

export default function DelayAdded(props) {
    const { item } = props;
    const delayTimeset = item.parameters.new_delay_time_set ?
        <DelayTimesetChanges timeset={item.parameters.new_delay_time_set} /> : '';
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('delay.removed.confirmed-added')} <strong>{ item.user ? item.user.fullName : item.parameters.user }</strong>
            { delayTimeset }
        </div>
    );
}

DelayAdded.propTypes = {
    item: PropTypes.object.isRequired,
};
