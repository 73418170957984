import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function RideStationTimeChanged(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);

    return (
        <div>
            { item.parameters.stopCode } { item.parameters.stopName }
            &nbsp;
            { item.parameters.newArrivalTime && (
                <span>
                    {translate('ride-station-time-changed.arrival-time-changed')} &nbsp;
                    <nobr>{item.parameters.oldArrivalTime}-->{item.parameters.newArrivalTime}</nobr>
                </span>
            )}
            { item.parameters.newArrivalTime && item.parameters.newDepartureTime ? ', ' : '' }
            { item.parameters.newDepartureTime && (
                <span>
                    {translate('ride-station-time-changed.departure-time-changed')} &nbsp;
                    <nobr>{item.parameters.oldDepartureTime}-->{item.parameters.newDepartureTime}</nobr>
                </span>
            )}
            { !item.parameters.newArrivalTime && !item.parameters.newDepartureTime ? translate('ride-station-time-changed.phrase-one') : '' }.&nbsp;
            { item.parameters.affectedOrdersTotal } {translate('ride-station-time-changed.phrase-two')}
        </div>
    );
}

RideStationTimeChanged.propTypes = {
    item: PropTypes.object.isRequired,
};
