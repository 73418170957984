import { useCallback, useState } from 'react';

const predefinedFilters = {
    status: (order, value) => {
        return order.passengers.some((pax) => pax.checkin_status === value);
    },
    ghost: (order, value) => {
        return order.extras.ghosted === value;
    },
    from: (order, value) => {
        return order.route.from_code === value;
    },
    to: (order, value) => {
        return order.route.to_code === value;
    },
    shop: (order, value) => {
        return order.shop === value;
    },
};

export default function useOrdersFilters(incomeOrders) {
    const [orders, setOrders] = useState(incomeOrders);
    const [filters, setFilters] = useState({});

    const filter = useCallback(() => {
        if (Object.keys(filters).length > 0) {
            const filteredOrders = incomeOrders.filter((order) => {
                return Object.keys(filters).every((key) => {
                    return predefinedFilters[key](order, filters[key]);
                });
            });
            setOrders(filteredOrders);
        } else {
            setOrders(incomeOrders);
        }
    }, [incomeOrders, filters]);

    function reset() {
        setFilters({});
        setOrders(incomeOrders);
    }

    const addFilters = useCallback(
        (inputFilters) => {
            const newFilters = { ...filters };
            Object.keys(inputFilters).forEach((key) => {
                if (inputFilters[key] === null || inputFilters[key] === '') {
                    delete newFilters[key];
                } else {
                    newFilters[key] = inputFilters[key];
                }
            });
            setFilters({ ...newFilters });
        },
        [filters]
    );

    return {
        orders,
        filters,
        addFilters,
        filter,
        reset,
    };
}
