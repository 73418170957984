import { datadogRum } from '@datadog/browser-rum';
import { getUser } from '../auth';

const isProd = process.env.NODE_ENV === 'production';

function setUserData() {
    const {
        profile: { name, roles },
        userName,
    } = getUser();

    datadogRum.setUser({ id: name, email: userName, name, userName, roles });
    datadogRum.startSessionReplayRecording();
}

datadogRum.init({
    applicationId: 'a7197cd9-c897-488f-81e3-9c71400e70e9',
    clientToken: 'puba401ec66d61213e677906707d156148e',
    site: 'datadoghq.eu',
    service: 'fxt.ops.services.ride-viewer-fe',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: isProd ? 100 : 0,
    sessionReplaySampleRate: isProd ? 20 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

const interval = setInterval(() => {
    const userObject = getUser();
    if (userObject !== null && userObject?.profile !== undefined) {
        setUserData();
        clearInterval(interval);
    }
}, 400);

/**
 * Send custom actions
 * https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/?tab=npm
 */
