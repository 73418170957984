import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import api from '../../../../api/Client';
import { TranslateContext } from '../../../System/Translations';

export default function RideBusPaired(props) {
    const { item } = props;
    const BACKEND_URL = api?.config?.backend_host;
    const busUrl = `${BACKEND_URL}inventory/buses/${item.parameters.bus_id}/show`;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('ride-bus-paired.bus-paired-with-ride')}
            <a target="_blank" rel="noopener noreferrer" href={busUrl}>
                {item.parameters.license_plate}
            </a>
        </div>
    );
}

RideBusPaired.propTypes = {
    item: PropTypes.object.isRequired,
};
