import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';
import RideMetaContext from '../../App/RideMeta/context';

function transformResponse(dataJson) {
    const reservations = JSON.parse(dataJson);
    return Object.keys(reservations).reduce((acc, key) => {
        const reservation = { ...reservations[key] };
        reservation.key = key;
        acc.push(reservation);
        return acc;
    }, []);
}

export default function withOrdersApi(Component) {
    return class WithOrdersApi extends React.Component {
        static propTypes = {
            match: PropTypes.object,
            rideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            stopsSequence: PropTypes.shape(),
        };

        static defaultProps = {
            match: undefined,
            rideId: '',
            stopsSequence: {},
        };

        static contextType = RideMetaContext;

        state = {
            orders: [],
            loading: true,
        };

        componentDidMount() {
            this.fetchOrders();
        }

        UNSAFE_componentWillReceiveProps(newProps) {
            /**
             * Check if rideId have changed
             * to fetch data for new ride
             * (e.g.) url params is changed
             */
            // get current props
            const { match, rideId } = this.props;
            // get new props map with non concurrent names
            const { match: newMatch, rideId: newRideId } = newProps;
            // provide compatibility with route match params
            const id = (match && match.params.rideId) || rideId;
            const newId = (newMatch && newMatch.params.rideId) || newRideId;
            if (id !== newId) {
                this.setState({ orders: [] }, () => {
                    this.fetchOrders();
                });
            }
        }

        componentWillUnmount() {
            api.cancel(this.requestUrl);
        }

        get requestUrl() {
            const { match, rideUuid } = this.props;
            // provide compatibility with route match params
            const id = (match && match.params.rideUuid) || rideUuid;
            return `ride/${id}/v2/passengers`;
        }

        /**
         * extract unique stops list from passenger reservations
         * in timeline sequence
         * @method ordersToStopsList
         * @param {string} direction `from` or `to` departure or arrival stops
         * @returns {object} {[code]: {[name]: string}}
         */
        ordersToStopsList(direction) {
            const { orders } = this.state;
            const { stopsSequence } = this.props;

            // sort orders in a stops sequence
            const sortOrders = orders.sort((a, b) => {
                const aCode = a.route[`${direction}_code`];
                const bCode = b.route[`${direction}_code`];
                return (
                    stopsSequence[aCode]?.sequence -
                    stopsSequence[bCode]?.sequence
                );
            });
            // generate proper required stops list
            const stopsList = sortOrders.reduce((acc, order) => {
                const stationCode = order.route[`${direction}_code`];
                const stationName = order.route[`${direction}_name`];

                if (typeof acc[stationCode] === 'undefined') {
                    acc[stationCode] = {
                        name: stopsSequence[stationCode]?.name || stationName,
                    };
                }
                return acc;
            }, {});
            return stopsList;
        }

        shops = () => {
            const shopsSet = new Set();
            const { orders } = this.state;
            orders.forEach((order) => {
                if (order.shop) {
                    shopsSet.add(order.shop);
                }
            });
            return Array.from(shopsSet);
        };

        markInvalidStops(orders) {
            const { stopsSequence } = this.props;

            return orders.map((order) => {
                const fromCode = order.route?.from_code;
                const toCode = order.route?.to_code;

                if (!stopsSequence[fromCode]) {
                    order.route.invalid_from_code = fromCode;
                }

                if (!stopsSequence[toCode]) {
                    order.route.invalid_to_code = toCode;
                }

                return order;
            });
        }

        fetchData = () => {
            const { rideMeta, setRideMeta } = this.context;

            return api
                .get(this.requestUrl, { transformResponse })
                .then((orders) => {
                    return this.markInvalidStops(orders);
                })
                .then((orders) =>
                    this.setState({ orders, loading: false }, () => {
                        // update rideMeta with actual passengers amount to update Nav item with proper number
                        const paxCount = orders.reduce(
                            (count, order) => count + order.passengers.length,
                            0
                        );
                        setRideMeta({ ...rideMeta, paxCount });
                    })
                )
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        this.setState({ loading: false });
                    }
                });
        };

        refetchOrders = (delay = 0) => {
            if (delay) {
                setTimeout(() => {
                    this.fetchOrders();
                }, delay);
                return;
            }
            this.fetchOrders();
        };

        fetchOrders() {
            this.setState({ loading: true }, () => {
                this.fetchData();
            });
        }

        render() {
            const { orders, loading } = this.state;
            const { match, rideId } = this.props;
            // provide compatibility with route match params
            const id = (match && match.params.rideId) || rideId;
            if (orders.length > 0) {
                return (
                    <Component
                        {...this.props}
                        orders={orders}
                        rideId={id}
                        paxStopsFrom={this.ordersToStopsList('from')}
                        paxStopsTo={this.ordersToStopsList('to')}
                        shops={this.shops()}
                        ordersReFetch={this.refetchOrders}
                    />
                );
            }
            if (loading) {
                return 'Loading...';
            }
            return 'No reservations';
        }
    };
}
