import React, { useState, useContext } from 'react';
import moment from "moment/moment";
import { Button, Tooltip } from '@flixbus/honeycomb-react';
import {
    IconNewsletterSolid,
    Icon,
    IconPhoneTablet,
} from '@flixbus/honeycomb-icons-react';
import { DataRowGroup, DataRow, DataCol } from '../../UI/DataGrid';
import { formatDate, getUtcOffsetText } from '../../../util/date';
import { TranslateContext } from '../../System/Translations';

export default function LogItem(props) {
    const { logItem } = props;
    const [formatted, setFormatted] = useState(false);
    const translate = useContext(TranslateContext);
    function hasHTML() {
        return logItem.message.search(/<\/?\w*\/?>/gi) !== -1;
    }
    function toggleFormatted() {
        setFormatted(!formatted);
    }
    function sanitizeMessage() {
        const test = logItem.message;
        return test.replace(
            /(<script.*?>.*<\/script>)|(<style.*?>.*<\/style>)/gi,
            ''
        );
    }
    return (
        <React.Fragment>
            <DataRowGroup>
                <DataRow valign="top">
                    <DataCol>
                        {logItem.recipient}
                        {logItem.isPush && (
                            <Tooltip
                                id="push-id"
                                content="Push"
                                openOnHover
                                smartPosition
                                size="content-fit"
                            >
                                <Icon
                                    appearance="primary"
                                    InlineIcon={IconPhoneTablet}
                                    title="Push"
                                />
                            </Tooltip>
                        )}
                    </DataCol>
                    <DataCol style={{ whiteSpace: 'nowrap' }}>
                        {formatDate(
                            logItem.createdAt,
                            'DD.MM.yyyy, HH:mm'
                        ) + getUtcOffsetText(moment())}
                    </DataCol>
                    <DataCol>
                        {hasHTML() && (
                            <Tooltip
                                content={translate(
                                    'notifications-log.format-email'
                                )}
                                id="format-email"
                                smartPosition
                                size="content-fit"
                                openOnHover
                            >
                                <Button link onClick={toggleFormatted}>
                                    <Icon
                                        appearance="primary"
                                        InlineIcon={IconNewsletterSolid}
                                    />
                                </Button>
                            </Tooltip>
                        )}
                        {formatted ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeMessage(),
                                }}
                            />
                        ) : (
                            logItem.message
                                .replace(/<\/?\w*\/?>|&nbsp;/gi, ' ')
                                .replace(/\s+/, ' ')
                        )}
                    </DataCol>
                    <DataCol style={{ whiteSpace: 'nowrap' }}>
                        {logItem.creatorName}
                    </DataCol>
                </DataRow>
            </DataRowGroup>
        </React.Fragment>
    );
}
