import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup, PopupSection, Button } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../System/Translations';

import {
    PAX_STATUS_CHECKED,
    PAX_STATUS_ABSENT,
} from './hocs/withPaxStatusChange';

export default function PopupUncheckConfirm({
    popupIsOpen,
    saving,
    paxName,
    togglePaxStatus,
    toggleConfirmPopup,
    status,
}) {
    const translate = useContext(TranslateContext);
    function getText() {
        if (status === PAX_STATUS_CHECKED) {
            return translate('popup-uncheck-confirm.uncheck-passenger');
        }
        if (status === PAX_STATUS_ABSENT) {
            return translate('popup-uncheck-confirm.check-absent-pax');
        }
        return '';
    }
    return (
        <React.Fragment>
            {popupIsOpen && (
                <Popup active onClose={toggleConfirmPopup}>
                    <PopupSection type="title">
                        {getText()} {paxName}?
                    </PopupSection>
                    <PopupSection type="actions">
                        <Button
                            appearance="primary"
                            onClick={togglePaxStatus}
                            disabled={saving}
                            loading={saving}
                        >
                            {translate('popup-uncheck-confirm.yes')}
                        </Button>
                        <Button
                            appearance="secondary"
                            onClick={toggleConfirmPopup}
                        >
                            {translate('popup-uncheck-confirm.no')}
                        </Button>
                    </PopupSection>
                </Popup>
            )}
        </React.Fragment>
    );
}

PopupUncheckConfirm.propTypes = {
    popupIsOpen: PropTypes.bool,
    saving: PropTypes.bool,
    paxName: PropTypes.string,
    togglePaxStatus: PropTypes.func,
    toggleConfirmPopup: PropTypes.func,
};

PopupUncheckConfirm.defaultProps = {
    popupIsOpen: false,
    saving: false,
    paxName: '',
    togglePaxStatus: () => {},
    toggleConfirmPopup: () => {},
};
