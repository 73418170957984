import WsConnection from './WsConnection';

class WsPublisher {
    publish(topic, data) {
        WsConnection()
            .getSession()
            .then((session) => {
                if (session.isOpen) {
                    session.publish(topic, data, null, { acknowledge: true }).then(
                        () => {},
                        (error) => {
                            console.log('WS publish error: ', { topic, error });
                        },
                    );
                }
            })
            .catch((e) => console.log('WS publish error: ', topic, e));
    }
}

let instance;

function getInstance() {
    if (instance) {
        return instance;
    }

    instance = new WsPublisher();

    return instance;
}

export default getInstance;
