import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';
import { Accordion, Link, Text } from '@flixbus/honeycomb-react';
import api from '../../../../api/Client';

export default function Rebookr2RebookingError(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const BACKEND_HOST = api?.config?.backend_host?.replace(/\/$/, '');

    return (
        <div>
            {translate('rebookr2-rebooking-error.message')}&nbsp;
            <Link
                href={`${BACKEND_HOST}/reports/orders/${item.parameters.source_order_id}/view`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {item.parameters.source_order_id}
            </Link>
            &nbsp;
            {translate('rebookr2-rebooking-error.passengers-were-not-rebooked')}
            <div>
                <Accordion
                    title={`${translate('rebookr2-rebooking-error.reason')} ${
                        item.parameters.error_message.match(
                            /^(\[.*\]):/
                        )?.[1] || ''
                    }`}
                    id={item.id}
                    extraClasses="rv-action-log__accordion"
                >
                    <Text>{item.parameters.error_message}</Text>
                </Accordion>
            </div>
        </div>
    );
}

Rebookr2RebookingError.propTypes = {
    item: PropTypes.object.isRequired,
};
