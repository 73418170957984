import PropTypes from 'prop-types';
import withPageTitleProvider from './hocs/withPageTitleProvider';
import withPageTitleConsumer from './hocs/withPageTitleConsumer';

const pageTitleProp = {
    types: PropTypes.shape({
        set: PropTypes.func,
    }),
    default: {
        set: () => {},
    },
};

export { withPageTitleProvider, withPageTitleConsumer, pageTitleProp };
