import React from 'react';
import PageTitle from '../context';

/**
 * HOC to add context consumer for PageTitle control
 * @func withPageTitle
 * @param {object} Component - react component
 */

export default function withPageTitleConsumer(Component) {
    return function WithPageTitleConsumer(props) {
        return <PageTitle.Consumer>{(pageTitle) => <Component {...props} pageTitle={pageTitle} />}</PageTitle.Consumer>;
    };
}
