import React from 'react';
import ShowHideICsContext from '../context';

/**
 * HOC to add context consumer for ShowHideICsHelper handler
 * @func withShowHideICsHelperConsumer
 * @param {object} Component - react component
 */

export default function withShowHideICsHelperConsumer(Component) {
    return function WithShowHideICsHelperConsumer(props) {
        return (
            <ShowHideICsContext.Consumer>
                {(showHideICsHandler) => <Component {...props} showHideICsHandler={showHideICsHandler} />}
            </ShowHideICsContext.Consumer>
        );
    };
}
