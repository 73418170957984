/** list of field names for form */
export const MULTIPLE = 'multiple'; // multiple agruments
export const LINE = 'lineUuid'; // ['dcbaaee6-9603-11e6-9066-549f350fcb0c']
export const TAGS = 'tags'; // ['dcbaaee6-9603-11e6-9066-549f350fcb0c']
export const EXCLUDE_TAGS = 'excludeTags'; // ['dcbaaee6-9603-11e6-9066-549f350fcb0c']
export const TRIP_NUMBER = 'tripNumbers'; // ['43434']
export const DATE = 'date'; // 10.10.2020,11.10.2020 | 10.10.2020
export const DEPTIME = 'departureTime'; // 10:20
export const ARRTIME = 'arrivalTime'; // 12:30
export const STOP_DEPARTURE = 'stopDepartureUuid'; // dcbaaee6-9603-11e6-9066-549f350fcb0c
export const STOP_ARRIVAL = 'stopArrivalUuid'; // dcbaaee6-9603-11e6-9066-549f350fcb0c
export const PLATE_NUMBER = 'plateNumber'; // BH7968
export const STATUSES = 'statuses'; // on_sale, hidden etc.
export const STOP_PASSING_THROUGH = 'stopPassingThroughUuid'; // ID for stops.
export const PARTNERS = 'partnerIds'; // ID or coma separated list of Ids for partners
export const WEEKDAYS = 'weekDays'; // coma separated weekdays in short format
export const LIVE_RIDES = 'filterLive'; // boolean
export const EXCLUDE_ASSIGNED_BUSES = 'excludeAssignedBuses'; // boolean
export const CONCESSION_OWNERS = 'concessionOwners'; // ['dcbaaee6-9603-11e6-9066-549f350fcb0c']
export const TIMEZONE = 'timezone'; // 'Europe/Berlin'
export const BUS_NUMBER = 'busNumber'; // 123 or '123'
export const BUS_IDS = 'busIds'; // 123, 345 or '123, 345'
