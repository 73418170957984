import React from 'react';
import ShowHideICsContext from '../context';

const STORAGE_IC_EXPANDED_KEY = 'rv-storage-ic-expanded';

export default function withShowHideICsHelperProvider(Component) {
    return class WithShowHideICsHelperProvider extends React.Component {
        static storage = {
            isExpanded: () => {
                const localStorageValue = window.localStorage.getItem(
                    STORAGE_IC_EXPANDED_KEY
                );
                const storageValue = localStorageValue
                    ? JSON.parse(localStorageValue)
                    : false;

                return storageValue || false;
            }
        };
        state = {
            isExpanded: this.constructor.storage.isExpanded(),
        };
        toggleShowHideICs = () => {
            const { isExpanded } = this.state;
            const newValue = !isExpanded;

            this.setState({ isExpanded: newValue });

            try {
                window.localStorage.setItem(STORAGE_IC_EXPANDED_KEY, newValue);
            } catch (e) {
                console.error(e);
            }
        };
        render() {
            const { isExpanded } = this.state;
            const providerValue = {
                isExpanded,
                toggleShowHideICs: this.toggleShowHideICs,
            };
            return (
                <ShowHideICsContext.Provider value={providerValue}>
                    <Component {...this.props} />
                </ShowHideICsContext.Provider>
            );
        }
    };
}
