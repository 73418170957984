import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box } from '@flixbus/honeycomb-react';
import { formatDate } from '../../../util/date';
import { getUser } from '../../../auth';

export default function CommentItem(props) {
    const { commentData } = props;
    const currentUser = getUser();

    const itemClassName = classNames('comment-feed-item', {
        'own-comment': currentUser.userName === commentData.author,
    });

    return (
        <li className={itemClassName}>
            <Box>
                <div>{commentData.content}</div>
                <div className="comment-info">
                    {commentData.author}&nbsp;
                    <strong>
                        {formatDate(commentData.createdAt, 'DD.MM.YY hh:mm')}
                    </strong>
                </div>
            </Box>
        </li>
    );
}

CommentItem.propTypes = {
    commentData: PropTypes.object.isRequired,
};
