import React from 'react';
import TimezoneContext from '../context';

export default function withTimezoneHelperProvider(Component) {
    return class WithTimezoneHelperProvider extends React.Component {
        state = {
            isLocal: false,
        };
        toggleTimezone = () => {
            const { isLocal } = this.state;
            this.setState({ isLocal: !isLocal });
        };
        render() {
            const { isLocal } = this.state;
            const providerValue = {
                isLocal,
                toggleTimezone: this.toggleTimezone,
            };
            return (
                <TimezoneContext.Provider value={providerValue}>
                    <Component {...this.props} />
                </TimezoneContext.Provider>
            );
        }
    };
}
