import { useRef, useCallback } from 'react';

export default function useDefaults(customFilters, formData) {
    const activated = useRef({});
    const setDefaults = useCallback(
        (defaults) => {
            customFilters.forEach((filter) => {
                const filters = Object.keys(defaults);
                // set default value for statuses once this filter will be activated

                if (!activated.current[filter.name] && filters.includes(filter.name) && filter.checked) {
                    const [action, value] = defaults[filter.name];
                    activated.current[filter.name] = true;
                    formData[filter.name] === undefined && action(value);
                }
            });
        },
        [customFilters, formData],
    );

    return [setDefaults];
}
