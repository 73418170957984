import { useEffect, useState, useCallback, useRef } from 'react';
import api from '../../../api/Client';

const entryPoint = '/notifications/templates';

function setInitialResponse() {
    let defaults = { data: null, loading: false, error: false };

    return (object = {}) => {
        const result = Object.assign({}, defaults, object);
        defaults = result;
        return result;
    };
}

const createResponse = setInitialResponse();

function normalize(templates) {
    return templates.reduce((acc, template) => {
        const result = { ...acc };

        // set platform
        if (result[template.shopGroup] === undefined) {
            result[template.shopGroup] = {};
        }

        // set channel
        if (result[template.shopGroup][template.channel] === undefined) {
            result[template.shopGroup][template.channel] = {};
        }

        // add default translation from root to the flat list of translations
        if (
            result[template.shopGroup][template.channel][template.locale] ===
            undefined
        ) {
            result[template.shopGroup][template.channel][template.locale] = [];
        }

        result[template.shopGroup][template.channel][template.locale].push({
            ...template,
            translations: {},
        });

        // wrap out translations
        if (
            template.translations &&
            Object.keys(template.translations).length > 0
        ) {
            Object.entries(template.translations).forEach(([key, value]) => {
                if (
                    result[template.shopGroup][template.channel][key] ===
                    undefined
                ) {
                    result[template.shopGroup][template.channel][key] = [];
                }

                // either uid or id is required to send template
                if (template.uid || template.id) {
                    result[template.shopGroup][template.channel][key].push({
                        ...value,
                        uid: template.uid || template.id,
                        channel: template.channel,
                        shopGroup: template.shopGroup,
                        locale: key,
                    });
                }
            });
        }
        return result;
    }, {});
}

export default function () {
    const [response, setResponse] = useState(createResponse());
    const countRequests = useRef(0);

    useEffect(
        () => () => {
            // cancel pending request with component unmount
            api.cancel(entryPoint);
        },
        []
    );

    const request = useCallback((language) => {
        if (countRequests.current >= 2) {
            /**
             * if request will be made with default language
             * only default translations will be returned if
             * if any other language will passed
             * the all list of translations wil be sent
             */
            return;
        }
        setResponse(createResponse({ loading: true }));
        api.get(entryPoint, {
            params: { language: language === 'en' ? undefined : language },
        })
            .then((res) => {
                setResponse(
                    createResponse({ data: normalize(res), error: false })
                );
                countRequests.current++;
            })
            .catch((err) => {
                if (!api.isCancel(err)) {
                    setResponse(createResponse({ data: null, error: err }));
                    console.log('error templates: ', err);
                }
            })
            .finally(() => {
                setResponse(createResponse({ loading: false }));
            });
    }, []);
    return [response, request];
}
