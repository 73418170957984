import React from 'react';
import PropTypes from 'prop-types';
import { NotifyContext } from '..';

/**
 * HOC to add context provider for notification API
 * @func withNotifyProvider
 * @param {object} Component - react component
 */
export default function withNotifyProvider(Component) {
    function WithNotifyProvider(props) {
        const { providerValue, ...restProps } = props;
        return (
            <NotifyContext.Provider value={providerValue}>
                <Component {...restProps} />
            </NotifyContext.Provider>
        );
    }
    WithNotifyProvider.propTypes = {
        providerValue: PropTypes.func.isRequired,
    };
    return WithNotifyProvider;
}
