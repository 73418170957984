import React from 'react';
import PageTitleContext from '../context';

export default function withPageTitleProvider(Component) {
    return class WithPageTitleProvider extends React.Component {
        setTitle = (title) => {
            document.title = title;
        };

        render() {
            const providerValue = {
                set: this.setTitle,
            };
            return (
                <PageTitleContext.Provider value={providerValue}>
                    <Component {...this.props} />
                </PageTitleContext.Provider>
            );
        }
    };
}
