import { useQuery } from "@tanstack/react-query";
import { useApi } from "./index";

export default function useRideSearch(params) {
    const [, searchRequest] = useApi('get', '/search/rides');

    function fetch({ queryKey }) {
        const [, params] = queryKey;
        return searchRequest({ params })
    }

    return useQuery({
        queryKey: ['search', params],
        queryFn: fetch,
        enabled: false,
        keepPreviousData: true
    })
}