export default {
    en: {
        code: 'en',
        shortTitle: 'En',
        title: 'English',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/gb.min.svg',
    },
    de: {
        code: 'de',
        shortTitle: 'De',
        title: 'Deutsch',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/de.min.svg',
    },
    tr: {
        code: 'tr',
        shortTitle: 'Tr',
        title: 'Türkçe',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/tr.min.svg',
    },
    cl: {
        code: 'cl',
        shortTitle: 'Cl',
        title: 'Chile',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/cl.min.svg',
    },
    hi: {
        code: 'hi',
        shortTitle: 'Hin',
        title: 'हिंदी(Hindi)',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/in.min.svg',
    },
    bg: {
        code: 'bg',
        shortTitle: 'bg',
        title: 'Български',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/bg.min.svg',
    },
    bs: {
        code: 'bs',
        shortTitle: 'bs',
        title: 'Bosanski',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/bs.min.svg',
    },
    ca: {
        code: 'ca',
        shortTitle: 'ca',
        title: 'Català',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/es.min.svg',
    },
    cs: {
        code: 'cs',
        shortTitle: 'cs',
        title: 'Čeština',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/cz.min.svg',
    },
    da: {
        code: 'da',
        shortTitle: 'da',
        title: 'Dansk',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/dk.min.svg',
    },
    de_AT: {
        code: 'de-at',
        shortTitle: 'de-at',
        title: 'Deutsch',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/de.min.svg',
    },
    de_CH: {
        code: 'de-ch',
        shortTitle: 'de-ch',
        title: 'Deutsch',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/de.min.svg',
    },
    en_GB: {
        code: 'en-gb',
        shortTitle: 'en-gb',
        title: 'English',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/gb.min.svg',
    },
    en_US: {
        code: 'en-us',
        shortTitle: 'en-us',
        title: 'English',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/us.min.svg',
    },
    es: {
        code: 'es',
        shortTitle: 'es',
        title: 'Español',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/es.min.svg',
    },
    es_US: {
        code: 'es-us',
        shortTitle: 'es-us',
        title: 'Español',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/es.min.svg',
    },
    et: {
        code: 'et',
        shortTitle: 'et',
        title: 'Estonian',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/et.min.svg',
    },
    fr: {
        code: 'fr',
        shortTitle: 'fr',
        title: 'Français',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/fr.min.svg',
    },
    fr_BE: {
        code: 'fr-be',
        shortTitle: 'fr-be',
        title: 'Français',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/fr.min.svg',
    },
    fr_CH: {
        code: 'fr-ch',
        shortTitle: 'fr-ch',
        title: 'Français',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/fr.min.svg',
    },
    hr: {
        code: 'hr',
        shortTitle: 'hr',
        title: 'Hrvatski',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/hr.min.svg',
    },
    hu: {
        code: 'hu',
        shortTitle: 'hu',
        title: 'Magyar',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/hu.min.svg',
    },
    it: {
        code: 'it',
        shortTitle: 'it',
        title: 'Italiano',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/it.min.svg',
    },
    it_CH: {
        code: 'it-ch',
        shortTitle: 'it-ch',
        title: 'Italiano',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/it.min.svg',
    },
    lt: {
        code: 'lt',
        shortTitle: 'lt',
        title: 'Lithuanian',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/lt.min.svg',
    },
    lv: {
        code: 'lv',
        shortTitle: 'lv',
        title: 'Latvian',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/lv.min.svg',
    },
    mk: {
        code: 'mk',
        shortTitle: 'mk',
        title: 'Македонски',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/mk.min.svg',
    },
    nb: {
        code: 'nb',
        shortTitle: 'nb',
        title: 'Norsk (bokmål)',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/no.min.svg',
    },
    nl: {
        code: 'nl',
        shortTitle: 'nl',
        title: 'Nederlands',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/nl.min.svg',
    },
    nl_BE: {
        code: 'nl-be',
        shortTitle: 'nl-be',
        title: 'Nederlands',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/nl.min.svg',
    },
    pl: {
        code: 'pl',
        shortTitle: 'pl',
        title: 'Polski',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/pl.min.svg',
    },
    pt: {
        code: 'pt-pt',
        shortTitle: 'pt-pt',
        title: 'Português',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/pt.min.svg',
    },
    ro: {
        code: 'ro',
        shortTitle: 'ro',
        title: 'Română',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/ro.min.svg',
    },
    ru: {
        code: 'ru',
        shortTitle: 'ru',
        title: 'Русский',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/ru.min.svg',
    },
    sk: {
        code: 'sk',
        shortTitle: 'sk',
        title: 'Slovenčina',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/sk.min.svg',
    },
    sl: {
        code: 'sl',
        shortTitle: 'sl',
        title: 'Slovenščina',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/sl.min.svg',
    },
    sq: {
        code: 'sq',
        shortTitle: 'sq',
        title: 'Shqip',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/al.min.svg',
    },
    sr: {
        code: 'sr',
        shortTitle: 'sr',
        title: 'Srpski',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/sr.min.svg',
    },
    sv: {
        code: 'sv',
        shortTitle: 'sv',
        title: 'Svenska',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/se.min.svg',
    },
    uk: {
        code: 'uk',
        shortTitle: 'uk',
        title: 'Українська',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/ua.min.svg',
    },
    zh_US: {
        code: 'zh-us',
        shortTitle: 'zh-us',
        title: '中文',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/cn.min.svg',
    },
};
