import { compose } from 'recompose';
import ReservationsTableV2 from './ReservationsTableV2';
import withOrdersApi from './hocs/withOrdersApi';
import withPaxSelection from './hocs/withPaxSelection';
import withPaxSorting from './hocs/withPaxSorting';
import withRideMetaConsumer from '../App/RideMeta/hocs/withRideMetaConsumer';

import './ReservationsTableV2.scss';

export default compose(
    withOrdersApi,
    withPaxSorting,
    withPaxSelection,
    withRideMetaConsumer
)(ReservationsTableV2);
