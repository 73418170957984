import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../System/Translations';
import api from "../../../../api/Client";

export default function MassRebookingFromRide(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const BACKEND_HOST = api?.config?.backend_host?.replace(/\/$/, '');

    return (
        <div>
            {translate('mass-rebooking-ride.passengers')}&nbsp;
            {translate('mass-rebooking-ride.from-order')}&nbsp;
            <Link
                href={`${ BACKEND_HOST }/reports/orders/${ item.parameters.source_order_id }/view`}
                target="_blank"
                rel="noopener noreferrer"
            >{ item.parameters.source_order_id }
            </Link>&nbsp;
            {translate('mass-rebooking-ride.passengers-were-rebooked-from')}&nbsp;
            <Link
                href={`/ride-view/${item.parameters.ride.id}/timeline`}
                target="_blank"
            >{ item.parameters.ride.title }
            </Link>
        </div>
    );
}

MassRebookingFromRide.propTypes = {
    item: PropTypes.object.isRequired,
};
