import React from 'react';
import PropTypes from 'prop-types';
import Content from '../components/Content';

/**
 * Wrap component to the Content component
 * @param {array} components - list of components from props
 * @param {string} alias - alias from route to set proper content box
 * @param {array} rendered - list of alias for components that already was rendered
 * @returns {array} - set of content boxes with nested components
 */
function getComponents(components = [], alias, rendered = []) {
    const content = [];
    // compare alias component with current route params
    // and set active state
    Object.keys(components).forEach((key) => {
        if (key === alias) {
            content.push(
                <Content key={key} isActive>
                    {components[key]}
                </Content>,
            );
        } else if (rendered.indexOf(key) !== -1) {
            // components that not requested yet wont be rendered
            content.push(<Content key={key}>{components[key]}</Content>);
        }
    });
    return content;
}

export default function withContentControl(Component) {
    /**
     * Control rendered and active components
     */
    return class WithContentControl extends React.Component {
        static propTypes = {
            components: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);
            const { components, match } = props;
            const { params } = match;
            this.state = {
                // components to render
                components: getComponents(components, params.alias),
                // list components aliases that have been rendered
                rendered: [params.alias],
            };
        }

        static getDerivedStateFromProps(props, state) {
            const { components, match } = props;
            const { params } = match;
            const { rendered } = state;
            return { components: getComponents(components, params.alias, rendered) };
        }

        componentDidUpdate(prevProps) {
            const { match } = this.props;
            const { match: prevMatch } = prevProps;
            if (match.params.alias !== prevMatch.params.alias) {
                this.setRenderedContent(match.params.alias);
            }
        }

        /**
         * Wrap component to the Content component
         * @param {[object]} props - components props if they are not the same as current
         * @returns {array} - array of components with appropriate active state
         */
        getComponents(components = [], alias, rendered = []) {
            const content = [];
            // compare alias component with current route params
            // and set active state
            Object.keys(components).forEach((key) => {
                if (key === alias) {
                    content.push(
                        <Content key={key} isActive>
                            {components[key]}
                        </Content>,
                    );
                } else if (rendered.indexOf(key) !== -1) {
                    // components that not requested yet wont be rendered
                    content.push(<Content key={key}>{components[key]}</Content>);
                }
            });
            return content;
        }

        /**
         * store the Content that was requested and rendered
         * @param {string} alias - alias for Contents component
         * @return {array} - array with alias for requested and rendered Contents Components
         */
        setRenderedContent = (alias) => {
            const rendered = [...this.state.rendered];
            if (rendered.indexOf(alias) === -1) {
                rendered.push(alias);
                this.setState({ rendered });
            }
            return rendered;
        };

        render() {
            const { components } = this.state;
            return <Component {...this.props} components={components} />;
        }
    };
}
