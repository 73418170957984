import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { TranslateContext } from '../System/Translations';
import api from '../../api/Client';

/**
 * function that provide list of navigation items for Header component
 * using on translations function
 * @returns {array[object]} array for navigation prop in Header component
 */
export default function useHeaderNavigation() {
    const translate = useContext(TranslateContext);
    const {
        customer_notifier_host: templateManagerHost,
        autocontrol_host: autocontrolHost,
    } = api.config;
    return [
        {
            href: '/',
            content: translate('header_menu.ride_list'),
            RouterLink: NavLink,
            exact: true,
        },
        {
            href: templateManagerHost,
            content: translate('header_menu.templates_manager'),
            target: '_blank',
        },
        {
            to: '/search',
            content: translate('header_menu.ride_search'),
            RouterLink: NavLink,
            exact: true,
        },
        {
            href: autocontrolHost,
            content: 'Autocontrol',
            target: '_blank',
        },
    ];
}
