import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@flixbus/honeycomb-react';
import { Icon, IconBus } from '@flixbus/honeycomb-icons-react';
import { DataRow, DataCol, DataRowGroup } from '../../UI/DataGrid';
import { TranslateContext } from '../../System/Translations';

export default function BusterBusRow({ plate, colSpan }) {
    const translate = useContext(TranslateContext);
    return (
        <DataRowGroup>
            <DataRow>
                <DataCol colSpan={colSpan}>
                    <Tag
                        small
                        id={plate}
                        appearance="success"
                        outlined
                        style={{ margin: '6px' }}
                    >
                        <Icon appearance="primary" InlineIcon={IconBus} />
                        {plate === ''
                            ? translate(
                                  'reservations.table.distribution.undistributed'
                              )
                            : plate}
                    </Tag>
                </DataCol>
            </DataRow>
        </DataRowGroup>
    );
}
BusterBusRow.propTypes = { plate: PropTypes.string, colSpan: PropTypes.number };
BusterBusRow.defaultProps = { plate: '', colSpan: 9 };
