import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tag, Tooltip, Spinner } from '@flixbus/honeycomb-react';
import {
    IconAttentionSolid,
    IconCheckmarkStrongSolid,
    IconCheckmarkStrong,
    Icon,
} from '@flixbus/honeycomb-icons-react';
import PopupUncheckConfirm from './PopupUncheckConfirm';
import {
    PAX_STATUS_CHECKED,
    PAX_STATUS_ABSENT,
    PAX_STATUS_UNCHECKED,
} from './hocs/withPaxStatusChange';
import Translate from '../../../System/Translations';
import { formatDate, getUtcOffsetText } from '../../../../util/date';

export default function PassengerStatus({
    status,
    paxName,
    updatedAt,
    togglePaxStatus,
    popupIsOpen,
    saving,
    toggleConfirmPopup,
    push,
}) {
    const [tooltipActive, setTooltip] = useState(false);
    const tagCssName = classNames('reservation-passengers-tag', {
        'reservation-passengers-tag--push': push,
        [`reservation-passengers-tag--${status}`]: status,
        [`reservation-passenger-icon--${status}`]: status,
    });

    return (
        <Translate>
            {(translate) => (
                <div className="reservation-passenger-push-value">
                    {status === PAX_STATUS_ABSENT ? (
                        <>
                            <Tooltip
                                position="top"
                                content={`${formatDate.parseZone(
                                    updatedAt,
                                    'DD.MM.yyyy, HH:mm'
                                )} ${getUtcOffsetText(updatedAt)}`}
                                id={`${paxName}-status`}
                                openOnHover
                            >
                                <span>
                                    {saving ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        <Tag
                                            Elem="button"
                                            extraClasses={tagCssName}
                                            small
                                            outlined
                                            onClick={toggleConfirmPopup}
                                        >
                                            <Icon
                                                appearance="primary"
                                                InlineIcon={IconAttentionSolid}
                                            />
                                            &nbsp;
                                            {translate(
                                                'reservations.table.status.absent'
                                            )}
                                        </Tag>
                                    )}
                                </span>
                            </Tooltip>
                            <PopupUncheckConfirm
                                popupIsOpen={popupIsOpen}
                                saving={saving}
                                toggleConfirmPopup={toggleConfirmPopup}
                                paxName={paxName}
                                togglePaxStatus={togglePaxStatus}
                                status={PAX_STATUS_ABSENT}
                            />
                        </>
                    ) : (
                        <React.Fragment>
                            <Tooltip
                                position="top"
                                active={tooltipActive}
                                onMouseEnter={() => {
                                    setTooltip(Boolean(updatedAt) && true);
                                }}
                                onMouseLeave={() => {
                                    setTooltip(false);
                                }}
                                content={`${formatDate.parseZone(
                                    updatedAt,
                                    'DD.MM.yyyy, HH:mm'
                                )} ${getUtcOffsetText(updatedAt)}`}
                                id={`${paxName}-status`}
                            >
                                {saving ? (
                                    <Spinner size="sm" />
                                ) : (
                                    <span>
                                        <Tag
                                            Elem="button"
                                            extraClasses={tagCssName}
                                            onClick={
                                                status === PAX_STATUS_UNCHECKED
                                                    ? togglePaxStatus
                                                    : toggleConfirmPopup
                                            }
                                            small
                                            outlined
                                        >
                                            <Icon
                                                appearance="primary"
                                                InlineIcon={() => (
                                                    <React.Fragment>
                                                        {status ===
                                                        PAX_STATUS_CHECKED ? (
                                                            <IconCheckmarkStrongSolid
                                                                title={translate(
                                                                    `reservations.table.status.${status}`
                                                                )}
                                                            />
                                                        ) : (
                                                            <IconCheckmarkStrong
                                                                title={translate(
                                                                    `reservations.table.status.${status}`
                                                                )}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            />
                                            &nbsp;
                                            {translate(
                                                `reservations.table.status.${status}`
                                            )}
                                        </Tag>
                                    </span>
                                )}
                            </Tooltip>

                            {status === PAX_STATUS_CHECKED && (
                                <PopupUncheckConfirm
                                    popupIsOpen={popupIsOpen}
                                    saving={saving}
                                    toggleConfirmPopup={toggleConfirmPopup}
                                    paxName={paxName}
                                    togglePaxStatus={togglePaxStatus}
                                    status={PAX_STATUS_CHECKED}
                                />
                            )}
                        </React.Fragment>
                    )}
                </div>
            )}
        </Translate>
    );
}

PassengerStatus.propTypes = {
    // status for passenger unchecked, checked, absent etc.
    status: PropTypes.string.isRequired,
    paxName: PropTypes.string,
    // time when the status was updated (e.g 2018-06-15T10:08:14+0200)
    updatedAt: PropTypes.string,
    togglePaxStatus: PropTypes.func,
    popupIsOpen: PropTypes.bool,
    saving: PropTypes.bool,
    toggleConfirmPopup: PropTypes.func,
    push: PropTypes.bool,
};

PassengerStatus.defaultProps = {
    paxName: '',
    updatedAt: '',
    togglePaxStatus: () => {},
    popupIsOpen: false,
    saving: false,
    toggleConfirmPopup: () => {},
    push: false,
};
