import { compose } from 'recompose';
import RebookingRideStatus from './RebookingRideStatus';
import withRebookingRIdeStatusApi from './hocs/withRebookingRIdeStatusApi';
import withNotifyConsumer from '../NotificationSystem/context/hocs/withNotifyConsumer';

import './RebookingRideStatus.css';

export default compose(
    withNotifyConsumer,
    withRebookingRIdeStatusApi,
)(RebookingRideStatus);
