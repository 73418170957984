import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';
import apiTagsBank from '../../../api/TagsBankApi';
import { DispatcherContext } from '../../System/Dispatcher';

export default function withTagsApi(Component) {
    return class WithTagsApi extends React.Component {
        static propTypes = {
            rideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            rideUuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
        };

        static contextType = DispatcherContext;

        state = {
            tags: [],
            tagsGroup: [],
        };

        groupsUrl = 'api/tags';

        componentDidMount() {
            this.dispatcher = this.context;
            this.dispatcher.on('set-canceled-tag', this.getTags);
            this.getTags();
        }

        componentWillUnmount() {
            apiTagsBank.cancel(this.getTagsBankUrl);
            apiTagsBank.cancel(this.groupsUrl);
            api.cancel('/remove-tag');

            this.dispatcher.off('set-canceled-tag', this.getTags);
        }

        /**
         * trigger tags group loading if it's not been loaded
         * @method getTagsGroup
         * @returns {function} function to cancel request
         */
        getTagsGroup = () => {
            const { tagsGroup } = this.state;
            if (tagsGroup.length === 0) {
                apiTagsBank
                    .get(this.groupsUrl)
                    .then((tagGroupsResponse) => {
                        this.setState({
                            tagsGroup: tagGroupsResponse,
                        });
                    })
                    .catch((error) => {
                        if (!apiTagsBank.isCancel(error)) {
                            console.log(error);
                        }
                    });
            }
            return () => {
                apiTagsBank.cancel(this.groupsUrl);
            };
        };

        getTags = () =>
            apiTagsBank
                .get(this.getTagsBankUrl)
                .then((tagsResponse) => {
                    this.setState({
                        tags: tagsResponse,
                    });
                })
                .catch((error) => {
                    if (!apiTagsBank.isCancel(error)) {
                        console.log(error);
                    }
                });

        get tagsUrl() {
            const { rideId } = this.props;
            return `/ride/${rideId}/tags`;
        }

        get getTagsBankUrl() {
            const { rideUuid } = this.props;
            return `api/ride/${rideUuid}/tags`;
        }

        addRideTags = (tagIds) =>
            api.post(this.tagsUrl, { tags: tagIds }).then((tags) => {
                this.setState({
                    tags,
                });
            });

        removeRideTag = (tagId) =>
            api
                .delete(`${this.tagsUrl}/${tagId}`, {
                    cancelTokenId: 'remove-tag',
                })
                .then(() => {
                    const { tags } = this.state;
                    const newTagsSet = tags.filter((tag) => tag.id !== tagId);
                    this.setState({ tags: newTagsSet });
                });

        render() {
            const { tags, tagsGroup } = this.state;
            return (
                <Component
                    {...this.props}
                    addRideTags={this.addRideTags}
                    removeRideTag={this.removeRideTag}
                    tags={tags}
                    tagsGroup={tagsGroup}
                    setRideTags={this.setRideTags}
                    getTagsGroup={this.getTagsGroup}
                />
            );
        }
    };
}
