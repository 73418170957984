import React from 'react';
import DataGrid, { DataHeaderGroup, DataRow, DataCol } from '../UI/DataGrid';
import LogItem from './components/LogItem';
import { TranslateContext } from '../System/Translations';

export default class NotificationsLog extends React.Component {
    static contextType = TranslateContext;

    render() {
        const { notificationsLog } = this.props;
        const translate = this.context;

        return (
            <DataGrid>
                <DataHeaderGroup>
                    <DataRow header={true}>
                        <DataCol>
                            {translate('notifications-log.recipient')}
                        </DataCol>
                        <DataCol>{translate('notifications-log.date')}</DataCol>
                        <DataCol>
                            {translate('notifications-log.message')}
                        </DataCol>
                        <DataCol>
                            {translate('notifications-log.sent-by')}
                        </DataCol>
                    </DataRow>
                </DataHeaderGroup>
                {notificationsLog.map((logItem) => (
                    <LogItem
                        key={
                            logItem.id ||
                            `${logItem.createdAt}-${logItem.recipient}`
                        }
                        logItem={logItem}
                    />
                ))}
            </DataGrid>
        );
    }
}
