import { useEffect, useCallback, useState, useRef } from 'react';

function useSticky() {
    const [isSticky, setSticky] = useState(false);
    const element = useRef(null);
    const subNavHeight = 40;

    // Debounce handles the scroll performance issue
    const debounce = useCallback((func, timeFrame) => {
        var lastTime = 0;
        return function () {
            var now = new Date();
            if (now - lastTime >= timeFrame) {
                func();
                lastTime = now;
            }
        };
    }, []);

    useEffect(() => {
        const debounceHandleScoll = debounce(() => {
            const offsetTop = element.current.offsetTop + subNavHeight;
            const shouldStick = window.scrollY + subNavHeight / 2 > offsetTop; // subNavHeight / 2 to avoid zero scroll value

            shouldStick ? setSticky(true) : setSticky(false);
        }, 30); // best tested debounce timeframe

        window.addEventListener('scroll', debounceHandleScoll);

        return () => {
            window.removeEventListener('scroll', debounceHandleScoll);
        };
    }, [debounce]);

    return { isSticky, element };
}

export default useSticky;
