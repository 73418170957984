import { useApi } from '../../RideSearch/hooks';

export default function useDriver(rideUuid) {
    const [response, request] = useApi(
        'get',
        `ride/${rideUuid}/drivers/current`
    );

    return [response, request];
}
