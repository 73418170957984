import React from 'react';
import PropTypes from 'prop-types';

export default function StationDate(props) {
    const { utc, date } = props;
    const utcText = utc !== null ? `${utc > 0 ? '+' : ''}${utc}` : utc;
    return (
        <div className="timeline-station-schedule">
            {date}
            <div>UTC {utcText}</div>
        </div>
    );
}
StationDate.propTypes = {
    utc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date: PropTypes.string,
};
StationDate.defaultProps = { utc: 'n/a', date: 'n/a' };
