import React from 'react';
import { Notification } from '@flixbus/honeycomb-react';
import NotificationSystem from '../NotificationSystem';

const STYLE = {
    Containers: {
        DefaultStyle: {
            width: 380,
            marginRight: 5,
            padding: 0,
        },
    },
    NotificationItem: {
        DefaultStyle: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: 'none',
            padding: 0,
        },
    },
};

export default function withNotificationSystem(Component) {
    return class WithNotificationSystem extends React.Component {
        setNotificationSystem = (ref) => {
            this.notificationSystem = ref;
        };

        showMessage = (param) => {
            const { type, message, ...rest } = param;
            const children = (
                <Notification
                    appearance={type || null}
                    closeProps={{
                        'aria-label': `close notification ${message}`,
                        onClick: () => {},
                    }}
                >
                    {message}
                </Notification>
            );
            const level = () => {
                if (!type) {
                    return 'info';
                }
                return type === 'danger' ? 'error' : type;
            };
            const options = {
                children,
                dismissible: 'click',
                autoDismiss: type === 'danger' ? 0 : 8,
                level: level(),
                ...rest,
            };
            return this.notificationSystem.addNotification(options);
        };

        render() {
            return (
                <React.Fragment>
                    <Component
                        {...this.props}
                        providerValue={this.showMessage}
                    />
                    <NotificationSystem
                        setNotificationSystemRef={this.setNotificationSystem}
                        style={STYLE}
                    />
                </React.Fragment>
            );
        }
    };
}
