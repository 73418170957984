import React from 'react';
import PropTypes from 'prop-types';
import SideBarControlsItem from './SideBarControlsItem';

import './SideBarControls.scss';

export default function SideBarControls(props) {
    const { items } = props;
    return (
        <div className="rv-side-bar-control">
            {items.map((item) => (
                <SideBarControlsItem key={item.key} onClick={item.onClick} InlineIcon={item.icon} />
            ))}
        </div>
    );
}
SideBarControls.propTypes = { items: PropTypes.arrayOf(PropTypes.shape()) };
SideBarControls.defaultProps = { items: [] };
