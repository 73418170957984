import React, { useContext } from 'react';
import { Button, Dropdown } from '@flixbus/honeycomb-react';
import { Icon, IconKebab } from '@flixbus/honeycomb-icons-react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../System/Translations';
import api from '../../../api/Client';
import useUserCan from '../../../auth/useUserCan';
import { CONFIG as CONF } from '../../../config';

export default function RideInfoActions(props) {
    const { rideId, rideUuid } = props;
    const { config: CONFIG } = api;
    const translate = useContext(TranslateContext);
    const rideViewUrl = `${CONFIG.backend_host}inventory/rides/${rideId}/view`;
    const radarUrl = `${CONFIG.radar_host}?rideId=${rideUuid}`;
    const isUserCanDriverAppMsg = useUserCan('driver_app_message');

    // Open in backend
    const openInBackendAction = {
        href: rideViewUrl,
        text: translate('kebab_menu.backend_link_label'),
        target: '_blank',
    };

    // Driver app link
    const driverAppMessageUrl = CONF('DRIVER_APP_URL');
    const driverAppAction = {
        href: driverAppMessageUrl,
        text: translate('toolbar.driver_app_message.title'),
        target: '_blank',
    };

    // Radar link
    const radarAction = {
        href: radarUrl,
        text: translate('content_box.side_nav.radar'),
        target: '_blank',
        rel: 'noreferrer noopener',
    };

    const linksArray = [openInBackendAction, radarAction];
    if (isUserCanDriverAppMsg) {
        linksArray.push(driverAppAction);
    }

    return (
        <>
            <Dropdown links={linksArray}>
                <Button display="square" extraClasses="no-border-button">
                    <Icon InlineIcon={IconKebab} />
                </Button>
            </Dropdown>
        </>
    );
}

RideInfoActions.propTypes = {
    rideUuid: PropTypes.string.isRequired,
    rideId: PropTypes.number.isRequired,
};
