import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Panel,
    PanelHeader,
    PanelContent,
    PanelFooter,
    PanelFooterColumn,
    Button,
    Tooltip,
    Text,
} from '@flixbus/honeycomb-react';
import { Icon, IconFilter } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../System/Translations';

export default function ColumnsPanel(props) {
    const { columns, saveColumns, showDescription } = props;
    const [isActive, setActive] = useState(false);
    const translate = useContext(TranslateContext);

    function setChecked(e) {
        const { target } = e;
        const updatedColumns = Object.entries(columns).reduce(
            (acc, [column, value]) => {
                const out = { ...acc };
                out[column] = value.checked;
                return out;
            },
            {}
        );
        updatedColumns[target.name] = target.checked;
        saveColumns(updatedColumns);
    }

    function showAll() {
        const updatedColumns = Object.keys(columns).reduce((acc, column) => {
            const out = { ...acc };
            out[column] = true;
            return out;
        }, {});
        saveColumns(updatedColumns);
    }

    function open() {
        setActive(true);
    }

    function close() {
        setActive(false);
    }

    return (
        <React.Fragment>
            <Button link appearance="secondary" onClick={open}>
                <Icon appearance="primary" InlineIcon={IconFilter} />
                {translate('reservations.custom-columns.button')}
            </Button>
            <Tooltip
                id="column-selector-panel"
                active={showDescription}
                hasClose
                position="bottom"
                closeBtn={{ onClick: showAll, 'aria-label': 'close tooltip' }}
                content={
                    <Text>
                        {translate('reservations.custom-columns.tooltip.text')}
                    </Text>
                }
            >
                &nbsp;
            </Tooltip>
            <Panel hasOverlay active={isActive}>
                <PanelHeader
                    closeButtonProps={{ label: 'Close', onClick: close }}
                >
                    {translate('reservations.custom-columns.panel.title')}
                </PanelHeader>
                <PanelContent>
                    {Object.entries(columns).map(([, column]) => (
                        <Checkbox
                            id={`${column.name}-column`}
                            key={`${column.name}-column`}
                            name={column.name}
                            label={translate(column.label)}
                            checked={column.checked}
                            onChange={setChecked}
                            small
                            value={column.name}
                        />
                    ))}
                </PanelContent>
                <PanelFooter>
                    <PanelFooterColumn>
                        <Button
                            appearance="tertiary"
                            display="block"
                            onClick={showAll}
                        >
                            {translate(
                                'reservations.custom-columns.panel.button.secondary'
                            )}
                        </Button>
                    </PanelFooterColumn>
                    <PanelFooterColumn>
                        <Button
                            appearance="primary"
                            display="block"
                            onClick={close}
                        >
                            {translate(
                                'reservations.custom-columns.panel.button.primary'
                            )}
                        </Button>
                    </PanelFooterColumn>
                </PanelFooter>
            </Panel>
        </React.Fragment>
    );
}

ColumnsPanel.propTypes = {
    columns: PropTypes.shape().isRequired,
    saveColumns: PropTypes.func.isRequired,
};
