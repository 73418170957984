import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../System/Translations';
import api from '../../../../api/Client';

const initState = {
    languages: [],
    loading: true,
    error: false,
};
const reducer = function (state, action) {
    const { type, payload } = action;
    switch (type) {
        case 'loading':
            return { ...state, loading: true };
        case 'loaded':
            const languages = [...payload].sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
            return { ...state, loading: false, languages };
        case 'error':
            return { ...state, loading: false, error: payload };
        default:
            return { ...state };
    }
};

export default function LanguageSelector(props) {
    const { onSwitch, currentLang } = props;
    const [state, dispatch] = useReducer(reducer, initState);
    const translate = useContext(TranslateContext);
    const selectLng = (e) => {
        onSwitch(e.target.value);
    };
    useEffect(() => {
        api.get('dictionary/languages')
            .then((response) => {
                dispatch({ type: 'loaded', payload: response });
            })
            .catch((e) => {
                if (!api.isCancel(e)) {
                    dispatch({ type: 'error', payload: e });
                }
            });
    }, []);

    return (
        <Select
            id="language"
            value={currentLang}
            label={translate('send_sms.passengers_list.language')}
            onChange={selectLng}
        >
            {state.languages.map((lng) => {
                const title = `${lng.name}(${lng.code})`;
                return (
                    <SelectOption key={lng.code} value={lng.code}>
                        {title}
                    </SelectOption>
                );
            })}
        </Select>
    );
}
LanguageSelector.propTypes = {
    onSwitch: PropTypes.func,
    currentLang: PropTypes.string,
};
LanguageSelector.defaultProps = {
    onSwitch: () => {},
    currentLang: 'en',
};
