import { useEffect, useState } from 'react';
import api from '../../api/CommentsBankApi';

export default function useCommentsCount(rideUuid) {
    const [count, setCount] = useState(null);
    useEffect(() => {
        api.get(`/${rideUuid}/count`).then((response) => {
            setCount(response);
        });
    }, [rideUuid]);
    return count;
}
