import React, { useContext } from 'react';
import { Select, SelectOption } from '@flixbus/honeycomb-react';
import StationSelection from './StationSelection';
import { TranslateContext } from '../../../System/Translations';
import './index.scss';

export default function BatchSelectionForm(props) {
    const { paxStopsFrom, paxStopsTo, shops, attr, onSetAttr } = props;
    const translate = useContext(TranslateContext);

    return (
        <div className="batch-selection-form">
            <div className="batch-selection-form__row">
                <StationSelection
                    selectStation={(value) => {
                        onSetAttr('from', value);
                    }}
                    title={translate('reservations.passengers_departs_from')}
                    stops={paxStopsFrom}
                    value={attr['from'] || ''}
                />
                <StationSelection
                    selectStation={(value) => {
                        onSetAttr('to', value);
                    }}
                    title={translate('reservations.passengers_arrives_to')}
                    stops={paxStopsTo}
                    value={attr['to'] || ''}
                />
            </div>
            <div className="batch-selection-form__row">
                <Select
                    id="select-by-shop"
                    label={translate('reservations.order_shop')}
                    value={attr['shop'] || ''}
                    placeholder={translate('reservations.no_selected_shop')}
                    onChange={(e) => onSetAttr('shop', e.target.value)}
                >
                    <SelectOption key="empty-shop-selection" value="">
                        {translate('reservations.no_selected_shop')}
                    </SelectOption>
                    {shops.map((shop) => (
                        <SelectOption key={shop} value={shop}>
                            {shop}
                        </SelectOption>
                    ))}
                </Select>
            </div>
        </div>
    );
}
