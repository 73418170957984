import React, { useState, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, FormRow } from '@flixbus/honeycomb-react';
import { Icon, IconCopy, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import { NotifyContext } from '../NotificationSystem/context';
import useSendHook from './hooks/useSendHook';
import Editor from '../Editor';

const formReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'setMessage':
            return {
                ...state,
                message: payload,
            };
        case 'setSubject':
            return {
                ...state,
                subject: payload,
            };
        case 'clearSubjectAndMessage':
            return {
                ...state,
                subject: '',
                message: '',
            };
        case 'setTemplateMode':
            return {
                ...state,
                templateMode: payload,
            };
        case 'setValidSubject':
            return {
                ...state,
                validSubject: payload,
            };
        case 'setValidMessage':
            return {
                ...state,
                validMessage: payload,
            };
        case 'setValidity':
            return {
                ...state,
                validSubject: payload,
                validMessage: payload,
            };
        default:
            return { ...state };
    }
};
const customSmsApi = '/notifications/custom/send/sms';
const templatesSmsApi = '/notifications/templates/send/sms';
const customEmailApi = '/notifications/custom/send/email';
const templatesEmailApi = '/notifications/templates/send/email';

export default function MessageField(props) {
    const {
        orders,
        paxList,
        onCancel,
        sendMode,
        currentLang,
        content,
        title,
        placeholders,
        uid,
    } = props;

    const [emailMode, setEmailMode] = useState(sendMode === 'e-mail');
    const [paxToSend, setPaxToSend] = useState([]);
    const [paxNotSend, setPaxNotSend] = useState([]);
    const initFormState = {
        message: content,
        subject: title,
        disable: true,
        validSubject: false,
        validMessage: false,
    };
    const [form, dispatch] = useReducer(formReducer, initFormState);
    const entryPoint = emailMode
        ? form.templateMode
            ? templatesEmailApi
            : customEmailApi
        : form.templateMode
        ? templatesSmsApi
        : customSmsApi;

    const [response, send] = useSendHook(entryPoint);
    const { error, loading, data } = response;

    const translate = useContext(TranslateContext);
    const notify = useContext(NotifyContext);

    function onChangeMessage({ target }) {
        dispatch({ type: 'setMessage', payload: target.value });
        dispatch({ type: 'setValidMessage', payload: Boolean(target.value) });

        if (!emailMode) {
            // we are not showing subject for SMS, force to make it valid
            dispatch({
                type: 'setValidSubject',
                payload: true,
            });
        }
    }
    function onChangeSubject({ target }) {
        dispatch({ type: 'setSubject', payload: target.value });
        dispatch({ type: 'setValidSubject', payload: Boolean(target.value) });
    }
    function onClickSubjectMessage() {
        notify({
            type: 'warning',
            message: translate('notifier.dialog.sending.warning'),
        });

        dispatch({ type: 'setTemplateMode', payload: false });
    }
    function callSend() {
        if (paxToSend.length > 0) {
            const dataToSend = form.templateMode
                ? {
                      template_id: uid,
                      pax_list: paxToSend,
                      placeholders,
                  }
                : emailMode
                ? {
                      pax_list: paxToSend,
                      email_text: form.message,
                      subject: form.subject,
                  }
                : {
                      pax_list: paxToSend,
                      sms_text: sanitizeInput(form.message),
                  };
            send(dataToSend);
        } else {
            notify({
                type: 'warning',
                message: translate('notifier.dialog.sending.empty'),
            });
        }

        if (paxToSend.length > 0 && paxNotSend.length) {
            notify({
                type: 'warning',
                message:
                    translate('notifier.dialog.sending.contact_empty') +
                    '\n' +
                    paxNotSend.join(', \r\n'),
            });
        }
    }
    function copyText() {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(form.message);
            navigator.clipboard.readText().then(() => {
                notify({
                    type: 'success',
                    message: translate('reservations.select_email.copied'),
                });
            });
        }
    }
    function sanitizeInput(text) {
        let tmp = document.createElement('div');
        tmp.innerHTML = text;

        text = tmp.textContent || tmp.innerText || '';

        const msg = text
            .replace(/<style[^>]*>.*<\/style>/gm, '')
            // Remove script tags and content
            .replace(/<script[^>]*>.*<\/script>/gm, '')
            // Remove all opening, closing and orphan HTML tags
            .replace(/<[^>]+>/gm, '')
            // Remove leading spaces and repeated CR/LF
            .replace(/([\r\n]+ +)+/gm, '');

        return msg;
    }

    if (error) {
        notify({
            type: 'danger',
            message: `${translate('notifier.dialog.sending.error')}: ${error}`,
        });
    }
    if (error === false && data !== null) {
        if (data?.failedOrders?.length > 0) {
            const failedOrders = data.failedOrders.join(', ');

            notify({
                type: 'danger',
                message: `${translate(
                    'notifier.dialog.sending.failedOrders'
                )}: ${failedOrders}`,
            });
        }

        if (data?.processedOrders?.length > 0) {
            notify({
                type: 'success',
                message: translate('notifier.dialog.sending.success'),
            });

            onCancel();
        }
    }

    useEffect(() => {
        dispatch({ type: 'setTemplateMode', payload: true });

        if (content) {
            dispatch({ type: 'setMessage', payload: content });
            dispatch({ type: 'setValidMessage', payload: true });
        }
        if (title) {
            dispatch({ type: 'setSubject', payload: title });
            dispatch({ type: 'setValidSubject', payload: true });
        }
    }, [content, title]);

    useEffect(() => {
        dispatch({ type: 'clearSubjectAndMessage' });
        dispatch({ type: 'setTemplateMode', payload: true });
        dispatch({ type: 'setValidity', payload: false });
    }, [currentLang]);

    useEffect(() => {
        const notSendList = [];

        // create list of pax with limited properties
        const selectedPAXList = orders.reduce((acc, order) => {
            const output = [...acc];

            if (paxList.indexOf(order.id) !== -1) {
                if (
                    emailMode
                        ? order.email
                        : order.phone_number || order.push_subscribers_count > 0
                ) {
                    let pax = {
                        ride_id: order.ride_id,
                        order_id: order.id,
                        contact: '',
                        lang: order.language.toUpperCase(),
                    };

                    if (emailMode) {
                        if (order.email) {
                            pax.contact = order.email;
                        }
                    } else {
                        pax.contact = order.phone_number || order.id;
                    }

                    if (pax.contact) {
                        output.push(pax);
                    }
                } else {
                    notSendList.push(order.id);
                }
            }

            return output;
        }, []);

        setPaxToSend(selectedPAXList);
        setPaxNotSend(notSendList);
    }, [paxList, orders, emailMode]);

    useEffect(() => {
        setEmailMode(sendMode === 'e-mail');

        dispatch({ type: 'clearSubjectAndMessage' });
        dispatch({ type: 'setValidity', payload: false });
    }, [sendMode]);

    return (
        <React.Fragment>
            {emailMode && (
                <FormRow
                    extraClasses="nc-template-form"
                    onClick={form.templateMode ? onClickSubjectMessage : null}
                >
                    <Input
                        id="email-subject-text"
                        placeholder={translate(
                            'notifier.email.subject.placeholder'
                        )}
                        label={translate('notifier.email.subject.label')}
                        value={form.subject}
                        valid={form.validSubject}
                        onChange={onChangeSubject}
                        disabled={form.templateMode}
                    />
                </FormRow>
            )}
            <FormRow
                extraClasses="nc-template-form"
                onClick={form.templateMode ? onClickSubjectMessage : null}
            >
                <Editor
                    label={translate('notifier.email.message.label')}
                    placeholder={translate(
                        'notifier.email.message.placeholder'
                    )}
                    content={form.message}
                    disabled={form.templateMode}
                    error={!form.validMessage}
                    onChange={({ data }) => {
                        onChangeMessage({
                            target: { value: data },
                        });
                    }}
                    currentLang={currentLang}
                />
            </FormRow>
            {!form.templateMode && (
                <FormRow>
                    <Button onClick={copyText} link>
                        <Icon appearance="primary" InlineIcon={IconCopy} />
                        {translate('notifier.dialog.sending.copy')}
                    </Button>
                    <Button
                        link
                        href="https://www.deepl.com/translator"
                        extraClasses="notifier-button"
                        target="_blank"
                    >
                        <Icon appearance="primary" InlineIcon={IconNewTab} />
                        {translate('notifier.dialog.sending.deepl')}
                    </Button>
                </FormRow>
            )}
            <FormRow>
                <Button onClick={onCancel}>
                    {translate('notifier.dialog.button_cancel')}
                </Button>
                <Button
                    appearance="secondary"
                    onClick={callSend}
                    disabled={
                        loading || !form.validSubject || !form.validMessage
                    }
                    loading={loading}
                >
                    {loading
                        ? translate('notifier.dialog.button_sending')
                        : translate('notifier.dialog.button_send')}
                </Button>
            </FormRow>
        </React.Fragment>
    );
}
MessageField.propTypes = {
    orders: PropTypes.array,
    paxList: PropTypes.array,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    uid: PropTypes.string,
    placeholders: PropTypes.array,
    sendMode: PropTypes.string,
    currentLang: PropTypes.string,
};
MessageField.defaultProps = {
    orders: [],
    paxList: [],
    onCancel: () => {},
    title: '',
    content: '',
    uid: '',
    placeholders: [],
    sendMode: '',
    currentLang: '',
};
