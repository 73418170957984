import React, { useEffect, useState } from 'react';
import { Button, FormRow, SelectGroup } from '@flixbus/honeycomb-react';
import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';

import './styles.scss';

export default function OrdersFilters({ onFilterSet, filters, onReset }) {
    const [status, setStatus] = useState(filters['status']);
    const [ghost, setGhost] = useState(filters['ghost']);

    useEffect(() => {
        setStatus(filters['status']);
        setGhost(filters['ghost']);
    }, [filters]);

    function onChange(event) {
        const { name, value, checked } = event.target;

        if (name.search('status') !== -1) {
            const newValue = checked ? value : '';
            setStatus(newValue);
            onFilterSet({ status: newValue });
        }
        if (name === 'ghost') {
            setGhost(checked);
            onFilterSet({ ghost: checked });
        }
    }

    const statusOptions = [
        {
            id: 'checked',
            label: 'Checked',
            value: 'checked',
            checked: status === 'checked',
            name: 'status-checked',
            onChange,
        },
        {
            id: 'unchecked',
            label: 'Unchecked',
            value: 'unchecked',
            checked: status === 'unchecked',
            name: 'status-unchecked',
            onChange,
        },
        {
            id: 'absent',
            label: 'Absent',
            value: 'absent',
            checked: status === 'absent',
            name: 'status-absent',
            onChange,
        },
        {
            id: 'ghost',
            label: 'Ghost',
            value: 'ghost',
            checked: ghost === true,
            name: 'ghost',
            onChange,
        },
    ];

    return (
        <div className="orders-filters">
            <FormRow last extraClasses="orders-filters__select">
                <SelectGroup
                    label="PAX Status"
                    options={statusOptions}
                    id="checkin-status"
                    multi={true}
                    value={status}
                    onChange={onChange}
                />
            </FormRow>

            <Button
                onClick={onReset}
                disabled={!Object.keys(filters).length}
                extraClasses="orders-filters__reset"
            >
                <Icon InlineIcon={IconClose} />
                Clear Filters
            </Button>
        </div>
    );
}
