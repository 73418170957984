import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Checkbox,
    Input,
    Text,
    Button,
    Tooltip,
} from '@flixbus/honeycomb-react';
import {
    Icon,
    IconClose,
    IconSeat,
    IconBike,
    IconLock,
    IconWheelchair,
} from '@flixbus/honeycomb-icons-react';

export default function SeatsLockerForm(props) {
    const {
        seatsLocked,
        totalSeats,
        bikeSlotsLocked,
        totalBikeSlots,
        wheelchairsLocked,
        totalWheelchairs,
        lockSeats,
        setMaxBikes,
        setMaxSeats,
        setMaxWheelchairs,
        onClose,
        onSeatsChange,
        onBikeChange,
        onWheelchairChange,
        isProcessing,
        translate,
        segmentId,
    } = props;

    const [prevWheelchairsLocked] = useState(wheelchairsLocked);
    const [prevBikeSlotsLocked] = useState(bikeSlotsLocked);
    const [prevSeatsLocked] = useState(seatsLocked);
    const [allToNextSegments, setAllToNextSegments] = useState(0);

    const formClassName = classNames('time-line-seats-locker__form', {
        'time-line-seats-locker__form--processing': isProcessing,
        'ride-view-spinner--before': isProcessing,
    });

    const toggleAllToNextSegments = () => {
        const nextValue = Boolean(!allToNextSegments);
        setAllToNextSegments(Number(nextValue));
    };

    const seatLockHanlder = () => {
        const seatsToLock =
            seatsLocked > totalSeats ? prevSeatsLocked : seatsLocked;
        const bikesToLock =
            bikeSlotsLocked > totalBikeSlots
                ? prevBikeSlotsLocked
                : bikeSlotsLocked;
        const wheelchairsToLock =
            wheelchairsLocked > totalWheelchairs
                ? prevWheelchairsLocked
                : wheelchairsLocked;

        const submit = lockSeats({
            seatsLocked: seatsToLock,
            bikeSlotsLocked: bikesToLock,
            wheelchairsLocked: wheelchairsToLock,
            prevSeatsLocked,
            prevBikeSlotsLocked,
            prevWheelchairsLocked,
            fromSegment: segmentId,
            allToNextSegments: Boolean(allToNextSegments),
        });

        submit();
    };

    function disableButton() {
        return (
            seatsLocked > totalSeats ||
            bikeSlotsLocked > totalBikeSlots ||
            wheelchairsLocked > totalWheelchairs
        );
    }
    return (
        <div className={formClassName}>
            <div className="time-line-seats-locker__form-wrapper">
                <Icon
                    appearance="primary"
                    extraClasses="time-line-seats-locker__cancel"
                    size={2}
                    InlineIcon={IconClose}
                    onClick={onClose}
                />

                <Text>{translate('segments.locking-capacity')}</Text>

                <div className="time-line-seats-locker__row">
                    <div className="time-line-seats-locker__input-wrapper">
                        <Tooltip
                            id="lock-seats-tooltip"
                            content={translate('icon_hint.lock_all_seats')}
                            openOnHover
                        >
                            <span>
                                <Icon
                                    appearance="primary"
                                    size={3}
                                    InlineIcon={IconLock}
                                    onClick={() => {
                                        setMaxSeats(totalSeats);
                                    }}
                                />
                            </span>
                        </Tooltip>
                        <Input
                            type="number"
                            min="0"
                            max={totalSeats}
                            onChange={onSeatsChange}
                            value={seatsLocked}
                            iconLeft={
                                <Icon
                                    appearance="primary"
                                    InlineIcon={IconSeat}
                                />
                            }
                            extraClasses={
                                seatsLocked > totalSeats ? 'lock-input' : ''
                            }
                            aria-label="seats"
                            id="seats"
                        />
                    </div>

                    {totalBikeSlots > 0 ? (
                        <div className="time-line-seats-locker__input-wrapper">
                            <Tooltip
                                id="lock-seats-tooltip"
                                content={translate('icon_hint.lock_all_slots')}
                                openOnHover
                            >
                                <span>
                                    <Icon
                                        appearance="primary"
                                        size={3}
                                        InlineIcon={IconLock}
                                        onClick={() => {
                                            setMaxBikes(totalBikeSlots);
                                        }}
                                    />
                                </span>
                            </Tooltip>
                            <Input
                                type="number"
                                min="0"
                                max={totalBikeSlots}
                                onChange={onBikeChange}
                                value={bikeSlotsLocked}
                                iconLeft={
                                    <Icon
                                        appearance="primary"
                                        InlineIcon={IconBike}
                                    />
                                }
                                extraClasses={
                                    bikeSlotsLocked > totalBikeSlots
                                        ? 'lock-input'
                                        : ''
                                }
                                aria-label="bikes"
                                id="bikes"
                            />
                        </div>
                    ) : null}

                    {totalWheelchairs > 0 ? (
                        <div className="time-line-seats-locker__input-wrapper">
                            <Tooltip
                                id="lock-seats-tooltip"
                                content={translate(
                                    'icon_hint.lock_all_wheelchairs'
                                )}
                                openOnHover
                            >
                                <span>
                                    <Icon
                                        appearance="primary"
                                        size={3}
                                        InlineIcon={IconLock}
                                        onClick={() => {
                                            setMaxWheelchairs(totalWheelchairs);
                                        }}
                                    />
                                </span>
                            </Tooltip>
                            <Input
                                type="number"
                                min="0"
                                max={totalWheelchairs}
                                onChange={onWheelchairChange}
                                value={wheelchairsLocked}
                                iconLeft={
                                    <Icon
                                        appearance="primary"
                                        InlineIcon={IconWheelchair}
                                    />
                                }
                                extraClasses={
                                    wheelchairsLocked > totalWheelchairs
                                        ? 'lock-input'
                                        : ''
                                }
                                aria-label="wheelchairs"
                                id="wheelchairs"
                            />
                        </div>
                    ) : null}
                </div>

                <div className="time-line-seats-locker__control time-line-seats-locker__control--bulk">
                    <Checkbox
                        label={translate('segment.all_to_next_segment')}
                        id="all_to_next_segment"
                        value={allToNextSegments}
                        onChange={toggleAllToNextSegments}
                        small
                    />
                </div>
                <div className="time-line-seats-locker__control">
                    <Button
                        appearance="tertiary"
                        onClick={seatLockHanlder}
                        disabled={disableButton()}
                    >
                        {translate('segment.lock')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

SeatsLockerForm.propTypes = {
    // amount of locked seats
    seatsLocked: PropTypes.number.isRequired,
    // bus seats capacity
    totalSeats: PropTypes.number.isRequired,
    // amount of locked bike slots
    bikeSlotsLocked: PropTypes.number.isRequired,
    // bus slots capacity
    totalBikeSlots: PropTypes.number.isRequired,
    // amount of locked bike slots
    wheelchairsLocked: PropTypes.number.isRequired,
    // bus wheelchairs capacity
    totalWheelchairs: PropTypes.number.isRequired,
    // functions returns submit callback that sets locked seats and slots
    lockSeats: PropTypes.func.isRequired,
    // function that set max value to lock  according bus capacity
    setMaxBikes: PropTypes.func.isRequired,
    // function that set max value to lock  according bus capacity
    setMaxSeats: PropTypes.func.isRequired,
    // function that set max value to lock  according bus capacity
    setMaxWheelchairs: PropTypes.func.isRequired,
    // handler to close form
    onClose: PropTypes.func.isRequired,
    // handler to change form values
    onSeatsChange: PropTypes.func.isRequired,
    // handler to change form values
    onBikeChange: PropTypes.func.isRequired,
    // handler to change form values
    onWheelchairChange: PropTypes.func.isRequired,
    // flag for request on going
    isProcessing: PropTypes.bool.isRequired,
    // error text is request is fail
    error: PropTypes.string,
    translate: PropTypes.func,
};

SeatsLockerForm.defaultProps = {
    error: '',
    translate: (key) => key,
};
