import React from 'react';
import PropTypes from 'prop-types';

export default function IFrame(props) {
    const {
        src, title, refIframe, ...restProps
    } = props;
    return <iframe scrolling="no" src={src} title={title} ref={refIframe} {...restProps} />;
}

IFrame.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    refIframe: PropTypes.func,
};

IFrame.defaultProps = {
    refIframe: () => {},
};
