import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DispatcherProvider as Dispatcher } from './components/System/Dispatcher';
import { msalInstance } from './auth';
import App from './components/App';
import './DataDog';
import './index.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity
        }
    }
});

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <Dispatcher>
            <ThemeWrapper>
                <MainWrapper extraClasses="rideviewer-main-wrapper">
                    <QueryClientProvider client={queryClient}>
                        <App />
                        <ReactQueryDevtools />
                    </QueryClientProvider>
                </MainWrapper>
            </ThemeWrapper>
        </Dispatcher>
    </MsalProvider>,
    document.getElementById('root')
);
