import React from 'react';
import PropTypes from 'prop-types';

export default function DelayTimesetChanges(props) {
    const { timeset } = props;
    const segmentIds = Object.keys(timeset);

    const collection = segmentIds.reduce((output, segmentId) => {
        const timesetItem = timeset[segmentId];

        const component = (
            <li key={segmentId}>
                <strong>{ timesetItem.station_from.code } </strong>
                { timesetItem.station_from.departure_time } &rarr;
                <strong>{ timesetItem.station_to.code } </strong>
                { timesetItem.station_to.arrival_time }
            </li>);
        output.push(component);

        return output;
    }, []);

    return <ul className="confirmed-delay-time-set-changes">{ collection }</ul>;
}

DelayTimesetChanges.propTypes = {
    timeset: PropTypes.object.isRequired,
};
