import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Toolbar provides static bar with sets group of action buttons
 */
export default function Toolbar(props) {
    const { className, children, ...restProps } = props;
    const classNamesJoin = classNames(['timeline-toolbar', className]);
    return (
        <div className={classNamesJoin} {...restProps}>
            {children}
        </div>
    );
}

Toolbar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

Toolbar.defaultProps = {
    className: '',
    children: null,
};
