import React from 'react';
import PropTypes from 'prop-types';
import Dispatcher from './Dispatcher';
import Context from './DispatcherContext';

function DispatcherProvider(props) {
    const { children } = props;
    const dispatcher = new Dispatcher();
    const { on, off, trigger } = dispatcher;
    return <Context.Provider value={{ on, off, trigger }}>{children}</Context.Provider>;
}

DispatcherProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DispatcherProvider;
