import React from 'react';
import PropTypes from 'prop-types';

export default function WithTimelineHad(Component) {
    return class WithTimelineHad extends React.Component {
        static propTypes = {
            timeline: PropTypes.arrayOf(PropTypes.shape()).isRequired,
            rideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            rideUuid: PropTypes.string.isRequired,
        };

        constructor(props) {
            super(props);
            const { timeline } = props;
            const hadStopsCollection = {};
            const hadSegmentsCollection = {};

            timeline.reduce((previousValue, item, index, array) => {
                if (item.entry_type !== 'stop') {
                    hadSegmentsCollection[item.id] = {
                        arrival_delay: null,
                        departure_delay: null,
                    };

                    return previousValue;
                }

                const delayedItem = {
                    arrivalDelay: 0,
                    departureDelay: 0,
                    previousStopId:
                        previousValue != null ? previousValue.item.id : null,
                    nextStopId: null,
                    item,
                    itemIndex: index,
                    prevSegmentId: null,
                    nextSegmentId: null,
                    delayHasBeenRemoved: false,
                };

                if (delayedItem.previousStopId !== null) {
                    hadStopsCollection[delayedItem.previousStopId].nextStopId =
                        delayedItem.item.id;
                }

                // add segments id to the stops collection
                if (item.entry_type === 'stop') {
                    if (
                        previousValue &&
                        array[index - 1] &&
                        array[index - 1]?.entry_type === 'segment'
                    ) {
                        delayedItem.prevSegmentId = array[index - 1].id;
                    }
                    if (
                        array[index + 1] &&
                        array[index + 1]?.entry_type === 'segment'
                    ) {
                        delayedItem.nextSegmentId = array[index + 1].id;
                    }
                }

                hadStopsCollection[item.id] = delayedItem;

                return delayedItem;
            }, null);

            this.state = {
                requestPending: false,
                bigDelayNotify: false,
                affectedSegments: [],
                hadStopsCollection,
                hadSegmentsCollection,
                sendNotifyProgress: false,
            };
        }

        resetDelay = (id) => {
            const { hadSegmentsCollection } = this.state;

            hadSegmentsCollection[id] = {
                arrival_delay: null,
                departure_delay: null,
            };

            this.setState({
                hadSegmentsCollection,
            });
        };

        resetAllDelayChanges = () => {
            const { hadSegmentsCollection } = this.state;

            for (const id in hadSegmentsCollection) {
                this.resetDelay(id);
            }
        };

        removeDelay = (stopId) => {
            const { hadStopsCollection, hadSegmentsCollection } = this.state;

            if (hadStopsCollection[stopId].prevSegmentId) {
                const prevSegmentId = hadStopsCollection[stopId].prevSegmentId;
                hadSegmentsCollection[prevSegmentId].departure_delay = 0;
            }

            if (hadStopsCollection[stopId].nextSegmentId) {
                const nextSegmentId = hadStopsCollection[stopId].nextSegmentId;
                hadSegmentsCollection[nextSegmentId].arrival_delay = 0;
            }

            if (hadStopsCollection[stopId]?.item) {
                hadStopsCollection[stopId].item.arrival_delay = 0;
                hadStopsCollection[stopId].item.departure_delay = 0;
            }

            hadStopsCollection[stopId].arrivalDelay = 0;
            hadStopsCollection[stopId].departureDelay = 0;
            hadStopsCollection[stopId].delayHasBeenRemoved = true;

            this.setState({
                hadStopsCollection,
                hadSegmentsCollection,
            });
        };

        removeDelaysToTheRest = (stops) => {
            // otherwise remove all the rest
            stops.forEach((id) => {
                this.removeDelay(id);
            });
        };

        sendNotify = () => {
            const { sendPaxRightsNotification } = this.props;
            const { affectedSegments, segmentsDepartureDelays } = this.state;
            this.setState({ sendNotifyProgress: true });
            return sendPaxRightsNotification(
                affectedSegments,
                segmentsDepartureDelays
            ).then(() => {
                this.closeBigDelayNotify();
            });
        };

        closeBigDelayNotify = () => {
            this.setState({ bigDelayNotify: false, sendNotifyProgress: false });
        };

        setStopDelay = (stopId) => (arrivalDelay, departureDelay) => {
            const { hadStopsCollection } = this.state;

            hadStopsCollection[stopId].arrivalDelay = arrivalDelay || 0;
            hadStopsCollection[stopId].departureDelay = departureDelay || 0;

            this.setState({
                hadStopsCollection,
            });
        };

        getPrevStopDelay = (stopId) => () => {
            const { hadStopsCollection } = this.state;
            const prevId = hadStopsCollection[stopId].previousStopId;
            if (prevId === null) {
                return null;
            }
            const prevDelay = hadStopsCollection[prevId];

            return prevDelay;
        };

        extendDelay = (stopId) => {
            const { hadStopsCollection } = this.state;
            const currentStop = hadStopsCollection[stopId];

            let delayToAdd =
                currentStop.departureDelay !== 0
                    ? currentStop.departureDelay
                    : currentStop.arrivalDelay;

            if (delayToAdd <= 0) delayToAdd = 0;

            if (
                typeof hadStopsCollection[currentStop.nextStopId] !==
                'undefined'
            ) {
                // first remove the existing delay
                this.removeDelay(currentStop.nextStopId);

                const nextStop = hadStopsCollection[currentStop.nextStopId];

                nextStop.arrivalDelay = delayToAdd;
                nextStop.departureDelay = delayToAdd;

                hadStopsCollection[currentStop.nextStopId] = nextStop;

                this.setState({ hadStopsCollection });
                this.extendDelay(currentStop.nextStopId);
            }

            this.setState({ hadStopsCollection });
        };

        getStopBreakTime(stop) {
            const arrivalDate = new Date(Date.parse(stop.arrival));
            const departureDate = new Date(Date.parse(stop.departure));
            const stopBreakSeconds =
                (departureDate.getTime() - arrivalDate.getTime()) / 1000;

            return stopBreakSeconds;
        }

        saveDelays = (remove) => {
            const { hadStopsCollection, hadSegmentsCollection } = this.state;
            this.setState({ requestPending: true }, () => {
                this.props
                    .saveTimelineDelays(
                        hadStopsCollection,
                        hadSegmentsCollection,
                        remove
                    )
                    .then((resp) => {
                        const { new_confirmed_delays: confirmedDelays } = resp;
                        const requiredNotify = Object.keys(confirmedDelays)
                            .filter((key) => {
                                return confirmedDelays[key]
                                    .isRequireNotification;
                            })
                            .map((item) => Number(item));

                        const segmentsDepartureDelays = {};

                        for (const [
                            segmentId,
                            confirmedDelay,
                        ] of Object.entries(confirmedDelays)) {
                            if (
                                requiredNotify.indexOf(Number(segmentId)) > -1
                            ) {
                                segmentsDepartureDelays[String(segmentId)] =
                                    confirmedDelay.absDepartureDelay;
                            }
                        }

                        if (requiredNotify.length > 0) {
                            this.setState({
                                bigDelayNotify: true,
                                affectedSegments: requiredNotify,
                                segmentsDepartureDelays:
                                    segmentsDepartureDelays,
                            });
                        }

                        this.resetAllDelayChanges();

                        return resp;
                    })
                    .finally(() => {
                        this.setState({ requestPending: false });
                    });
            });
        };

        render() {
            const {
                requestPending,
                hadStopsCollection,
                bigDelayNotify,
                sendNotifyProgress,
            } = this.state;
            return (
                <Component
                    {...this.props}
                    hadCollection={hadStopsCollection}
                    setStopDelay={this.setStopDelay}
                    removeDelay={this.removeDelay}
                    removeDelaysToTheRest={this.removeDelaysToTheRest}
                    applyStopDelay={this.saveDelays}
                    getPrevStopDelay={this.getPrevStopDelay}
                    extendDelay={this.extendDelay}
                    hadSavePending={requestPending}
                    bigDelayNotify={bigDelayNotify}
                    sendNotify={this.sendNotify}
                    closeBigDelayNotify={this.closeBigDelayNotify}
                    sendNotifyProgress={sendNotifyProgress}
                />
            );
        }
    };
}
