import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function Sale(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);

    return (
        <div>
            {translate('sale.sale')} <strong>{ item.parameters.from.name }</strong> →
            <strong>{ item.parameters.to.name } </strong>
            { item.parameters.adult
                ? <span> | {translate('sale.adult')} <strong>{ item.parameters.adult }</strong></span> : '' }
            { item.parameters.children
                ? <span> | {translate('sale.children')} <strong>{ item.parameters.children }</strong></span> : '' }
            { item.parameters.bikes_slots
                ? <span> | {translate('sale.bikes-slots')} <strong>{ item.parameters.bikes_slots }</strong></span> : '' }
            { item.parameters.label
                ? <span> | {translate('sale.name')} <strong>{ item.parameters.label }</strong></span> : '' }
        </div>
    );
}

Sale.propTypes = {
    item: PropTypes.object.isRequired,
};
