import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    AutocompleteOptions,
    Input,
} from '@flixbus/honeycomb-react';
import { useFilter } from '../hooks';
import { Icon } from '@flixbus/honeycomb-icons-react';

export default function ListPicker(props) {
    const {
        id,
        onChange,
        options,
        label,
        placeholder,
        shouldResetValue,
        InlineIcon,
        value: innerVal,
        loading,
        processorOptions,
        ...rest
    } = props;
    const [filteredOptions, filter] = useFilter(
        options,
        'search',
        processorOptions
    );
    const [value, setValue] = useState();
    const [results, setResults] = useState(null);
    const [query, setQuery] = useState('');
    const [multiOptions, setMultiOptions] = useState();
    const { multiselect } = rest;

    const mapInnerValueToValue = useCallback(() => {
        if (Array.isArray(innerVal) && multiselect && options.length > 0) {
            const mlpOpts = innerVal.map((val) =>
                options.reduce(
                    (acc, opt) => (opt.value === val ? opt : acc),
                    {}
                )
            );
            setMultiOptions(mlpOpts);
        } else {
            const searchValue = options.reduce((acc, item) => {
                if (item.value === innerVal) {
                    return item.title;
                }
                return acc;
            }, '');
            setValue(searchValue);
        }
    }, [options, setValue, innerVal, setMultiOptions, multiselect]);

    // Track if a search query is empty to do not show options list
    // with initial render
    useEffect(() => {
        if (query !== '') {
            setResults(filteredOptions);
        } else {
            setResults(null);
        }
    }, [query, setResults, filteredOptions]);

    // match income value with options and set alias
    useEffect(() => {
        mapInnerValueToValue();
    }, [mapInnerValueToValue]);

    // do not show list while change options list with new response/sets
    // and update it while it is active with search query
    useEffect(() => {
        if (results !== null && filteredOptions !== results) {
            setResults(filteredOptions);
        }
    }, [options, filteredOptions, setResults, results]);

    // filter options with query and options changed
    useEffect(() => {
        filter(query);
    }, [query, options, filter]);

    return (
        <Autocomplete
            id={id}
            options={results === null ? [] : results.slice(0, 20)}
            value={value}
            onChange={(e) => {
                // set the same value in to different state
                // to track actions differently with useEffect
                setValue(e.target.value);
                setQuery(e.target.value);
            }}
            onSelect={(item) => {
                setResults(null);
                // reset query to prevent of showing list after re-render
                setQuery('');
                if (shouldResetValue() === true) {
                    setValue('');
                } else {
                    setValue(item.title);
                }
                onChange(item.value);
            }}
            onFocus={() => {
                if (query) {
                    filter(query);
                }
            }}
            optionsSelected={multiOptions}
            {...rest}
        >
            <Input
                label={label}
                id={`${id}-autocomplete-input`}
                placeholder={placeholder}
                iconLeft={<Icon appearance="primary" InlineIcon={InlineIcon} />}
                type="search"
                autoComplete="off"
                loading={loading}
            />
            <AutocompleteOptions
                label={`${label}-options`}
                optionHasSubtitle={false}
            />
        </Autocomplete>
    );
}

ListPicker.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    shouldResetValue: PropTypes.func,
};
ListPicker.defaultProps = {
    onChange: () => {},
    options: [],
    shouldResetValue: () => false,
};
