import React from 'react';
import PropTypes from 'prop-types';
import Switcher from '../UI/Switcher';

export default function BlockSurveyMails(props) {
    const { isEnabled, toggleSurveyMail, inProcess } = props;
    return <Switcher isOn={isEnabled} onChange={toggleSurveyMail} inProcess={inProcess} legend="Survey email" />;
}

BlockSurveyMails.propTypes = {
    isEnabled: PropTypes.bool.isRequired,
    toggleSurveyMail: PropTypes.func.isRequired,
    inProcess: PropTypes.bool,
};

BlockSurveyMails.defaultProps = {
    inProcess: false,
};
