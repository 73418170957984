import { useReducer, useCallback, useEffect } from 'react';
import WsSubscriber from '../../../ws/WsSubscriber';

const reducer = (state, payload) => {
    const { id, cb } = payload;
    return { ...state, [id]: cb };
};

export default function useWsStatusUpdates(topic) {
    const [subscribers, dispatch] = useReducer(reducer, {});
    const ws = WsSubscriber();

    const subscribe = useCallback(
        (id, cb) => {
            dispatch({ id, cb });
        },
        [dispatch]
    );

    const onMessage = useCallback(
        (message) => {
            const data = message[0];
            const cb = subscribers[data.ticketId];

            if (typeof cb === 'function') {
                cb(data.status);
            }
        },
        [subscribers]
    );

    useEffect(() => {
        if (topic !== null && ws) {
            ws.subscribe(topic, onMessage);
        }

        return () => {
            if (ws) {
                ws.unsubscribe(topic, onMessage);
            }
        };
    }, [topic, onMessage, subscribers, ws]);

    return subscribe;
}
