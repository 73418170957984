import React from 'react';
import PropTypes from 'prop-types';
import BarBox from '../../UI/BarBox';
import ListItem from './ListItem';

export default function SearchResults(props) {
    const { results, loading, selectRide, selectedRides, expanded, withSelection } = props;
    const resultsLength = results.length;
    const listItems = results.map((item) => {
        const selectedRide = selectedRides.some(
            (ride) => ride.uuid === item.uuid
        );

        return (
            <BarBox
                hoverStyle
                loading={loading}
                key={Math.random() + resultsLength}
            >
                <ListItem
                    withSelection={withSelection}
                    collapsible
                    item={item}
                    selectRide={selectRide}
                    isRideSelected={selectedRide}
                    isExpanded={expanded}
                />
            </BarBox>
        );
    });

    return listItems;
}

SearchResults.propTypes = {
    results: PropTypes.array,
    selectRide: PropTypes.func,
    selectedRides: PropTypes.array,
    expanded: PropTypes.bool,
    withSelection: PropTypes.bool,
};
SearchResults.defaultProps = {
    results: [],
    selectRide: () => {},
    selectedRides: [],
    expanded: false,
    withSelection: true,
};
