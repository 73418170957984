import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { TimeInput } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';

const options = [
    '00:00',
    '01:05',
    '02:10',
    '03:15',
    '04:20',
    '05:25',
    '06:30',
    '07:35',
    '08:40',
    '09:45',
    '10:50',
    '11:55',
    '12:00',
    '13:05',
    '14:10',
    '15:15',
    '16:20',
    '17:25',
    '18:30',
    '19:35',
    '20:40',
    '21:45',
    '22:50',
    '23:55',
];

export default function TimeHoursAndMinsInput(props) {
    const { value: propValue, label, onChange, id } = props;
    const [value, setValue] = useState(propValue);
    const translate = useContext(TranslateContext);

    useEffect(() => {
        setValue(propValue);
    }, [propValue, setValue]);

    const onSelect = (value) => {
        // fix HR issue with hours starting with 0
        value = value.toString().length < 5 ? `0${value}` : value;

        onChange(value);
    };

    return (
        <TimeInput
            id={id}
            ariaLabelHours={translate('ride-search.select-hours')}
            ariaLabelMinutes={translate('ride-search.select-minutes')}
            defaultValue={value}
            label={label}
            options={options}
            showMinutes
            onChange={(value) => onSelect(value)}
            extraClasses="time-input-white-bg"
        />
    );
}
TimeHoursAndMinsInput.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
TimeHoursAndMinsInput.defaultProps = {
    value: '',
    label: '',
    onChange: () => {},
};
