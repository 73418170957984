import {
    PublicClientApplication,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';
import {
    msalConfig,
    msalSilentRequest,
    megadriveApplication,
    megadriveNewApplication,
    actionLoggerApplication,
    commentsBankApplication,
    paxBankApplication,
    tagsBankApplication,
    rebookrApplication,
    fireflyScope,
    customerNotifierV2Application,
} from './adalConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export const MEGADRIVE_SCOPE = 'megadirve';
export const MEGADRIVE_NEW_SCOPE = 'megadirveNew';
export const ACTION_LOGGER_SCOPE = 'action_logger';
export const CUSTOMER_NOTIFIER_V2_SCOPE = 'customer_notifier_v2';
export const COMMENTS_BANK_SCOPE = 'comments_bank';
export const PAX_BANK_SCOPE = 'pax_bank';
export const TAGS_BANK_SCOPE = 'tags_bank';
export const REBOOKR_SCOPE = 'rebookr';
export const FIREFLY_SCOPE = 'firefly';

// scopes for different jwt tokens for another APIs
const scopes = {
    default: msalSilentRequest,
    [MEGADRIVE_SCOPE]: megadriveApplication,
    [MEGADRIVE_NEW_SCOPE]: megadriveNewApplication,
    [ACTION_LOGGER_SCOPE]: actionLoggerApplication,
    [COMMENTS_BANK_SCOPE]: commentsBankApplication,
    [PAX_BANK_SCOPE]: paxBankApplication,
    [TAGS_BANK_SCOPE]: tagsBankApplication,
    [REBOOKR_SCOPE]: rebookrApplication,
    [FIREFLY_SCOPE]: fireflyScope,
    [CUSTOMER_NOTIFIER_V2_SCOPE]: customerNotifierV2Application,
};

export function getToken(authScope = 'default') {
    return new Promise((resolve, reject) => {
        const scope = scopes[authScope];
        msalInstance
            .acquireTokenSilent({
                ...scope,
                account: msalInstance.getAllAccounts()[0],
            })
            .then((response) => {
                resolve(response.accessToken);
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    return msalInstance
                        .acquireTokenPopup({
                            ...scope,
                            account: msalInstance.getAllAccounts()[0],
                        })
                        .then((response) => {
                            resolve(response.accessToken);
                        });
                }
                return e;
            })
            .catch((e) => reject(new Error(`Auth fail with error: ${e}`)));
    });
}

/**
 * Set a get token functions with predefined scope
 * @func getTokenScoped
 * @param {string} scope scope alias
 * @returns {func}
 */
export function getTokenScoped(scope) {
    return function () {
        return getToken(scope);
    };
}

export function getUser() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts[0]) {
        return {
            profile: accounts[0].idTokenClaims,
            userName: accounts[0].username,
        };
    }
    return null;
}
