import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconBike, IconBikeSolid } from '@flixbus/honeycomb-icons-react';

export default function BikesOccupancy(props) {
    const { free, occupied } = props;
    return (
        <div className="timeline-segment-seats">
            <div
                className="timeline-segment-seats-row timeline-segment-seats-row--occupied"
                title="occupied"
            >
                <Icon InlineIcon={IconBikeSolid} />
                <strong>{occupied}</strong>
            </div>
            <div
                className="timeline-segment-seats-row timeline-segment-seats-row--occupied"
                title="free"
            >
                <Icon InlineIcon={IconBike} />
                <span>{free}</span>
            </div>
        </div>
    );
}
BikesOccupancy.propTypes = {
    occupied: PropTypes.number,
    free: PropTypes.number,
};
BikesOccupancy.defaultProps = { occupied: null, free: null };
