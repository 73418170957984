import { compose } from 'recompose';
import StationActions from './StationActions';
import withStationStatusApi from './hocs/withStationStatusApi';
import withStationStatusWsSubscriber from './hocs/withStationStatusWsSubscriber';
import withNotifyConsumer from '../../../../NotificationSystem/context/hocs/withNotifyConsumer';

export default compose(
    withNotifyConsumer,
    withStationStatusApi,
    withStationStatusWsSubscriber
)(StationActions);
