import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';

export default function withAutoModeControl(Component) {
    return class WithAutoModeControl extends React.Component {
        static propTypes = {
            enabled: PropTypes.bool.isRequired,
            rideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        };
        constructor(props) {
            super(props);
            this.state = {
                enabled: this.props.enabled,
                inProcess: false,
            };
        }
        componentWillUnmount() {
            const { rideId } = this.props;
            api.cancel(`/ride/${rideId}/delay/automatic-mode`);
        }
        setAutomaticMode = () => {
            const { enabled } = this.state;
            const { rideId } = this.props;
            this.setState({ inProcess: true });
            api.post(`/ride/${rideId}/delay/automatic-mode`, {
                is_enabled: !enabled,
                source: 'rideviewer',
            })
                .then((status) => {
                    this.setState({
                        enabled: status.is_delay_processing_enabled,
                        inProcess: false,
                    });
                })
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        alert(error.message);
                        this.setState({ inProcess: false });
                    }
                });
        };
        render() {
            const { enabled, inProcess } = this.state;
            return (
                <Component
                    {...this.props}
                    enabled={enabled}
                    inProcess={inProcess}
                    setAutomaticMode={this.setAutomaticMode}
                />
            );
        }
    };
}
