import React, { useState, useContext } from 'react';
import { TranslateContext } from '../../System/Translations';
import { Button } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconNoEntrySolid,
    IconPlaySolid,
} from '@flixbus/honeycomb-icons-react';
import CancelConfirm from '../CancelConfirm/CancelConfirm';

const CancelRideButton = (props) => {
    const { rideMeta, processCancellation, isLoading } = props;
    const translate = useContext(TranslateContext);
    const [dialogActive, setDialogActive] = useState(false);

    const openConfirm = () => {
        setDialogActive(true);
    };

    const closeConfirm = () => {
        setDialogActive(false);
    };

    const processRideCancellation = (
        notifyPassengers,
        reasonId,
        reasonText
    ) => {
        processCancellation(notifyPassengers, reasonId, reasonText);
        closeConfirm();
    };

    const ride = rideMeta.ride;
    const label =
        ride && ride.is_cancelled
            ? 'ride-info.uncancel'
            : 'ride-info.cancel_trip';
    const icon = ride && ride.is_cancelled ? IconPlaySolid : IconNoEntrySolid;
    const appearance = ride && ride.is_cancelled ? 'tertiary' : 'danger';

    return (
        <React.Fragment>
            <Button
                onClick={openConfirm}
                appearance={appearance}
                loading={isLoading}
            >
                <Icon InlineIcon={icon} />
                &nbsp;
                {translate(label)}
            </Button>

            {dialogActive ? (
                <CancelConfirm
                    rideMeta={rideMeta}
                    onClose={closeConfirm}
                    onSubmit={processRideCancellation}
                />
            ) : null}
        </React.Fragment>
    );
};

export default CancelRideButton;
