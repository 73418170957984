import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tag } from '@flixbus/honeycomb-react';
import { Icon, IconInfoSolid } from '@flixbus/honeycomb-icons-react';
import delaysCategory from '../../../../util/delaysCategory';

export default function StationDelays(props) {
    const { eta, ata, etaDiff, ataDiff, rideType } = props;

    function getTagCss(delay) {
        return classNames(
            'timeline-station-delays__tag',
            `timeline-station-delays__tag--${delaysCategory(
                delay,
                rideType
            )}-delay`
        );
    }
    return (
        <ul className="timeline-station-delays">
            {eta && (
                <li className="timeline-station-delays__item">
                    <Tag extraClasses={getTagCss(etaDiff)} outlined small>
                        <Icon appearance="primary" InlineIcon={IconInfoSolid} />
                        ETA {eta}
                    </Tag>
                </li>
            )}
            {ata && (
                <li className="timeline-station-delays__item">
                    <Tag extraClasses={getTagCss(ataDiff)} outlined small>
                        <Icon appearance="primary" InlineIcon={IconInfoSolid} />
                        ATA {ata}
                    </Tag>
                </li>
            )}
        </ul>
    );
}
StationDelays.propTypes = {
    eta: PropTypes.string,
    ata: PropTypes.string,
    rideType: PropTypes.oneOf(['bus', 'train']),
};
StationDelays.defaultProps = { eta: null, ata: null };
