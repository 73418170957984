import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Popup, PopupSection, Tooltip } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconChat,
    IconChatSolid,
    IconWifi,
} from '@flixbus/honeycomb-icons-react';
import CommentsTooltip from './compounds/CommentsTooltip';
import CommentsFeed from '../CommentsFeed';
import CommentsForm from '../CommentsForm';
import { TranslateContext } from '../System/Translations';

import './style.css';
import useGetComments from '../CommentsHooks/useGetComments';

export default function PassengerComments({
    passenger,
    initComments = [],
    initLoading = false,
}) {
    const {
        comments: updateComments,
        error: loadError,
        loading,
        getComments,
    } = useGetComments(passenger.ticket_id);

    const [comments, setComments] = useState(initComments);

    useEffect(() => {
        if (updateComments.length > 0) {
            setComments(updateComments);
        }
    }, [updateComments, setComments]);

    useEffect(() => {
        setComments(initComments);
    }, [initComments, setComments]);

    const translate = useContext(TranslateContext);

    const [popupActive, setPopupActive] = useState(false);

    const openPopup = () => {
        setPopupActive(true);
    };

    const closePopup = () => {
        setPopupActive(false);
    };

    const onAddComment = () => {
        getComments();
    };

    const bubbleIcon = useCallback(() => {
        if (loading || initLoading) {
            return IconWifi;
        }

        if (comments.length > 0) {
            return IconChatSolid;
        }
        if (loadError) {
            return () => {
                return <IconChat fill="red" />;
            };
        }
        return IconChat;
    }, [comments, loadError, loading, initLoading]);

    return (
        <React.Fragment>
            <Tooltip
                id={`tooltip-${passenger.ticket_id || passenger.id}`}
                openOnHover
                content={
                    <CommentsTooltip comments={comments} error={loadError} />
                }
            >
                <span className="passenger-comments-action" onClick={openPopup}>
                    <Icon
                        InlineIcon={bubbleIcon()}
                        title={translate('icon_hint.passengers_comments')}
                    />
                </span>
            </Tooltip>
            {popupActive && (
                <Popup active={popupActive} onOverlayClick={closePopup}>
                    <PopupSection type="title">{passenger.name}</PopupSection>
                    <PopupSection type="content">
                        <CommentsFeed comments={comments} />
                        <CommentsForm
                            type="passenger"
                            id={passenger.ticket_id}
                            onCancel={closePopup}
                            onAddComment={onAddComment}
                        />
                    </PopupSection>
                </Popup>
            )}
        </React.Fragment>
    );
}
