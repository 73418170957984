import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@flixbus/honeycomb-react';
import Order from './Order';
import { TranslateContext } from '../System/Translations';
import DataGrid, {
    DataRowGroup,
    DataHeaderGroup,
    DataRow,
    DataCol,
} from '../UI/DataGrid';
import SortHandler from '../ReservationsTable/components/SortHandler';
import withPaxSorting from '../ReservationsTable/hocs/withPaxSorting';

function PaxList(props) {
    const {
        orders,
        selectedPax,
        sortBy,
        activeSort,
        toggleBatch,
        togglePax,
        showMode,
    } = props;
    const translate = useContext(TranslateContext);
    const orderIds = orders.map((order) => order.id);
    const allChecked = orderIds.length === selectedPax.length;

    return (
        <DataGrid>
            <DataHeaderGroup>
                <DataRow header>
                    <DataCol>
                        <div className="reservation-passenger-item">
                            <Checkbox
                                id="select-all-passenger"
                                label={translate(
                                    'reservations.table.passengers'
                                )}
                                value={String(allChecked)}
                                onChange={toggleBatch(orderIds)}
                                checked={allChecked}
                                small
                            />
                        </div>
                    </DataCol>
                    <DataCol>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {translate('reservations.table.language')}
                            <SortHandler
                                sortMethod="order.language"
                                sortBy={sortBy}
                                activeSort={activeSort}
                            />
                        </div>
                    </DataCol>
                    <DataCol>
                        {translate('reservations.table.shop')}
                        <SortHandler
                            sortMethod="order.shop"
                            sortBy={sortBy}
                            activeSort={activeSort}
                        />
                    </DataCol>
                    <DataCol>{translate('reservations.table.contact')}</DataCol>
                </DataRow>
            </DataHeaderGroup>
            {orders.map((order, idx) => (
                <DataRowGroup key={order.id + idx}>
                    <Order
                        order={order}
                        togglePax={togglePax}
                        toggleBatch={toggleBatch}
                        selectedPax={selectedPax}
                        showMode={showMode}
                    />
                </DataRowGroup>
            ))}
        </DataGrid>
    );
}

PaxList.propTypes = {
    orders: PropTypes.array,
    selectedPax: PropTypes.array,
    sortBy: PropTypes.func.isRequired,
    activeSort: PropTypes.object.isRequired,
    currentLang: PropTypes.string.isRequired,
    togglePax: PropTypes.func.isRequired,
    toggleBatch: PropTypes.func.isRequired,
    removeBatch: PropTypes.func.isRequired,
};
PaxList.defaultProps = { orders: [], selectedPax: [] };

export default withPaxSorting(PaxList);
