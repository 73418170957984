import { useCallback, useState } from 'react';
import api from '../../api/CommentsBankApi';

export default function useGetCommentHistory(commentId) {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getCommentHistory = useCallback(() => {
        setLoading(true);
        return api
            .get(`/history/${commentId}`)
            .then((response) => {
                response.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
                setHistory(response);
                setError(null);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [commentId]);

    const reset = () => {
        setHistory([]);
        setError(null);
    };

    return {
        history,
        loading,
        error,
        getCommentHistory,
        reset,
    };
}
