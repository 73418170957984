import React, { useContext } from 'react';
import ProprTypes from 'prop-types';
import { TranslateContext } from '../../System/Translations';
import SelectGroupInput from './SelectGroupInput';

const getWeekDaysOptions = (weekdays) => [
    {
        id: 11,
        value: 'Mon',
        name: 'weekday',
        label: weekdays[0],
        checked: false,
        onChange: () => {},
    },
    {
        id: 21,
        value: 'Tue',
        name: 'weekday',
        label: weekdays[1],
        checked: false,
        onChange: () => {},
    },
    {
        id: 31,
        value: 'Wed',
        name: 'weekday',
        label: weekdays[2],
        checked: false,
        onChange: () => {},
    },
    {
        id: 41,
        value: 'Thu',
        name: 'weekday',
        label: weekdays[3],
        checked: false,
        onChange: () => {},
    },
    {
        id: 51,
        value: 'Fri',
        name: 'weekday',
        label: weekdays[4],
        checked: false,
        onChange: () => {},
    },
    {
        id: 61,
        value: 'Sat',
        name: 'weekday',
        label: weekdays[5],
        checked: false,
        onChange: () => {},
    },
    {
        id: 71,
        value: 'Sun',
        name: 'weekday',
        label: weekdays[6],
        checked: false,
        onChange: () => {},
    },
];
export default function WeekDaysSelect(props) {
    const { onSelect, onUnselect, value } = props;
    const translate = useContext(TranslateContext);
    const weekDays = translate('week-days.full').split(',');

    return (
        <SelectGroupInput
            label={translate('ride-search.weekdays.label')}
            options={getWeekDaysOptions(weekDays)}
            id="weekdays-select"
            onSelect={onSelect}
            onUnselect={onUnselect}
            value={value}
        />
    );
}

WeekDaysSelect.propTypes = {
    onSelect: ProprTypes.func.isRequired,
    onUnselect: ProprTypes.func.isRequired,
    value: ProprTypes.array,
};
WeekDaysSelect.defaultProps = {
    value: [],
};
