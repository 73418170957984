import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popup, PopupSection, Button } from '@flixbus/honeycomb-react';
import { Icon, IconDownload, IconCopy } from '@flixbus/honeycomb-icons-react';
import Translate from '../../System/Translations';

export default class PopupSelectedPaxEmails extends React.Component {
    state = {
        popupIsOpen: false,
        copied: false,
    };

    onCopyToClipboard = () => {
        this.setState({ copied: true });
        setTimeout(() => this.setState({ copied: false }), 2000);
    };

    getSelectedEmails() {
        const { orders, selectedPax } = this.props;
        const selectedEmails = [];
        orders.forEach((order) => {
            order.passengers.forEach((passenger) => {
                if (
                    selectedPax.indexOf(passenger.ticket_id) !== -1 &&
                    passenger.email &&
                    selectedEmails.indexOf(passenger.email) === -1
                ) {
                    selectedEmails.push(passenger.email);
                }
            });
        });

        return selectedEmails;
    }

    togglePopup = () => {
        this.setState((prevState) => ({ popupIsOpen: !prevState.popupIsOpen }));
    };

    render() {
        const selectedEmails = this.getSelectedEmails();
        const emailsCommaSeparated = selectedEmails.join('; ');
        const { copied, popupIsOpen } = this.state;
        return (
            <Translate>
                {(translate) => (
                    <React.Fragment>
                        <span
                            onClick={this.togglePopup}
                            className="timeline-toolbar-icon-button"
                        >
                            <Icon
                                InlineIcon={IconDownload}
                                title={translate('reservations.export_emails')}
                            />
                            {translate('reservations.export_emails')}
                        </span>
                        {popupIsOpen && (
                            <CopyToClipboard
                                text={emailsCommaSeparated}
                                onCopy={this.onCopyToClipboard}
                            >
                                <Popup active onOverlayClick={this.togglePopup}>
                                    <PopupSection type="title">
                                        {translate(
                                            'reservations.select_email.poup_title'
                                        )}
                                    </PopupSection>
                                    <PopupSection type="content">
                                        {emailsCommaSeparated ||
                                            translate(
                                                'reservations.select_email.no_selected_pax'
                                            )}
                                    </PopupSection>
                                    <PopupSection type="actions">
                                        {emailsCommaSeparated && (
                                            <Button
                                                disabled={copied}
                                                appearance="primary"
                                                InlineIcon={IconCopy}
                                            >
                                                {copied
                                                    ? translate(
                                                          'reservations.select_email.copied'
                                                      )
                                                    : translate(
                                                          'reservations.select_email.copy_button'
                                                      )}
                                            </Button>
                                        )}
                                        <Button
                                            appearance="secondary"
                                            onClick={this.togglePopup}
                                        >
                                            {translate(
                                                'reservations.select_email.close_button'
                                            )}
                                        </Button>
                                    </PopupSection>
                                </Popup>
                            </CopyToClipboard>
                        )}
                    </React.Fragment>
                )}
            </Translate>
        );
    }
}
