import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';
import { Icon, IconDocument } from '@flixbus/honeycomb-icons-react';

export default function Attachment(props) {
    const { attachments } = props;
    const translate = useContext(TranslateContext);

    return (
        <React.Fragment>
            <div className="single-comment__attachments">
                <Text>
                    <strong>{translate('attachment.heading')}</strong>
                </Text>
                {attachments.map((attachment, idx) => {
                    const attachmentName = `Attachment ${idx + 1} - ${
                        attachment.mimeType
                    }`;

                    return (
                        <Button
                            link
                            key={attachment.id}
                            Elem="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={attachment.filePath}
                        >
                            {attachment.mimeType.search('image') !== -1 ? (
                                <>
                                    <img
                                        src={attachment.filePath}
                                        alt={attachment.filePath}
                                        style={{
                                            maxWidth: '50px',
                                            maxHeight: '50px',
                                            width: 'auto',
                                            height: 'auto',
                                        }}
                                    />
                                    <span>{attachmentName}</span>
                                </>
                            ) : (
                                <>
                                    <Icon InlineIcon={IconDocument} />{' '}
                                    <span>{attachmentName}</span>
                                </>
                            )}
                        </Button>
                    );
                })}
            </div>
        </React.Fragment>
    );
}

Attachment.propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.object),
};

Attachment.defaultProps = {
    attachments: [],
};
