import React from 'react';

/**
 * React Context to provide i18n system
 * across application components
 * @constant TranslateContext
 */
const TranslateContext = React.createContext();

export default TranslateContext;
