import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Popup,
    PopupSection,
    Button,
    FormRow,
    Input,
    Spinner,
} from '@flixbus/honeycomb-react';
import { Icon, IconLabelSolid } from '@flixbus/honeycomb-icons-react';
import TagGroup from './TagGroup';
import { TranslateContext } from '../../System/Translations';

export default class AddTag extends Component {
    static propTypes = {
        getTagsGroup: PropTypes.func.isRequired,
        addRideTags: PropTypes.func.isRequired,
        skipTagIds: PropTypes.array,
        tagsGroup: PropTypes.array,
        onClose: PropTypes.func.isRequired,
    };

    static defaultProps = {
        skipTagIds: [],
        tagsGroup: [],
    };

    static contextType = TranslateContext;

    state = {
        filter: '',
    };

    componentDidMount() {
        const { getTagsGroup } = this.props;
        this.groupRequestCancel = getTagsGroup();
    }

    componentWillUnmount() {
        if (typeof this.groupRequestCancel === 'function') {
            this.groupRequestCancel();
        }
    }

    addTag = (tagId) => {
        const { addRideTags } = this.props;

        addRideTags([tagId]);
    };

    selectGroup = (e) => {
        const { value } = e.target;
        this.setState({ groupId: value });
    };

    setFilterTags = (e) => {
        const { value } = e.target;
        this.setState({ filter: value });
    };

    filterTags = (tags) => {
        const { filter } = this.state;
        if (!tags) {
            return [];
        }
        if (filter === '') {
            return tags;
        }
        const filterExp = new RegExp(
            filter.replace(
                /(\[|\]|\(|\)|\*|\.|\^|\{|\}|\?|\+|\$|\\|\/)/g,
                '\\$1'
            ),
            'i'
        );
        return tags.filter((tag) => tag.name.match(filterExp) !== null);
    };

    render() {
        const { skipTagIds, tagsGroup, onClose } = this.props;
        const { filter } = this.state;
        const translate = this.context;
        const tagGroupsList = this.filterTags(tagsGroup);

        return (
            <Popup active={true}>
                <PopupSection type="icon">
                    <Icon InlineIcon={IconLabelSolid} size={8} />
                </PopupSection>
                <PopupSection type="title">
                    {translate('tag.add-tag')}
                </PopupSection>
                {tagsGroup.length === 0 ? (
                    <PopupSection type="content">
                        <Spinner size="lg" />
                    </PopupSection>
                ) : (
                    <PopupSection type="content">
                        <FormRow>
                            <Input
                                id="filter-tags-input"
                                label={translate('add-tags.tags.filter')}
                                type="text"
                                onChange={this.setFilterTags}
                                value={filter}
                            />
                        </FormRow>
                        {tagGroupsList && (
                            <TagGroup
                                addRideTag={this.addTag}
                                tags={tagGroupsList}
                                skipTagIds={skipTagIds}
                            />
                        )}
                    </PopupSection>
                )}
                <PopupSection type="actions">
                    <Button appearance="primary" onClick={onClose}>
                        {translate('pagedialog.close')}
                    </Button>
                </PopupSection>
            </Popup>
        );
    }
}
