import React from 'react';
import { Switch } from '@flixbus/honeycomb-react';
import PropTypes from 'prop-types';

/**
 * generates random HEX number
 * @func getInputId
 * @returns {string} random char sequence
 */
function getInputId() {
    return Math.floor(Math.random() * new Date().getTime()).toString(16);
}

export default class Switcher extends React.Component {
    static propTypes = {
        // flag to render spinner if switch process ongoing
        inProcess: PropTypes.bool,
        // state of switcher
        isOn: PropTypes.bool,
        // onchange callback
        onChange: PropTypes.func,
        // title that will be shown next to switcher
        legend: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        // css class name.
        className: PropTypes.string,
    };
    static defaultProps = {
        inProcess: false,
        isOn: false,
        onChange: () => {},
        className: '',
    };
    constructor(props) {
        super(props);
        this.inputId = getInputId();
    }
    render() {
        const { isOn, onChange, legend, className } = this.props;
        return (
            <React.Fragment>
                <Switch
                    label={legend}
                    id={this.inputId}
                    onChange={onChange}
                    checked={isOn}
                    small
                    extraClasses={className}
                />
            </React.Fragment>
        );
    }
}
