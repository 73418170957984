import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Heading, Spinner, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconDelete, IconOffer } from '@flixbus/honeycomb-icons-react';
import TimezoneContext from '../../System/TimezoneHelper/context';
import {
    formatDate,
    getUtcOffset,
    getUtcOffsetLocal,
    timeDiff,
} from '../../../util/date';
import { calculateEta } from '../../../util/calculateEta';
import {
    StationTitle,
    StationSchedule,
    StationActions,
    StationPaxDiff,
    StationDate,
} from './compounds';
import HadForm from '../HadForm';
import useUserCan from '../../../auth/useUserCan';

import {
    TIME_FORMAT,
    DAY_FORMAT,
    STATION_STATUS_OPEN,
    STATION_STATUS_CLOSED,
    STATION_STATUS_CANCELLED,
} from './constants';

import './Station.scss';
import { HadFormContext } from '../../HadForm';
import StationHad from './compounds/StationHad';
import StationDelays from './compounds/StationDelays';
import { TranslateContext } from '../../System/Translations';

/**
 * @TODO
 * all commented code will be clean out after user trial
 *  https://jira.flix.tech/browse/BF-2720
 */
export default function Station(props) {
    const {
        id,
        uuid,
        rideUuid,
        code,
        name,
        status,
        actions,
        arrival,
        departure,
        canManualDelay,
        arrivalDelay,
        departureDelay,
        eta,
        ata,
        allToNextStopsIds,
        extendDelay,
        removeDelaysToTheRest,
        removeDelay,
        setStopDelay,
        applyStopDelay,
        hadSavePending,
        getPrevStopDelay,
        paxIn,
        paxOut,
        isDisabledHAD,
        allToNextSegments,
        setAllToNextSegments,
        time_zone,
        rideType,
    } = props;

    const [altStatus, changeStatus] = useState(status);

    const timezone = useContext(TimezoneContext);
    const { isLocal } = timezone;
    const translate = useContext(TranslateContext);

    // use proper function to format with time zone count or not
    const timeFormatter = isLocal ? formatDate : formatDate.parseZone;

    const arrivalText = arrival && timeFormatter(arrival, TIME_FORMAT);
    const departureText = departure && timeFormatter(departure, TIME_FORMAT);
    const scheduleDate = timeFormatter(departure || arrival, DAY_FORMAT);
    const utc = isLocal
        ? getUtcOffsetLocal(departure || arrival)
        : getUtcOffset(departure || arrival);

    const arrivalDelayedMins =
        arrivalDelay && calculateEta(arrival, arrivalDelay);
    const departureDelayedMins =
        departureDelay && calculateEta(departure, departureDelay);

    const arrivalDelayText =
        arrivalDelayedMins &&
        (isLocal
            ? formatDate(arrivalDelayedMins, TIME_FORMAT)
            : formatDate.tz(arrivalDelayedMins, time_zone, TIME_FORMAT));
    const departureDelayText =
        departureDelayedMins &&
        (isLocal
            ? formatDate(departureDelayedMins, TIME_FORMAT)
            : formatDate.tz(departureDelayedMins, time_zone, TIME_FORMAT));

    const etaText = eta && timeFormatter(eta, TIME_FORMAT);
    const ataText = ata && timeFormatter(ata, TIME_FORMAT);
    const etaDiff = eta && timeDiff(eta, arrival || departure, 'minutes');
    const ataDiff = ata && timeDiff(ata, arrival || departure, 'minutes');

    const isOpen = !altStatus || altStatus === STATION_STATUS_OPEN;
    const isClosed = altStatus === STATION_STATUS_CLOSED;
    const isCancelled = altStatus === STATION_STATUS_CANCELLED;

    const stationClass = classNames('timeline-station', {
        'timeline-station--open': isOpen,
        'timeline-station--closed': isClosed,
        'timeline-station--cancelled': isCancelled,
    });
    const stationBoxClass = classNames('timeline-station-box', {
        'timeline-station-box--disabled': isDisabledHAD,
    });

    const performHad = useUserCan('adding_had');
    const had = useContext(HadFormContext);

    const onRemoveDelay = () => {
        removeDelay(id);
        applyStopDelay(true);
    };

    const stationHadActionClass = classNames('timeline-station-had-action', {
        'timeline-station-had-action--disabled': !canManualDelay,
    });

    return (
        <div className={stationClass} id={code}>
            <StationPaxDiff paxIn={paxIn} paxOut={paxOut} />
            <div className="timeline-station-status">
                {isOpen && translate('station-status-open')}
                {isCancelled && translate('station-status-cancelled')}
                {isClosed && translate('station-status-closed')}
            </div>

            <Box
                extraClasses={`${stationBoxClass} timeline-station-box--first`}
            >
                {altStatus !== '' && (
                    <div className="timeline-station-top-section">
                        <StationActions
                            status={altStatus}
                            actions={actions}
                            id={id}
                            uuid={uuid}
                            rideUuid={rideUuid}
                            onStatusChanged={changeStatus}
                        />
                    </div>
                )}
                <StationTitle code={code} name={name} />
                <hr />
                <StationDate date={scheduleDate} utc={utc} />
                <StationSchedule
                    arrival={arrivalText}
                    departure={departureText}
                    date={scheduleDate}
                    highlight={timezone.isLocal}
                    utc={utc}
                />
            </Box>
            <Box extraClasses={stationBoxClass}>
                <Heading extraClasses="rv-text-center" size={3} flushSpace>
                    {translate('delays')}
                </Heading>
                {!had.isOpen && (
                    <>
                        {arrivalDelay || departureDelay ? (
                            <StationHad
                                arrival={arrivalDelay && arrivalDelayText}
                                departure={departureDelay && departureDelayText}
                                highlight={timezone.isLocal}
                                arrivalDelay={arrivalDelay}
                                departureDelay={departureDelay}
                                rideType={rideType}
                            />
                        ) : null}
                        {eta || ata ? (
                            <StationDelays
                                eta={etaText}
                                ata={ataText}
                                etaDiff={etaDiff}
                                ataDiff={ataDiff}
                                rideType={rideType}
                            />
                        ) : null}
                        {performHad && (
                            <>
                                <hr />
                                <div className="rv-text-justify">
                                    {hadSavePending ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <Spinner size="sm" />
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className="timeline-station-had-action"
                                                onClick={() => {
                                                    if (canManualDelay) {
                                                        had.setOpen(true);
                                                    }
                                                }}
                                            >
                                                {!canManualDelay ? (
                                                    <Tooltip
                                                        id="manual-delay"
                                                        content={translate(
                                                            'had.manual-delay.disabled'
                                                        )}
                                                        openOnHover
                                                        size="content-fit"
                                                        position="top"
                                                    >
                                                        <span
                                                            className={
                                                                stationHadActionClass
                                                            }
                                                        >
                                                            <Icon
                                                                InlineIcon={
                                                                    IconOffer
                                                                }
                                                            />
                                                            {translate(
                                                                'had.manual-delay'
                                                            )}
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <>
                                                        <Icon
                                                            InlineIcon={
                                                                IconOffer
                                                            }
                                                        />
                                                        {translate(
                                                            'had.manual-delay'
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div
                                                className="timeline-station-had-action"
                                                onClick={() => {
                                                    onRemoveDelay(id);
                                                }}
                                            >
                                                <Icon InlineIcon={IconDelete} />
                                                {translate('had.remove-delays')}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
            </Box>

            {had.isOpen && (
                <Box extraClasses={stationBoxClass}>
                    <HadForm
                        id={id}
                        had={had}
                        arrivalDelay={arrivalDelay}
                        departureDelay={departureDelay}
                        departureText={departureText}
                        stopUuid={uuid}
                        allToNextStopsIds={allToNextStopsIds}
                        extendDelay={extendDelay}
                        removeDelaysToTheRest={removeDelaysToTheRest}
                        removeDelay={removeDelay}
                        setStopDelay={setStopDelay}
                        applyStopDelay={applyStopDelay}
                        getPrevStopDelay={getPrevStopDelay}
                        hadSavePending={hadSavePending}
                        isDisabledHAD={isDisabledHAD}
                        allToNextSegments={allToNextSegments}
                        setAllToNextSegments={setAllToNextSegments}
                    />
                </Box>
            )}
        </div>
    );
}
Station.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    uuid: PropTypes.string.isRequired,
    rideUuid: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    paxIn: PropTypes.number,
    paxOut: PropTypes.number,
    status: PropTypes.oneOf([
        STATION_STATUS_OPEN,
        STATION_STATUS_CLOSED,
        STATION_STATUS_CANCELLED,
        '',
    ]),
    actions: PropTypes.shape().isRequired,
    arrival: PropTypes.string,
    departure: PropTypes.string,
    arrivalDelay: PropTypes.number,
    departureDelay: PropTypes.number,
    eta: PropTypes.string,
    ata: PropTypes.string,
    time_zone: PropTypes.string,
};
Station.defaultProps = {
    paxIn: 0,
    paxOut: 0,
    status: STATION_STATUS_OPEN,
    arrival: null,
    departure: null,
    arrivalDelay: 0,
    departureDelay: 0,
    eta: null,
    ata: null,
};
