import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EmptyItem from './EmptyItem';
import './BarBox.scss';

export default function BarBox({ loading, hoverStyle, children, color }) {
    const cssClass = classNames('bar-box', {
        'bar-box--with-hover': hoverStyle,
    });
    const boxShadow = `inset -4px 0px 0px ${
        color || '#5e846f'
    }, inset 4px 0px 0px ${color || '#5e846f'}`;
    return (
        <div className={cssClass} style={{ boxShadow }}>
            {loading ? <EmptyItem /> : children}
        </div>
    );
}

BarBox.propTypes = {
    hoverStyle: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};
BarBox.defaultProps = { loading: false, hoverStyle: false };
