import React from 'react';
import classNames from 'classnames';

export default function DataGrid(props) {
    const gridClassNames = classNames('flix-data-grid', {
        [props.className]: props.className,
    });
    return (
        <table {...props} className={gridClassNames}>
            {props.children}
        </table>
    );
}
