import PropTypes from 'prop-types';

export default function Translate({
    code, translate, render, children,
}) {
    if (render) {
        return render(translate);
    }
    if (typeof children === 'function') {
        return children(translate);
    }
    return translate(code);
}

Translate.propTypes = {
    code: PropTypes.string,
    translate: PropTypes.func.isRequired,
    render: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    children: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

Translate.defaultProps = {
    render: false,
    code: 'error.translation_key_undefined',
    children: false,
};
