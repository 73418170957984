import React from 'react';
import PropTypes from 'prop-types';

export default function ContentBox(props) {
    const { components } = props;
    return (
        <div className="content-box-items content-box-items--on-timeline">
            {components}
        </div>
    );
}

ContentBox.propTypes = {
    components: PropTypes.arrayOf(PropTypes.node),
};

ContentBox.defaultProps = {
    components: [],
};
