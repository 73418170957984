import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const RideInfoTag = ({ children, type, onClick }) => {
    const itemClassName = classNames('ride-info-tag', `ride-info-tag--${type}`);

    return <div onClick={onClick} className={itemClassName}>{children}</div>;
};

export default RideInfoTag;

RideInfoTag.propTypes = {
    type: PropTypes.string,
};

RideInfoTag.defaultProps = {
    type: 'default',
};
