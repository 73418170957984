import React, {
    useEffect,
    useReducer,
    useState,
    useContext,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Panel,
    PanelHeader,
    PanelContent,
    PanelFooter,
    Checkbox,
    Fieldset,
    Heading,
    PanelFooterColumn,
} from '@flixbus/honeycomb-react';
import { Icon, IconFilter } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../System/Translations';

function reducer(state, action) {
    switch (action.type) {
        case 'add':
            return { ...state, filters: state.filters.concat(action.name) };
        case 'remove':
            const filters = state.filters.filter(
                (filter) => filter !== action.name
            );
            return { ...state, filters };
        case 'batch':
            return { ...state, filters: state.filters.concat(action.filters) };
        case 'reset':
            return { ...state, filters: [] };
        case 'selectAll':
            return { ...state, filter: action.filters };
        default:
            return { ...state };
    }
}
export default function SidePanel(props) {
    const { filters, onChange } = props;
    const [active, setActive] = useState(false);
    const [state, dispatch] = useReducer(reducer, { filters: [] });
    const translate = useContext(TranslateContext);

    // sync state with custom filters list (local storage)
    useEffect(() => {
        function filtersFromProps() {
            dispatch({
                type: 'batch',
                filters: filters.reduce((acc, filter) => {
                    let res = [...acc];
                    if (filter.checked) {
                        res.push(filter.name);
                    }
                    return res;
                }, []),
            });
        }
        if (state.filters.length === 0 && filters.length > 0) {
            filtersFromProps();
        }
    }, [filters, state.filters.length]);

    // update local storage with filters sets when state updated
    useEffect(() => {
        if (filters.length !== 0 && state.filters.length !== 0) {
            onChange(state.filters);
        }
    }, [state.filters, state.filters.length, onChange, filters.length]);

    function resetAll() {
        dispatch({ type: 'reset' });
        // fire onChange here course it wont fire within effect by restriction
        onChange([]);
    }

    function selectAll() {
        const selectedFilters = filters.reduce((acc, filter) => {
            let res = [...acc];
            res.push(filter.name);
            return res;
        }, []);
        dispatch({
            type: 'selectAll',
            filters: selectedFilters,
        });
        // fire onChange here course it wont fire within effect by restriction
        onChange(selectedFilters);
    }

    function close() {
        setActive(false);
    }

    function setChecked(e) {
        const { checked, name } = e.target;
        if (checked) {
            dispatch({ type: 'add', name });
        } else {
            /**
             * @todo come up with a better solution
             * need to force update filters before state will changed
             * due to conditions in useEffect
             */
            if (state.filters.length === 1) {
                onChange([]);
            }
            dispatch({ type: 'remove', name });
        }
    }

    function sortFilters() {
        const filtersToSort = [...filters];
        return filtersToSort.sort((a, b) => {
            const v = b.label > a.label;
            return v ? -1 : 1;
        });
    }

    const clickOutside = useCallback(
        (e) => {
            if (active === true) {
                setActive(false);
            }
        },
        [active, setActive]
    );

    // hide panel with outside clicks
    useEffect(() => {
        function clickToClose(e) {
            const className = e.target && e.target.className;
            /**
             * @todo come up with another solutions to detect element,
             * more specific. Issues with SVGElement.
             */
            if (
                typeof className === 'string' &&
                className.includes('panel__overlay')
            ) {
                clickOutside();
            }
        }
        if (active === true) {
            document.addEventListener('click', clickToClose, false);
        }
        return () => {
            document.removeEventListener('click', clickToClose, false);
        };
    }, [active, clickOutside]);

    return (
        <>
            <Button
                link
                onClick={() => {
                    setActive(true);
                }}
            >
                <Icon appearance="primary" InlineIcon={IconFilter} />
                {translate('ride-search.form.custom-filters')}
            </Button>
            <Panel hasOverlay active={active}>
                <PanelHeader
                    closeButtonProps={{ label: 'Close', onClick: close }}
                >
                    {translate('ride-search.side-panel.title')}
                </PanelHeader>
                <PanelContent>
                    <Heading size={3}>
                        {translate('ride-search.side-panel.heading')}
                    </Heading>
                    <Fieldset>
                        {sortFilters().map((filter) => (
                            <Checkbox
                                id={`custom-filter-${filter.name}`}
                                key={`custom-filter-${filter.name}`}
                                name={filter.name}
                                label={filter.label}
                                checked={filter.checked}
                                onChange={setChecked}
                                small
                                value={filter.name}
                            />
                        ))}
                    </Fieldset>
                </PanelContent>
                <PanelFooter>
                    <PanelFooterColumn>
                        <Button
                            appearance="tertiary"
                            display="block"
                            onClick={resetAll}
                        >
                            {translate('ride-search.side-panel.reset')}
                        </Button>
                    </PanelFooterColumn>
                    <PanelFooterColumn>
                        <Button
                            appearance="primary"
                            display="block"
                            onClick={selectAll}
                        >
                            {translate('capacity.rides_picker.select_all')}
                        </Button>
                    </PanelFooterColumn>
                </PanelFooter>
            </Panel>
        </>
    );
}
SidePanel.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            checked: PropTypes.bool,
        })
    ),
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};
SidePanel.defaultProps = {
    filters: [],
    onChange: () => {},
};
