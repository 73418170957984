import React from 'react';
import PropTypes from 'prop-types';

export default function MassAssignment(props) {
    const { selectedIds, actionUrl, refEl } = props;

    return (
        <form
            method="post"
            action={actionUrl}
            ref={refEl}
            className="background-post-form"
            target="_blank"
        >
            <input
                type="text"
                name="rideIds"
                value={selectedIds}
                onChange={(e) => e.preventDefault()}
            />
            <button type="submit">Submit</button>
        </form>
    );
}

MassAssignment.propTypes = {
    selectedIds: PropTypes.array.isRequired,
    actionUrl: PropTypes.string.isRequired,
    refEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        .isRequired,
};
MassAssignment.defaultProps = {
    lang: 'en',
};
