import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@flixbus/honeycomb-react';
import { useApi } from '../hooks';
import { TranslateContext } from '../../System/Translations';
import { getBrowserTimezone } from '../../../util/date';

export default function SelectAll(props) {
    const { selectRides, formData, totalResults } = props;
    const [searchResponse, searchRequest, reset] = useApi(
        'get',
        '/search/rides'
    );
    const { data, pending } = searchResponse;
    const translate = useContext(TranslateContext);

    useEffect(() => {
        if (data !== null) {
            selectRides([...data.results]);
            reset();
        }
    }, [data, selectRides, reset]);

    function callRequest() {
        formData.timezone = getBrowserTimezone();
        searchRequest({ params: { ...formData, batchSize: totalResults } });
    }
    return (
        <>
            <Button
                loading={pending}
                appearance="tertiary"
                onClick={callRequest}
                size="sm"
                extraClasses="ride-search-results__select-all"
            >
                {translate('ride-search.select-all-founded', {
                    count: totalResults,
                })}
            </Button>
        </>
    );
}
SelectAll.propTypes = {
    selectRides: PropTypes.func,
    batchSize: PropTypes.number,
};
SelectAll.defaultProps = { selectRides: () => {}, batchSize: 50 };
