import { useState } from 'react';
import api from '../../api/CommentsBankApi';

export default function useEditComment(referenceId) {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState(null);

    const editComment = (content) => {
        const formData = new FormData();
        formData.append('content', content);
        setComment(null);
        setSaving(true);
        return api
            .put(`/update/${referenceId}`, formData)
            .then((response) => {
                setError(null);
                setComment(response);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setSaving(false);
            });
    };
    return {
        saving,
        error,
        comment,
        editComment,
    };
}
