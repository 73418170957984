import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconNewsletter,
    IconPhone,
    IconPhoneTabletSolid,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import Passenger from './Passenger';
import { DataRow, DataCol } from '../UI/DataGrid';

export default function Order(props) {
    const { order, selectedPax, togglePax, toggleBatch, showMode } = props;
    const translate = useContext(TranslateContext);
    const allChecked = selectedPax.indexOf(order.id) !== -1;
    const pax = order.passengers[0];
    const contactCssClass = classNames('reservation-passenger-email', {
        'reservation-passenger-email--highlight':
            showMode === 'e-mail'
                ? !pax.email
                : !(pax.phone_number || pax.push_subscribers_count > 0),
    });

    return (
        <DataRow valign="top">
            <DataCol>
                {order.passengers.length > 1 ? (
                    <Checkbox
                        label={translate(
                            'reservations.table.select_passenger_control'
                        )}
                        value={String(allChecked)}
                        onChange={toggleBatch([order.id])}
                        id={`order-${order.id}`}
                        checked={allChecked}
                        small
                    />
                ) : null}
                {order.passengers.map((passenger) => (
                    <Passenger
                        key={passenger.id}
                        passenger={passenger}
                        togglePax={togglePax}
                        isChecked={selectedPax.indexOf(order.id) !== -1}
                        push={order.passengers.length > 1}
                        showMode={showMode}
                    />
                ))}
            </DataCol>
            <DataCol>{order.language}</DataCol>
            <DataCol>{order.shop}</DataCol>
            <DataCol>
                {pax && showMode === 'e-mail' ? (
                    <span className={contactCssClass}>
                        {pax.email ? (
                            <>
                                <Icon InlineIcon={IconNewsletter} />
                                {pax.email}
                            </>
                        ) : (
                            translate('reservations.table.no_email')
                        )}
                    </span>
                ) : pax.phone_number || pax.push_subscribers_count > 0 ? (
                    <span className={contactCssClass}>
                        {pax && pax.phone_number && (
                            <>
                                <Icon InlineIcon={IconPhone} />
                                {`${pax.phone_number} `}
                            </>
                        )}
                        {pax && pax.push_subscribers_count > 0 && (
                            <Icon InlineIcon={IconPhoneTabletSolid} />
                        )}
                    </span>
                ) : (
                    <span className={contactCssClass}>
                        {translate('reservations.table.no_phone_no_push')}
                    </span>
                )}
            </DataCol>
        </DataRow>
    );
}

Order.propTypes = {
    order: PropTypes.object.isRequired,
    togglePax: PropTypes.func.isRequired,
    toggleBatch: PropTypes.func.isRequired,
    selectedPax: PropTypes.array.isRequired,
    showMode: PropTypes.string.isRequired,
};
