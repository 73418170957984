const cancelationReasons = {
    1: 'Accident',
    2: 'Bus held by authorities',
    3: 'Bus not compliant',
    4: 'Breakdown during the ride',
    5: 'Breakdown before ride',
    6: 'Driver held by police',
    7: 'Driver late',
    8: 'Driver shortage',
    9: 'Driver strike',
    10: 'GoLive/New Schedule issues',
    11: 'Incident',
    12: 'Rest and driving times',
    13: 'Weather conditions',
    14: 'Bus shortage',
};
export default Object.freeze(cancelationReasons);
