import React from 'react';
import TimezoneContext from '../context';

/**
 * HOC to add context consumer for TimezoneHelper handler
 * @func withTimezoneHelperConsumer
 * @param {object} Component - react component
 */

export default function withTimezoneHelperConsumer(Component) {
    return function WithTimezoneHelperConsumer(props) {
        return (
            <TimezoneContext.Consumer>
                {(timezoneHandler) => <Component {...props} timezoneHandler={timezoneHandler} />}
            </TimezoneContext.Consumer>
        );
    };
}
