const TIMEZONES_LIST = [
    {
        name: '(GMT-11:00) Midway Island',
        timezone: 'Pacific/Midway',
    },
    {
        name: '(GMT-10:00) Hawaii',
        timezone: 'US/Hawaii',
    },
    {
        name: '(GMT-09:00) Alaska',
        timezone: 'US/Alaska',
    },
    {
        name: '(GMT-08:00) Pacific Time (US & Canada)',
        timezone: 'US/Pacific',
    },
    {
        name: '(GMT-07:00) Mountain Time (US & Canada)',
        timezone: 'US/Mountain',
    },
    {
        name: '(GMT-06:00) Central Time (US & Canada)',
        timezone: 'US/Central',
    },
    {
        name: '(GMT-05:00) Eastern Time (US & Canada)',
        timezone: 'US/Eastern',
    },
    {
        name: '(GMT-04:30) Caracas',
        timezone: 'America/Caracas',
    },
    {
        name: '(GMT-04:00) Atlantic Time (Canada)',
        timezone: 'Canada/Atlantic',
    },
    {
        name: '(GMT-03:30) Newfoundland',
        timezone: 'Canada/Newfoundland',
    },
    {
        name: '(GMT-03:00) Greenland',
        timezone: 'Greenland',
    },
    {
        name: '(GMT-02:00) Stanley',
        timezone: 'Atlantic/Stanley',
    },
    {
        name: '(GMT-01:00) Azores',
        timezone: 'Atlantic/Azores',
    },
    {
        name: '(GMT) London',
        timezone: 'Europe/London',
    },
    {
        name: '(GMT+01:00) Berlin',
        timezone: 'Europe/Berlin',
    },
    {
        name: '(GMT+02:00) Istanbul',
        timezone: 'Europe/Istanbul',
    },
    {
        name: '(GMT+03:00) Kuwait',
        timezone: 'Asia/Kuwait',
    },
    {
        name: '(GMT+04:00) Baku',
        timezone: 'Asia/Baku',
    },
    {
        name: '(GMT+04:30) Kabul',
        timezone: 'Asia/Kabul',
    },
    {
        name: '(GMT+05:00) Tashkent',
        timezone: 'Asia/Tashkent',
    },
    {
        name: '(GMT+05:30) Kolkata',
        timezone: 'Asia/Kolkata',
    },
    {
        name: '(GMT+05:45) Kathmandu',
        timezone: 'Asia/Kathmandu',
    },
    {
        name: '(GMT+06:00) Dhaka',
        timezone: 'Asia/Dhaka',
    },
    {
        name: '(GMT+07:00) Bangkok',
        timezone: 'Asia/Bangkok',
    },
    {
        name: '(GMT+08:00) Hong Kong',
        timezone: 'Asia/Hong_Kong',
    },
    {
        name: '(GMT+09:00) Tokyo',
        timezone: 'Asia/Tokyo',
    },
    {
        name: '(GMT+09:30) Adelaide',
        timezone: 'Australia/Adelaide',
    },
    {
        name: '(GMT+10:00) Canberra',
        timezone: 'Australia/Canberra',
    },
    {
        name: '(GMT+11:00) Vladivostok',
        timezone: 'Asia/Vladivostok',
    },
    {
        name: '(GMT+12:00) Auckland',
        timezone: 'Pacific/Auckland',
    },
];

export default TIMEZONES_LIST;
