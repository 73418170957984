import React from 'react';
import PropTypes from 'prop-types';

export default function withPaxSelection(Component) {
    return class WithPaxSelection extends React.Component {
        static propTypes = {
            // List of pre-selected passengers IDs
            preSelectedPax: PropTypes.array,
        };

        static defaultProps = {
            preSelectedPax: [],
        };

        constructor(props) {
            super();

            const { preSelectedPax } = props;

            this.state = {
                selectedPax: preSelectedPax || [],
            };
        }

        /**
         * Adds/removes passenger to/from the selected passengers list
         * @method
         * @param {number} id - Order id
         */
        togglePax = (id) => () => {
            let selectedPax = [...this.state.selectedPax];
            if (selectedPax.indexOf(id) === -1) {
                selectedPax.push(id);
            } else {
                selectedPax = selectedPax.filter((sId) => sId !== id);
            }
            this.setState({ selectedPax });
        };

        /**
         * Adds/removes multiple passengers to/from the selected passengers list
         * @method
         * @param {array} ids - List with orders ids
         */
        toggleBatch = (ids) => () => {
            let selectedPax = [...this.state.selectedPax];
            const diff = ids.filter((id) => selectedPax.indexOf(id) === -1);

            if (ids.length >= diff.length && diff.length !== 0) {
                selectedPax = selectedPax.concat(diff);
            }

            if (diff.length === 0) {
                selectedPax = selectedPax.filter(
                    (id) => ids.indexOf(id) === -1
                );
            }
            this.setState({ selectedPax });
        };

        /**
         * Adds multiple passengers to selected passengers list
         * @method
         * @param {array} ids - List with passengers ids
         */
        addBatch = (ids) => () => {
            const selectedPax = [...this.state.selectedPax];
            ids.forEach((id) => {
                if (selectedPax.indexOf(id) === -1) {
                    selectedPax.push(id);
                }
            });
            this.setState({ selectedPax });
        };

        /**
         * Removes multiple passengers from selected passengers list
         * @method
         * @param {array} ids - List with passengers ids
         */
        removeBatch = (ids) => () => {
            let selectedPax = [...this.state.selectedPax];
            selectedPax = selectedPax.filter((id) => ids.indexOf(id) === -1);
            this.setState({ selectedPax });
        };

        /**
         * Adds single passenger to selected passengers list
         * @method
         * @param {number} id - Passenger's id
         */
        addPax = (id) => () => {
            const { selectedPax } = this.state.selectedPax;
            selectedPax.forEach((paxId) => paxId !== id);
            this.setState({ selectedPax });
        };

        /**
         * Removes single passenger from selected passengers list
         * @method
         * @param {number} id - Passenger's id
         */
        removePax = (id) => () => {
            const { selectedPax } = this.state.selectedPax;
            const filteredPax = selectedPax.filter((paxId) => paxId !== id);
            this.setState({ selectedPax: filteredPax });
        };

        /**
         * set explicit PAX selection
         * @method setPax
         * @param {array} pax - Passengers id's
         */
        setPax = (pax = []) => {
            this.setState({ selectedPax: pax });
        };

        /**
         * clear PAX selection
         * @method resetPax
         */
        resetPax = () => {
            this.setState({ selectedPax: [] });
        };

        render() {
            const { selectedPax } = this.state;
            return (
                <Component
                    {...this.props}
                    selectedPax={selectedPax}
                    togglePax={this.togglePax}
                    toggleBatch={this.toggleBatch}
                    addBatch={this.addBatch}
                    removeBatch={this.removeBatch}
                    addPax={this.addPax}
                    setPax={this.setPax}
                />
            );
        }
    };
}
