import DelayAdded from './renderers/DelayAdded';
import DelayChanged from './renderers/DelayChanged';
import DelayLog from './renderers/DelayLog';
import ManualDelayLog from './renderers/ManualDelayLog';
import Sale from './renderers/Sale';
import JoinTheRide from './renderers/JoinTheRide';
import LeaveTheRide from './renderers/LeaveTheRide';
import Checkin from './renderers/Checkin';
import StationClosed from './renderers/StationClosed';
import StationOpened from './renderers/StationOpened';
import DelayRemoved from './renderers/DelayRemoved';
import RideStatusChanged from './renderers/RideStatusChanged';
import RideBusPaired from './renderers/RideBusPaired';
import MassRebookingFromRide from './renderers/MassRebookingFromRide';
import MassRebookingToRide from './renderers/MassRebookingToRide';
import RebookingStartedBy from './renderers/RebookingStartedBy';
import OpenTicketsEnabled from './renderers/OpenTicketsEnabled';
import OpenTicketsDisabled from './renderers/OpenTicketsDisabled';
import DeferredNotificationQueued from './renderers/DeferredNotificationQueued';
import DeferredNotificationProcessed from './renderers/DeferredNotificationProcessed';
import NotificationAntispam from './renderers/NotificationAntispam';
import MassRebookingError from './renderers/MassRebookingError';
import BigDelay from './renderers/BigDelay';
import RideCancellation from './renderers/RideCancellation';
import SeatRefund from './renderers/SeatRefund';
import PaxListStatus from './renderers/PaxListStatus';
import SeatLockLog from './renderers/SeatLockLog';
import Pax2Bus from './renderers/Pax2Bus';
import Pax2BusDistributionError from './renderers/Pax2BusDistributionError';
import Pax2BusDistributionSuccess from './renderers/Pax2BusDistributionSuccess';
import Pax2BusUnDistributed from './renderers/Pax2BusUnDistributed';
import RideStationTimeChanged from './renderers/RideStationTimeChanged';
import StopRelocated from './renderers/StopRelocated';
import DriverInfoLog from './renderers/DriverInfoLog';
import DelayNotificationTriggered from "./renderers/DelayNotificationTriggered";
import DelayNotificationScheduled from "./renderers/DelayNotificationScheduled";
import RideTagAssignment from "./renderers/RideTagAssignment";
import StationCancelled from "./renderers/StationCancelled";
import TrainPlatformChange from "./renderers/TrainPlatformChange";
import Rebookr2RebookingError from "./renderers/Rebookr2RebookingError";
import Rebookr2RebookingFromRide from "./renderers/Rebookr2RebookingFromRide";
import Rebookr2RebookingToRide from "./renderers/Rebookr2RebookingToRide";
import RideConceptChange from "./renderers/RideConceptChange";
import GhostOrderDetected from "./renderers/GhostOrderDetected";

const RendererMap = {
    delay_log: DelayLog,
    manual_delay_log: ManualDelayLog,
    delay_added: DelayAdded,
    delay_changed: DelayChanged,
    sale: Sale,
    join_the_ride: JoinTheRide,
    leave_the_ride: LeaveTheRide,
    checkin: Checkin,
    open_ticket_rebook_and_checkin: Checkin,
    close_station: StationClosed,
    open_station: StationOpened,
    cancel_station: StationCancelled,
    delay_removed: DelayRemoved,
    ride_status_changed: RideStatusChanged,
    ride_bus_paired: RideBusPaired,
    mass_rebooking_from_ride: MassRebookingFromRide,
    rebookr_rebooking_from_ride: MassRebookingFromRide,
    mass_rebooking_to_ride: MassRebookingToRide,
    rebookr_rebooking_to_ride: MassRebookingToRide,
    rebookr_rebooking_error: MassRebookingError,
    rebooking_started_by: RebookingStartedBy,
    rebookr2_rebooking_error: Rebookr2RebookingError,
    rebookr2_rebooking_from_ride: Rebookr2RebookingFromRide,
    rebookr2_rebooking_to_ride: Rebookr2RebookingToRide,
    open_tickets: OpenTicketsEnabled,
    close_tickets: OpenTicketsDisabled,
    deferred_notification_queued: DeferredNotificationQueued,
    deferred_notification_processed: DeferredNotificationProcessed,
    notification_antispam: NotificationAntispam,
    big_delay: BigDelay,
    ride_cancellation: RideCancellation,
    seat_refund: SeatRefund,
    pax_list_status: PaxListStatus,
    seat_lock_log: SeatLockLog,
    pax2bus: Pax2Bus,
    pax2bus_distribution_error: Pax2BusDistributionError,
    pax2bus_distribution_success: Pax2BusDistributionSuccess,
    pax2bus_undistributed: Pax2BusUnDistributed,
    ride_station_time_changed: RideStationTimeChanged,
    stop_relocation: StopRelocated,
    driver_added: DriverInfoLog,
    driver_removed: DriverInfoLog,
    delay_notification_triggered: DelayNotificationTriggered,
    delay_notification_scheduled: DelayNotificationScheduled,
    ride_tag_added: RideTagAssignment,
    ride_tag_removed: RideTagAssignment,
    train_platform_change_log: TrainPlatformChange,
    ride_concept_change: RideConceptChange,
    ghost_order_detected: GhostOrderDetected
};

export default RendererMap;
