import React from 'react';
import PropTypes from 'prop-types';
import Switcher from '../../UI/Switcher';

export default function ShowHideICsSwitcher(props) {
    const { showHideICsHandler, translate } = props;
    const { toggleShowHideICs, isExpanded } = showHideICsHandler;

    return (
        <Switcher
            isOn={isExpanded}
            onChange={toggleShowHideICs}
            legend={translate('toolbar.expand-collaps-ics')}
        />
    );
}

ShowHideICsSwitcher.propTypes = {
    hint: PropTypes.string,
    showHideICsHandler: PropTypes.shape({
        isExpanded: PropTypes.bool,
        toggleShowHideICs: PropTypes.func,
    }).isRequired,
};
