import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Infobox, Link } from '@flixbus/honeycomb-react';
import { IFrame, IFrameDialog } from '../IFrame';

import './BusAssignmentWidget.css';

const env = process.env.NODE_ENV;

const widgetHost =
    env === 'production'
        ? 'https://management.fleet.flixbus.com'
        : 'https://management.fleet-dev.flix.tech';
const widgetPath = 'taurus-lite-widget';
const popUpPath = 'taurus-lite-popup';
const BUS_CHANGE_LOG_URL = `${widgetHost}/taurus-lite-changelog/`;

export default class BusAssignmentWidget extends React.Component {
    /**
     * HTMLIframe element for widget
     * @property widget
     */
    widget = null;

    /**
     * HTMLIframe element for dialog
     * @property dialog
     */
    dialog = null;

    /**
     * object with functions that call widget messages
     * @property actions
     */
    actions = {
        'open-popup': ({ id }) => {
            // widget send UIID instead of id
            // and have to use it to open dialog
            this.setState({ dialogRideUUID: id });
            this.openDialog();
        },
        'close-popup': () => {
            this.closeDialog();
        },
        'close-popup-after-submit': () => {
            this.closeDialog();
            this.refreshWidget();
        },
        'change-page': ({ id }) => {
            const { history, match } = this.props;
            /** @todo deal with app stack with deep route */
            history.push(match.path.replace(':id', id));
        },
        'height-ready': ({ height }) => {
            this.setState({ widgetNotReady: false });
            if (this.widget instanceof window.Element) {
                this.widget.style.height = `${height}px`;
            }
        },
    };

    static propTypes = {
        rideUuid: PropTypes.string.isRequired,
        history: PropTypes.object,
        match: PropTypes.object,
        translate: PropTypes.func,
    };

    static defaultProps = {
        history: {
            push: () => {},
        },
        match: {
            path: '',
        },
        translate: (key) => key,
    };

    state = {
        refresh: 0,
        dialogIsOpen: false,
        dialogRideUUID: null,
        widgetNotReady: true,
    };

    componentDidMount() {
        window.addEventListener('message', this.onMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onMessage);
    }

    get busWidgetUrl() {
        const { rideUuid, translate } = this.props;
        return `${widgetHost}/${widgetPath}/${rideUuid}/${translate.language}`;
    }

    get busPopUpUrl() {
        const { translate } = this.props;
        const { dialogRideUUID } = this.state;
        return `${widgetHost}/${popUpPath}/${dialogRideUUID}/${translate.language}`;
    }

    onMessage = (e) => {
        const { action, data } = e.data;
        if (action) {
            this.runAction(action, data);
        }
    };

    runAction = (action, id) => {
        const { [action]: fn } = this.actions;
        if (typeof fn === 'function') {
            fn(id);
        }
    };

    refreshWidget = () => {
        const { refresh } = this.state;
        this.setState({ refresh: refresh + 1 });
    };

    refIframeWidget = (element) => {
        this.widget = element;
    };

    refIframeDialog = (element) => {
        this.dialog = element;
    };

    openDialog = () => {
        this.setState({ dialogIsOpen: true });
    };

    closeDialog = () => {
        this.setState({ dialogIsOpen: false });
    };

    render() {
        const { refresh, dialogIsOpen, widgetNotReady } = this.state;
        const { translate, rideUuid } = this.props;
        const widgetCssClass = classNames('flix-space-flush-bottom', {
            'hide-on-print': widgetNotReady,
        });
        return (
            <Box extraClasses={widgetCssClass}>
                <Link
                    extraClasses="rideview-taurus-bus-change-log hide-on-print"
                    href={`${BUS_CHANGE_LOG_URL}${rideUuid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {translate('taurus.widget.bus_change_log')}
                </Link>
                {widgetNotReady && (
                    <Infobox>
                        {translate('taurus.widget.reload.message')}
                    </Infobox>
                )}
                <IFrame
                    className="rideview-taurus-iframe"
                    src={`${this.busWidgetUrl}?refresh=${refresh}#${window.location.origin}`}
                    title={translate('taurus.widget.iframe.title')}
                    data-locale={translate.language}
                    refIframe={this.refIframeWidget}
                />
                <IFrameDialog
                    popUpUrl={`${this.busPopUpUrl}#${window.location.origin}`}
                    active={dialogIsOpen}
                    title={translate('taurus.widget.dialog.iframe.title')}
                    className="rideview-taurus-dialog"
                />
            </Box>
        );
    }
}
