import { compose } from 'recompose';
import ContentBox from './ContentBox';
import Content from './components/Content';
import withContentBox from './hocs/withContentBox';
import withContentControl from './hocs/withContentControl';

import './ContentBox.css';

export default compose(withContentBox, withContentControl)(ContentBox);

export { Content };
