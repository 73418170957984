import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Grid, GridCol, Checkbox, Tag } from '@flixbus/honeycomb-react';
import {
    IconNewTab,
    Icon,
    IconArrowDown,
    IconArrowUp,
    IconBus,
    IconTrain,
    IconTrip,
} from '@flixbus/honeycomb-icons-react';
import { formatDate, tripDaysDiff } from '../../../util/date.js';
import { TranslateContext } from '../../System/Translations';
import RideInfoTag from '../../RideView/RideInfoTag';
import './ListItem.scss';

export default function ListItem({
    item,
    collapsible,
    selectRide,
    isRideSelected,
    isExpanded,
    withSelection = true,
}) {
    const translate = useContext(TranslateContext);
    const statusLabels = {
        on_sale: { label: 'On sale', type: 'primary' },
        hidden: { label: 'Hidden', type: 'secondary' },
        ghost: { label: 'Ghost', type: 'secondary' },
        archived: { label: 'Archived', type: 'default' },
        drafted: { label: 'Drafted', type: 'default' },
        planr_drafted: { label: 'Planr drafted', type: 'secondary' },
        preview: { label: 'Preview', type: 'secondary' },
    };
    const fmMainUrl = 'https://management.fleet.flixbus.com';
    const vehicleTypes = {
        bus: { icon: IconBus, fmUrl: `${fmMainUrl}/buses/view` },
        train: { icon: IconTrain, fmUrl: `${fmMainUrl}/circulations/detail` },
        circulation: {
            icon: IconTrip,
            fmUrl: `${fmMainUrl}/circulations/detail`,
        },
    };
    const [expanded, setExpanded] = useState(false);
    const [moreAssignments, setMoreAssignments] = useState(false);
    const iconSwith = expanded ? IconArrowUp : IconArrowDown;
    const expand = () => {
        setExpanded(!expanded);
    };
    const showMoreAssignments = () => {
        setMoreAssignments(!moreAssignments);
    };

    const listCssClass = classNames('list-item', {
        'list-item--collapsible': collapsible,
        'list-item--expanded': expanded,
    });
    const mainInfoClass = classNames('list-item__main-info', {
        'list-item__main-info--expanded': expanded,
    });
    const assignmentsClass = classNames('list-item__assignments', {
        'list-item__assignments--more': moreAssignments,
    });

    const rideDate = formatDate.parseZone(item.date, 'ddd, DD MMM YYYY');

    const RideviewerLink = () => (
        <div className="list-item__rideviewer-link">
            <Button
                to={`/ride-view/${item.uuid}/timeline`}
                RouterLink={Link}
                extraClasses="list-item__assignment-btn"
                link
            >
                <Icon appearance="secondary" InlineIcon={IconNewTab} />
                {translate('ride-search.results.rideviewer')}
            </Button>
        </div>
    );

    const arrivalDaysInFuture = tripDaysDiff(
        item.departureDate,
        item.arrivalDate
    );

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);

    return (
        <div className={listCssClass}>
            <Grid align={expanded ? 'top' : 'center'}>
                <GridCol size={expanded ? 2 : 3}>
                    <div className="list-item__with-controls">
                        <Icon
                            appearance="primary"
                            InlineIcon={iconSwith}
                            onClick={expand}
                            extraClasses="list-item__collapse-toggle"
                        />
                        {withSelection && (
                            <Checkbox
                                extraClasses="list-item__select"
                                aria-label="select"
                                id={item.uuid}
                                onChange={selectRide(item.uuid)}
                                value={String(isRideSelected)}
                                checked={isRideSelected}
                                small
                            />
                        )}
                        <div className={mainInfoClass}>
                            <div className="list-item__uid" title={item.uid}>
                                {
                                    item.uid
                                        .slice(1, item.uid.length)
                                        .split(' ')[0]
                                }
                                {expanded && (
                                    <div className="list-item__trip-number">
                                        {item.uid.split(' ')[1]}
                                    </div>
                                )}
                            </div>
                            <RideInfoTag type={statusLabels[item.status].type}>
                                {statusLabels[item.status].label}
                            </RideInfoTag>
                        </div>
                    </div>
                </GridCol>
                <GridCol size={expanded ? 4 : 8}>
                    <div className={expanded ? 'list-item__del' : null}>
                        <div className={mainInfoClass}>
                            <div className="list-item__date-time">
                                {rideDate}
                                ,&nbsp;
                                {item.departureTime}
                                &nbsp;-&nbsp;
                                {item.arrivalTime}
                                {arrivalDaysInFuture && (
                                    <sup>{arrivalDaysInFuture}</sup>
                                )}
                            </div>
                            <div className="list-item__secondary-info">
                                {item.departureStation}
                                &nbsp;→&nbsp;
                                {item.arrivalStation}
                                {expanded && item.concessionOwner && (
                                    <>
                                        <br />{' '}
                                        {translate(
                                            'ride-search.results.concession'
                                        )}{' '}
                                        {item.concessionOwner.name}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </GridCol>

                <GridCol size={expanded ? 6 : 1}>
                    {expanded ? (
                        <div className="list-item__wrapper">
                            <div className="list-item__del list-item__del--no-border">
                                <div className={assignmentsClass}>
                                    {item.assignments.map((assignment, idx) => {
                                        const { plateNumber } = assignment;
                                        const capacity =
                                            assignment.capacityPhysical;
                                        const seat = capacity && capacity.seat;
                                        const bike = capacity && capacity.bike;
                                        const wchair =
                                            capacity && capacity.wheelchair;
                                        const partner =
                                            assignment.partner &&
                                            assignment.partner.name;
                                        const decker =
                                            assignment.decks > 1 ? 'DD' : 'SD';
                                        const configuration =
                                            seat || wchair || bike ? (
                                                <span>
                                                    {`\u00A0 | \u00A0${
                                                        seat || ' - '
                                                    }/${bike || ' - '}/${
                                                        wchair || ' - '
                                                    }`}
                                                </span>
                                            ) : (
                                                <span>
                                                    {`\u00A0 | \u00A0- ${translate(
                                                        'ride-search.results.no-capacity'
                                                    )} -`}
                                                </span>
                                            );
                                        const showPartner = () => {
                                            return partner ? (
                                                <span
                                                    className="list-item__assignment-partner"
                                                    title={partner}
                                                >
                                                    &nbsp; | &nbsp;
                                                    {partner}
                                                </span>
                                            ) : (
                                                <span>
                                                    &nbsp; | &nbsp;-{' '}
                                                    {translate(
                                                        'ride-search.results.no-partner'
                                                    )}{' '}
                                                    -
                                                </span>
                                            );
                                        };
                                        const ifBusNumber = assignment.busNumber
                                            ? `\u00A0 | \u00A0${assignment.busNumber}`
                                            : '';
                                        const ifBusId = assignment.shortId
                                            ? `\u00A0 | \u00A0${assignment.shortId}`
                                            : '';
                                        return (
                                            <div
                                                className="list-item__assignment"
                                                key={`${idx}_${assignment.fromStationId}`}
                                            >
                                                <a
                                                    href={`${
                                                        vehicleTypes[
                                                            assignment.type
                                                        ].fmUrl
                                                    }/${assignment.id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="list-item__assignment-icon-link"
                                                >
                                                    <Icon
                                                        appearance="secondary"
                                                        InlineIcon={
                                                            vehicleTypes[
                                                                assignment.type
                                                            ].icon
                                                        }
                                                        extraClasses="list-item__assignment-icon"
                                                    />
                                                </a>
                                                {assignment.type ===
                                                'circulation' ? (
                                                    <>
                                                        <span>
                                                            &nbsp; {decker} -{' '}
                                                            {assignment.shortId}
                                                        </span>
                                                        {configuration}
                                                        {showPartner()}
                                                        {ifBusNumber}
                                                    </>
                                                ) : (
                                                    <>
                                                        {plateNumber ? (
                                                            <span>{`\u00A0 ${plateNumber}`}</span>
                                                        ) : (
                                                            <span>
                                                                {`\u00A0 - ${translate(
                                                                    'ride-search.results.no-plate-number'
                                                                )} -`}
                                                            </span>
                                                        )}
                                                        {configuration}
                                                        {showPartner()}
                                                        {ifBusNumber}
                                                        {ifBusId}
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                {item.assignments.length > 2 && (
                                    // show more if there are more than 2 rows
                                    <Button
                                        onClick={showMoreAssignments}
                                        appearance="secondary"
                                        extraClasses="list-item__assignment-more"
                                        link
                                    >
                                        {moreAssignments
                                            ? `${
                                                  item.assignments.length - 2
                                              } less`
                                            : `+${
                                                  item.assignments.length - 2
                                              } more`}
                                    </Button>
                                )}

                                {item.tags && item.tags.length > 0 && (
                                    <div className="list-item__tags">
                                        {item.tags.map((tag) => {
                                            const bgColor = {
                                                backgroundColor:
                                                    tag.color ||
                                                    'var(--hcr-input-border-color)',
                                            };

                                            return (
                                                <Tag
                                                    outlined
                                                    extraClasses="ride-tag"
                                                    key={tag.id}
                                                >
                                                    <span
                                                        className="ride-tag__dot"
                                                        style={bgColor}
                                                    />
                                                    {tag.name}
                                                </Tag>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                            <RideviewerLink />
                        </div>
                    ) : (
                        <RideviewerLink />
                    )}
                </GridCol>
            </Grid>
        </div>
    );
}

ListItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        uuid: PropTypes.string,
        lineName: PropTypes.string,
        uid: PropTypes.string,
        date: PropTypes.string,
        depStation: PropTypes.string,
        arrStation: PropTypes.string,
        depTime: PropTypes.string,
        arrTime: PropTypes.string,
    }),
    collapsible: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isRideSelected: PropTypes.bool.isRequired,
    withSelection: PropTypes.bool,
};
ListItem.defaultProps = {
    item: {},
    collapsible: false,
    isExpanded: false,
    isRideSelected: false,
    withSelection: true,
};
