import WsConnection from './WsConnection';

class WsSubscriber {
    constructor() {
        this.subscribers = {};
        this.subscription = {};
        WsConnection().onReady(this.resubscribeOnReconnect);
    }

    resubscribeOnReconnect = (session) => {
        const topics = Object.keys(this.subscribers);
        if (session.isOpen) {
            topics.forEach((topic) => {
                session
                    .subscribe(topic, this.callHandlers(topic))
                    .then((subscription) => {
                        this.subscription[topic] = subscription;
                    });
            });
        }
    };

    subscribe(topic, callback) {
        if (this.subscribers[topic]) {
            this.subscribers[topic].push(callback);
        } else {
            this.subscribers[topic] = [callback];
            this.setSubscription(topic);
        }
    }
    callHandlers = (topic) => {
        return (...args) => {
            const handlers = this.subscribers[topic];
            topic &&
                handlers.forEach((handler) => {
                    typeof handler === 'function' && handler.apply(null, args);
                });
        };
    };
    setSubscription = (topic) => {
        WsConnection()
            .getSession()
            .then(
                (session) => {
                    if (session.isOpen) {
                        session
                            .subscribe(topic, this.callHandlers(topic))
                            .then((subscription) => {
                                this.subscription[topic] = subscription;
                            });
                    }
                },
                (error) => {
                    console.log('WS subscribe error: ', { topic, error });
                }
            )
            .catch((e) => {
                console.log('WS subscribe error: ', topic, e);
            });
    };
    unsubscribe(topic, callback) {
        if (this.subscribers[topic] === undefined) {
            return;
        }
        this.subscribers[topic] = this.subscribers[topic].filter(
            (cb) => cb !== callback
        );

        if (this.subscribers[topic].length === 0) {
            this.subscribers[topic] && delete this.subscribers[topic];
            this.subscription[topic] &&
                this.subscription[topic].unsubscribe().then(
                    () => {
                        delete this.subscription[topic];
                    },
                    (error) => {
                        console.log('Unsubscribe error: ', { topic, error });
                    }
                );

            return;
        }
    }
}

let instance;

function getInstance() {
    if (instance) {
        return instance;
    }

    instance = new WsSubscriber();

    return instance;
}

export default getInstance;
