import React from 'react';
import classNames from 'classnames';

export default function TimelineTool(props) {
    const { children, align, disabled } = props;
    const tollClassNames = classNames([
        'timeline-toolbar__tool',
        {
            'timeline-toolbar__tool--align-right': align === 'right',
            'timeline-toolbar__tool--align-left': align === 'left',
            'timeline-toolbar__tool--disabled': disabled,
        },
    ]);

    return <div className={tollClassNames}>{children}</div>;
}
