import { withHandlers } from 'recompose';
import api from '../../../api/Client';

/**
 * Enhancer that provides lockAllSeats handler
 */
export default withHandlers({
    lockAllSeats: (props) => () => {
        const { rideId, notify, timeline } = props;
        notify({
            message: 'Request to lock all seats was sent',
        });
        return api
            .post(`/rideviewer/ride/${rideId}/seat-lock/all`)
            .catch((error) => {
                if (!api.isCancel(error)) {
                    notify({
                        message:
                            "Lock all seats: Works in the demo mode! All changes that you 've made not applied to the real data",
                        type: 'error',
                    });
                }

                return timeline.reduce((acc, entry) => {
                    let entrySet = [...acc];
                    if (entry.entry_type === 'segment') {
                        entrySet.push({
                            id: entry.id,
                            entry_type: entry.entry_type,
                            seats_locked: entry.total_seats,
                            bike_slots_locked: entry.total_bike_slots,
                        });
                    }
                    return entrySet;
                }, []);
            })
            .then((updateTimeline) => updateTimeline);
    },
});
