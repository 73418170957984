import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@flixbus/honeycomb-react';
import Translate from '../../../System/Translations';

/**
 * @TODO deal with selection actions out of form
 * and reset state for form if any perform
 */

export default class StationSelection extends React.Component {
    static propTypes = {
        selectStation: PropTypes.func,
        title: PropTypes.string,
        value: PropTypes.string,
        stops: PropTypes.shape(),
    };
    static defaultProps = {
        value: '',
        stops: {},
        selectStation: () => {},
        title: 'Select station',
    };

    onSelect = (e) => {
        const { selectStation } = this.props;
        const station = e.target.value;
        selectStation(station);
    };

    render() {
        const { title, value, stops } = this.props;
        const options = Object.keys(stops).map((code) => {
            const optionTitle = `${code}-${stops[code].name}`;
            return (
                <SelectOption key={code} value={code}>
                    {optionTitle}
                </SelectOption>
            );
        });
        return (
            <Translate>
                {(translate) => (
                    <Select
                        id={`${title}-1`}
                        label={title}
                        value={value}
                        onChange={this.onSelect}
                        placeholder={translate(
                            'reservations.no_selected_station'
                        )}
                    >
                        <SelectOption key={`${title}-1-empty-value`} value="">
                            {translate('reservations.no_selected_station')}
                        </SelectOption>
                        {options}
                    </Select>
                )}
            </Translate>
        );
    }
}
