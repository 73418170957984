/**
 *
 * @callback eventHandler
 * @param {object} event
 */

/** Class represents Dispatcher Event  */
class DispatcherEvent {
    /**
     * create an Event object
     * @param {object|string} event
     */
    constructor(event) {
        if (typeof event === 'string') {
            this.type = event;
        }

        if (event instanceof Object) {
            Object.keys(event).forEach((key) => {
                this[key] = event[key];
            });
        }
    }
}

/**
 * Class represents Dispatcher
 */
export default class Dispatcher {
    constructor() {
        this.listeners = new Map();
    }

    /**
     * Trigger event
     * @method trigger
     * @param {object|string} event type as a string or object with type and additional data
     * @param {string} event.type type
     */
    trigger = (event) => {
        if (typeof event === 'undefined' || event === null) {
            return;
        }

        const dispatcherEvent = new DispatcherEvent(event);
        const { type } = dispatcherEvent;

        if (type && this.listeners.has(type)) {
            this.listeners.get(type).forEach((callback) => {
                callback(dispatcherEvent);
            });
        }
    };

    /**
     * Subscribe handler to the Event
     * @method on
     * @param {string|object} event type as a string or object with type and additional data
     * @param {string} event.type type
     * @param {callback} callback event handler
     */
    on = (event, callback) => {
        if (typeof event === 'undefined' || event === null) {
            return;
        }
        const dispatcherEvent = new DispatcherEvent(event);
        const { type } = dispatcherEvent;

        if (type && typeof callback === 'function') {
            if (!this.listeners.has(type)) {
                this.listeners.set(type, new Set());
            }
            this.listeners.get(type).add(callback);
        }
    };

    /**
     * Unsubscribe event handler
     * @param {string|object} event type as a string or object with type and additional data
     * @param {string} event.type type
     * @param {callback} callback event handler
     */
    off = (type, callback) => {
        if (type && this.listeners.has(type)) {
            this.listeners.get(type).delete(callback);
        }
    };
}
