export function accentedNormalize(input) {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function regExpNormalize(input) {
    return input.replace(/(\[|\]|\(|\)|\*|\.|\^|\{|\}|\+|\?|\\)/g, '\\$1');
}
// create search query safe for RegExp and equalize accented characters
export function queryNormalize(input) {
    return accentedNormalize(regExpNormalize(input));
}
