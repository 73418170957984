import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SelectGroup, Tooltip } from '@flixbus/honeycomb-react';

import { TranslateContext } from '../../System/Translations';

export default function TimezoneSwitcher(props) {
    const { timezoneHandler, hint } = props;
    const { toggleTimezone } = timezoneHandler;
    const translate = useContext(TranslateContext);
    const options = [
        {
            id: 'Stations-time',
            value: 'true',
            name: 'isLocal',
            label: translate('toolbar.stations_time'),
            defaultChecked: true,
        },
        {
            id: 'My-time',
            value: 'false',
            name: 'isLocal',
            label: translate('toolbar.my_time'),
        },
    ];
    return (
        <Tooltip
            id={`timezone-switcher-tooltip-${String(hint)}`}
            content={hint}
            position="bottom"
            openOnHover
        >
            <SelectGroup
                aria-label="Timeline Time"
                options={options}
                onChange={toggleTimezone}
                id="timezone-switcher"
            />
        </Tooltip>
    );
}

TimezoneSwitcher.propTypes = {
    hint: PropTypes.string,
    timezoneHandler: PropTypes.shape({
        isLocal: PropTypes.bool,
        toggleTimezone: PropTypes.func,
    }).isRequired,
};
