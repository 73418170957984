import React, { useContext, useEffect } from 'react';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import {
    Grid,
    GridCol,
    Button,
    Heading,
    Header,
    HeaderBrand,
} from '@flixbus/honeycomb-react';
import { Icon, IconSecuritySolid } from '@flixbus/honeycomb-icons-react';
import { EventType } from '@azure/msal-browser';
import { NotifyContext } from '../../NotificationSystem/context';
import { msalPopupRequest } from '../../../auth/adalConfig';
import { msalInstance } from '../../../auth';
import { TranslateContext } from '../../System/Translations';

import logo from '../../AppHeader/rv_logo.svg';

export default function withMsalAuth(Component) {
    return function WithMsalAuth(props) {
        const notify = useContext(NotifyContext);
        const translate = useContext(TranslateContext);
        /**
         * @todo
         * processed login flow events
         * send exceptions to monitoring
         * notify users if errors
         * */
        /**
         * @todo
         * place translations handler to header
         * use translations for text
         * */
        useEffect(() => {
            const callback = msalInstance.addEventCallback((message) => {
                if (
                    message.eventType === EventType.LOGIN_FAILURE ||
                    message.eventType === EventType.SSO_SILENT_FAILURE ||
                    message.eventType === EventType.ACQUIRE_TOKEN_FAILURE
                ) {
                    if (
                        message.error
                            .toString()
                            .search(/popup_window_error|empty_window_error/) !==
                        -1
                    ) {
                        notify({
                            type: 'danger',
                            message: translate('auth.error.window-block'),
                            // 'Login failed. Please check if popups are blocked in a browser. Change settings and try again.',
                        });
                    }
                }
            });
            return () => {
                msalInstance.removeEventCallback(callback);
            };
        }, [notify, translate]);

        function login() {
            msalInstance.loginPopup({ ...msalPopupRequest }).catch((e) => {
                notify({ type: 'danger', message: `Auth error ${e}` });
            });
        }
        return (
            <>
                <AuthenticatedTemplate>
                    <Component {...props} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Header fixed={false}>
                        <HeaderBrand
                            alt="Rideviwer"
                            href="/"
                            src={logo}
                            appearance="tall"
                        />
                    </Header>
                    <div
                        className="rv-login-box"
                        style={{ textAlign: 'center' }}
                    >
                        <Grid justify="center">
                            <GridCol size={3}>
                                <Heading size={3}>
                                    <Icon
                                        InlineIcon={IconSecuritySolid}
                                        size={8}
                                    />
                                </Heading>
                                <Heading size={4}>Login to Rideviewer</Heading>
                                <Button onClick={login} appearance="primary">
                                    Login
                                </Button>
                            </GridCol>
                        </Grid>
                    </div>
                </UnauthenticatedTemplate>
            </>
        );
    };
}
