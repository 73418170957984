import React from 'react';
import {
    Button,
    Panel,
    PanelContent,
    PanelFooter,
    PanelFooterColumn,
    PanelHeader
} from "@flixbus/honeycomb-react";
import { SearchResults } from "../RideSearch/SearchResults";
import './SearchResultsPanel.scss';

const SearchResultsPanel = ({ isActive, togglePanel, rides }) => {
    return (
        <Panel
            extraClasses="rv-search-panel"
            hasOverlay
            fullSize
            onOverlayClick={togglePanel}
            position='bottom'
            active={isActive}
        >
            <PanelHeader>
                <div className="rv-search-panel__header">Rides found: <b>{rides.length}</b></div>
            </PanelHeader>
            <PanelContent extraClasses="rv-search-panel__content">
                <SearchResults results={rides} loading={false} withSelection={false} />
            </PanelContent>
            <PanelFooter extraClasses="rv-search-panel__footer" justify="end">
                <PanelFooterColumn narrow>
                    <Button appearance="secondary" onClick={togglePanel}>Close</Button>
                </PanelFooterColumn>
            </PanelFooter>
        </Panel>
    )
}

export default SearchResultsPanel;