import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';
import { Select, SelectOption } from '@flixbus/honeycomb-react';

export default function PlatformSelector(props) {
    const { platforms, onSelect } = props;
    const translate = useContext(TranslateContext);
    const onChange = (e) => {
        onSelect(e.target.value);
    };
    return (
        <Select
            label={translate('notifier.templates.platform.label')}
            id="platform-selector"
            onChange={onChange}
            defaultValue={platforms[0]}
        >
            {platforms.map((platform) => (
                <SelectOption key={platform} value={platform}>
                    {platform}
                </SelectOption>
            ))}
        </Select>
    );
}
PlatformSelector.propTypes = {
    platforms: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
};
