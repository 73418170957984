import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Header,
    HeaderBrand,
    HeaderNavigation,
    NavItem,
    HeaderWidgets,
    HeaderBurgerMenu,
} from '@flixbus/honeycomb-react';
import useHeaderNavigation from './useHeaderNavigation';
import logo from './rv_logo.svg';
import AppHeaderWidgets from './HeaderWidgets';

import './AppHeader.scss';

export default function AppHeader({ sticky }) {
    const linkItems = useHeaderNavigation();
    const headerCssClass = classNames('ride-view-header hide-on-print', {
        'ride-view-header--sticky': sticky,
    });

    const MainNavigation = (
        <HeaderNavigation aria-label="main">
            {linkItems.map((linkItem) => {
                const { content, ...rest } = linkItem;
                return (
                    <NavItem {...rest} key={rest.to || rest.href}>
                        {content}
                    </NavItem>
                );
            })}
        </HeaderNavigation>
    );

    return (
        <Header fixed extraClasses={headerCssClass}>
            <HeaderBrand
                alt="Rideviwer"
                href="/"
                src={logo}
                appearance="tall"
            />
            <HeaderBurgerMenu
                openPanelLabel="Open main menu"
                closePanelLabel="Close main menu"
                panelId="main-menu-panel"
                applyWidgetContainer
            >
                {MainNavigation}
            </HeaderBurgerMenu>
            {MainNavigation}
            <HeaderWidgets>
                <AppHeaderWidgets />
            </HeaderWidgets>
        </Header>
    );
}

AppHeader.propTypes = {
    sticky: PropTypes.bool.isRequired,
};
