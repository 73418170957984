import React from 'react';
import ReactNotificationSystem from 'react-notification-system';
import PropTypes from 'prop-types';

export default function NotificationSystem(props) {
    const { setNotificationSystemRef, style, ...restProps } = props;
    return <ReactNotificationSystem ref={setNotificationSystemRef} style={style} {...restProps} />;
}

NotificationSystem.propTypes = {
    setNotificationSystemRef: PropTypes.func.isRequired,
    style: PropTypes.object,
};

NotificationSystem.defaultProps = {
    style: {},
};
