import React from 'react';

export default function withPaxStatusChangeConfirm(Component) {
    return class WithPaxStatusChangeConfirm extends React.Component {
        /**
         * @property {object} state - Initial state for component
         * @property {array} state.popupIsOpen - Popup state
         */
        state = {
            popupIsOpen: false,
        };

        /**
         * @method
         */
        toggleConfirmPopup = () => {
            this.setState(prevState => ({ popupIsOpen: !prevState.popupIsOpen }));
        };

        /**
         * @method
         */
        closeConfirmPopup = () => {
            this.setState({ popupIsOpen: false });
        };

        render() {
            return (
                <Component
                    {...this.props}
                    popupIsOpen={this.state.popupIsOpen}
                    toggleConfirmPopup={this.toggleConfirmPopup}
                    closeConfirmPopup={this.closeConfirmPopup}
                />
            );
        }
    };
}
