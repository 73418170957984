import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StationTime from './StationTime';

export default function StationSchedule(props) {
    const { arrival, departure, highlight, type, strikethrough } = props;
    const stationCss = classNames('timeline-station-schedule', {
        'timeline-station-schedule--highlight': highlight,
        'timeline-station-schedule--strikethrough': strikethrough,
        [`timeline-station-schedule--${type}`]: type,
    });
    return (
        <div className={stationCss}>
            <StationTime arrival={arrival} departure={departure} />
        </div>
    );
}
StationSchedule.propTypes = {
    arrival: PropTypes.string,
    departure: PropTypes.string,
    highlight: PropTypes.bool,
    type: PropTypes.oneOf([null, 'no-delay', 'small', 'medium', 'big']),
};
StationSchedule.defaultProps = {
    arrival: null,
    departure: null,
    highlight: false,
    type: null,
};
