import React from 'react';

export default function withWhoIsOnPage(Component) {
    return class WithWhoIsOnPage extends React.Component {
        render() {
            const { whoIsOnPage, ...restProps } = this.props;
            return <Component users={whoIsOnPage} {...restProps} />;
        }
    };
}
