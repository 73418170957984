import React, { useContext } from 'react';
import {TranslateContext} from "../../../System/Translations";

export default function DelayNotificationTriggered(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('pax-auto-delay-notification-triggered')} <strong>({item.parameters.sourceStation.code}) { item.parameters.sourceStation.shortName }</strong>
        </div>
    );
}
