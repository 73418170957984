import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';
import RideTag from '../../../RideTags/compounds/RideTag';

export default function RideTagAssignment(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const actionText =
        item.action === 'ride_tag_removed'
            ? translate('ride-tag.removed')
            : translate('ride-tag.added');
    return (
        <div>
            {translate('ride-tag.tag')}&nbsp;
            <RideTag
                key={item.parameters.tagId}
                name={item.parameters.tagName}
                color={item.parameters.tagColor}
                small
            />
            &nbsp;{actionText}
        </div>
    );
}
RideTagAssignment.propTypes = {
    item: PropTypes.object.isRequired,
};
