import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Heading } from '@flixbus/honeycomb-react';
import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../System/Translations';
import { ReactComponent as IconPrinter } from '../printer.svg';

import '../print.scss';

const withPrintWrapper =
    (Component) =>
    ({ ...props }) => {
        const match = useRouteMatch('/ride-view/:id/:name/print');
        const history = useHistory();
        const backUrl =
            match !== null && match.url
                ? match.url.replace('/print', '')
                : null;
        const translate = useContext(TranslateContext);
        const printBodyClass = 'print-view';
        const pageTitles = {
            reservations: translate('content_box.side_nav.reservations'),
            'actions-log': translate('content_box.side_nav.action_log'),
        };
        const [printView, setPrintView] = useState(false);

        const navigateBack = () => {
            history.push(backUrl);
        };

        const closePrintView = () => {
            setPrintView(false);

            navigateBack();
        };

        const printPage = () => {
            window.print();
        };

        useEffect(() => {
            setPrintView(Boolean(match));
        }, [match]);

        useEffect(() => {
            document.body.classList.toggle(printBodyClass, printView);
        }, [printView]);

        return (
            <>
                {match !== null && match.params.name ? (
                    <>
                        <div className="print-content-block">
                            <Heading
                                size={2}
                                flushSpace
                                extraClasses="print-heading"
                            >
                                {pageTitles[match.params.name] || null}
                            </Heading>
                            <Icon
                                InlineIcon={() => <IconPrinter />}
                                onClick={printPage}
                                extraClasses="print-action"
                            />
                            <Icon
                                InlineIcon={IconClose}
                                onClick={closePrintView}
                                extraClasses="print-close"
                            />
                        </div>
                    </>
                ) : null}

                <Component {...props} printView={printView} />
            </>
        );
    };

export default withPrintWrapper;
