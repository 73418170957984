import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function NotificationAntispam(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('notification-antispam.notification')} <strong>{ item.parameters.text }</strong> 
            {translate('notification-antispam.has-been-sent')} <strong>{ item.parameters.sent_to }</strong>
        </div>
    );
}

NotificationAntispam.propTypes = {
    item: PropTypes.object.isRequired,
};
