import React, { useContext } from 'react';
import ProprTypes from 'prop-types';
import { TranslateContext } from '../../System/Translations';

const SHORTCUTS = { one: 'today', two: 'yesterday', three: 'tomorrow' };
const DATE = {
    [SHORTCUTS.one]: new Date(),
    [SHORTCUTS.two]: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1),
    [SHORTCUTS.three]: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1),
};

export default function DateShortcuts(props) {
    const { onSelect } = props;
    const translate = useContext(TranslateContext);

    const presetDate = (param) => (e) => {
        e.preventDefault();
        onSelect(DATE[param]);
    };

    return (
        <div className="date-shortcuts">
            <button type="button" data-param="today" onClick={presetDate(SHORTCUTS.one)}>
                {translate('ride-search.date-input.today')}
            </button>
            |
            <button type="button" data-param="yesterday" onClick={presetDate(SHORTCUTS.two)}>
                {translate('ride-search.date-input.yesterday')}
            </button>
            |
            <button type="button" data-param="tomorrow" onClick={presetDate(SHORTCUTS.three)}>
                {translate('ride-search.date-input.tomorrow')}
            </button>
        </div>
    );
}

DateShortcuts.propTypes = {
    onSelect: ProprTypes.func.isRequired,
};
