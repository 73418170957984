import React from 'react';

/**
 * React Context to share TimezoneHelper handler
 * across application components
 * @constant TimezoneContext
 */
const TimezoneContext = React.createContext();

export default TimezoneContext;
