import React from 'react';
import { IconPlus } from '@flixbus/honeycomb-icons-react';
import RideTag from './RideTag';

export default function TagGroup(props) {
    const {
        // eslint-disable-next-line react/prop-types
        addRideTag,
        skipTagIds,
        tags,
    } = props;

    const tagList = tags.reduce((acc, item) => {
        if (
            skipTagIds.indexOf(item.id) === -1 &&
            !item.isDeleted &&
            !item.isRideViewerReadonly
        ) {
            acc.push(
                <RideTag
                    action={() => {
                        addRideTag(item.id);
                    }}
                    InlineIcon={IconPlus}
                    name={item.name}
                    color={item.color}
                    key={item.id}
                />
            );
        }
        return acc;
    }, []);

    return <div className="tag-group">{tagList}</div>;
}
