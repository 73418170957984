import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const COLUMN_PREFIX = 'flix-data-grid-col';

export default function DataCol(props) {
    const {
        className, size, sm, md, lg, xl, ...restProps
    } = props;
    const colClassNames = classNames('flix-data-grid-col', {
        [`${COLUMN_PREFIX}-${size}`]: size,
        [`${COLUMN_PREFIX}-${sm}-sm`]: sm,
        [`${COLUMN_PREFIX}-${md}-md`]: md,
        [`${COLUMN_PREFIX}-${lg}-lg`]: lg,
        [`${COLUMN_PREFIX}-${xl}-xl`]: xl,
        [className]: className,
    });
    return (
        <td {...restProps} className={colClassNames}>
            {props.children}
        </td>
    );
}

DataCol.propTypes = {
    // width of column
    // width for any screens
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // width for specific screen
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

DataCol.defaultProps = {
    size: '',
    sm: '',
    md: '',
    lg: '',
    xl: '',
    className: '',
    children: null,
};
