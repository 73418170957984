import { useCallback, useEffect, useReducer } from 'react';
import {
    useStops,
    usePartners,
    useLines,
    useTags,
    useConcessionOwnersNames,
} from '.';

import TIMEZONES_LIST from './timeZonesList';

function reducer(state, action) {
    const { payload } = action;
    return { ...state, ...payload };
}
/**
 *
 * @todo
 * Provide errors handling
 */
export default function useLists() {
    const [stops, getStops] = useStops();
    const [lines, getLines] = useLines();
    const [partners, getPartners] = usePartners();
    const [tags, getTags] = useTags();
    const [concessionOwners, getConcessionOwners] = useConcessionOwnersNames();
    const [state, dispatch] = useReducer(reducer, {
        data: null,
        pending: false,
        error: false,
    });

    useEffect(() => {
        if (
            stops.data !== null &&
            lines.data !== null &&
            partners.data !== null &&
            tags !== null &&
            concessionOwners.data !== null
        ) {
            dispatch({
                payload: {
                    data: {
                        stops: stops.data,
                        lines: lines.data,
                        partners: partners.data,
                        tags: tags,
                        concessionOwners: concessionOwners.data,
                        timezones: [...TIMEZONES_LIST],
                    },
                    error: false,
                    pending: false,
                },
            });
        }
    }, [stops.data, lines.data, partners.data, tags, concessionOwners.data]);

    const request = useCallback(
        (data, ...restArgs) => {
            dispatch({
                payload: {
                    error: false,
                    pending: true,
                },
            });
            getStops(data, ...restArgs);
            getLines(data, ...restArgs);
            getPartners(data, ...restArgs);
            getTags(data, ...restArgs);
            getConcessionOwners(data, ...restArgs);
        },
        [getStops, getLines, getPartners, getTags, getConcessionOwners]
    );
    return [state, request];
}
