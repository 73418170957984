import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../util/date';
import { TranslateContext } from '../../System/Translations';

export default function CommentsTooltip(props) {
    const { comments, error } = props;
    const commentsCount = comments.length;
    const lastComment = commentsCount !== 0 ? comments[0] : null;
    const translate = useContext(TranslateContext);

    if (error) {
        return translate('passengers.comments_form.error') + error.toString();
    }

    return lastComment === null ? (
        translate('passengers.comments_form.no_comments')
    ) : (
        <div>
            <div className="last-comment-content">{lastComment.content}</div>
            <div className="last-comment-info">
                {formatDate(lastComment.createdAt, 'DD.MM.YY hh:mm')}
                <div className="comets-total-count">
                    {commentsCount} {translate('comments.tooltip.message')}
                    {commentsCount > 1 ? 's' : ''}
                </div>
            </div>
        </div>
    );
}
CommentsTooltip.propTypes = {
    comments: PropTypes.array.isRequired,
};
