import { useCallback, useState } from 'react';
import tagsBankApi from '../../../api/TagsBankApi';

export default function useTags(includeDeleted = true) {
    const [response, setResponse] = useState(null);

    const request = useCallback(() => {
        tagsBankApi.get('/api/tags').then((res) => {
            setResponse(
                res.filter((tag) => {
                    if (includeDeleted) {
                        return true;
                    }
                    return tag.isDeleted === false;
                })
            );
        });
    }, [includeDeleted]);

    return [response, request];
}
