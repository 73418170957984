import React from 'react';

/**
 * React Context to share ShowHideICsHelper handler
 * across application components
 * @constant ShowHideICsContext
 */
const ShowHideICsContext = React.createContext();

export default ShowHideICsContext;
