import React, {
    useContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { Grid, GridCol, FormRow, Checkbox } from '@flixbus/honeycomb-react';
import {
    IconStation,
    IconRideTransfer,
    IconBus,
    IconBuses,
    IconWheel,
    IconPartner,
    IconLabel,
    IconLabelSolid,
    IconTimelineAlt,
    IconDocument,
} from '@flixbus/honeycomb-icons-react';
import equal from 'fast-deep-equal';
import { TranslateContext } from '../../../System/Translations';
import { useFiltersMap } from '../../hooks';
import {
    ListPicker,
    DateInput,
    TimeHoursAndMinsInput,
    LicensePlateInput,
    StatusesSelect,
    ComboStopsPicker,
    NumberInput,
    WeekDaysSelect,
} from '../../FormElements';
import * as FILTERS from '../../SearchForm/formMap';
import SidePanel from '../SidePanel';

const COMBO_STOPS_PICKER = 'comboStopsPicker';

export default function FormBuilder({
    customFilters,
    panelCustomFilters,
    saveCustomFilters,
    formData,
    actions,
    lists,
}) {
    const translate = useContext(TranslateContext);
    const filtersMap = useFiltersMap();
    const [addAction, deleteAction] = actions;

    function getLineOptions() {
        if (lists.data) {
            const { lines } = lists.data;
            return lines.map((line) => ({
                title: `${line.code}-${line.title}`,
                sortTitle: line.code,
                value: line.uuid,
                type: FILTERS.LINE,
                search: line.code,
            }));
        }
        return [];
    }

    function getTagsOptions() {
        if (lists.data) {
            const { tags } = lists.data;
            return tags.map((tag) => ({
                title: tag.name,
                sortTitle: tag.name,
                value: tag.id.toString(),
                type: FILTERS.TAGS,
            }));
        }
        return [];
    }

    function getPartnersOptions() {
        if (lists.data) {
            const { partners } = lists.data;
            return partners.map((partner) => ({
                title: `${partner.shortName} (${partner.name})`,
                sortTitle: `${partner.shortName} ${partner.name}`,
                value: partner.uuid,
                type: FILTERS.PARTNERS,
            }));
        }
        return [];
    }

    function getStopsOptions() {
        if (lists.data) {
            const { stops } = lists.data;
            return stops.map((stop) => ({
                title: `${stop.code}-${stop.name}`,
                sortTitle: `${stop.code} ${stop.name}`,
                value: stop.uuid,
                type: FILTERS.STOP_DEPARTURE,
            }));
        }
        return [];
    }

    function getConcessionOwnersNamesOptions() {
        if (lists.data) {
            const { concessionOwners } = lists.data;
            return concessionOwners.map((name) => ({
                title: name.title,
                sortTitle: name.title,
                value: name.title,
                type: FILTERS.CONCESSION_OWNERS,
            }));
        }
        return [];
    }

    function getTimeZoneNamesOptions() {
        if (lists.data) {
            const { timezones } = lists.data;
            return timezones.map((item) => ({
                title: `${item.name} (${item.timezone})`,
                sortTitle: item.name,
                value: item.timezone,
                type: FILTERS.TIMEZONE,
            }));
        }
        return [];
    }

    const components = {
        [FILTERS.ARRTIME]: [
            <GridCol size={2} key={FILTERS.ARRTIME}>
                <FormRow>
                    <TimeHoursAndMinsInput
                        id={FILTERS.ARRTIME}
                        value={formData[FILTERS.ARRTIME]}
                        label={filtersMap[FILTERS.ARRTIME]}
                        title={filtersMap[FILTERS.ARRTIME]}
                        onChange={addAction(FILTERS.ARRTIME)}
                    />
                </FormRow>
            </GridCol>,
            2,
            9,
        ],
        [FILTERS.DATE]: [
            <GridCol size={2} key={FILTERS.DATE}>
                <FormRow last>
                    <DateInput
                        onChange={addAction(FILTERS.DATE)}
                        value={formData[FILTERS.DATE]}
                    />
                </FormRow>
            </GridCol>,
            2,
            4,
        ],
        [FILTERS.DEPTIME]: [
            <GridCol size={2} key={FILTERS.DEPTIME}>
                <FormRow>
                    <TimeHoursAndMinsInput
                        id={FILTERS.DEPTIME}
                        value={formData[FILTERS.DEPTIME]}
                        label={filtersMap[FILTERS.DEPTIME]}
                        title={filtersMap[FILTERS.DEPTIME]}
                        onChange={addAction(FILTERS.DEPTIME)}
                    />
                </FormRow>
            </GridCol>,
            2,
            10,
        ],
        [FILTERS.PLATE_NUMBER]: [
            <GridCol size={2} key={FILTERS.PLATE_NUMBER}>
                <FormRow>
                    <LicensePlateInput
                        id={FILTERS.PLATE_NUMBER}
                        InlineIcon={IconWheel}
                        label={filtersMap[FILTERS.PLATE_NUMBER]}
                        title={filtersMap[FILTERS.PLATE_NUMBER]}
                        value={formData[FILTERS.PLATE_NUMBER]}
                        onChange={addAction(FILTERS.PLATE_NUMBER)}
                    />
                </FormRow>
            </GridCol>,
            2,
            2,
        ],
        [FILTERS.STATUSES]: [
            <GridCol size={4} key={FILTERS.STATUSES}>
                <FormRow>
                    <StatusesSelect
                        onSelect={addAction(FILTERS.STATUSES, true)}
                        onUnselect={deleteAction(FILTERS.STATUSES)}
                        value={formData[FILTERS.STATUSES]}
                    />
                </FormRow>
            </GridCol>,
            4,
            6,
        ],
        [FILTERS.WEEKDAYS]: [
            <GridCol size={7} key={FILTERS.WEEKDAYS}>
                <FormRow>
                    <WeekDaysSelect
                        onSelect={addAction(FILTERS.WEEKDAYS, true)}
                        onUnselect={deleteAction(FILTERS.WEEKDAYS)}
                        value={formData[FILTERS.WEEKDAYS]}
                    />
                </FormRow>
            </GridCol>,
            7,
            12,
        ],
        [FILTERS.STOP_ARRIVAL]: [
            <GridCol size={3} key={FILTERS.STOP_ARRIVAL}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.STOP_ARRIVAL}
                        label={filtersMap[FILTERS.STOP_ARRIVAL]}
                        InlineIcon={IconStation}
                        options={getStopsOptions()}
                        onChange={addAction(FILTERS.STOP_ARRIVAL)}
                        loading={lists.pending}
                        value={formData[FILTERS.STOP_ARRIVAL]}
                    />
                </FormRow>
            </GridCol>,
            3,
            8,
        ],
        [FILTERS.STOP_PASSING_THROUGH]: [
            <GridCol size={3} key={FILTERS.STOP_PASSING_THROUGH}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.STOP_PASSING_THROUGH}
                        label={filtersMap[FILTERS.STOP_PASSING_THROUGH]}
                        InlineIcon={IconRideTransfer}
                        options={getStopsOptions()}
                        onChange={addAction(FILTERS.STOP_PASSING_THROUGH)}
                        loading={lists.pending}
                        value={formData[FILTERS.STOP_PASSING_THROUGH]}
                    />
                </FormRow>
            </GridCol>,
            3,
            7,
        ],
        [FILTERS.LINE]: [
            <GridCol size={2} key={FILTERS.LINE}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.LINE}
                        label={filtersMap[FILTERS.LINE]}
                        InlineIcon={IconBus}
                        options={getLineOptions()}
                        onChange={addAction(FILTERS.LINE, true)}
                        loading={lists.pending}
                        value={formData[FILTERS.LINE]}
                        shouldResetValue={() => true}
                        processorOptions={{
                            regExpFn: (search) => search,
                            sortByIndex: true,
                        }}
                        // multiselect
                    />
                </FormRow>
            </GridCol>,
            2,
            1,
        ],
        [FILTERS.TAGS]: [
            <GridCol size={2} key={FILTERS.TAGS}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.TAGS}
                        label={filtersMap[FILTERS.TAGS]}
                        InlineIcon={IconLabel}
                        options={getTagsOptions()}
                        onChange={addAction(FILTERS.TAGS, true)}
                        loading={lists.pending}
                        value={formData[FILTERS.TAGS]}
                        shouldResetValue={() => true}
                        // multiselect
                    />
                </FormRow>
            </GridCol>,
            2,
        ],
        [FILTERS.EXCLUDE_TAGS]: [
            <GridCol size={2} key={FILTERS.EXCLUDE_TAGS}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.EXCLUDE_TAGS}
                        label={filtersMap[FILTERS.EXCLUDE_TAGS]}
                        InlineIcon={IconLabelSolid}
                        options={getTagsOptions()}
                        onChange={addAction(FILTERS.EXCLUDE_TAGS, true)}
                        loading={lists.pending}
                        value={formData[FILTERS.EXCLUDE_TAGS]}
                        shouldResetValue={() => true}
                        // multiselect
                    />
                </FormRow>
            </GridCol>,
            2,
        ],
        [FILTERS.TRIP_NUMBER]: [
            <GridCol size={2} key={FILTERS.TRIP_NUMBER}>
                <FormRow>
                    <NumberInput
                        id={FILTERS.TRIP_NUMBER}
                        InlineIcon={IconBuses}
                        label={filtersMap[FILTERS.TRIP_NUMBER]}
                        title={filtersMap[FILTERS.TRIP_NUMBER]}
                        value={formData[FILTERS.TRIP_NUMBER]}
                        onChange={addAction(FILTERS.TRIP_NUMBER, true)}
                    />
                </FormRow>
            </GridCol>,
            3,
            4,
        ],
        [FILTERS.BUS_NUMBER]: [
            <GridCol size={2} key={FILTERS.BUS_NUMBER}>
                <FormRow>
                    <NumberInput
                        id={FILTERS.BUS_NUMBER}
                        InlineIcon={IconBus}
                        label={filtersMap[FILTERS.BUS_NUMBER]}
                        title={filtersMap[FILTERS.BUS_NUMBER]}
                        value={formData[FILTERS.BUS_NUMBER]}
                        onChange={addAction(FILTERS.BUS_NUMBER, true)}
                    />
                </FormRow>
            </GridCol>,
            3,
            4,
        ],
        [FILTERS.STOP_DEPARTURE]: [
            <GridCol size={3} key={FILTERS.STOP_DEPARTURE}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.STOP_DEPARTURE}
                        label={filtersMap[FILTERS.STOP_DEPARTURE]}
                        InlineIcon={IconStation}
                        options={getStopsOptions()}
                        onChange={addAction(FILTERS.STOP_DEPARTURE)}
                        loading={lists.pending}
                        value={formData[FILTERS.STOP_DEPARTURE]}
                    />
                </FormRow>
            </GridCol>,
            3,
            5,
        ],
        [COMBO_STOPS_PICKER]: [
            <GridCol
                size={5}
                key={[FILTERS.STOP_DEPARTURE, FILTERS.STOP_ARRIVAL].join('-')}
            >
                <FormRow>
                    <ComboStopsPicker
                        ids={[FILTERS.STOP_DEPARTURE, FILTERS.STOP_ARRIVAL]}
                        labels={[
                            filtersMap[FILTERS.STOP_DEPARTURE],
                            filtersMap[FILTERS.STOP_ARRIVAL],
                        ]}
                        actions={[
                            addAction(FILTERS.STOP_DEPARTURE),
                            addAction(FILTERS.STOP_ARRIVAL),
                        ]}
                        options={getStopsOptions()}
                        loading={lists.pending}
                        values={[
                            formData[FILTERS.STOP_DEPARTURE],
                            formData[FILTERS.STOP_ARRIVAL],
                        ]}
                    />
                </FormRow>
            </GridCol>,
            5,
            4,
        ],
        [FILTERS.PARTNERS]: [
            <GridCol size={2} key={FILTERS.PARTNERS}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.PARTNERS}
                        label={filtersMap[FILTERS.PARTNERS]}
                        InlineIcon={IconPartner}
                        options={getPartnersOptions()}
                        onChange={addAction(FILTERS.PARTNERS, true)}
                        loading={lists.pending}
                        value={formData[FILTERS.PARTNERS]}
                        shouldResetValue={() => true}
                    />
                </FormRow>
            </GridCol>,
            2,
            5,
        ],
        [FILTERS.BUS_IDS]: [
            <GridCol size={2} key={FILTERS.BUS_IDS}>
                <FormRow>
                    <NumberInput
                        id={FILTERS.BUS_IDS}
                        InlineIcon={IconBus}
                        label={filtersMap[FILTERS.BUS_IDS]}
                        title={filtersMap[FILTERS.BUS_IDS]}
                        value={formData[FILTERS.BUS_IDS]}
                        onChange={addAction(FILTERS.BUS_IDS, true)}
                    />
                </FormRow>
            </GridCol>,
            3,
            4,
        ],
        [FILTERS.CONCESSION_OWNERS]: [
            <GridCol size={3} key={FILTERS.CONCESSION_OWNERS}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.CONCESSION_OWNERS}
                        label={filtersMap[FILTERS.CONCESSION_OWNERS]}
                        InlineIcon={IconDocument}
                        options={getConcessionOwnersNamesOptions()}
                        onChange={addAction(FILTERS.CONCESSION_OWNERS, true)}
                        loading={lists.pending}
                        value={formData[FILTERS.CONCESSION_OWNERS]}
                        shouldResetValue={() => true}
                        // multiselect
                    />
                </FormRow>
            </GridCol>,
            3,
            5,
        ],
        [FILTERS.TIMEZONE]: [
            <GridCol size={3} key={FILTERS.TIMEZONE}>
                <FormRow>
                    <ListPicker
                        id={FILTERS.TIMEZONE}
                        label={filtersMap[FILTERS.TIMEZONE]}
                        InlineIcon={IconTimelineAlt}
                        options={getTimeZoneNamesOptions()}
                        onChange={addAction(FILTERS.TIMEZONE, true)}
                        value={formData[FILTERS.TIMEZONE]}
                        shouldResetValue={() => true}
                    />
                </FormRow>
            </GridCol>,
            3,
            3,
        ],
    };

    const getComponents = useCallback(() => {
        function analyzeFilters() {
            const isComboStopPicker =
                customFilters.includes(FILTERS.STOP_ARRIVAL) &&
                customFilters.includes(FILTERS.STOP_DEPARTURE);
            return customFilters.reduce((acc, filter) => {
                const res = [...acc];
                if (
                    (filter === FILTERS.STOP_ARRIVAL && isComboStopPicker) ||
                    (filter === FILTERS.STOP_DEPARTURE && isComboStopPicker)
                ) {
                    !res.includes(COMBO_STOPS_PICKER) &&
                        res.push(COMBO_STOPS_PICKER);
                    return res;
                }
                res.push(filter);
                return res;
            }, []);
        }
        const componentsCollection = analyzeFilters()
            .sort(
                (filterA, filterB) =>
                    components[filterA][2] - components[filterB][2]
            )
            .map((filter) => components[filter][0]);
        return componentsCollection;
    }, [customFilters, components]);

    const [filters, setFilters] = useState(getComponents());
    // use ref as an mutable object storage
    // that can keep value cross over all renders
    const cachedFilters = useRef(customFilters);
    const depData = [lists.pending, JSON.stringify(formData)];
    const stateDep = useRef(depData);

    useEffect(() => {
        // use ref and equal for deep compare dependency object to avoid unnecessary rerender
        if (
            !equal(customFilters, cachedFilters.current) ||
            (!equal(stateDep.current, depData) && lists.data !== null)
        ) {
            setFilters(getComponents());
        }
        cachedFilters.current = customFilters;
        stateDep.current = depData;
    }, [
        customFilters,
        components,
        depData,
        getComponents,
        formData,
        lists.data,
    ]);

    return (
        <Grid>
            <GridCol size={12}>
                <Grid>{filters}</Grid>
            </GridCol>
            <GridCol size={12}>
                <Grid align="bottom">
                    <GridCol size={9}>
                        <Grid align="center">
                            <GridCol size={2}>
                                <SidePanel
                                    filters={panelCustomFilters}
                                    onChange={saveCustomFilters}
                                />
                            </GridCol>
                            <GridCol size={10}>
                                <Grid extraClasses="ride-search__checkbox-filters">
                                    <GridCol size={2}>
                                        <Checkbox
                                            small
                                            id={`${FILTERS.LIVE_RIDES}-switcher-2`}
                                            label={translate(
                                                'ride-search.live-rides.label'
                                            )}
                                            onChange={(e) => {
                                                addAction(FILTERS.LIVE_RIDES)(
                                                    e.target.checked
                                                );
                                            }}
                                            checked={
                                                formData[FILTERS.LIVE_RIDES] ||
                                                false
                                            }
                                            value=""
                                        />
                                    </GridCol>
                                    <GridCol size={3}>
                                        <Checkbox
                                            small
                                            id={`${FILTERS.EXCLUDE_ASSIGNED_BUSES}-switcher-3`}
                                            label={translate(
                                                'ride-search.exclude-assigned-buses.label'
                                            )}
                                            onChange={(e) => {
                                                addAction(
                                                    FILTERS.EXCLUDE_ASSIGNED_BUSES
                                                )(e.target.checked);
                                            }}
                                            checked={
                                                formData[
                                                    FILTERS
                                                        .EXCLUDE_ASSIGNED_BUSES
                                                ] || false
                                            }
                                            value=""
                                        />
                                    </GridCol>
                                </Grid>
                            </GridCol>
                        </Grid>
                    </GridCol>
                </Grid>
            </GridCol>
        </Grid>
    );
}

FormBuilder.propTypes = {
    setAction: PropTypes.func.isRequired,
    saveCustomFilters: PropTypes.func.isRequired,
    customFilters: PropTypes.array,
    panelCustomFilters: PropTypes.array,
    formData: PropTypes.object,
};

FormBuilder.defaultProps = {
    customFilters: [],
    formData: {},
    panelCustomFilters: [],
};
