import React from 'react';
import PropTypes from 'prop-types';
import IFrame from './IFrame';

export default function IFrameDialog(props) {
    const { popUpUrl, active, title, ...rest } = props;
    if (!active) {
        return null;
    }
    return <IFrame src={popUpUrl} title={title} {...rest} />;
}

IFrameDialog.propTypes = {
    popUpUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    active: PropTypes.bool,
};

IFrameDialog.defaultProps = {
    active: false,
};
