import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';
import { NotifyContext } from '../../NotificationSystem/context';
import api from '../../../api/Client';

const PAX2BUS_DIST_CANCEL_TOKEN = 'SET_PAX2BUS_DIST';

export default function EnableDisablePAXDistribution(props) {
    const { rideUuid, pax2busDistributionEnabled } = props;
    const [loading, setLoading] = useState(false);
    const [isEnabled, setEnabled] = useState(false);

    const translate = useContext(TranslateContext);
    const notify = useContext(NotifyContext);

    const label = loading
        ? translate('with-comments-api.loading')
        : translate('toolbar.pax_distribution');

    function onChange() {
        setLoading(true);

        setEnabled(!isEnabled);
        setPax2BusDistribution(!isEnabled);
    }

    function setPax2BusDistribution(value) {
        api.post(`/pax2bus/${rideUuid}/enabled`, {
            cancelTokenId: PAX2BUS_DIST_CANCEL_TOKEN,
            enabled: value,
        })
            .then(() => {
                setLoading(false);

                notify({
                    type: 'success',
                    message: translate('notify.pax2bus_distrubution.success'),
                });
            })
            .catch((e) => {
                setLoading(false);
                setEnabled(!value);
                if (!api.isCancel(e)) {
                    const messages =
                        undefined === e.response.data.messages
                            ? ''
                            : ' ' + e.response.data.messages.join(', ');
                    notify({
                        type: 'danger',
                        message: e.toString() + messages,
                    });
                }
            });
    }

    useEffect(() => {
        setEnabled(pax2busDistributionEnabled);

        return () => {
            api.cancel(PAX2BUS_DIST_CANCEL_TOKEN);
        };
    }, [pax2busDistributionEnabled]);

    return (
        <Switch
            label={label}
            id="with_booster_buses"
            onChange={onChange}
            checked={isEnabled}
            small
        />
    );
}

EnableDisablePAXDistribution.propTypes = {
    rideUuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    pax2busDistributionEnabled: PropTypes.bool.isRequired,
};
