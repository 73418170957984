import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function StopRelocated(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);

    let newAddress = item.parameters.newAddress.addressOne;
    if (newAddress.length > 0 && item.parameters.newAddress.addressTwo) {
        newAddress += `, ${item.parameters.newAddress.addressTwo}`;
    }
    if (newAddress.length > 0 && item.parameters.newAddress.zip) {
        newAddress += `, ${item.parameters.newAddress.zip}`;
    }
    if (item.parameters.newAddress.gmapParams) {
        const { gmapParams } = item.parameters.newAddress;
        newAddress = (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={`http://www.google.com/maps/place/${gmapParams.latitude},${gmapParams.longitude}`} target="_blank">
                { newAddress }
            </a>
        );
    }

    return (
        <div>
            <strong>{`${item.parameters.stopName} (${item.parameters.stopCode})`}</strong>&nbsp;
            { translate('stop-was-relocated') }
            <br />
            {`${translate('relocated-stop-new-address')} `}
            { newAddress }

        </div>
    );
}

StopRelocated.propTypes = {
    item: PropTypes.object.isRequired,
};
