import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';

export default function LoadMore(props) {
    const { loadMore, loading, disabled } = props;
    const translate = useContext(TranslateContext);
    const buttonLabel = loading
        ? translate('ride-search.form.load-more-loading')
        : translate('ride-search.form.load-more');

    return (
        <div className="load-more-wrapper">
            <Button
                appearance="tertiary"
                disabled={disabled || loading}
                onClick={loadMore}
                loading={loading}
            >
                {buttonLabel}
            </Button>
        </div>
    );
}

LoadMore.propTypes = {
    loadMore: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};
LoadMore.defaultProps = { loading: false, disabled: false };
