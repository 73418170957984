import React from 'react';
import PropTypes from 'prop-types';

export default function TrainPlatformChange(props) {
    const { item } = props;

    return (
        <div>
            Platform of { item.parameters.stopName } has been changed.
            Planned platform: { item.parameters.plannedPlatform }.
            New platform: { item.parameters.changedPlatform }.
        </div>
    );
}
TrainPlatformChange.propTypes = {
    item: PropTypes.object.isRequired,
};
