import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../System/Translations';
import useGetCommentHistory from '../../CommentsHooks/useGetCommentHistory';
import { Connection, ConnectionStop } from '@flixbus/honeycomb-react';
import { formatDate } from '../../../util/date';

export default function History(props) {
    const { notify, commentId, format } = props;
    const { history, error, loading, getCommentHistory } =
        useGetCommentHistory(commentId);
    const translate = useContext(TranslateContext);

    const showLoading = () => {
        if (loading && history.length === 0) {
            return translate('with-comments-api.loading');
        }
        return null;
    };

    const showNoComments = () => {
        if (loading === false && history.length === 0) {
            return translate('with-comments-api.no-comments');
        }
        return null;
    };

    if (error) {
        notify({
            type: 'error',
            message: translate('comments.error') + error.toString(),
        });
    }

    useEffect(() => {
        if (commentId) {
            getCommentHistory();
        }
    }, [commentId, getCommentHistory]);

    return (
        <>
            {showLoading()}
            {showNoComments()}

            {history.length === 0 ? /**
             * @todo
             * show loading table skeleton
             */ null : (
                <Connection extraClasses="single-comment__history">
                    {history.map(({ updatedAt, content, author }, idx) => {
                        const body = () => (
                            <div className="single-comment__history-item">
                                <span>
                                    {idx === history.length - 1
                                        ? `${author} ${translate(
                                              'comment.created-comment'
                                          )} "${content}"`
                                        : `${author} ${translate(
                                              'comment.edited-comment-to'
                                          )} "${content}"`}
                                    "
                                </span>
                                <span>{formatDate(updatedAt, format)}</span>
                            </div>
                        );
                        return (
                            <ConnectionStop key={updatedAt} station={body()} />
                        );
                    })}
                </Connection>
            )}
        </>
    );
}

History.propTypes = {
    notify: PropTypes.func.isRequired,
    commentId: PropTypes.number.isRequired,
    format: PropTypes.string,
};
