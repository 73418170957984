import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../../../../api/megadriveApi';
import {
    stopStatusChangeAction,
    stopStatusChangeFailAction,
    stopStatusChangeConfirmAction,
} from '../../../../../../DataDog/actions';

const WAMP_STATION_STATUS_TIMEOUT = 30 * 1000; // 30 seconds

export default function withStationStatusApi(Component) {
    return class WithStationStatusApi extends React.Component {
        static propTypes = {
            actions: PropTypes.shape({
                open: PropTypes.string,
                close: PropTypes.string,
            }),
            uuid: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            notify: PropTypes.func,
        };

        static defaultProps = {
            notify: () => {},
        };

        constructor(props) {
            super(props);
            this.state = {
                saving: false,
            };
        }

        componentWillUnmount() {
            const { actions } = this.props;
            if (actions) {
                api.cancel(actions.open.replace(api.options.apiUrl, ''));
                api.cancel(actions.close.replace(api.options.apiUrl, ''));
                api.cancel(actions.cancel.replace(api.options.apiUrl, ''));
            }
        }
        /**
         * As we consuming station status from WAMP let's set a timeout for that
         * (@see ./withStationStatusWsSubscriber.js method toggleSaving(false) is called)
         */

        setWampTimeout = (timeout = WAMP_STATION_STATUS_TIMEOUT) => {
            const { notify, uuid } = this.props;
            this.wampTimeout = setTimeout(() => {
                if (this.state.saving) {
                    notify({
                        type: 'danger',
                        message:
                            'Change station status timeout. Please contact support',
                    });
                    stopStatusChangeFailAction({
                        id: uuid,
                        reason: 'No confirmation',
                    });
                    this.toggleSaving(false);
                }
            }, timeout);
        };

        wampTimeout = null;

        openStation = () => {
            const { actions, uuid } = this.props;

            if (this.state.saving) {
                return;
            }
            stopStatusChangeAction({
                id: uuid,
                status: 'open',
                timestamp: new Date().getTime(),
            });
            this.setState({ saving: true }, () => {
                api.put(actions.open)
                    .then(() => {
                        this.setWampTimeout();
                    })
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            const message =
                                error.response.data?.error || error.message;
                            this.props.notify({
                                type: 'danger',
                                message,
                            });
                            stopStatusChangeFailAction({
                                id: uuid,
                                reason: message,
                                status: 'open',
                            });
                            this.toggleSaving(false);
                        }
                    });
            });
        };

        closeStation = () => {
            if (this.state.saving) {
                return;
            }
            const { actions, uuid } = this.props;
            stopStatusChangeAction({
                id: uuid,
                status: 'close',
                timestamp: new Date().getTime(),
            });
            this.setState({ saving: true }, () => {
                api.put(actions.close)
                    .then(() => {
                        this.setWampTimeout();
                    })
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            const message =
                                error.response.data?.error || error.message;
                            this.props.notify({
                                type: 'danger',
                                message,
                            });
                            stopStatusChangeFailAction({
                                id: uuid,
                                reason: message,
                                status: 'close',
                            });
                            this.toggleSaving(false);
                        }
                    });
            });
        };

        cancelStation = () => {
            if (this.state.saving) {
                return;
            }

            const { actions, uuid } = this.props;
            stopStatusChangeAction({
                id: uuid,
                status: 'cancel',
                timestamp: new Date().getTime(),
            });
            this.setState({ saving: true }, () => {
                api.put(actions.cancel)
                    .then(() => {
                        this.setWampTimeout();
                    })
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            const message =
                                error.response.data?.error || error.message;
                            this.props.notify({
                                type: 'danger',
                                message,
                            });
                            stopStatusChangeFailAction({
                                id: uuid,
                                reason: message,
                                status: 'cancel',
                            });
                            this.toggleSaving(false);
                        }
                    });
            });
        };

        toggleSaving = (saving) => {
            clearTimeout(this.wampTimeout);
            this.setState({ saving });
        };

        confirmStatusChange = (status) => {
            const { uuid } = this.props;
            const { saving } = this.state;
            if (saving === true) {
                stopStatusChangeConfirmAction({
                    id: uuid,
                    timestamp: new Date().getTime(),
                    status,
                });
            }
            this.toggleSaving(false);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    openStation={this.openStation}
                    closeStation={this.closeStation}
                    cancelStation={this.cancelStation}
                    toggleSaving={this.toggleSaving}
                    confirmStatusChange={this.confirmStatusChange}
                    stationStatusSaving={this.state.saving}
                />
            );
        }
    };
}
