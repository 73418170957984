import moment from 'moment/moment';

/**
 * @todo place this helper to the date utils file
 * adds delay in seconds to the scheduled time
 * @func calculateEta
 * @param {string} scheduleTime - scheduled time in format `2018-10-25T14:50:00-07:00`
 * @param {string|number} delay - delay in seconds
 * @returns {string} resulting time in format `2018-10-25T14:50:00-07:00`
 */

export default function calculateEta(scheduleTime, delay) {
    if (!scheduleTime) {
        return null;
    }
    const mnt = moment.parseZone(scheduleTime).add(delay, 'seconds');
    return mnt.format();
}

export { calculateEta };
