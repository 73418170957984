import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';

export default function withBlockSurveyMailApi(Component) {
    return class WithBlockSurveyMailApi extends React.Component {
        static propTypes = {
            rideUuid: PropTypes.string.isRequired,
        };

        state = {
            isEnabled: false,
            inProcess: true,
        };

        componentDidMount() {
            const {notify} = this.props;
            api.get(this.requestUrl)
                .then((result) => {
                    this.setState({
                        isEnabled: result.survey_mail,
                        inProcess: false,
                    });
                })
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        this.setState({
                            inProcess: false,
                        });
                        notify({
                            message: `Survey Mail: ${error.toString()}`,
                            type: 'error',
                        })
                        if(error.toString().match(/(exception|uncaught)/gi) !== null) {
                            throw(error);
                        }
                    }
                });
        }

        componentWillUnmount() {
            api.cancel(this.requestUrl);
        }

        get requestUrl() {
            const { rideUuid } = this.props;
            return `/rideviewer/ride/${rideUuid}/survey_mail_option`;
        }

        toggleSurveyMail = () => {
            const { isEnabled } = this.state;
            const {notify} = this.props;
            this.setState({
                inProcess: true,
            });
            api.post(this.requestUrl, {
                status: !isEnabled,
            })
                .then((result) => {
                    this.setState({
                        isEnabled: result.survey_mail,
                        inProcess: false,
                    });
                })
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        this.setState({
                            inProcess: false,
                        });
                        notify({
                            message: `Survey Mail: ${error.toString()}`,
                            type: 'error',
                        })
                        if(error.toString().match(/(exception|uncaught)/gi) !== null) {
                            throw(error);
                        }
                    }
                });
        };

        render() {
            const { isEnabled, inProcess } = this.state;
            return <Component isEnabled={isEnabled} toggleSurveyMail={this.toggleSurveyMail} inProcess={inProcess} />;
        }
    };
}
