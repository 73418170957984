import DataGrid from './DataGrid';
import DataRow from './DataRow';
import DataCol from './DataCol';
import DataHeaderGroup from './DataHeaderGroup';
import DataRowGroup from './DataRowGroup';
import DataFooterGroup from './DataFooterGroup';

// import './flixui-data-grid.css';
import './DataGrid.css';

export default DataGrid;
export { DataRow };
export { DataCol };
export { DataHeaderGroup };
export { DataRowGroup };
export { DataFooterGroup };
