import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Connection,
    ConnectionStop,
    Heading,
    Panel,
    PanelHeader,
    PanelContent,
} from '@flixbus/honeycomb-react';
import TimezoneContext from '../System/TimezoneHelper/context';
import { formatDate, ISO_8601_FORMAT, addTime } from '../../util/date';

const TIME_FORMAT = 'HH:mm';
const START_FORMAT = 'ddd, DD MMM, HH:mm';

function generateStops(timeline, timeFormatter, isLocal, onStationClick) {
    // const timeFormatter = timezone.isLocal ? formatDate : formatDate.parseZone;

    const out = timeline.reduce((acc, item) => {
        const results = [...acc];
        if (item.entry_type === 'stop') {
            const {
                name,
                arrival,
                departure,
                arrival_delay: arrivalDelay,
                departure_delay: departureDelay,
                code,
            } = item;

            const isArrDelay = arrivalDelay > 0;
            const isDepDelay = departureDelay > 0;

            const arrivalText = arrival ? timeFormatter(arrival, 'HH:mm') : '';
            const departureText = departure
                ? timeFormatter(departure, 'HH:mm')
                : '';

            const arrivalDelayText =
                (isArrDelay || isDepDelay) && arrival
                    ? timeFormatter(
                          addTime(
                              arrivalDelay,
                              'seconds',
                              ISO_8601_FORMAT,
                              arrival,
                              !isLocal
                          ),
                          TIME_FORMAT
                      )
                    : '';
            const departureDelayText =
                (isDepDelay || isArrDelay) && departure
                    ? timeFormatter(
                          addTime(
                              departureDelay,
                              'seconds',
                              ISO_8601_FORMAT,
                              departure,
                              !isLocal
                          ),
                          TIME_FORMAT
                      )
                    : '';

            results.push({
                station: `(${code})-${name}`,
                overflow: true,
                departureTime: {
                    current: {
                        time: departureDelayText || departureText,
                        appearance: departureDelayText ? 'warning' : null,
                    },
                    old: {
                        time: departureDelayText && departureText,
                        appearance: departureDelayText ? 'old' : null,
                    },
                },
                arrivalTime: {
                    current: {
                        time: arrivalDelayText || arrivalText,
                        appearance: arrivalDelayText ? 'warning' : null,
                    },
                    old: {
                        time: arrivalDelayText && arrivalText,
                        appearance: arrivalDelayText ? 'old' : null,
                    },
                },
                onClick: onStationClick(code),
            });
        }
        return results;
    }, []);
    return out;
}

export default function SideTimeline(props) {
    const { timeline, active, ride, onClose, onStationClick } = props;
    const timezone = useContext(TimezoneContext);
    const timeFormatter = timezone.isLocal ? formatDate : formatDate.parseZone;
    const stops = generateStops(
        timeline,
        timeFormatter,
        timezone.isLocal,
        onStationClick
    );
    const title = {
        start: timeFormatter(ride.departure, START_FORMAT),
        end: timeFormatter(ride.arrival_time, TIME_FORMAT),
    };

    return (
        <Panel
            active={active}
            title="Timeline"
            hasOverlay
            onOverlayClick={onClose}
        >
            <PanelHeader
                closeButtonProps={{ label: 'Close', onClick: onClose }}
            >
                Timeline
            </PanelHeader>
            <PanelContent>
                <Heading size={1}>{ride.uid}</Heading>
                <Connection live title={title}>
                    {stops.map((stop) => (
                        // for some reason honeycomb timeline shows arrival time below departure time
                        <ConnectionStop
                            key={stop.station}
                            station={stop.station}
                            arrivalTime={{
                                time: stop.departureTime.current.time,
                            }}
                            departureTime={{
                                time: stop.arrivalTime.current.time,
                            }}
                            oldDepartureTime={
                                stop.departureTime.old.time
                                    ? {
                                          time: stop.arrivalTime.old.time,
                                      }
                                    : null
                            }
                            oldArrivalTime={
                                stop.arrivalTime.old.time
                                    ? {
                                          time: stop.departureTime.old.time,
                                      }
                                    : null
                            }
                        />
                    ))}
                </Connection>
            </PanelContent>
        </Panel>
    );
}
SideTimeline.propTypes = { timeline: PropTypes.array };
SideTimeline.defaultProps = { timeline: [] };
