import React from 'react';
import TranslateContext from '../context';
import langsList from '../langsList';
import localStorage from '../../../../util/localStorage';

const DEFAULT_LNG = 'en';

export default function withTranslateProvider(Component) {
    return class WithTranslateProvider extends React.Component {
        static displayName = `WithTranslateProvider(${
            Component.name || Component.displayName
        })`;

        constructor(props) {
            super(props);
            // add properties to extend function provide some extra functionality
            // method to set another language
            this.translate.setLanguage = this.setLanguage;
            // available languages (this list is limited to EN, DE and TR)
            this.translate.langsList = {
                en: langsList.en,
                tr: langsList.tr,
                de: langsList.de,
                cl: langsList.cl,
                hi: langsList.hi,
                es: langsList.es,
            };
            // current language
            Object.defineProperty(this.translate, 'language', {
                get: () => this.language,
            });

            this.storage = localStorage;
            this.state.lang = this.storedLanguage || DEFAULT_LNG;
        }

        state = {
            translations: {},
            loading: true,
        };

        componentDidMount() {
            this.getTranslation();
        }

        get language() {
            const { lang } = this.state;
            return lang;
        }

        get storedLanguage() {
            return this.storage.getItem(this.storage.LANGUAGE);
        }

        set storedLanguage(value) {
            this.storage.setItem(this.storage.LANGUAGE, value);
            return value;
        }

        translate = (key, param) => {
            const { translations } = this.state;
            const regExp = /{(.*?)}/gi;
            let message = translations[key];

            // replace the translation with the passed param
            if (param && message) {
                if (typeof param === 'object') {
                    message = message.replace(
                        regExp,
                        (match, substr) => param[substr] || match
                    );
                } else {
                    message = message.replace(regExp, param);
                }
            }

            return message || `<${key.split('.').at(-1)}>`;
        };

        setLanguage = (code) => {
            this.setState({ lang: code }, () => {
                this.storedLanguage = code;
                this.getTranslation();
            });
        };

        getTranslation = () => {
            import(`../../../../translations/${this.state.lang}.json`)
                .then((translations) => {
                    if (typeof translations !== 'object') {
                        throw new Error('Wrong translation file');
                    }

                    this.setState({
                        translations,
                        loading: false,
                    });

                    return translations;
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        lang: DEFAULT_LNG,
                    });

                    return error;
                });
        };

        render() {
            const { loading } = this.state;
            if (loading) {
                return null;
            }
            return (
                <TranslateContext.Provider value={this.translate}>
                    <Component {...this.props} />
                </TranslateContext.Provider>
            );
        }
    };
}
