import React from 'react';
import PropTypes from "prop-types";

export default function Pax2BusUnDistributed(props) {
    const { item } = props;

    return (
        <div>
            Undistributed orders:
            { item.parameters.map((item, index) => (
                <span className="content-box">
                    Order ID: { item.orderId }
                    (Overbooked segments:
                        {
                            item.OverbookedSegmentsList.map((segment, index) => (
                                <span className="p2b-item">{ segment.fromStopCode }-{ segment.toStopCode }</span>
                            ))
                        }
                    )
                </span>
            ))
            }
        </div>
    );
}

Pax2BusUnDistributed.propTypes = {
    item: PropTypes.object.isRequired,
};
