import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';
import api from "../../../../api/Client";
import {Link} from "@flixbus/honeycomb-react";

export default function GhostOrderDetected(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const BACKEND_HOST = api?.config?.backend_host?.replace(/\/$/, '');

    const reason =
        'SCHEDULE_CHANGED' === item.parameters.reason
            ? <span>{translate('ghost-order-detected.because-schedule-changed')}:&nbsp;<strong>{item.parameters.stopName}</strong> </span>
            : 'CONNECTION_MISSING' === item.parameters.reason
                ? <span>
                    {translate('ghost-order-detected.because-connection-missing')}&nbsp;
                    <strong>{item.parameters.stopName}</strong>&nbsp;
                  </span>
                : 'STATION_MISSING' === item.parameters.reason
                    ? <span>
                        {translate('ghost-order-detected.because-station')}&nbsp;
                        <strong>{item.parameters.stopName}</strong>&nbsp;
                        {translate('ghost-order-detected.because-station-removed')}
                      </span>
                    : item.parameters.reason

    return (
        <div>
            {translate('ghost-order-detected.order')}&nbsp;
            <Link
                href={`${ BACKEND_HOST }/reports/orders/${ item.parameters.orderId }/view`}
                target="_blank"
                rel="noopener noreferrer"
            >{ item.parameters.orderId }
            </Link>&nbsp;
            {translate('ghost-order-detected.marked-ghost')}&nbsp;
            {reason}
        </div>
    );
}

GhostOrderDetected.propTypes = {
    item: PropTypes.object.isRequired,
};
