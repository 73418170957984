import { useCallback } from 'react';

export default function formReducerNew(state, action = { payload: {} }) {
    const { type, payload } = action;
    const { key, value, list } = payload || {};
    const newState = { ...state };

    switch (type) {
    case 'add':
        let addStateValue = newState[key];
        if (list || Array.isArray(addStateValue)) {
            if (addStateValue && Array.isArray(addStateValue)) {
                addStateValue.push(value);
            } else {
                addStateValue = [value];
            }
            return { ...newState, [key]: addStateValue };
        }

        return { ...newState, [key]: value };
    case 'delete':
        let deleteStateValue = newState[key];
        if (Array.isArray(deleteStateValue)) {
            if (deleteStateValue && deleteStateValue.length === 1) {
                delete newState[key];
                return newState;
            }
            return { ...newState, [key]: deleteStateValue.filter((val) => val !== value) };
        }
        newState[key] !== undefined && delete newState[key];
        return newState;
    case 'batch':
        return { ...payload };
    case 'reset':
        return {};
    default:
        return state;
    }
}

export function useActionCreator(dispatcher) {
    const addAction = useCallback(
        (key, list = false) => (value) => {
            dispatcher({ type: 'add', payload: { key, value, list } });
        },
        [dispatcher],
    );

    const deleteAction = useCallback(
        (key, list = false) => (value) => {
            dispatcher({ type: 'delete', payload: { key, value, list } });
        },
        [dispatcher],
    );
    const batchAction = useCallback(
        () => (payload) => {
            dispatcher({ type: 'batch', payload });
        },
        [dispatcher],
    );

    const resetAction = useCallback(
        () => () => {
            dispatcher({ type: 'reset' });
        },
        [dispatcher],
    );

    return [addAction, deleteAction, resetAction, batchAction];
}
