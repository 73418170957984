import React from 'react';
import { Button, Heading, Popup, PopupSection } from '@flixbus/honeycomb-react';

export default function RemoveTagPromt({
    onClose,
    onConfirm,
    tagToRemove,
    translate,
}) {
    const handleTab = (event) => {
        // tab trap #1
        // when tab out of last button, return focus to the first button
        if (!event.shiftKey && event.key === 'Tab') {
            event.preventDefault();
            event.target.previousSibling.focus();
        }
    };

    const handleShiftTab = (event) => {
        // tab trap #2
        // when shift+tab out of the first button, move focus to the last button
        if (event.shiftKey && event.key === 'Tab') {
            event.preventDefault();
            event.target.nextSibling.focus();
        }
    };

    return (
        <Popup
            aria-labelledby="accessible-popup-title"
            active={true}
            onOverlayClick={onClose}
        >
            <PopupSection type="title">
                <Heading id="accessible-popup-title" size={2}>
                    {translate('tag.are_you_sure')}
                    <br />
                    {tagToRemove.name}?
                </Heading>
            </PopupSection>
            <PopupSection type="actions">
                <Button
                    appearance="tertiary"
                    onKeyDown={handleShiftTab}
                    onClick={onConfirm}
                >
                    {translate('sidepanel.footer.confirm')}
                </Button>
                <Button
                    id="accessible-popup-close"
                    appearance="primary"
                    onKeyDown={handleTab}
                    onClick={onClose}
                >
                    {translate('sidepanel.footer.close')}
                </Button>
            </PopupSection>
        </Popup>
    );
}
