import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function RideStatusChanged(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);

    return (
        <div>
            {translate('ride-status-changed')} {translate('from')}{' '}
            <strong>{item.parameters.old_status}</strong> {translate('to')}{' '}
            <strong>{item.parameters.new_status}</strong>
        </div>
    );
}

RideStatusChanged.propTypes = {
    item: PropTypes.object.isRequired,
};
