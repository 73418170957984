import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Icon,
    IconRideArrival,
    IconRideDeparture,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../System/Translations';
import delaysCategory from '../../../../util/delaysCategory';
import { formatMinutes } from '../../../../util/date';

export default function StationHad(props) {
    const { arrival, departure, arrivalDelay, departureDelay, rideType } =
        props;
    const hadCss = classNames('timeline-station-had');
    const translate = useContext(TranslateContext);

    function getDelayTimeText(minutes) {
        if (minutes > 120) {
            const timeSet = formatMinutes(minutes);
            if (timeSet[1] === 0) {
                return `${timeSet[0]}h`;
            }
            return `${timeSet[0]}h ${timeSet[1]}`;
        }
        return minutes;
    }
    const arrDelayInMinutes = Math.round(arrivalDelay / 60);
    const arrMinutesFormatted = getDelayTimeText(arrDelayInMinutes);
    const depDelayInMinutes = Math.round(departureDelay / 60);
    const depMinutesFormatted = getDelayTimeText(depDelayInMinutes);

    const arrivalCategory = delaysCategory(arrDelayInMinutes, rideType);
    const departureCategory = delaysCategory(depDelayInMinutes, rideType);

    return (
        <div className={hadCss}>
            {arrival || departure ? (
                <div className="timeline-station-had__delays">
                    <div className="timeline-station-had__delays-item">
                        <span className="timeline-station-had__delays-name">
                            <Icon InlineIcon={IconRideArrival} />
                            {translate('had.arrival')}
                        </span>
                        <span
                            className={`timeline-station-had__delays-time timeline-station-had__delays-time--${arrivalCategory}`}
                        >
                            {arrival || ''}
                        </span>
                        <span className="timeline-station-had__delays-mins">
                            ({arrMinutesFormatted}min)
                        </span>
                    </div>
                    <div className="timeline-station-had__delays-item">
                        <span className="timeline-station-had__delays-name">
                            <Icon InlineIcon={IconRideDeparture} />
                            {translate('had.departure')}
                        </span>
                        <span
                            className={`timeline-station-had__delays-time timeline-station-had__delays-time--${departureCategory}`}
                        >
                            {departure || ''}
                        </span>
                        <span className="timeline-station-had__delays-mins">
                            {depMinutesFormatted
                                ? `(${depMinutesFormatted}min)`
                                : null}
                        </span>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
StationHad.propTypes = {
    arrival: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    departure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    arrivalDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    departureDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rideType: PropTypes.oneOf(['bus', 'train']),
};
StationHad.defaultProps = {
    arrival: 0,
    departure: 0,
    arrivalDelay: 0,
    departureDelay: 0,
    rideType: 'bus',
};
