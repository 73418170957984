import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Text } from '@flixbus/honeycomb-react';

export default function StationTitle(props) {
    const { code, name } = props;
    return (
        <div className="timeline-station-title">
            <Heading size={1} flushSpace extraClasses="timeline-station-code">
                {code}
            </Heading>
            <div className="timeline-station-name">
                <Text size={4} extraClasses="timeline-station-name">
                    {name}
                </Text>
            </div>
        </div>
    );
}
StationTitle.propTypes = {
    code: PropTypes.string,
    name: PropTypes.string,
};
StationTitle.defaultProps = { code: '', name: '' };
