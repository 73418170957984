import React from 'react';

export default function withWhoIsOnPage(Component) {
    return class WithWhoIsOnPage extends React.Component {
        state = {
            whoIsOnPage: []
        };

        onWhoIsOnPageChanged = (whoIsOnPage) => {
            this.setState({ whoIsOnPage });
        };

        render() {
            const { whoIsOnPage } = this.state;

            return (
                <Component {...this.props} whoIsOnPage={whoIsOnPage} onWhoIsOnPageChanged={this.onWhoIsOnPageChanged} />
            );
        }
    };
}
