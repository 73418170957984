import React from 'react';
import PropTypes from 'prop-types';

export default function InterconnectionsContainer(props) {
    const { type, children } = props;
    const items = children.length ? children : [children];
    return (
        <div className="timeline-interconnections-container">
            {items.map((item) => {
                return React.cloneElement(item, { type });
            })}
        </div>
    );
}
InterconnectionsContainer.propTypes = { type: PropTypes.oneOf(['in', 'out']) };
InterconnectionsContainer.defaultProps = { type: 'in' };
