import React from 'react';
import PropTypes from 'prop-types';

export default function Pax2BusDistributionError(props) {
    const { item } = props;

    return (
        <div>
            Pax2bus distribution failed. {item.parameters.message}
        </div>
    );
}
Pax2BusDistributionError.propTypes = {
    item: PropTypes.object.isRequired,
};
