import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function TimelineToolGroup(props) {
    const { className, children, full } = props;
    const classNamesJoin = classNames([
        'timeline-toolbar__group',
        {
            ...className,
            'timeline-toolbar__group--full': full,
        },
    ]);
    return <div className={classNamesJoin}>{children}</div>;
}

TimelineToolGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
        .isRequired,
    full: PropTypes.bool,
};

TimelineToolGroup.defaultProps = {
    className: '',
};
