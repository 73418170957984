import { compose } from 'recompose';
import RideView from './RideView';
import withRideMetaApi from './hocs/withRideMetaApi';
import { withPageTitleConsumer } from '../System/PageTitle';
import { withTranslateConsumer } from '../System/Translations';
import withRideMetaConsumer from '../App/RideMeta/hocs/withRideMetaConsumer';
import withNotifyConsumer from '../NotificationSystem/context/hocs/withNotifyConsumer';
import withPrintWrapper from './hocs/withPrintWrapper';

export default compose(
    withNotifyConsumer,
    withTranslateConsumer,
    withPageTitleConsumer,
    withRideMetaConsumer,
    withRideMetaApi,
    withPrintWrapper,
)(RideView);
