import saveCSV from '../../../../util/csvSaver';

/**
 * setup CSV converter
 * @param {function} notify function that will called to notify user arguments {type: 'error|warning|success', message: 'string'}
 * @returns {function}
 */
export default function convertToCsv(notify) {
    return (results) => {
        let propsMap = [];
        // create full set of objects properties cross over results
        results.forEach((result) => {
            Object.entries(result).forEach(([key]) => {
                if (!propsMap.includes(key)) {
                    propsMap.push(key);
                }
            });
        });
        //  split for rows based on propsMap
        const res = results.map((result) => propsMap.map((prop) => {
            if (typeof result[prop] === 'object') {
                return JSON.stringify(result[prop]).replace(/,|\{|\}|\[|\]/g, ' | ');
            }
            return `"${result[prop]}"` || '';
        }));
        //  combine properties names with data rows
        const content = [].concat([propsMap], res);

        return saveCSV('search-results.csv', 'CSV - Coma separated values', content, notify);
    };
}
