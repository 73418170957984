import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { Input, Calendar } from '@flixbus/honeycomb-react';
import { Icon, IconCalendar } from '@flixbus/honeycomb-icons-react';
import { DateShortcuts } from '.';
import { TranslateContext } from '../../System/Translations';
import { formatDate } from '../../../util/date';

const PAYLOAD_DATE_FORMAT = 'DD.MM.YYYY';
const VALUE_DATE_FORMAT = PAYLOAD_DATE_FORMAT; // 'ddd, DD MMM'; // till we manage translations

export default function DateInput(props) {
    const { value: propValue, onChange } = props;
    const [value, setValue] = useState(propValue.replace(',', ' — '));
    const [hidden, setHidden] = useState(true);
    const [selectedRange, setSelected] = useState([]);
    const translate = useContext(TranslateContext);
    const containerRef = useRef(null);

    const months = translate('months-list').split(',');
    const daysShort = translate('days-short-list').split(',');

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const defaultMonth = () => {
        const oneOfRange = selectedRange[1] || selectedRange[0];
        if (oneOfRange) {
            return new Date(oneOfRange);
        }
        return new Date();
    };

    // store state to compare in next render for deep changes
    const prevSelectedRange = useRef(selectedRange);

    const handleSelect = useCallback(
        (selected) => {
            let range = [...selectedRange];
            // if first selected or reselect whole range again
            if (range.length === 0 || range.length === 2) {
                range = [selected];
            } else if (range.length === 1) {
                // if start date already selected add end date
                if (range[0] > selected) {
                    range.unshift(selected);
                } else if (range[0] !== selected) {
                    range.push(selected);
                }
                setHidden(true);
            }
            setSelected(range);
        },
        [selectedRange, setSelected, setHidden]
    );

    const selectShortcut = (date) => {
        handleSelect(date);
    };

    const clickOutside = useCallback(
        (e) => {
            if (containerRef.current.contains(e.target) === false) {
                setHidden(true);
            }
        },
        [setHidden]
    );

    // sync value with prop update
    useEffect(() => {
        if (propValue) {
            setValue(propValue.replace(',', ' — '));
        } else {
            setSelected([]);
            setValue('');
        }
    }, [propValue, setValue]);

    // set value after date is picked
    useEffect(() => {
        if (!equal(prevSelectedRange.current, selectedRange)) {
            const val = selectedRange
                .map((range) => formatDate(range, VALUE_DATE_FORMAT))
                .join(' — ');
            const payload = selectedRange
                .map((range) => formatDate(range, PAYLOAD_DATE_FORMAT))
                .join(',');

            if (val !== '') {
                setValue(val);
            }
            if (payload !== '') {
                onChange(payload);
            }
        }
        prevSelectedRange.current = selectedRange;
    }, [selectedRange, onChange]);

    // to hide calendar with outside clicks
    useEffect(() => {
        if (hidden === false) {
            document.addEventListener('click', clickOutside, false);
        } else {
            document.removeEventListener('click', clickOutside, false);
        }
        return () => {
            document.removeEventListener('click', clickOutside, false);
        };
    }, [hidden, clickOutside]);

    return (
        <div className="rs-calendar" ref={containerRef}>
            <Input
                label={translate('ride-search.date-input-label')}
                id="DateUuid"
                type="text"
                iconLeft={
                    <Icon appearance="primary" InlineIcon={IconCalendar} />
                }
                onFocus={() => {
                    setHidden(false);
                }}
                value={value}
                autoComplete="off"
                readOnly
            />
            <DateShortcuts onSelect={selectShortcut} />
            <div className="rs-calendar__wrapper">
                <Calendar
                    id="date-picker"
                    hidden={hidden}
                    startDate={new Date(currentYear - 1, currentMonth, 1)}
                    endDate={new Date(currentYear + 1, currentMonth, 1)}
                    defaultMonth={defaultMonth()}
                    startSelected={selectedRange[0] || null}
                    endSelected={selectedRange[1] || null}
                    appearance="compact"
                    months={months.length < 12 ? undefined : months}
                    daysShort={daysShort.length < 7 ? undefined : daysShort}
                    handleSelect={handleSelect}
                    selected={new Date()}
                />
            </div>
        </div>
    );
}
DateInput.propTypes = { value: PropTypes.string, onChange: PropTypes.func };
DateInput.defaultProps = { value: '', onChange: () => {} };
