import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function DeferredNotificationProcessed(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('deferred-notification-processed.deferred-notification-processed-for-station')} 
            <strong>{ item.parameters.station.name } </strong>
            - <strong> { item.parameters.sms_sent ? item.parameters.sms_sent : 0 }</strong> SMS
            {translate('deferred-notification-processed.and')} <strong>{ item.parameters.push_sent ? item.parameters.push_sent : 0 }</strong>
            {translate('deferred-notification-processed.push-notifications-sent')}
        </div>
    );
}

DeferredNotificationProcessed.propTypes = {
    item: PropTypes.object.isRequired,
};
