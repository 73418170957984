import React from 'react';
import RideMetaContext from '../context';

/**
 * HOC to add context consumer for Ride object
 * @func withRideConsumerHoc
 * @param {object} Component - react component
 */

export default function withRideMetaConsumer(Component) {
    return function WithRideMetaConsumer(props) {
        return (
            <RideMetaContext.Consumer>
                {({ rideMeta, setRideMeta }) => <Component {...props} rideMeta={rideMeta} setRideMeta={setRideMeta} />}
            </RideMetaContext.Consumer>
        );
    };
}
