import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../../../api/megadriveApi';
import { formatDate, ISO_8601_FORMAT } from '../../../../../util/date';
import {
    paxCheckInAction,
    paxCheckInConfirmationAction,
    paxCheckInFailAction,
} from '../../../../../DataDog/actions';

export const PAX_STATUS_UNCHECKED = 'unchecked';
export const PAX_STATUS_CHECKED = 'checked';
export const PAX_STATUS_ABSENT = 'absent';
export const STATUS_UPDATE_TIME_FORMAT = 'DD.MM.YYYY | HH:mm:ss';

const CHANGE_STATUS_URL = 'rides/{rideUuid}/passengers/status';

export default function withPaxStatusChange(Component) {
    return class WithPaxStatusChange extends React.Component {
        static propTypes = {
            match: PropTypes.object,
            rideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            paxUuid: PropTypes.string.isRequired,
            status: PropTypes.string,
            updatedAt: PropTypes.string,
            closeConfirmPopup: PropTypes.func,
            notify: PropTypes.func,
            paxStatusUpdate: PropTypes.func,
            ticketId: PropTypes.string.isRequired,
        };

        static defaultProps = {
            match: undefined,
            status: PAX_STATUS_UNCHECKED,
            updatedAt: '',
            closeConfirmPopup: () => {},
            notify: () => {},
            paxStatusUpdate: () => {},
        };

        /**
         * @property {object} state - Initial state for component
         * @property {array} state.paxStatus - Passenger status (checked/unchecked)
         */
        state = {
            paxStatus: this.props.status,
            updatedAt: this.props.updatedAt,
            saving: false,
        };

        /**
         * @method
         */
        togglePaxStatus = () => {
            const { paxStatus, saving } = this.state;
            const { ticketId, closeConfirmPopup, notify } = this.props;
            const newStatus =
                paxStatus === PAX_STATUS_UNCHECKED ||
                paxStatus === PAX_STATUS_ABSENT
                    ? PAX_STATUS_CHECKED
                    : PAX_STATUS_UNCHECKED;

            if (saving) {
                return;
            }

            /**
             * @TODO
             * Create generator fo batch check in
             */
            const data = {
                passengers: [
                    {
                        ticket_id: ticketId,
                        status: newStatus.toUpperCase(),
                    },
                ],
            };

            paxCheckInAction({
                id: ticketId,
                timestamp: new Date().getTime(),
                initStatus: paxStatus,
                status: newStatus.toUpperCase(),
                apiVersion: 'new',
            });

            this.setState({ saving: true }, () => {
                api.put(
                    CHANGE_STATUS_URL.replace('{rideUuid}', this.rideId),
                    data
                )
                    .then(() => {
                        this.setTimer();
                        notify({
                            type: null,
                            message: 'Request to change passengers status send',
                        });
                        closeConfirmPopup();
                    })
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            const message =
                                error.response.data?.error || error.message;
                            notify({
                                type: 'danger',
                                message,
                            });
                            paxCheckInFailAction({
                                id: ticketId,
                                timestamp: new Date().getTime(),
                                reason: message,
                                apiVersion: 'new',
                            });
                            this.setState({ saving: false }, () => {
                                closeConfirmPopup();
                            });
                        }
                    });
            });
        };

        get rideId() {
            const { match, rideId } = this.props;
            return (match && match.params.rideId) || rideId;
        }

        paxStatusCallback = (status) => {
            const { ticketId } = this.props;
            const { saving } = this.state;
            if (saving === true) {
                paxCheckInConfirmationAction({
                    id: ticketId,
                    timestamp: new Date().getTime(),
                    paxStatus: status.toLowerCase(),
                    apiVersion: 'new',
                });
            }
            this.setState(
                {
                    saving: false,
                    paxStatus: status.toLowerCase(),
                    updatedAt: formatDate(
                        new Date().getTime(),
                        ISO_8601_FORMAT
                    ),
                },
                () => {
                    this.stopTimer();
                    this.props.closeConfirmPopup();
                }
            );
        };

        setTimer() {
            const { ticketId } = this.props;
            this.timer = setTimeout(() => {
                this.setState(
                    {
                        saving: false,
                    },
                    () => {
                        this.timer = null;
                        this.props.notify({
                            type: 'danger',
                            message:
                                'There is no status change confirmation, you can refresh the page or try again',
                        });
                        paxCheckInFailAction({
                            id: ticketId,
                            timestamp: new Date().getTime(),
                            reason: 'No confirmation',
                        });
                    }
                );
            }, 30000);
        }

        stopTimer() {
            if (this.timer !== null) {
                clearTimeout(this.timer);
            }
        }

        componentDidMount() {
            const { ticketId, paxStatusUpdate } = this.props;
            paxStatusUpdate(ticketId, this.paxStatusCallback);
        }

        render() {
            const { paxStatus, updatedAt, saving } = this.state;
            return (
                <Component
                    {...this.props}
                    status={paxStatus}
                    updatedAt={updatedAt}
                    saving={saving}
                    togglePaxStatus={this.togglePaxStatus}
                />
            );
        }
    };
}
