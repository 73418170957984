import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from "moment/moment";
import { Icon, IconPhoneSolid } from '@flixbus/honeycomb-icons-react';
import { DataRowGroup, DataRow, DataCol } from '../../UI/DataGrid';
import { formatDate, getUtcOffsetText } from '../../../util/date';
import RendererMap from './renderers';
import { TranslateContext } from '../../System/Translations';

export default function ActionLogItem(props) {
    const { item } = props;
    const {
        client,
        smi_version: smiVersion,
        created_at: createdAt,
        action,
        user,
        parameters,
    } = item;
    const translate = useContext(TranslateContext);
    const { client_info: clientInfo } = parameters;

    let clientText;

    function getClientPhoneNumber() {
        const match = clientInfo ? clientInfo.match(/lin=(\+?\d+?),/) : null;
        return match !== null ? match[1] : null;
    }

    if (client.toLowerCase() === 'backend') {
        clientText = translate('action-log-item.monolith-backend');
    } else if (client.toLowerCase() === 'api') {
        clientText = translate('action-log-item.monolith-api');
    } else if (smiVersion && smiVersion.name && smiVersion.code) {
        clientText = `${smiVersion.name}.${smiVersion.code}`;
    } else if (client.toLowerCase() === 'rebookr') {
        clientText = translate('action-log-item.rebookr');
    } else if (client.toLowerCase() === 'chancellor') {
        clientText = translate('action-log-item.chancellor');
    } else if (client.toLowerCase() === 'rideviewer') {
        clientText = translate('action-log-item.rideviewer');
    } else if (client.toLowerCase() === 'ridetracker') {
        clientText = translate('action-log-item.ridetracker');
    } else if (client.toLowerCase() === 'autocontrol') {
        clientText = translate('action-log-item.autocontrol');
    } else if (client.toLowerCase() === 'rebookr 2.0') {
        clientText = 'Rebookr 2.0';
    } else if (client.toLowerCase() === 'driverapp') {
        clientText = 'Driver App';
    } else {
        clientText = translate('action-log-item.unknown');
    }

    const clientPhoneNumber = getClientPhoneNumber();

    return (
        <DataRowGroup>
            <DataRow valign="top">
                <DataCol>
                    {formatDate(createdAt, 'DD.MM.yyyy, HH:mm') +
                        getUtcOffsetText(moment())}
                </DataCol>
                <DataCol>
                    {RendererMap[action]
                        ? React.createElement(RendererMap[action], {
                              item,
                          })
                        : translate('action-log-item.unknown-action')}
                </DataCol>
                <DataCol>
                    {user
                        ? `${user.fullName} (${user.email})`
                        : parameters.user}
                    {clientPhoneNumber && (
                        <div>
                            <Icon InlineIcon={IconPhoneSolid} size={4} />
                            {clientPhoneNumber}
                        </div>
                    )}
                </DataCol>
                <DataCol>{clientText}</DataCol>
            </DataRow>
        </DataRowGroup>
    );
}

ActionLogItem.propTypes = {
    item: PropTypes.object.isRequired,
};
