/**
 * function that create handler to save file
 * @param {string} suggestedName default file name export.cvs
 * @param {string} description description in save file dialog window
 */
async function getSaveHandler(suggestedName, description) {
    return await window.showSaveFilePicker({
        suggestedName: suggestedName || 'export.csv',
        types: [
            {
                description: description || 'CSV file',
                accept: { 'text/plain': ['.csv'] },
            },
        ],
    });
}
/**
 *
 * @param {Promise} handler promise for save file dialog
 * @param {string} content csv valid string
 */
async function write(handler, content) {
    const writable = await handler.createWritable();
    await writable.write(content);
    await writable.close();
}

/**
 * Save given array of rows to the CVS file
 * @function saveCSV
 * @param {string} suggestedName
 * @param {sting} description
 * @param {array} content
 * @param {function} notify
 * @returns {Promise}
 */
export default function saveCSV(suggestedName, description, content, notify) {
    return getSaveHandler(suggestedName, description)
        .then((handler) =>
            write(handler, content.join('\n'))
                .then(() => {
                    notify({
                        type: 'success',
                        message: `Success export to the ${handler.name}`,
                    });
                })
                .catch(() => {
                    notify({
                        type: 'danger',
                        message: 'Export to CSV file failed',
                    });
                })
        )
        .catch((e) => {
            if (e.toString().indexOf('AbortError') !== -1) {
                // User press cancel button
                notify({
                    type: 'warning',
                    message: 'Export to CSV file was aborted',
                });
            } else {
                notify({
                    type: 'danger',
                    message:
                        'Browser does not support this feature try to use Chrome or Edge',
                });
            }
        });
}
