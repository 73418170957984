import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function SeatRefund(props) {
    const { item } = props;
    const status = item.parameters.status;
    const orderId = item.parameters.orderId;
    const message = item.parameters.message || '';
    const translate = useContext(TranslateContext);

    if (status === 'refunded') {
        return (
            <div>
                {translate('seat-refund.refunded-status', orderId)}
            </div>
        );
    }
    if (status === 'error') {
        return (
            <div>
                {translate('seat-refund.refunded-error', orderId)}
                <span style={{color: "red", "font-weight": "bold"}}>{translate('seat-refund.failed')}</span> 
                {translate('seat-refund.with-error', message)}
            </div>
        );
    }
}

SeatRefund.propTypes = {
    item: PropTypes.object.isRequired,
};
