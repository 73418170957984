const categories = {
    bus: [
        [10, 59, 'small'],
        [60, 119, 'medium'],
        [120, Number.MAX_SAFE_INTEGER, 'big'],
    ],
    train: [
        [10, 29, 'small'],
        [30, 59, 'medium'],
        [60, Number.MAX_SAFE_INTEGER, 'big'],
    ],
};

/**
 * Returns a name for delay category based on delay and ride type
 * @func delaysCategory
 * @param {int} minutes
 * @param {('bus'|'train')} rideType
 * @return {('small'|'medium'|'big')}
 */
export default function delaysCategory(minutes, rideType) {
    return (
        categories[rideType].find(
            (cat) => cat[0] <= minutes && cat[1] >= minutes
        )?.[2] || 'no-delay'
    );
}
