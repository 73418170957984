import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    GridCol,
    FormRow,
    Button,
    Input,
    Select,
    SelectOption,
    Tooltip,
} from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';

export default function LockCapacity(props) {
    const { rideUuid, stations, lockSeats, allSegments, isProcessing } = props;
    const translate = useContext(TranslateContext);

    const [showForm, setShowForm] = useState(false);
    const [optionsProcessing, setOptionsProcessing] = useState(false);

    const tempStations = [...stations];
    const initFromStations = tempStations.slice(0, tempStations.length - 1);

    const [fromStation, setFromStation] = useState('');
    const [toStation, setToStation] = useState('');

    const [toStations, setToStations] = useState('');

    const [seatsLocked, setSeatsLocked] = useState('');
    const [bikeSlotsLocked, setBikeSlotsLocked] = useState('');
    const [wheelchairsLocked, setWheelchairsLocked] = useState('');

    const segmentFromChange = (e) => {
        let val = e.target.value;

        setFromStation(val);

        setOptionsProcessing(true);

        const tempIndex = stations.findIndex((item) => item.value === val);
        const tempStations = stations.slice(tempIndex + 1);

        setToStations(tempStations);

        // wait untill options updated
        setTimeout(() => {
            setOptionsProcessing(false);
        }, 100);
    };

    const segmentToChange = (e) => {
        let val = e.target.value;

        setToStation(val);
    };

    const seatsChange = (e) => {
        let val = e.target.value;

        setSeatsLocked(val);
    };

    const bikesChange = (e) => {
        let val = e.target.value;

        setBikeSlotsLocked(val);
    };

    const wheelchairsChange = (e) => {
        let val = e.target.value;

        setWheelchairsLocked(val);
    };

    const capacityChange = () => {
        let fromIndex = stations.findIndex(
            (item) => item.value === fromStation
        );
        let toIndex =
            stations.findIndex((item) => item.value === toStation) - 1;

        const fromSegment = allSegments[fromIndex];
        const toSegment = allSegments[toIndex];

        const prevSeatsLocked = fromSegment && fromSegment.seats_locked;
        const prevBikeSlotsLocked =
            fromSegment && fromSegment.bike_slots_locked;
        const prevWheelchairsLocked =
            fromSegment && fromSegment.wheelchairs_locked;

        lockSeats({
            seatsLocked,
            bikeSlotsLocked,
            wheelchairsLocked,
            prevSeatsLocked,
            prevBikeSlotsLocked,
            prevWheelchairsLocked,
            fromSegment: fromSegment.id,
            toSegment: toSegment.id,
            rideUuid,
        })();
    };

    const capacitySubmitDisabled = () => {
        return (
            (seatsLocked === '' &&
                bikeSlotsLocked === '' &&
                wheelchairsLocked === '') ||
            validate(seatsLocked, 0, 1000) === false ||
            validate(bikeSlotsLocked, 0, 1000) === false ||
            validate(wheelchairsLocked, 0, 1000) === false
        );
    };

    const validate = (value, min, max) => {
        if (value === '') {
            return true;
        }
        return value >= min && value <= max;
    };

    useEffect(() => {
        if (stations && stations.length) {
            const fromStation = stations[0].value;
            const tempToStations = [...stations];
            const initToStations = tempToStations.slice(
                1,
                tempToStations.length
            );
            const toStation = stations[stations.length - 1].value;

            setFromStation(fromStation);
            setToStations(initToStations);
            setToStation(toStation);

            setShowForm(true);
        }
    }, [stations]);

    return (
        showForm && (
            <Grid align="bottom">
                <GridCol size={7}>
                    <Grid align="bottom">
                        <GridCol size={6}>
                            <FormRow>
                                <Select
                                    id="from-station"
                                    label={translate('from')}
                                    options={initFromStations}
                                    onChange={segmentFromChange}
                                    disabled={isProcessing || optionsProcessing}
                                >
                                    {initFromStations.map((st) => (
                                        <SelectOption
                                            key={st.value}
                                            value={st.value}
                                        >
                                            {st.displayValue}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </FormRow>
                        </GridCol>
                        <GridCol size={6}>
                            <FormRow>
                                <Select
                                    id="to-station"
                                    label={translate('to')}
                                    defaultValue={toStation}
                                    options={toStations}
                                    onChange={segmentToChange}
                                    disabled={isProcessing || optionsProcessing}
                                >
                                    {toStations.map((st) => (
                                        <SelectOption
                                            key={st.value}
                                            value={st.value}
                                        >
                                            {st.displayValue}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </FormRow>
                        </GridCol>
                    </Grid>
                </GridCol>
                <GridCol size={1}>
                    <FormRow>
                        <Tooltip
                            active={!validate(seatsLocked, 0, 1000)}
                            danger
                            size="content-fit"
                            id="input-seatsAmount"
                            content={translate('capacity.tooltip.validation')}
                        >
                            <Input
                                type="number"
                                name="seatsAmount"
                                id="seatsAmount"
                                value={seatsLocked}
                                label={translate('capacity.seats')}
                                onChange={seatsChange}
                                disabled={isProcessing || optionsProcessing}
                                min={0}
                                max={1000}
                                valid={
                                    validate(seatsLocked, 0, 1000)
                                        ? undefined
                                        : false
                                }
                            />
                        </Tooltip>
                    </FormRow>
                </GridCol>
                <GridCol size={1}>
                    <FormRow>
                        <Tooltip
                            active={!validate(bikeSlotsLocked, 0, 1000)}
                            danger
                            size="content-fit"
                            id="input-bikesAmount"
                            content={translate('capacity.tooltip.validation')}
                        >
                            <Input
                                type="number"
                                name="bikesAmount"
                                id="bikesAmount"
                                value={bikeSlotsLocked}
                                label={translate('capacity.bikes')}
                                onChange={bikesChange}
                                disabled={isProcessing || optionsProcessing}
                                max={1000}
                                valid={
                                    validate(bikeSlotsLocked, 0, 1000)
                                        ? undefined
                                        : false
                                }
                            />
                        </Tooltip>
                    </FormRow>
                </GridCol>
                <GridCol size={1}>
                    <FormRow>
                        <Tooltip
                            active={!validate(wheelchairsLocked, 0, 1000)}
                            danger
                            size="content-fit"
                            id="input-wheelchairsAmount"
                            content={translate('capacity.tooltip.validation')}
                        >
                            <Input
                                type="number"
                                name="wheelchairsAmount"
                                id="wheelchairsAmount"
                                value={wheelchairsLocked}
                                label={translate('capacity.wheelchairs')}
                                onChange={wheelchairsChange}
                                disabled={isProcessing || optionsProcessing}
                                max={1000}
                                valid={
                                    validate(wheelchairsLocked, 0, 1000)
                                        ? undefined
                                        : false
                                }
                            />
                        </Tooltip>
                    </FormRow>
                </GridCol>
                <GridCol size={2}>
                    <FormRow extraClasses="rv-text-right">
                        <Button
                            appearance="primary"
                            onClick={capacityChange}
                            loading={isProcessing}
                            disabled={capacitySubmitDisabled()}
                        >
                            {translate('capacity.update-capacity')}
                        </Button>
                    </FormRow>
                </GridCol>
            </Grid>
        )
    );
}

LockCapacity.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.object).isRequired,
};
