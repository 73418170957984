import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@flixbus/honeycomb-react';

export default function StationTime(props) {
    const { arrival, departure } = props;
    const time = [];
    if (arrival) {
        time.push(arrival);
    }
    if (departure) {
        time.push(departure);
    }
    return (
        <Heading flushSpace size={3} extraClasses="timeline-station-time">
            {time.join('-')}
        </Heading>
    );
}
StationTime.propTypes = {
    arrival: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    departure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
StationTime.defaultProps = { arrival: null, departure: null };
