import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Spinner } from '@flixbus/honeycomb-react';
import { Icon, IconKebab } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../../System/Translations';
import {
    STATION_STATUS_OPEN,
    STATION_STATUS_CLOSED,
    STATION_STATUS_CANCELLED,
} from '../../constants';
import useUserCan from '../../../../../auth/useUserCan';

function handlerWrapper(cb) {
    return function (e) {
        e.preventDefault();
        cb();
    };
}

export default function StationActions(props) {
    const {
        openStation,
        closeStation,
        cancelStation,
        stationStatusSaving,
        status,
    } = props;
    const isUserCanReopenCloseStation = useUserCan('reopen_close_station');
    const translate = useContext(TranslateContext);
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        setActive(false);
    }, [stationStatusSaving, status]);

    function getStatusAction() {
        switch (status) {
            case STATION_STATUS_OPEN:
                return isUserCanReopenCloseStation
                    ? [
                          {
                              text: translate('station.status.close'),
                              href: '#',
                              onClick: handlerWrapper(closeStation),
                          },
                          {
                              text: translate('station.status.cancel'),
                              href: '#',
                              onClick: handlerWrapper(cancelStation),
                          },
                      ]
                    : null;
            case STATION_STATUS_CLOSED:
                return isUserCanReopenCloseStation
                    ? [
                          {
                              text: translate('station.status.reopen'),
                              href: '#',
                              onClick: handlerWrapper(openStation),
                          },
                          {
                              text: translate('station.status.cancel'),
                              href: '#',
                              onClick: handlerWrapper(cancelStation),
                          },
                      ]
                    : null;
            case STATION_STATUS_CANCELLED:
                return isUserCanReopenCloseStation
                    ? [
                          {
                              text: translate('station.status.reopen'),
                              href: '#',
                              onClick: handlerWrapper(openStation),
                          },
                      ]
                    : null;
            default:
                return isUserCanReopenCloseStation
                    ? [
                          {
                              text: translate('station.status.close'),
                              href: '#',
                              onClick: handlerWrapper(closeStation),
                          },
                      ]
                    : null;
        }
    }
    const actions = getStatusAction() ? getStatusAction() : null;
    return (
        <React.Fragment>
            {stationStatusSaving ? <Spinner size="sm" /> : null}

            {actions && !stationStatusSaving ? (
                <Dropdown active={isActive} links={actions}>
                    <span>
                        <Button
                            display="square"
                            onBlur={() => {
                                setTimeout(() => {
                                    setActive(false);
                                }, 400);
                            }}
                            onClick={() => {
                                setActive(!isActive);
                            }}
                            link
                        >
                            <Icon appearance="primary" InlineIcon={IconKebab} />
                        </Button>
                    </span>
                </Dropdown>
            ) : null}
        </React.Fragment>
    );
}

StationActions.propTypes = {
    openStation: PropTypes.func,
    closeStation: PropTypes.func,
    cancelStation: PropTypes.func,
    stationStatusSaving: PropTypes.bool,
    status: PropTypes.string,
};
StationActions.defaultProps = {
    openStation: () => {},
    closeStation: () => {},
    cancelStation: () => {},
    stationStatusSaving: false,
    status: '',
};
