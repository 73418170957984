import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Comment from './components/Comment';
import AddComment from './components/AddComment';
import EditComment from './components/EditComment';
import { TranslateContext } from '../System/Translations';
import useGetComments from '../CommentsHooks/useGetComments';

import './styles.scss';

export default function Comments(props) {
    const { rideUuid, notify } = props;
    const { comments, error, loading, getComments } = useGetComments(rideUuid);
    const translate = useContext(TranslateContext);
    const [commentsToShow, setCommentsToShow] = useState(comments);
    const [editMode, setEditMode] = useState(false);
    const [curCommentId, setCurrentCommentId] = useState();

    const onEditModeEnter = (commentId) => {
        setCurrentCommentId(commentId);
        setEditMode(true);
    };

    const onEditModeExit = () => {
        setEditMode(false);
        setCurrentCommentId();
    };

    if (error) {
        notify({
            type: 'error',
            message: translate('comments.error') + error.toString(),
        });
    }

    const onCommentAdd = () => {
        onEditModeExit();
        getComments();
    };

    const showLoading = () => {
        if (loading && comments.length === 0) {
            return translate('with-comments-api.loading');
        }
        return null;
    };

    const showNoComments = () => {
        if (loading === false && comments.length === 0) {
            return translate('with-comments-api.no-comments');
        }
        return null;
    };

    useEffect(() => {
        getComments();
    }, [getComments]);

    useEffect(() => {
        if (comments.length) {
            setCommentsToShow(comments);
        }
    }, [comments]);

    return (
        <div className="ride-view__context-block--narrow">
            <div className="comments-wrapper">
                <AddComment rideUuid={rideUuid} onCommentAdd={onCommentAdd} />

                {showLoading()}
                {showNoComments()}

                {commentsToShow.length === 0 ? /**
                 * @todo
                 * show lading table skeleton
                 */ null : (
                    <div className="comments-list">
                        {commentsToShow.map((commentData) => (
                            <div
                                className="single-comment"
                                key={commentData.id}
                            >
                                <Comment
                                    comment={commentData}
                                    onEditModeEnter={onEditModeEnter}
                                    isEditMode={
                                        editMode &&
                                        commentData.id === curCommentId
                                    }
                                    notify={notify}
                                />
                                {editMode &&
                                    commentData.id === curCommentId && (
                                        <>
                                            <EditComment
                                                commentId={curCommentId}
                                                content={commentData.content}
                                                onCommentAdd={onCommentAdd}
                                                onEditModeExit={onEditModeExit}
                                            />
                                        </>
                                    )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

Comments.propTypes = {
    notify: PropTypes.func.isRequired,
    rideUuid: PropTypes.string.isRequired,
};
