import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function DriverInfoLog(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const driverName = item.parameters.driverName;

    if (item.action === 'driver_added') {
        return <div>{translate('driver-info-log.added', {
            driverName,
        })}</div>;
    }

    if (item.action === 'driver_removed') {
        return <div>{translate('driver-info-log.removed', {
            driverName,
        })}</div>;
    }
}
DriverInfoLog.propTypes = {
    item: PropTypes.object.isRequired,
};
