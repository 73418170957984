import React from 'react';

/**
 * React Context to share notification system API
 * across application components
 * @constant NotifyContext
 */
const NotifyContext = React.createContext(() => {});

export { NotifyContext }; // eslint-disable-line
