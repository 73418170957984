import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InterconnectionItem from './InterconnectionItem';
import { TranslateContext } from '../../System/Translations';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconCollapse, IconExpand } from '@flixbus/honeycomb-icons-react';
import { ReactComponent as IconConnection } from './icon-connection.svg';
import './Interconnections.scss';
export default function Interconnections(props) {
    const { entries, type, isLast, showHideICsHandler } = props;
    const { isExpanded } = showHideICsHandler;
    const translate = useContext(TranslateContext);
    const [expanded, setExpanded] = useState(isExpanded);
    const expand = () => {
        setExpanded(!expanded);
    };
    let isDanger = false;
    let isWarning = false;
    const paxAmount = entries.reduce((acc, cur) => {
        isDanger = !isDanger ? cur.delayStatus === 'danger' : isDanger;
        isWarning =
            !isDanger && !isWarning ? cur.delayStatus === 'warning' : isWarning;
        acc += cur.count;
        return acc;
    }, 0);
    const expandBtnCss = classNames('timeline-interconnections-btn', {
        'timeline-interconnections-btn--open': expanded,
        'timeline-interconnections-btn--danger': isDanger,
        'timeline-interconnections-btn--warning': isWarning,
    });

    const showPaxCount = paxAmount ? ` (${paxAmount})` : '';

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);

    return (
        <div
            className={`timeline-interconnections timeline-interconnections--${type}`}
        >
            {entries.length > 0 && (
                <div className="timeline-interconnections-wrapper">
                    {type === 'out' && (
                        <IconConnection className="timeline-interconnections-icon timeline-interconnections-icon--out" />
                    )}
                    <React.Fragment>
                        {entries.length > 2 && (
                            <>
                                <Button
                                    onClick={expand}
                                    appearance="tertiary"
                                    extraClasses={expandBtnCss}
                                    link
                                >
                                    <Icon
                                        appearance="primary"
                                        InlineIcon={
                                            expanded ? IconCollapse : IconExpand
                                        }
                                    />
                                    {expanded
                                        ? translate(
                                              'toolbar.hide-ic-entries',
                                              entries.length
                                          ) + showPaxCount
                                        : translate(
                                              'toolbar.show-ic-entries',
                                              entries.length
                                          ) + showPaxCount}
                                </Button>
                                {expanded &&
                                    entries.map((item) => (
                                        <InterconnectionItem
                                            type={type}
                                            key={item.id}
                                            isLast={isLast}
                                            {...item}
                                        />
                                    ))}
                            </>
                        )}
                        {entries.length <= 2 &&
                            entries.map((item) => (
                                <InterconnectionItem
                                    type={type}
                                    key={item.id}
                                    isLast={isLast}
                                    {...item}
                                />
                            ))}
                    </React.Fragment>
                    {type === 'in' && (
                        <IconConnection className="timeline-interconnections-icon timeline-interconnections-icon--in" />
                    )}
                </div>
            )}
        </div>
    );
}
Interconnections.propTypes = {
    entries: PropTypes.arrayOf(PropTypes.shape()),
    type: PropTypes.oneOf(['in', 'out']),
    isLast: PropTypes.bool,
};
Interconnections.defaultProps = { entries: [], type: 'in', isLast: false };
