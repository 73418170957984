import { getUser } from '.';

export default function usePIICompliance(rideInfo = {}) {
    const { roles } = getUser()?.profile || {};
    const { isTurkey } = rideInfo;
    // if user has more than one role, he can see PII
    if (roles?.length > 1) {
        return true;
    }
    if (roles?.includes('kk_shop_station_employee') && !isTurkey) {
        return false;
    }
    return true;
}
