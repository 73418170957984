import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import api from '../../../api/Client';
import { Tooltip, Checkbox, Link, Fineprint } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconAttention,
    IconPhoneTabletSolid,
} from '@flixbus/honeycomb-icons-react';
import { DataRow, DataCol, DataRowGroup } from '../../UI/DataGrid';
import Passenger from './PassengersTable';
import PassengerStatus from './PassengerStatus';
import ExtrasInfo from './ExtrasInfo';
import Translate, { TranslateContext } from '../../System/Translations';
import InterconInfo from './InterconInfo';

import { ReactComponent as PhoneHandset } from './phone-handset.svg';
import TicketPdfLink from '../../TicketPdfLink';
import {
    formatDate,
    formatMinutes,
    getUtcOffsetText,
    relativeTime,
} from '../../../util/date';

import langsList from '../../System/Translations/langsList';

const SHOP = {
    GREYHOUND: 'greyhound',
    FLIXBUS: 'flixbus',
};

export default function Order(props) {
    const {
        order,
        togglePax,
        selectedPax,
        rideMeta,
        printView,
        columns,
        paxStatusUpdate,
        paxComments,
        paxCommentsLoading,
    } = props;
    const translate = useContext(TranslateContext);
    const BACKEND_URL = api?.config?.backend_host?.replace(/\/$/, '');
    const allChecked = selectedPax.indexOf(order.id) !== -1;

    const ifTR = rideMeta.should_print_pax_list;

    const { route } = order;
    const routeFromName = ifTR ? route.from_name : route.from_code;
    const routeFromHint = ifTR ? route.from_code : route.from_name;
    const routeToName = ifTR ? route.to_name : route.to_code;
    const routeToHint = ifTR ? route.to_code : route.to_name;
    const iccInfo = order?.passengers[0]?.interconnection_info;
    const icFrom = iccInfo?.from;
    const icTo = iccInfo?.to;
    const invalidFromCode = route.invalid_from_code;
    const invalidToCode = route.invalid_to_code;

    const paxPushClassName = () =>
        order.passengers.length > 1 ? 'reservation-passenger-push' : '';

    const orderCreatedAt = moment.parseZone(order.created_at);
    const orderCreatedAtFormatted =
        formatDate(orderCreatedAt, 'HH:mm, DD.MM.YYYY') +
        getUtcOffsetText(orderCreatedAt);
    const orderCreatedAge = formatMinutes(
        (new Date().getTime() - new Date(order.created_at).getTime()) /
            1000 /
            60
    );

    const relativeTimeText = relativeTime(
        -orderCreatedAge[0],
        -orderCreatedAge[1],
        translate.language
    );

    const { shop } = order;
    const shopCssClassName = useMemo(() => {
        return Object.values(SHOP).map((shopname) => {
            return (
                (shop &&
                    shop.toLowerCase().includes(shopname) &&
                    `is-${shopname}`) ||
                ''
            );
        });
    }, [shop]);

    return (
        <Translate>
            {(translate) => (
                <React.Fragment>
                    <DataRowGroup className={shopCssClassName}>
                        <DataRow valign="top">
                            {columns.passengers.checked && (
                                <DataCol>
                                    {order.passengers.length > 1 ? (
                                        <Checkbox
                                            label={translate(
                                                'reservations.table.select_passenger_control'
                                            )}
                                            value={String(allChecked)}
                                            id={`order-${order.id}`}
                                            checked={allChecked}
                                            onChange={togglePax(order.id)}
                                            extraClasses="reservation-passenger-item"
                                            small
                                        />
                                    ) : null}
                                    {order.passengers.map((passenger, idx) => {
                                        return (
                                            <Passenger
                                                key={`${idx}_${passenger.ticket_id}-pax`}
                                                passenger={passenger}
                                                togglePax={togglePax}
                                                selectedPax={selectedPax}
                                                rideId={rideMeta.ride.id}
                                                push={
                                                    order.passengers.length > 1
                                                }
                                                printView={printView}
                                                comments={paxComments?.filter(
                                                    (comment) =>
                                                        comment.referenceId ===
                                                        passenger.ticket_id
                                                )}
                                                commentsLoading={
                                                    paxCommentsLoading
                                                }
                                            />
                                        );
                                    })}
                                </DataCol>
                            )}

                            {!printView && columns.status.checked && (
                                <DataCol
                                    className={
                                        paxPushClassName(order) +
                                        ' reservation-passenger-push--status'
                                    }
                                >
                                    {order.passengers.length > 1 && (
                                        <div className="reservation-passenger-push-value">
                                            &nbsp;
                                        </div>
                                    )}
                                    {order.passengers.map((passenger, idx) => (
                                        <PassengerStatus
                                            key={`${idx}_${passenger.ticket_id}-status`}
                                            rideId={rideMeta.ride.uuid}
                                            paxUuid={passenger.uuid}
                                            paxName={passenger.name}
                                            status={passenger.checkin_status}
                                            updatedAt={
                                                passenger.checkin_status_update
                                            }
                                            push={order.passengers.length > 1}
                                            paxStatusUpdate={paxStatusUpdate}
                                            ticketId={passenger.ticket_id}
                                        />
                                    ))}
                                </DataCol>
                            )}

                            {rideMeta.should_print_pax_list &&
                                columns.revenue.checked && (
                                    <DataCol
                                        className={paxPushClassName(order)}
                                    >
                                        {order.passengers.length > 1 && (
                                            <div>&nbsp;</div>
                                        )}
                                        {order.passengers.map(
                                            (passenger, idx) => (
                                                <div
                                                    key={`${idx}_${passenger.ticket_id}-revenue`}
                                                    className="reservation-passenger-push-value"
                                                >
                                                    {passenger.revenue
                                                        ? new Intl.NumberFormat(
                                                              'tr',
                                                              {
                                                                  style: 'currency',
                                                                  currency:
                                                                      passenger.currency,
                                                              }
                                                          ).format(
                                                              passenger.revenue
                                                          )
                                                        : ' --- '}
                                                </div>
                                            )
                                        )}
                                    </DataCol>
                                )}

                            {columns.seats.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    {order.passengers.length > 1 && (
                                        <div className="reservation-passenger-push-value space-around">
                                            &nbsp;
                                        </div>
                                    )}
                                    {order.passengers.map(
                                        (passenger, idx) =>
                                            passenger.seat_type &&
                                            passenger.seat_type.seat_label && (
                                                <div
                                                    key={`${idx}_${passenger.ticket_id}-seats`}
                                                    className="reservation-passenger-push-value space-around"
                                                >
                                                    <span>
                                                        {
                                                            passenger.seat_type
                                                                .seat_label
                                                        }
                                                        {/*
                                                        TEMPORARY HIDDEN
                                                        {passenger.seat_type
                                                            .source && (
                                                            <Tooltip
                                                                id={`${idx}_${passenger.ticket_id}-seat-tooltip`}
                                                                position="top"
                                                                content={`${translate(
                                                                    'reservations.table.seat-assign-hint'
                                                                )}: ${translate(
                                                                    `reservations.table.seat-assign-type.${passenger.seat_type.source}`
                                                                )}`}
                                                                style={{
                                                                    textAlign:
                                                                        'left',
                                                                }}
                                                                openOnHover
                                                                size="content-fit"
                                                            >
                                                                <span
                                                                    className={`reservation-passenger-seat-badge reservation-passenger-seat-badge--${passenger.seat_type.source}`}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        */}
                                                    </span>
                                                    {passenger.seat_type.deck >
                                                        0 && (
                                                        <>
                                                            <span> | </span>
                                                            <span>
                                                                {
                                                                    passenger
                                                                        .seat_type
                                                                        .deck
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            )
                                    )}
                                </DataCol>
                            )}

                            {!printView && columns.order.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    <Tooltip
                                        id={`tooltip-checkec-${order.id}`}
                                        position="top"
                                        content={`${translate(
                                            'reservations.table.order-created-at'
                                        )}: ${orderCreatedAtFormatted}`}
                                        style={{ textAlign: 'left' }}
                                        openOnHover
                                        size="content-fit"
                                    >
                                        <Link
                                            href={`${BACKEND_URL}/reports/orders/${order.id}/view`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {order.id}
                                        </Link>
                                    </Tooltip>
                                    {order.download_hash ? (
                                        <TicketPdfLink
                                            orderId={order.id}
                                            downloadHash={order.download_hash}
                                        />
                                    ) : null}
                                    <div>
                                        {orderCreatedAge[0] > 3 ? null : (
                                            <Fineprint>
                                                {relativeTimeText}
                                            </Fineprint>
                                        )}
                                    </div>
                                </DataCol>
                            )}

                            {columns.route.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    <Tooltip
                                        id={`${order.id}-route-from-hint`}
                                        content={routeFromHint}
                                        position="top"
                                        openOnHover
                                        size="content-fit"
                                    >
                                        <strong>{routeFromName}</strong>
                                    </Tooltip>
                                    &nbsp;
                                    {invalidFromCode && (
                                        <Icon
                                            size={4}
                                            InlineIcon={IconAttention}
                                        />
                                    )}
                                    &nbsp; &ndash; &nbsp;
                                    <Tooltip
                                        id={`${order.id}-route-to-hint`}
                                        content={routeToHint}
                                        position="top"
                                        openOnHover
                                        size="content-fit"
                                    >
                                        <strong>{routeToName}</strong>
                                    </Tooltip>
                                    &nbsp;
                                    {invalidToCode && (
                                        <Icon
                                            size={4}
                                            InlineIcon={IconAttention}
                                        />
                                    )}
                                    {order.passengers.map(
                                        (passenger) =>
                                            passenger.pickup_station && (
                                                <div className="reservation-passenger__pickup-locations">
                                                    {passenger.pickup_station}
                                                </div>
                                            )
                                    )}
                                </DataCol>
                            )}

                            {columns.extras.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    <ExtrasInfo
                                        extras={
                                            Array.isArray(order.extras)
                                                ? {}
                                                : order.extras
                                        }
                                    />
                                </DataCol>
                            )}

                            {columns.language.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    {langsList[order.language] ? (
                                        <img
                                            src={langsList[order.language].icon}
                                            className="language-switcher_img"
                                            title={order.language}
                                            alt={langsList[order.language].code}
                                        />
                                    ) : (
                                        order.language
                                    )}
                                </DataCol>
                            )}

                            {!printView && columns.phone.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    {order.passengers[0] &&
                                        order.passengers[0].phone_number && (
                                            <span
                                                className="reservation-passenger-phone"
                                                data-dd-privacy="mask"
                                            >
                                                <Icon
                                                    InlineIcon={() => (
                                                        <PhoneHandset />
                                                    )}
                                                />
                                                {
                                                    order.passengers[0]
                                                        .phone_number
                                                }
                                            </span>
                                        )}
                                    {order.passengers[0] &&
                                        order.passengers[0]
                                            .push_subscribers_count > 0 && (
                                            <Icon
                                                InlineIcon={
                                                    IconPhoneTabletSolid
                                                }
                                            />
                                        )}
                                </DataCol>
                            )}

                            {columns.shop.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    {order.shop}
                                    {order.user &&
                                    order.user.includes('redbus') ? (
                                        <span className="redubs">R</span>
                                    ) : null}
                                    {order.user &&
                                    order.user.includes('paytm') ? (
                                        <span className="paytm">P</span>
                                    ) : null}
                                    {order.user &&
                                    order.user.includes('abhibus') ? (
                                        <span className="abhibus">A</span>
                                    ) : null}
                                </DataCol>
                            )}

                            {!printView && columns.transfer.checked && (
                                <DataCol className={paxPushClassName(order)}>
                                    {(icFrom || icTo) && (
                                        <InterconInfo
                                            icFrom={icFrom}
                                            icTo={icTo}
                                        />
                                    )}
                                </DataCol>
                            )}
                        </DataRow>
                    </DataRowGroup>
                </React.Fragment>
            )}
        </Translate>
    );
}

Order.propTypes = {
    order: PropTypes.object.isRequired,
    togglePax: PropTypes.func.isRequired,
    selectedPax: PropTypes.array.isRequired,
    rideMeta: PropTypes.object.isRequired,
    printView: PropTypes.bool,
};
Order.defaultProps = {
    printView: false,
};
