import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/megadriveApi';
import {
    Accordion,
    Box,
    Button,
    Fineprint,
    FormRow,
    Grid,
    GridCol,
    Heading,
    Infobox,
    Link,
    List,
    Radio,
} from '@flixbus/honeycomb-react';
import { Icon, IconNewTab, IconStation } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../System/Translations';
import { useStops } from '../hooks';
import { PageDialog, PageDialogContentGrid } from '../../UI/PageDialog';
import { ListPicker } from '../FormElements';

import './BatchStopStatus.scss';
import { Link as RouterLink } from 'react-router-dom';

const requestUrl = '/rides/stations/status';

export default function BatchStopStatus(props) {
    const { rides, active, onClose } = props;
    const translate = useContext(TranslateContext);
    const [stopsResponse, getStops] = useStops();
    const { data: stops, pending } = stopsResponse;
    const [stop, setStop] = useState(null);
    const [status, setStatus] = useState(null);
    const [batchResponseSuccess, setResponseSuccess] = useState(null);
    const [batchResponseError, setResponseError] = useState(null);
    const [batchError, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        getStops();
    }, [getStops]);

    const sendBatch = useCallback(() => {
        const data = rides.map((ridUuid) => {
            return {
                ride_uuid: ridUuid,
                station_uuid: stop,
                status: status.toUpperCase(),
            };
        });
        if (batchError !== null) {
            setError(null);
        }
        setSaving(true);
        api.put(requestUrl, {
            stations: data,
        })
            .then((response) => {
                setSaving(false);
                const successStations = response.stations.filter(
                    (s) => s.error_message === null
                );
                const errorStations = response.stations.filter(
                    (s) => s.error_message !== null
                );
                setResponseSuccess(successStations);
                setResponseError(errorStations);
            })
            .catch((e) => {
                setSaving(false);
                if (!api.isCancel(e)) {
                    setError(e?.response?.data?.error || e?.message);
                }
            });
    }, [
        rides,
        stop,
        status,
        setResponseSuccess,
        setResponseError,
        setError,
        batchError,
    ]);

    const getStopsOptions = useCallback(() => {
        if (stops !== null) {
            return stops.map((stop) => ({
                title: `${stop.code}-${stop.name}`,
                sortTitle: `${stop.code} ${stop.name}`,
                value: stop.uuid,
            }));
        }
        return [];
    }, [stops]);

    function onChangeStations(uuid) {
        setStop(uuid);
    }

    function onChangeStatus(e) {
        setStatus(e.target.value);
    }

    return (
        <PageDialog active={active} onClose={onClose}>
            <PageDialogContentGrid>
                <Heading size={1}>
                    {translate('ride-search.batch-status.title')}
                </Heading>
                <Grid>
                    <GridCol size={8}>
                        <FormRow>
                            <ListPicker
                                id="stops-picker-batch-station-status"
                                label={translate(
                                    'ride-search.batch-status.label'
                                )}
                                InlineIcon={IconStation}
                                options={getStopsOptions()}
                                onChange={onChangeStations}
                                loading={pending}
                                value={stop}
                            />
                        </FormRow>
                    </GridCol>
                </Grid>
                <Grid>
                    <GridCol size={4}>
                        <Box highlighted extraClasses="batch-status-box">
                            <Heading size={4}>
                                {translate(
                                    'ride-search.batch-status.number-of-selected'
                                )}
                                &nbsp;
                                <span className="batch-status-box__value">
                                    {rides.length}
                                </span>
                            </Heading>
                        </Box>
                    </GridCol>
                    <GridCol size={8}>
                        <Box highlighted extraClasses="batch-status-box">
                            {batchError === null ? (
                                <>
                                    <Heading size={4}>
                                        {translate(
                                            'ride-search.batch-status.number-of-processed'
                                        )}
                                        &nbsp;
                                        <span className="batch-status-box__value">
                                            {Array.isArray(
                                                batchResponseSuccess
                                            ) === true
                                                ? batchResponseSuccess.length
                                                : 0}
                                            {Array.isArray(
                                                batchResponseError
                                            ) === true &&
                                            batchResponseError.length > 0 ? (
                                                <>
                                                    <Accordion
                                                        title={`${translate(
                                                            'ride-search.batch-status.number-of-error'
                                                        )} ${
                                                            batchResponseError.length
                                                        } `}
                                                    >
                                                        <Fineprint>
                                                            <List>
                                                                {batchResponseError.map(
                                                                    (s) => (
                                                                        <li
                                                                            key={
                                                                                s.ride_uuid
                                                                            }
                                                                        >
                                                                            {
                                                                                s.error_message
                                                                            }
                                                                            :{' '}
                                                                            <Link
                                                                                to={`/ride-view/${s.ride_uuid}`}
                                                                                RouterLink={
                                                                                    RouterLink
                                                                                }
                                                                            >
                                                                                <Icon
                                                                                    appearance="secondary"
                                                                                    InlineIcon={
                                                                                        IconNewTab
                                                                                    }
                                                                                />{' '}
                                                                                {translate(
                                                                                    'ride-search.batch-status.ride-link'
                                                                                )}
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </List>
                                                        </Fineprint>
                                                    </Accordion>
                                                </>
                                            ) : null}
                                        </span>
                                    </Heading>
                                    {translate(
                                        'ride-search.batch-status.processed-note'
                                    )}
                                </>
                            ) : (
                                <Infobox appearance="danger" small>
                                    {batchError}
                                </Infobox>
                            )}
                        </Box>
                    </GridCol>
                </Grid>
                <Grid>
                    <GridCol>
                        <Heading size={3}>
                            {translate(
                                'ride-search.batch-status.status-group.title'
                            )}
                        </Heading>
                        <FormRow>
                            <div className="celling-box">
                                <div className="celling-box__cell">
                                    <Radio
                                        id="open-status"
                                        label={translate('station-status-open')}
                                        name="status"
                                        value="open"
                                        onChange={onChangeStatus}
                                        checked={status === 'open'}
                                    />
                                </div>
                                <div className="celling-box__cell">
                                    <Radio
                                        id="canceled-status"
                                        label={translate(
                                            'station-status-cancelled'
                                        )}
                                        name="status"
                                        value="cancelled"
                                        onChange={onChangeStatus}
                                        checked={status === 'cancelled'}
                                    />
                                </div>
                                <div className="celling-box__cell">
                                    <Radio
                                        id="closed-status"
                                        label={translate(
                                            'station-status-closed'
                                        )}
                                        name="status"
                                        value="closed"
                                        onChange={onChangeStatus}
                                        checked={status === 'closed'}
                                    />
                                </div>
                            </div>
                        </FormRow>
                    </GridCol>
                </Grid>
                <Grid justify="right">
                    <GridCol size={8} extraClasses="rv-text-right">
                        <Button appearance="tertiary" onClick={onClose}>
                            {translate(
                                'ride-search.batch-status.button.tertiary'
                            )}
                        </Button>
                        <Button
                            appearance="primary"
                            disabled={
                                stop === null || status === null || saving
                            }
                            onClick={sendBatch}
                            loading={saving}
                        >
                            {translate(
                                'ride-search.batch-status.button.primary'
                            )}
                        </Button>
                    </GridCol>
                </Grid>
            </PageDialogContentGrid>
        </PageDialog>
    );
}
BatchStopStatus.propTypes = { prop: PropTypes.string };
BatchStopStatus.defaultProps = { prop: '' };
