import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, IconPlus } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../System/Translations';

export default function AttachFile(props) {
    const { onDrop } = props;
    const dropzoneStyle = {
        height: 'auto',
        width: 'auto',
        padding: '0 10px',
    };
    const translate = useContext(TranslateContext);
    return (
        <div className="attach-block">
            <Dropzone style={dropzoneStyle} onDrop={onDrop}>
                {translate('attach-file.heading')}
                <Icon InlineIcon={IconPlus} extraClasses="attach-block__icon" />
            </Dropzone>
        </div>
    );
}

AttachFile.propTypes = {
    onDrop: PropTypes.func.isRequired,
};
