import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';
import SeatAssignmentDropdown from './SeatAssignmentDropdown';

const SeatsList = ({ seats, pax, selectedDeck, onSelect, resetCoach }) => {
    const [seatsOptions, setSeatsOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    function onSeatRemove() {
        onSelect(
            selected.id,
            pax.ticket_id,
            selected.reservations,
            selectedDeck,
            true
        );
        setSelected();

        if (selectedDeck) {
            resetCoach();
        } else {
            setSeatsOptions([]);
        }
    }

    useEffect(() => {
        setSeatsOptions(seats);
    }, [seats]);

    return (
        <>
            <SeatAssignmentDropdown
                options={seatsOptions}
                onChange={(target) => {
                    const seatId = target.id;
                    const selectedSeat = seats.find(
                        (seat) => seat.id === seatId
                    );
                    setSelected(selectedSeat);
                    onSelect(
                        selectedSeat.id,
                        pax.ticket_id,
                        selectedSeat.reservations,
                        selectedDeck
                    );
                }}
                pax={pax}
                placeholder="Select seat"
                selected={selected}
            />

            {selected && (
                <Icon
                    extraClasses="seat-assignment-dialog__seats-remove"
                    InlineIcon={IconClose}
                    onClick={onSeatRemove}
                />
            )}
        </>
    );
};

const SeatsAndCoaches = ({ coaches, coachesMap, pax, onSelect }) => {
    const [coach, setCoach] = useState();
    const [coachesOptions, setCoachesOptions] = useState(coaches);
    const [seatsOptions, setSeatsOptions] = useState([]);

    function onCoachSelect(coach) {
        setCoach(coach.toString());
        setSeatsOptions(coaches[coach]);
    }

    function resetCoach() {
        setCoach('');
        setCoachesOptions([]);
        setSeatsOptions([]);

        // update the coaches list to reset the select
        setTimeout(() => {
            setCoachesOptions(coaches);
        }, 500);
    }

    useEffect(() => {
        if (coach) {
            setCoachesOptions(coaches);
            setSeatsOptions(coaches[coach]);
        }
    }, [coaches, coach]);

    return (
        <>
            <SeatAssignmentDropdown
                options={Object.keys(coachesOptions)}
                onChange={(coach) => {
                    onCoachSelect(coach);
                }}
                placeholder="Select coach"
                coachesMap={coachesMap}
                pax={pax}
                selected={coach}
            />
            <SeatsList
                seats={seatsOptions}
                pax={pax}
                onSelect={onSelect}
                selectedDeck={coach}
                resetCoach={resetCoach}
            />
        </>
    );
};

export { SeatsAndCoaches, SeatsList };

SeatsAndCoaches.propTypes = {
    coaches: PropTypes.shape({
        [PropTypes.number]: PropTypes.array,
    }).isRequired,
    coachesMap: PropTypes.object.isRequired,
    pax: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
};
SeatsList.propTypes = {
    seats: PropTypes.array,
    pax: PropTypes.object.isRequired,
    selectedDeck: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    resetCoach: PropTypes.func,
};
