import {
    useRef, useState, useCallback, useEffect,
} from 'react';

const STORAGE_KEY = 'rv-storage-pax-columns';

const COLUMNS = {
    passengers: { name: 'passengers', checked: true, label: 'reservations.table.passengers' },
    status: { name: 'status', checked: true, label: 'reservations.table.status' },
    revenue: { name: 'revenue', checked: true, label: 'reservations.table.revenue' },
    seats: { name: 'seats', checked: true, label: 'reservations.table.seats' },
    order: { name: 'order', checked: true, label: 'reservations.table.order' },
    route: { name: 'route', checked: true, label: 'reservations.table.route' },
    extras: { name: 'extras', checked: true, label: 'reservations.table.extras' },
    language: { name: 'language', checked: true, label: 'reservations.table.language' },
    phone: { name: 'phone', checked: true, label: 'reservations.table.phone' },
    transfer: { name: 'transfer', checked: true, label: 'reservations.table.transfer' },
    shop: { name: 'shop', checked: true, label: 'reservations.table.shop' },
};

export default function useColumns() {
    const [cols, setColumns] = useState(COLUMNS);
    const [acknowledged, setAcknowledged] = useState(true);
    const sync = useRef(false);

    const mergeLists = useCallback(
        (storedColumns) => {
            const columnsSet = Object.entries(cols).reduce((acc, [column]) => {
                const out = { ...acc };
                const checked = storedColumns[column] || storedColumns[column] === undefined ? true : false;
                out[column] = { ...cols[column], checked };
                return out;
            }, {});
            setColumns(columnsSet);
        },
        [cols, setColumns],
    );

    // runs to load sets of columns from local storage
    useEffect(() => {
        try {
            const { localStorage } = window;
            const storedColumns = JSON.parse(localStorage.getItem(STORAGE_KEY));
            if (storedColumns !== null) {
                sync.current !== true && mergeLists(storedColumns);
            } else {
                setAcknowledged(false);
            }
        } catch (e) {
            // show a notification
        }
        sync.current = true;
    }, [setAcknowledged, mergeLists]);

    const saveColumns = useCallback(
        (columns) => {
            try {
                const { localStorage } = window;
                mergeLists(columns);
                localStorage.setItem(STORAGE_KEY, JSON.stringify(columns));
            } catch (e) {
                // show a notification
            }
            setAcknowledged(true);
        },
        [setAcknowledged, mergeLists],
    );

    return [cols, acknowledged, saveColumns];
}
