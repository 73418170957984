import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function StationOpened(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
        {translate('station-opened.station-is-open')} <strong>{ item.parameters.station.name }</strong>.
        {translate('station-opened.station-id')} <strong>{ item.parameters.station.id }</strong>
        </div>);
}

StationOpened.propTypes = {
    item: PropTypes.object.isRequired,
};
