import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';

export default function withRebookingRideStatusApi(Component) {
    return class WithRebookingRideStatusApi extends React.Component {
        static propTypes = {
            rideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            notify: PropTypes.func,
        };

        static defaultProps = {
            notify: () => {},
        };

        state = {
            status: {},
            loading: true,
        };

        componentDidMount() {
            const { rideId, notify } = this.props;
            api.get(`/ride/${rideId}/rebookr/status`)
                .then((status) =>
                    this.setState(() => {
                        if (status.is_pending) {
                            notify({
                                type: 'info',
                                message:
                                    'Rebooking is pending for this ride. Find more info on settings panel.',
                                autoDismiss: 0,
                            });
                        }
                        return { status, loading: false };
                    })
                )
                .catch((error) => {
                    if (!api.isCancel(error)) {
                        this.setState({ loading: false });
                        notify({
                            type: 'danger',
                            message: `Rebooking: ${error}`,
                        });
                    }
                });
        }

        componentWillUnmount() {
            const { rideId } = this.props;
            api.cancel(`/ride/${rideId}/rebookr/status`);
        }

        render() {
            const { status, loading } = this.state;
            if (loading) {
                return null;
            }
            return <Component {...this.props} status={status} />;
        }
    };
}
