import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function DataGrid(props) {
    const {
        header, children, className, ...restProps
    } = props;
    const gridClassNames = classNames('flix-data-grid-row', {
        [props.className]: className,
        'flix-data-grid__header': header,
    });
    return (
        <tr {...restProps} className={gridClassNames}>
            {children}
        </tr>
    );
}

DataGrid.propTypes = {
    header: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    className: PropTypes.string,
};

DataGrid.defaultProps = {
    header: false,
    children: null,
    className: '',
};
