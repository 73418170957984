import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, Button } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconCollaborationSolid,
    IconNewsletter,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../System/Translations';
import './Collaboration.scss';

function Item(props) {
    const { text } = props;
    return (
        <span>
            <Icon size={2} InlineIcon={IconNewsletter} /> {text}
        </span>
    );
}

export default function Collaboration(props) {
    const { users } = props;
    const translate = useContext(TranslateContext);
    const list = users.map((user) => ({
        text: <Item text={user.userName} />,
        href: `mailto:${user.userId}`,
    }));
    const cssNameBase = 'collaboration';
    const wrapperCssClass = classNames(`${cssNameBase}-wrapper`);

    return (
        <React.Fragment>
            {list.length > 0 ? (
                <Dropdown links={list} extraClasses={cssNameBase}>
                    <Button className={wrapperCssClass}>
                        <Icon
                            title={translate(
                                'who-is-on-page-widget.also-viewing'
                            )}
                            InlineIcon={IconCollaborationSolid}
                            extraClasses={`${cssNameBase}-icon`}
                        />
                        <span className={`${cssNameBase}-badged`}>
                            {list.length}
                        </span>
                    </Button>
                </Dropdown>
            ) : null}
        </React.Fragment>
    );
}
Collaboration.propTypes = { users: PropTypes.array };
Collaboration.defaultProps = { users: [] };
