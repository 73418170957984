import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';
import { formatDate } from '../../../../util/date';

export default function DelayLog(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const timeSet = item.parameters.time_set;
    const segmentIds = Object.keys(timeSet);
    const displayTimeFormat = 'HH:mm';
    const collection = segmentIds.reduce((output, segmentId) => {
        const timesetItem = timeSet[segmentId];
        const departureDelayMinutes = Math.floor(timesetItem.station_from.delay / 60);
        const arrivalDelayMinutes = Math.floor(timesetItem.station_to.delay / 60);

        const delayedArrivalTime = formatDate(timesetItem.station_to.delayed_arrival_time, displayTimeFormat);
        const delayedDepartureTime = formatDate(timesetItem.station_from.delayed_departure_time, displayTimeFormat);

        const component = (
            <li key={segmentId}>
                <strong>
                    {timesetItem.station_from.name}
                    {` (${timesetItem.station_from.code})`}
                </strong>
                &nbsp;
                { formatDate(timesetItem.station_from.departure_time, displayTimeFormat) }
                &rarr;
                <strong>
                    {timesetItem.station_to.name}
                    { ` (${timesetItem.station_to.code})` }
                </strong>
                &nbsp;
                { formatDate(timesetItem.station_to.arrival_time, displayTimeFormat) }
                &nbsp;
                &nbsp;
                &nbsp;
                { departureDelayMinutes === 0
                    ? delayedDepartureTime
                    : `${delayedDepartureTime} (${departureDelayMinutes} ${translate('delay.time_minutes')})`
                }
                &rarr;
                { arrivalDelayMinutes === 0
                    ? delayedArrivalTime
                    : `${delayedArrivalTime} (${arrivalDelayMinutes} ${translate('delay.time_minutes')})`
                }
            </li>
        );
        output.push(component);

        return output;
    }, []);

    return (
        <div>
            { translate('delay.set') }
            <ul className="confirmed-delay-time-set-changes">{ collection }</ul>
        </div>
    );
}

DelayLog.propTypes = {
    item: PropTypes.object.isRequired,
};
