import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function BigDelay(props) {
    const { item } = props;
    const delay = item.parameters.delay || '';
    const segment = item.parameters.segment || '';
    const totalAffectedPassengers = item.parameters.totalAffectedPassengers || '-';
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('big-delay.phrase-one')} { delay }
            &nbsp;
            {translate('big-delay.phrase-two')} { segment }
            &nbsp;
            {translate('big-delay.phrase-three')} { totalAffectedPassengers }
        </div>
    );
}

BigDelay.propTypes = {
    item: PropTypes.object.isRequired,
};
