import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconTicketSolid } from '@flixbus/honeycomb-icons-react';
import useOrderApi from './useOrderApi';

export default function TicketPdfLink(props) {
    const { orderId, downloadHash } = props;
    const [data, request, reset] = useOrderApi(orderId, downloadHash);

    if (data && typeof data.response === 'string') {
        window.open(data.response, orderId);
        reset();
    }
    return (
        <Button onClick={request} loading={data.pending} link>
            <Icon appearance="primary" InlineIcon={IconTicketSolid} />
        </Button>
    );
}
TicketPdfLink.propTypes = {
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    downloadHash: PropTypes.string.isRequired,
};
