import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@flixbus/honeycomb-react';
import { Icon, IconMagnifier } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../System/Translations';

export default function SearchField(props) {
    const { onSearch } = props;
    const translate = useContext(TranslateContext);
    return (
        <Input
            id="search-template"
            label={translate('notifier.templates.search.label')}
            placeholder={translate('notifier.templates.search.placeholder')}
            onChange={({ target }) => {
                onSearch(target.value);
            }}
            iconLeft={<Icon appearance="primary" InlineIcon={IconMagnifier} />}
        />
    );
}
SearchField.propTypes = { onSearch: PropTypes.func.isRequired };
