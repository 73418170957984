import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function JoinTheRide(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('join-the-ride')} <strong>{ item.user ? item.user.fullName : item.parameters.user }</strong>
            { item.parameters.license_plate ?
                <span> | {translate('join-the-ride.license-plate')} <strong>{ item.parameters.license_plate }</strong></span> : '' }
        </div>
    );
}

JoinTheRide.propTypes = {
    item: PropTypes.object.isRequired,
};
