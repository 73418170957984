import { useEffect, useState } from 'react';
import api from '../../../api/Client';

export default function (entryPoint) {
    const [response, setResponse] = useState({
        data: null,
        loading: false,
        error: false,
    });

    useEffect(
        () => () => {
            // cancel pending request with component unmount
            api.cancel(entryPoint);
        },
        [entryPoint]
    );

    const request = (data) => {
        setResponse({ loading: true });
        api.post(entryPoint, data)
            .then((res) => {
                setResponse({
                    ...response,
                    data: res,
                    error: false,
                });
            })
            .catch((err) => {
                if (!api.isCancel(err)) {
                    setResponse({ ...response, data: null, error: err });
                }
            })
            .finally(() => {
                setResponse({
                    ...response,
                    loading: false,
                });
            });
    };

    return [response, request];
}
