import React, { useContext } from 'react';
import ProprTypes from 'prop-types';
import { TranslateContext } from '../../System/Translations';
import SelectGroupInput from './SelectGroupInput';

const statusesOptions = [
    {
        id: 1,
        value: 'on_sale',
        name: 'status',
        label: 'On sale',
        checked: false,
        onChange: () => { },
    },
    {
        id: 2,
        value: 'hidden',
        name: 'status',
        label: 'Hidden',
        checked: false,
        onChange: () => { },
    },
    {
        id: 3,
        value: 'archived',
        name: 'status',
        label: 'Archived',
        checked: false,
        onChange: () => { },
    },
    {
        id: 4,
        value: 'preview',
        name: 'status',
        label: 'Preview',
        checked: false,
        onChange: () => { },
    },
];
export default function StatusesSelect(props) {
    const { onSelect, onUnselect, value } = props;
    const translate = useContext(TranslateContext);

    return (
        <SelectGroupInput
            label={translate('ride-search.status.label')}
            options={statusesOptions}
            id="select-status"
            onSelect={onSelect}
            onUnselect={onUnselect}
            value={value}
        />
    );
}

StatusesSelect.propTypes = {
    onSelect: ProprTypes.func.isRequired,
    onUnselect: ProprTypes.func.isRequired,
    value: ProprTypes.array,
};
StatusesSelect.defaultProps = {
    value: [],
};
