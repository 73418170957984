import { useCallback } from 'react';

export default function useBusterBusPax(orders) {
    const distribute = useCallback(() => {
        const distributed = orders.reduce((acc, order) => {
            const res = { ...acc };
            const busPlateNumber = order.passengers[0].bus_plate_number;

            if (res[busPlateNumber]) {
                res[busPlateNumber].push(order);
                return res;
            }

            res[busPlateNumber] = [order];
            return res;
        }, {});

        return Object.keys(distributed)
            .sort((a, b) => {
                // sort in away that orders without bus plate was at the top
                if (a === '') {
                    return -1;
                }
                if (b === '') {
                    return 1;
                }
                return a - b;
            })
            .reduce((acc, key) => {
                const result = { ...acc };
                result[key] = distributed[key];
                return result;
            }, {});
    }, [orders]);

    return [distribute];
}
