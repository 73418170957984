import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../../api/Client';

const ACTIONS = {
    CHANGE_STATUS: 'change_status'
};

export default function BackendRedirectForm(props) {
    const { selectedIds, action, refEl } = props;
    const BACKEND_URL = api?.config?.backend_host.replace(/\/$/, '');
    const [legacyIds, setLegacyIds] = useState([]);

    useEffect(() => {
        if (selectedIds.length > 0) {
            api.post(`/ride/get-legacy-ids`, { uuids: selectedIds }).then(
                (legacyIds) => setLegacyIds(legacyIds)
            );
        }

        return () => {
            api.cancel(`/ride/get-legacy-ids`);
        };
    }, [selectedIds, setLegacyIds]);

    return (
        <form
            method="post"
            action={`${BACKEND_URL}/inventory/rides/batch`}
            ref={refEl}
            className="background-post-form"
            target="_blank"
        >
            {legacyIds.map((id) => (
                <input type="hidden" name="idx[]" value={id} key={id} />
            ))}
            <input type="hidden" name="action" defaultValue={ACTIONS[action]} />
            <button type="submit">Submit</button>
        </form>
    );
}

BackendRedirectForm.propTypes = {
    selectedIds: PropTypes.array.isRequired,
    action: PropTypes.string.isRequired,
    refEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        .isRequired,
};
