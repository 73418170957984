import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export default function withContentBox(Component) {
    /**
     * Parse components from props.children
     * and make it accessible with Router
     */
    return class WithContentBox extends React.Component {
        static propTypes = {
            children: PropTypes.oneOfType([
                PropTypes.array.isRequired,
                PropTypes.node.isRequired,
            ]),
            path: PropTypes.string.isRequired,
        };

        static defaultProps = {
            children: null,
        };

        /**
         * map children components with alias
         */
        getComponents() {
            const { children } = this.props;
            const components = {};

            // props.children as array or single value
            if (children && children.length) {
                children.forEach((content) => {
                    const { alias } = content?.props || {};
                    alias && (components[alias] = content); // eslint-disable-line
                });
            } else if (children) {
                const { alias } = children?.props || {};
                alias && (components[alias] = children); // eslint-disable-line
            }

            return components;
        }

        render() {
            const { path } = this.props;
            return (
                <Route
                    path={path}
                    render={(props) => (
                        <Component
                            {...props}
                            components={this.getComponents()}
                        />
                    )}
                />
            );
        }
    };
}
