import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup, PopupSection, Button } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../System/Translations';

export default function PaxRightsPopup(props) {
    const { sendNotify, onClose, isActive, inProgress } = props;
    const translate = useContext(TranslateContext);

    return (
        <Popup active={isActive} onOverlayClick={onClose}>
            <PopupSection type="title">
                {translate('pax_rights.popup.title')}
            </PopupSection>

            <PopupSection type="content">
                {translate('pax_rights.popup.content')}
            </PopupSection>
            <PopupSection type="actions">
                <Button appearance="tertiary" onClick={onClose}>
                    {translate('pax_rights.popup.button.cancel')}
                </Button>
                <Button
                    appearance="secondary"
                    onClick={sendNotify}
                    disabled={inProgress}
                    loading={inProgress}
                >
                    {translate('pax_rights.popup.button.confirm')}
                </Button>
            </PopupSection>
        </Popup>
    );
}
PaxRightsPopup.propTypes = {
    sendNotify: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    onClose: PropTypes.func,
};
PaxRightsPopup.defaultProps = {
    isActive: false,
    onClose: () => {},
};
