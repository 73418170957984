import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@flixbus/honeycomb-react';
import { Icon } from '@flixbus/honeycomb-icons-react';

export default function SideBarControlsItem(props) {
    const { children, InlineIcon, ...restProps } = props;
    return (
        <Button display="square" appearance="secondary" {...restProps}>
            {children}
            <Icon InlineIcon={InlineIcon} />
        </Button>
    );
}
SideBarControlsItem.propTypes = { children: PropTypes.node };
SideBarControlsItem.defaultProps = { children: '' };
