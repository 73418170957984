import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function PaxListStatus(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);

    if (item.parameters.status === 'pending') {
        return <div>{translate('passenger_list.requested', item.parameters)}</div>;
    }

    return (
        <div>
            {translate('passenger_list.ready')} {item.parameters.stop} <a href={item.parameters.pdfLink}>{translate('passenger_list.download_file')}</a>
        </div>
    );
}
PaxListStatus.propTypes = {
    item: PropTypes.object.isRequired,
};
