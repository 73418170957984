import React from 'react';
import { NotifyContext } from '..';

/**
 * HOC to add context consumer for notification API
 * @func withNotifyConsumer
 * @param {object} Component - react component
 */

export default function withNotifyConsumer(Component) {
    return function WithNotifyConsumer(props) {
        return <NotifyContext.Consumer>{(notify) => <Component {...props} notify={notify} />}</NotifyContext.Consumer>;
    };
}
