import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DelayTimesetChanges from '../DelayTimesetChanges';
import { TranslateContext } from '../../../System/Translations';

export default function DelayRemoved(props) {
    const { item } = props;
    const oldDelayTimeset = item.parameters.old_delay_time_set ?
        <DelayTimesetChanges timeset={item.parameters.old_delay_time_set} /> : '';
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('delay.removed.confirmed')}
            <strong>{ item.user ? item.user.fullName : item.parameters.user }</strong>
            { oldDelayTimeset }
        </div>
    );
}

DelayRemoved.propTypes = {
    item: PropTypes.object.isRequired,
};
