import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridCol } from '@flixbus/honeycomb-react';

export default function PageDialogContentGrid(props) {
    const { children } = props;
    return (
        <Grid extraClasses="flix-page-dialog__grid">
            <GridCol
                size={12}
                sm={10}
                pushSm={1}
                md={8}
                pushMd={2}
                lg={6}
                pushLg={3}
            >
                {children}
            </GridCol>
        </Grid>
    );
}
PageDialogContentGrid.propTypes = { children: PropTypes.node.isRequired };
