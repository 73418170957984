import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Content(props) {
    const { children, isActive } = props;
    const itemClassName = classNames('content-box-item', {
        'content-box-item--active': isActive,
    });
    return (
        <div className={itemClassName} id="scroll-area">
            {children}
        </div>
    );
}

Content.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
    isActive: PropTypes.bool,
};

Content.defaultProps = {
    children: null,
    isActive: false,
};
