import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Tooltip, Tag } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconInfoSolid,
    IconStopwatch,
} from '@flixbus/honeycomb-icons-react';
import { formatDate } from '../../../util/date';
import { TranslateContext } from '../../System/Translations';

export default function InterconInfo(props) {
    // let's make names shorter
    const { icFrom, icTo } = props;
    const translate = useContext(TranslateContext);
    const [tooltipShow, setTooltip] = useState(false);
    const toolTipContent = (ic) => (
        <React.Fragment>
            <div>
                <strong>{ic.station}</strong>
            </div>
            <div>
                {translate('reservations.interconnection.arrival')}: &nbsp;
                {formatDate.parseZone(ic.arrival, 'DD.MM.YY HH:mm')},
            </div>
            <div>
                {translate('reservations.interconnection.departure')}: &nbsp;
                {formatDate.parseZone(ic.departure, 'DD.MM.YY HH:mm')}
            </div>
            <div>
                <Icon InlineIcon={IconStopwatch} />: &nbsp;
                {`${ic.waiting.hour || 0}:${ic.waiting.minutes || 0}`}
            </div>
        </React.Fragment>
    );

    return (
        <div>
            {icFrom.length !== 0 && (
                <>
                    {translate(
                        `reservations.interconnection.${icFrom.interconnection_type}`
                    )}
                    &nbsp;
                    <Tooltip
                        id={`from-${icFrom.ride_uid}`}
                        position="top"
                        active={tooltipShow === 'from'}
                        content={toolTipContent(icFrom)}
                        onMouseEnter={() => {
                            setTooltip('from');
                        }}
                        onMouseLeave={() => {
                            setTooltip(false);
                        }}
                    >
                        <Tag small outlined>
                            <Icon
                                InlineIcon={() => (
                                    <IconInfoSolid
                                        style={{ fill: icFrom.line_color }}
                                    />
                                )}
                            />
                            <NavLink
                                className="reservation-passenger-intercon"
                                to={`/ride-view/${icFrom.ride_id}`}
                            >
                                <span>{icFrom.ride_uid}</span>
                            </NavLink>
                        </Tag>
                    </Tooltip>
                </>
            )}
            {icTo.length !== 0 && (
                <>
                    {translate(
                        `reservations.interconnection.${icTo.interconnection_type}`
                    )}
                    &nbsp;
                    <Tooltip
                        id={`to-${icTo.ride_uid}`}
                        position="top"
                        active={tooltipShow === 'to'}
                        content={toolTipContent(icTo)}
                        onMouseEnter={() => {
                            setTooltip('to');
                        }}
                        onMouseLeave={() => {
                            setTooltip(false);
                        }}
                    >
                        <Tag small outlined>
                            <Icon
                                InlineIcon={() => (
                                    <IconInfoSolid
                                        style={{ fill: icTo.line_color }}
                                    />
                                )}
                            />
                            <NavLink
                                className="reservation-passenger-intercon"
                                to={`/ride-view/${icTo.ride_id}`}
                            >
                                <span>{icTo.ride_uid}</span>
                            </NavLink>
                        </Tag>
                    </Tooltip>
                </>
            )}
        </div>
    );
}

InterconInfo.propTypes = {
    icFrom: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    icTo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
