import React from 'react';
import api from '../../../api/Client';

import Spinner from '../../UI/Spinner';
import { TranslateContext } from '../../System/Translations';
import { Box } from '@flixbus/honeycomb-react';

export default function withAppConfig(Component) {
    return class WithAppConfig extends React.Component {
        static propTypes = {
            notify: (prop) => typeof prop === 'function',
        };

        static defaultProps = {
            notify: () => {},
        };

        static contextType = TranslateContext;

        state = {
            loading: false,
            init: true,
        };

        componentDidMount() {
            const { notify } = this.props;
            this.timer = setTimeout(() => {
                this.setState({ loading: true });
            }, 200);
            api.getAppConfig()
                .then(() =>
                    this.setState({ loading: false, init: false }, () => {
                        clearTimeout(this.timer);
                    })
                )
                .catch((e) => {
                    clearTimeout(this.timer);
                    if (!api.isCancel(e)) {
                        this.setState({ loading: false });
                        if (e.response && e.response.status === 401) {
                            notify({
                                type: 'danger',
                                message: 'Unauthorized. Invalid credentials.',
                            });
                        } else {
                            notify({
                                type: 'danger',
                                message: 'Config: Unexpected error occurred.',
                            });
                        }
                    }
                });
        }

        render() {
            const { loading, init } = this.state;
            const translate = this.context;

            if (loading) {
                return (
                    <Box extraClasses="rv-text-center">
                        <Spinner
                            message={translate(
                                'with-app-config.application-loading'
                            )}
                        />
                    </Box>
                );
            }
            if (!init) {
                return <Component {...this.props} />;
            }
            return null;
        }
    };
}
