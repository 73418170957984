import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, Text, Button, Tooltip } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconStar,
    IconStarSolid,
    IconEdit,
    IconArrowUp,
    IconArrowDown,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../System/Translations';

import { formatDate, getUtcOffsetText } from '../../../util/date';
import useHighlightComment from '../../CommentsHooks/useHighlightComment';
import Attachment from './Attachment';
import History from './History';

const COMMENT_DATE_FORMAT = 'MMMM Do YYYY. HH:mm';
export default function Comment(props) {
    const { comment, onEditModeEnter, isEditMode, notify } = props;
    const [showHistory, setShowHistory] = useState(false);

    const utcText = getUtcOffsetText(new Date());
    const translate = useContext(TranslateContext);
    const updated = comment.createdAt !== comment.updatedAt;
    const updatedBy = comment.updatedBy ? `, by: ${comment.updatedBy}` : '';
    const tooltipContent = `edited at: ${formatDate(
        comment.updatedAt,
        COMMENT_DATE_FORMAT
    )}${updatedBy}`;

    // Highlight comment
    const { error, saving, highlightUpdated, highlightComment } =
        useHighlightComment(comment.id, comment.highlighted);

    const onHistoryModeEnter = () => {
        setShowHistory(!showHistory);
    };

    const onHighlight = () => {
        if (!saving) {
            highlightComment(comment.highlighted);
        }
    };

    if (error) {
        notify({
            type: 'error',
            message: translate('comments.error') + error.toString(),
        });
    }

    const commentClassName = (isHighlighted) =>
        classNames('comment-wrapper', {
            'comment-wrapper--highlighted': isHighlighted,
        });

    return (
        <div className={commentClassName(highlightUpdated)}>
            <Heading size={4} extraClasses="single-comment__author">
                {comment.author}
                <time className="single-comment__date">
                    {updated ? (
                        <>
                            <Tooltip
                                id={`tooltip-${comment.id}`}
                                openOnHover
                                position="top"
                                content={tooltipContent}
                            >
                                <span className="comments-action">
                                    ({translate('comment.edited')}){' '}
                                    {formatDate(
                                        comment.createdAt,
                                        COMMENT_DATE_FORMAT
                                    )}
                                </span>
                            </Tooltip>
                        </>
                    ) : (
                        formatDate(comment.createdAt, COMMENT_DATE_FORMAT) +
                        utcText
                    )}
                </time>
            </Heading>
            {!isEditMode && (
                <>
                    <div className="single-comment__body">
                        {comment.content
                            .replace(/\n{1,}/gm, '\n')
                            .split('\n')
                            .map((line, index) => (
                                <Text key={`${line}-${index}`}>{line}</Text>
                            ))}
                    </div>
                    <div className="single-comment__controls">
                        <div className="single-comment__controls-edits">
                            {updated && (
                                <Button
                                    link
                                    Elem="a"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        onHistoryModeEnter(comment.id)
                                    }
                                >
                                    {showHistory
                                        ? translate('comment.hide-changes')
                                        : translate('comment.all-changes')}
                                    <Icon
                                        appearance="primary"
                                        InlineIcon={
                                            showHistory
                                                ? IconArrowUp
                                                : IconArrowDown
                                        }
                                    />
                                </Button>
                            )}
                        </div>

                        <div className="single-comment__controls-btns">
                            <Button
                                link
                                Elem="a"
                                rel="noopener noreferrer"
                                onClick={() => onEditModeEnter(comment.id)}
                            >
                                {translate('comment.edit')}
                                <Icon InlineIcon={IconEdit} />
                            </Button>
                            <Button
                                link
                                Elem="a"
                                rel="noopener noreferrer"
                                disabled={saving}
                                onClick={() => onHighlight(comment.id)}
                            >
                                <Icon
                                    InlineIcon={
                                        highlightUpdated
                                            ? IconStarSolid
                                            : IconStar
                                    }
                                    extraClasses={
                                        highlightUpdated ? 'highlighted' : ''
                                    }
                                />
                            </Button>
                        </div>
                        {/*
                            HIDE UNTILL NEXT ITERATION
                            {comment.attachments && comment.attachments.length > 0 && (
                                <Button link Elem="a" rel="noopener noreferrer">
                                    {translate('comment.download')}
                                    <Icon InlineIcon={IconDownload} />
                                </Button>
                            )}
                        */}
                    </div>
                    {showHistory && (
                        <div className="single-comment__history">
                            <History
                                notify={notify}
                                commentId={comment.id}
                                format={COMMENT_DATE_FORMAT}
                            />
                        </div>
                    )}
                    {comment.attachments && comment.attachments.length > 0 && (
                        <Attachment attachments={comment.attachments} />
                    )}
                </>
            )}
        </div>
    );
}

Comment.propTypes = {
    comment: PropTypes.shape({
        author: PropTypes.string,
        createdAt: PropTypes.string,
        content: PropTypes.string,
        attachments: PropTypes.array,
    }).isRequired,
    onEditModeEnter: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    notify: PropTypes.func.isRequired,
};
