import { useCallback, useState } from 'react';
import api from '../../api/CommentsBankApi';

export default function useGetComments(referenceIds = []) {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getComments = useCallback(() => {
        setLoading(true);
        return api
            .post('', referenceIds)
            .then((response) => {
                const sortResponse = response.sort((a, b) => {
                    const dateA = new Date(a.createdAt).getTime();
                    const dateB = new Date(b.createdAt).getTime();
                    return dateB - dateA;
                });

                setComments(sortResponse);
                setError(null);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [referenceIds]);

    const reset = () => {
        setComments([]);
        setError(null);
    };

    return {
        comments,
        loading,
        error,
        getComments,
        reset,
    };
}
