import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
    HeaderSubNavigation,
    HeaderUserWidget,
    NavItem,
} from '@flixbus/honeycomb-react';
import { Icon, IconProfile } from '@flixbus/honeycomb-icons-react';
import LangSwitcher from '../LanguageSwitcher';
import Collaboration from '../Collaboration';
import { TranslateContext } from '../System/Translations';
import { getUser, msalInstance } from '../../auth';
import { clientId, uniqueId } from '../../auth/adalConfig';
import USER_RULES, {
    USER_ROLE_TITLES,
    USER_ACTION_TITLES,
} from '../../auth/userRules';

export default function HeaderWidgets() {
    const translate = useContext(TranslateContext);
    const match = useRouteMatch('/ride-view/:id');
    const user = getUser();
    const profileName = user?.profile.name;
    const roles = user?.profile.roles || [];
    const userMenu = () => {
        return roles.reduce((acc, role) => {
            return acc.concat({
                content: (
                    <>
                        <strong>
                            <Icon InlineIcon={IconProfile} />
                            {USER_ROLE_TITLES[role] || role}
                        </strong>
                        {USER_RULES[role]?.length > 0 ? (
                            <>
                                <div>
                                    {translate(
                                        'role-managment.user-menu.actionslist-title'
                                    )}
                                </div>
                                <ul>
                                    {USER_RULES[role].map((rule) => (
                                        <li key={`${role}-${rule}`}>
                                            {USER_ACTION_TITLES[rule] || rule}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <div>
                                {translate(
                                    'role-managment.user-menu.readonly-access'
                                )}
                            </div>
                        )}
                    </>
                ),
                href: '/',
                onClick: (e) => {
                    e.preventDefault();
                },
                key: role,
            });
        }, []);
    };
    const logOutClickHandler = (instance) => {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        const homeAccountId = uniqueId + '.' + clientId;
        const logoutRequest = {
            account: instance.getAccountByHomeId(homeAccountId),
            mainWindowRedirectUri: window.location.origin,
            postLogoutRedirectUri: '/',
        };
        instance.logoutPopup(logoutRequest);
    };
    return (
        <div className="rv-header-widgets-container">
            {match !== null && match.params.id ? (
                <Collaboration id={match.params.id} />
            ) : null}
            <LangSwitcher
                languagesList={translate.langsList}
                language={translate.language}
                setLanguage={translate.setLanguage}
            />
            <HeaderUserWidget
                url=" "
                extraClasses="flix-space-xs-left"
                text={profileName}
                subMenu={
                    <HeaderSubNavigation id="user-menu">
                        {userMenu().map((item) => (
                            <NavItem key={item.key} onClick={item.onClick}>
                                {item.content}
                            </NavItem>
                        ))}
                        <NavItem
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                logOutClickHandler(msalInstance);
                            }}
                        >
                            Logout
                        </NavItem>
                    </HeaderSubNavigation>
                }
            >
                {profileName}
            </HeaderUserWidget>
        </div>
    );
}
