import React from 'react';
import PropTypes from 'prop-types';
import { Button, HeaderBar, Grid, GridCol } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeftL } from '@flixbus/honeycomb-icons-react';
import './PageDialog.css';

export default function PageDialog(props) {
    const { onClose, children, active } = props;

    return (
        active && (
            <div className="flix-page-dialog">
                <HeaderBar extraClasses="flix-page-dialog__header">
                    <Grid>
                        <GridCol push={1} size={3}>
                            <Button
                                link
                                aria-label="Close dialog"
                                onClick={onClose}
                                extraClasses="flix-page-dialog__back"
                            >
                                <Icon
                                    appearance="primary"
                                    InlineIcon={IconArrowLeftL}
                                />
                                Back
                            </Button>
                        </GridCol>
                    </Grid>
                </HeaderBar>
                {children}
            </div>
        )
    );
}

PageDialog.propTypes = {
    active: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
};
PageDialog.defaultProps = {
    active: true,
    onClose: () => {},
    children: [],
};
