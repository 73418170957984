import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconSit, IconSitSolid } from '@flixbus/honeycomb-icons-react';

export default function SegmentSits(props) {
    const { occupied, free } = props;
    return (
        <div className="timeline-segment-seats">
            <div
                className="timeline-segment-seats-row timeline-segment-seats-row--occupied"
                title="occupied"
            >
                <Icon InlineIcon={IconSitSolid} />
                <strong>{occupied}</strong>
            </div>
            <div
                className="timeline-segment-seats-row timeline-segment-seats-row--occupied"
                title="free"
            >
                <Icon InlineIcon={IconSit} />
                <span>{free}</span>
            </div>
        </div>
    );
}
SegmentSits.propTypes = {
    occupied: PropTypes.number,
    free: PropTypes.number,
};
SegmentSits.defaultProps = { occupied: null, free: null };
