import { useState } from 'react';

export default function useAttrSelection() {
    const [attr, setAttr] = useState(undefined);

    const onSet = (name, value) => {
        if (attr === undefined) {
            setAttr({});
        }
        setAttr((state) => {
            if (value === '') {
                const newState = { ...state };
                delete newState[name];
                return newState;
            }
            return { ...state, [name]: value };
        });
    };

    function reset() {
        setAttr(undefined);
    }

    return [attr, onSet, reset];
}
