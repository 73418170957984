import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function OpenTicketsEnabled(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>{translate('open-tickets-enabled')} <strong>{ item.parameters.from.name }</strong></div>
    );
}

OpenTicketsEnabled.propTypes = {
    item: PropTypes.object.isRequired,
};
