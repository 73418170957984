import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@flixbus/honeycomb-react';
import { Icon } from '@flixbus/honeycomb-icons-react';

export default function LicensePlateInput(props) {
    const {
        id,
        value: propValue,
        label,
        placeholder,
        InlineIcon,
        onChange,
    } = props;
    const [value, setValue] = useState(propValue);

    useEffect(() => {
        setValue(propValue);
    }, [propValue, setValue]);

    const onInput = ({ target }) => {
        onChange(target.value);
    };

    return (
        <Input
            id={id}
            iconLeft={<Icon appearance="primary" InlineIcon={InlineIcon} />}
            onChange={onInput}
            label={label}
            value={value}
            placeholder={placeholder}
            autoComplete="off"
        />
    );
}
LicensePlateInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    InlineIcon: PropTypes.func,
    onChange: PropTypes.func,
};
LicensePlateInput.defaultProps = {
    id: '',
    value: '',
    label: '',
    placeholder: '',
    InlineIcon: () => {},
    onChange: () => {},
};
