import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@flixbus/honeycomb-react';
import {
    SegmentSits,
    BikesOccupancy,
    SegmentLockSeats,
    WheelchairsOccupancy
} from './compounds';
import UserCan from '../../../auth/UserCan.js';

import './Segment.scss';

export default function Segment(props) {
    const {
        rideId,
        rideUuid,
        segmentId,
        busIsHere,
        seatsOccupancy,
        bikesOccupancy,
        wheelchairsOccupancy,
        allSegments,
        fetchTimeline,
        rideStatus,
        notify,
        translate
    } = props;
    const segmentCss = classNames('timeline-segment', {
        'timeline-segment--bus-is-here': busIsHere,
    });

    const seatLockEnabledRideStatuses = ['hidden', 'on_sale', 'archived'];
    const canLockSeat = (status) => {
        return seatLockEnabledRideStatuses.includes(status);
    };

    return (
        <div className={segmentCss}>
            <Box extraClasses="timeline-segment-box">
                <div className="timeline-segment-box__title">{translate('segment.title')}</div>
                <SegmentSits
                    occupied={seatsOccupancy.used}
                    free={seatsOccupancy.free}
                />
                <BikesOccupancy
                    occupied={bikesOccupancy.used}
                    free={bikesOccupancy.free}
                />
                <WheelchairsOccupancy
                    occupied={wheelchairsOccupancy.used}
                    free={wheelchairsOccupancy.free}
                />
                <UserCan
                    perform="lock_all_seats"
                    yes={() => (
                        <>
                            <hr />
                            <div className="timeline-segment-box__title">
                                {translate('segment.locking')}
                            </div>
                            <SegmentLockSeats
                                rideId={rideId}
                                rideUuid={rideUuid}
                                segmentId={segmentId}
                                allSegments={allSegments}
                                seatsLocked={seatsOccupancy.locked}
                                bikeSlotsLocked={bikesOccupancy.locked}
                                wheelchairsLocked={wheelchairsOccupancy.locked}
                                totalSeats={seatsOccupancy.total}
                                totalWheelchairs={wheelchairsOccupancy.total}
                                totalBikeSlots={bikesOccupancy.total}
                                fetchTimeline={fetchTimeline}
                                canLockSeat={canLockSeat(rideStatus)}
                                notify={notify}
                            />
                        </>
                    )}
                />
            </Box>
        </div>
    );
}
Segment.propTypes = {
    busIsHere: PropTypes.bool,
    midnightBus: PropTypes.bool,
    seatsOccupancy: PropTypes.object.isRequired,
    bikesOccupancy: PropTypes.object.isRequired,
    wheelchairsOccupancy: PropTypes.object.isRequired,
};
Segment.defaultProps = {
    busIsHere: false,
    midnightBus: false,
    seatsOccupancy: {},
    bikesOccupancy: {},
    wheelchairsOccupancy: {},
};
