import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Icon,
    IconBike,
    IconSeat,
    IconLock,
    IconWheelchair,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../../System/Translations';
import SeatsLockerForm from '../../../../SeatsLocker/SeatsLockerForm';

export default function SegmentLockSeats(props) {
    const {
        seatsLocked,
        bikeSlotsLocked,
        wheelchairsLocked,
        isFormOpen,
        openForm,
        segmentId,
        closeForm,
        allSegments,
        fetchTimeline,
        canLockSeat,
    } = props;
    const translate = useContext(TranslateContext);

    return (
        <div className="timeline-segment-lock-seats">
            {canLockSeat && (
                <>
                    {seatsLocked !== null && (
                        <div className="timeline-segment-seats">
                            <div
                                className="timeline-segment-seats-row timeline-segment-seats-row--locking"
                                title="locked"
                            >
                                <Icon InlineIcon={IconSeat} />
                                <span>{seatsLocked}</span>
                            </div>
                        </div>
                    )}

                    {bikeSlotsLocked !== null && (
                        <div className="timeline-segment-seats">
                            <div
                                className="timeline-segment-seats-row timeline-segment-seats-row--locking"
                                title="locked"
                            >
                                <Icon InlineIcon={IconBike} />
                                <span>{bikeSlotsLocked}</span>
                            </div>
                        </div>
                    )}

                    {wheelchairsLocked !== null && (
                        <div className="timeline-segment-seats">
                            <div
                                className="timeline-segment-seats-row timeline-segment-seats-row--locking"
                                title="locked"
                            >
                                <Icon InlineIcon={IconWheelchair} />
                                <span>{wheelchairsLocked}</span>
                            </div>
                        </div>
                    )}

                    <hr />
                    <Icon
                        size={4}
                        extraClasses="icon-lock"
                        InlineIcon={IconLock}
                        onClick={openForm}
                    />

                    {isFormOpen && (
                        <SeatsLockerForm
                            {...props}
                            segmentId={segmentId}
                            onClose={closeForm}
                            translate={translate}
                            allSegments={allSegments}
                            fetchTimeline={fetchTimeline}
                        />
                    )}
                </>
            )}
        </div>
    );
}
SegmentLockSeats.propTypes = {
    slots: PropTypes.number,
    seats: PropTypes.number,
};
SegmentLockSeats.defaultProps = { slots: null, seats: null };
