import React, { useContext } from 'react';
import {TranslateContext} from "../../../System/Translations";
import {formatDate} from "../../../../util/date";

export default function DelayNotificationScheduled(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('deferred-notification-queued.notification-sending-queued-for-station')}&nbsp;
            <strong>({item.parameters.sourceStation.code}) { item.parameters.sourceStation.shortName } </strong> {translate('deferred-notification-queued.notification-to-send')}&nbsp;
            {translate('deferred-notification-queued.notification-at')} <strong>{ formatDate(item.parameters.scheduledAt) } </strong>
        </div>
    );
}
