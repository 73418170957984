import { useState, useCallback, useEffect, useContext } from 'react';
import { NotifyContext } from '../NotificationSystem/context';
import api from '../../api/Client';

export default function useApi(orderId, downloadHash) {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(false);
    const [pending, setPending] = useState(false);
    const notify = useContext(NotifyContext);
    const url = `/order-info/order/${orderId}/download_hash/${downloadHash}/ticket-link`;
    useEffect(
        () => () => {
            api.cancel(url);
        },
        [url]
    );
    const reset = useCallback(() => {
        setResponse(null);
        setPending(false);
        setError(false);
    }, []);
    const request = useCallback(() => {
        setPending(true);
        api.get(url)
            .then((response) => {
                setResponse(response);
                setPending(false);
                setError(false);
            })
            .catch((error) => {
                if (!api.isCancel(error)) {
                    setError(error);
                    setPending(false);
                    setResponse(null);
                    notify({
                        type: 'danger',
                        message: 'Ticket information does not received',
                    });
                }
            });
    }, [url, notify]);

    return [{ response, error, pending }, request, reset];
}
