import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button } from '@flixbus/honeycomb-react';
import { Icon } from '@flixbus/honeycomb-icons-react';

export default function LanguageSwitcher(props) {
    const { languagesList, language, setLanguage } = props;
    const activeLanguage = languagesList[language];
    const activeLanguageIcon = () => (
        <img
            src={activeLanguage.icon}
            className="language-switcher_img"
            alt={activeLanguage.title}
        />
    );
    const list = Object.values(languagesList).map(
        ({ code, title, icon, shortTitle }) => ({
            text: title,
            href: `/${code}`,
            onClick: (e) => {
                e.preventDefault();
                setLanguage(code);
            },
            InlineIcon: {
                regular: () => (
                    <img
                        src={icon}
                        className="language-switcher_img"
                        alt={shortTitle}
                    />
                ),
                active: () => (
                    <img
                        src={icon}
                        className="language-switcher_img"
                        alt={shortTitle}
                    />
                ),
            },
            activeItem: code === activeLanguage.code,
        })
    );

    return (
        <Dropdown links={list} extraClasses="language-switcher">
            <Button className="language-switcher__button" link>
                <Icon InlineIcon={activeLanguageIcon} />
                &nbsp;
                {activeLanguage.shortTitle}
            </Button>
        </Dropdown>
    );
}

LanguageSwitcher.propTypes = {
    languagesList: PropTypes.shape({
        code: PropTypes.string,
        shortTitle: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
    language: PropTypes.string.isRequired,
    setLanguage: PropTypes.func.isRequired,
};
