import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconLockSolid } from '@flixbus/honeycomb-icons-react';
import api from '../../../api/Client';
import { TranslateContext } from '../../System/Translations';
import { NotifyContext } from '../../NotificationSystem/context';

const LOCK_CANCEL_TOKEN = 'ALL_CAPACITY_LOCK';
const UNLOCK_CANCEL_TOKEN = 'ALL_CAPACITY_ULOCK';

export default function LockUnlockAllCapacity(props) {
    const { rideUuid } = props;
    const [lockLoading, setLockLoading] = useState(false);
    const [unLockLoading, setUnLockLoading] = useState(false);

    const translate = useContext(TranslateContext);
    const notify = useContext(NotifyContext);

    const lockBtnLabel = lockLoading
        ? translate('with-comments-api.loading')
        : translate('toolbar.lock_all_capacity');
    const unLockBtnLabel = unLockLoading
        ? translate('with-comments-api.loading')
        : translate('toolbar.unlock_all_capacity');

    function lock() {
        setLockLoading(true);

        api.post(`/ride/${rideUuid}/seat-lock/lock-all`, {
            cancelTokenId: LOCK_CANCEL_TOKEN,
        })
            .then(() => {
                setLockLoading(false);

                notify({
                    type: 'success',
                    message: translate('notify.lock_all_capacity_success'),
                });
            })
            .catch((e) => {
                if (!api.isCancel(e)) {
                    notify({
                        type: 'danger',
                        message: e.toString(),
                    });
                }
            });
    }

    function unLock() {
        setUnLockLoading(true);

        api.post(`/ride/${rideUuid}/seat-lock/unlock-all`, {
            cancelTokenId: UNLOCK_CANCEL_TOKEN,
        })
            .then(() => {
                setUnLockLoading(false);
                notify({
                    type: 'success',
                    message: translate('notify.unlock_all_capacity_success'),
                });
            })
            .catch((e) => {
                if (!api.isCancel(e)) {
                    notify({
                        type: 'danger',
                        message: e.toString(),
                    });
                }
            });
    }

    useEffect(
        () => () => {
            api.cancel(LOCK_CANCEL_TOKEN);
            api.cancel(UNLOCK_CANCEL_TOKEN);
        },
        []
    );

    return (
        <>
            <Button
                appearance="secondary"
                onClick={lock}
                loading={lockLoading}
                extraClasses="lock-capacity"
                link
            >
                <Icon appearance="primary" InlineIcon={IconLockSolid} />
                &nbsp;
                {lockBtnLabel}
            </Button>
            <Button
                appearance="secondary"
                onClick={unLock}
                loading={unLockLoading}
                extraClasses="lock-capacity"
                link
            >
                <Icon appearance="primary" InlineIcon={IconLockSolid} />
                &nbsp;
                {unLockBtnLabel}
            </Button>
        </>
    );
}

LockUnlockAllCapacity.propTypes = {
    rideUuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
};
