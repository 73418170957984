import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Spinner } from '@flixbus/honeycomb-react';
import { Icon } from '@flixbus/honeycomb-icons-react';
import { ReactComponent as PhoneHandset } from '../ReservationsTable/components/phone-handset.svg';
import DataGrid, {
    DataHeaderGroup,
    DataRowGroup,
    DataRow,
    DataCol,
} from '../UI/DataGrid';
import { useDriver } from './hooks';

const DRIVER_ROLES = {
    main_driver: 'Main driver',
    sub_driver: 'Sub driver',
    office_employee: 'Office employee',
};

export default function DriverWidget({ rideUuid, translate }) {
    const [response, request] = useDriver(rideUuid);
    const { pending, data } = response;
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        request();
    }, [request]);

    useEffect(() => {
        if (data) {
            const dataArray = Object.values(data);
            setDrivers(dataArray);
        }
    }, [data]);

    return (
        <Box>
            <Heading size={3}>{translate('driver.widget.title')}</Heading>
            {pending ? <Spinner size="md" /> : null}
            {drivers?.length > 0 ? (
                <DataGrid>
                    <DataHeaderGroup>
                        <DataRow header>
                            <DataCol>{translate('driver.widget.name')}</DataCol>
                            <DataCol>{translate('driver.widget.role')}</DataCol>
                            <DataCol>
                                {translate('driver.widget.phone')}
                            </DataCol>
                            <DataCol>
                                {translate('driver.widget.source')}
                            </DataCol>
                        </DataRow>
                    </DataHeaderGroup>

                    {drivers?.map((item) => (
                        <DataRowGroup key={item.phoneNumber + item.name}>
                            <DataRow>
                                <DataCol>{item.name}</DataCol>
                                <DataCol className="driver-role">
                                    {DRIVER_ROLES[item.role] ||
                                        item.role ||
                                        ' '}
                                </DataCol>
                                <DataCol>
                                    {item.phoneNumber ? (
                                        <span className="reservation-passenger-phone">
                                            <Icon
                                                InlineIcon={() => (
                                                    <PhoneHandset />
                                                )}
                                            />
                                            {item.phoneNumber}
                                        </span>
                                    ) : (
                                        ' '
                                    )}
                                </DataCol>
                                <DataCol>{item.source || ' '}</DataCol>
                            </DataRow>
                        </DataRowGroup>
                    ))}
                </DataGrid>
            ) : (
                translate('driver.widget.no_data')
            )}
        </Box>
    );
}

DriverWidget.propTypes = {
    rideUuid: PropTypes.string.isRequired,
    translate: PropTypes.func,
};

DriverWidget.defaultProps = {
    translate: (key) => key,
};
