import React from 'react';
import classNames from 'classnames';

export default function DataRowGroup(props) {
    const gridClassNames = classNames('flix-data-grid-row-group', {
        [props.className]: props.className,
    });
    return (
        <tbody {...props} className={gridClassNames}>
            {props.children}
        </tbody>
    );
}
