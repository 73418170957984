import { withStateHandlers } from 'recompose';

const onInputChange = (e) => {
    if (e.target.value.startsWith('0')) {
        e.target.value = e.target.value.slice(1);
    }
};

/*
Enhancer adds handlers state to control inputs
for seats and slots amount to lock
*/
export default withStateHandlers(
    (props) => ({
        seatsLocked: props.seatsLocked,
        bikeSlotsLocked: props.bikeSlotsLocked,
        wheelchairsLocked: props.wheelchairsLocked,
    }),
    {
        onSeatsChange: () => (e) => {
            onInputChange(e);
            return { seatsLocked: Number(e.target.value) };
        },
        onBikeChange: () => (e) => {
            onInputChange(e);
            return {
                bikeSlotsLocked: Number(e.target.value),
            };
        },
        onWheelchairChange: () => (e) => {
            onInputChange(e);
            return {
                wheelchairsLocked: Number(e.target.value),
            };
        },
        setMaxBikes: () => (maxNumber) => ({ bikeSlotsLocked: maxNumber }),
        setMaxSeats: () => (maxNumber) => ({ seatsLocked: maxNumber }),
        setMaxWheelchairs: () => (maxNumber) => ({
            wheelchairsLocked: maxNumber,
        }),
    }
);
