import React from 'react';
import PropTypes from 'prop-types';

export default function withPaxSorting(Component) {
    return class WithPaxSorting extends React.Component {
        /**
         * @prop {object} sortMethods - object with set of sorting methods
         */
        sortMethods = {
            /**
             * methods sorts reservation orders with specify order
             * @param {string} order - sorting order DESC|ASC
             * @return {array} - sorted orders
             */
            'order.passenger.is_unattended_child': (order) => {
                const { orders } = this.props;
                const sortedOrders = orders.sort((orderA, orderB) => {
                    // set if any of passengers in this order is unattended child
                    const ucA = orderA.passengers.reduce((flag, passenger) => {
                        if (!flag) {
                            return Number(passenger.is_unattended_child);
                        }
                        return 0;
                    }, 0);
                    // set if any of passengers in this order is unattended child
                    const ucB = orderB.passengers.reduce((flag, passenger) => {
                        if (!flag) {
                            return Number(passenger.is_unattended_child);
                        }
                        return 0;
                    }, 0);
                    return order === 'DESC' ? ucA - ucB : ucB - ucA;
                });
                return sortedOrders;
            },
            'order.passenger.checkin_status': (order) => {
                const { orders } = this.props;
                /**
                 * Iterator for reduce method of Array to get number of passengers
                 * per each status ('checked', 'unchecked', 'absent', etc.)
                 */
                const statusIterator = (status, passenger) => {
                    if (status[passenger.checkin_status]) {
                        status[passenger.checkin_status]++;
                    } else {
                        status[passenger.checkin_status] = 1;
                    }
                    return status;
                };
                /**
                 * Iterator for reduce method of Array to get string with status
                 * that has maximum amount of passengers for current order
                 */
                const sumStatusIterator =
                    (statusStorage) => (statusKey, key) => {
                        if (!statusKey) {
                            return key;
                        }
                        if (statusStorage[key] > statusStorage[statusKey]) {
                            return key;
                        }
                        return statusKey;
                    };
                const sortedOrders = orders.sort((orderA, orderB) => {
                    // counts status per passenger
                    const statusA = orderA.passengers.reduce(
                        statusIterator,
                        {}
                    );
                    const statusB = orderB.passengers.reduce(
                        statusIterator,
                        {}
                    );

                    // get status with maximum amount passengers for order
                    const sumA = Object.keys(statusA).reduce(
                        sumStatusIterator(statusA),
                        ''
                    );
                    const sumB = Object.keys(statusB).reduce(
                        sumStatusIterator(statusB),
                        ''
                    );

                    const result = sumA.localeCompare(sumB);
                    return order === 'DESC' ? result : -result;
                });
                return sortedOrders;
            },
            'order.route': (order) => {
                const { orders } = this.props;
                const sortedOrders = orders.sort((orderA, orderB) => {
                    // create full route string
                    const routeA = `${orderA.route.from_code} ${orderA.route.to_code}`;
                    const routeB = `${orderB.route.from_code} ${orderB.route.to_code}`;

                    const result = routeA.localeCompare(routeB);
                    return order === 'DESC' ? result : -result;
                });
                return sortedOrders;
            },
            'order.extras': (order) => {
                const { orders } = this.props;
                /**
                 * Iterator for reduce method of Array to get string
                 * with list of extras properties for further sorting
                 */
                const extrasIterator = (extrasObject) => (extras, key) => {
                    if (extrasObject[key] > 0) {
                        return extras ? `${extras} ${key}` : key;
                    }
                    return extras;
                };
                const sortedOrders = orders.sort((orderA, orderB) => {
                    // get string with extras properties
                    const extrasA = Object.keys(orderA.extras).reduce(
                        extrasIterator(orderA.extras),
                        ''
                    );
                    const extrasB = Object.keys(orderB.extras).reduce(
                        extrasIterator(orderB.extras),
                        ''
                    );

                    const result = extrasA.localeCompare(extrasB);
                    return order === 'DESC' ? result : -result;
                });
                return sortedOrders;
            },
            'order.language': (order) => {
                const { orders } = this.props;
                const sortedOrders = orders.sort((orderA, orderB) => {
                    const langA = orderA.language;
                    const langB = orderB.language;

                    const result = langA.localeCompare(langB);
                    return order === 'DESC' ? result : -result;
                });
                return sortedOrders;
            },
            'order.shop': (order) => {
                const { orders } = this.props;
                const sortedOrders = orders.sort((orderA, orderB) => {
                    const shopA = orderA.shop;
                    const shopB = orderB.shop;

                    const result = shopA.localeCompare(shopB);
                    return order === 'DESC' ? result : -result;
                });
                return sortedOrders;
            },
            'order.byLanguage': (order) => {
                const { orders } = this.props;

                const sortedOrders = orders.reduce((total, cur) => {
                    if (cur.language === order) {
                        total.unshift(cur);
                    } else {
                        total.push(cur);
                    }

                    return total;
                }, []);

                return sortedOrders;
            },
            'order.passenger.seats_number': (order) => {
                const { orders } = this.props;

                const symbolAndNumberSort = (a, b) => {
                    let result = 0;

                    const seatA = a.seat_type && a.seat_type.seat_label;
                    const seatB = b.seat_type && b.seat_type.seat_label;

                    // if seats are not blank
                    if (seatA || seatB) {
                        // extract number from seat label
                        const numberA =
                            seatA.match(/(\d|,)+/g) &&
                            seatA.match(/(\d|,)+/g).pop();
                        const numberB =
                            seatB.match(/(\d|,)+/g) &&
                            seatB.match(/(\d|,)+/g).pop();

                        // extract symbol from seat label
                        const symbolA = seatA.replace(numberA, '');
                        const symbolB = seatB.replace(numberB, '');

                        // apply different sort algorhytms depending on the value type
                        if (symbolA || symbolB) {
                            result = new Intl.Collator('en', {
                                numeric: true,
                                sensitivity: 'accent',
                            }).compare(seatA, seatB);
                        } else {
                            result = numberA - numberB;
                        }

                        return order === 'ASC' ? result : -result;
                    }

                    return result;
                };

                const sortedOrders = orders.sort((orderA, orderB) => {
                    // Sort passengers per order first
                    if (orderA.passengers.length > 1) {
                        orderA.passengers.sort((a, b) =>
                            symbolAndNumberSort(a, b)
                        );
                    }

                    // First element of the array is the lowest seat value already
                    const paxA =
                        orderA.passengers.length && orderA.passengers[0];
                    const paxB =
                        orderB.passengers.length && orderB.passengers[0];

                    return symbolAndNumberSort(paxA, paxB);
                });

                return sortedOrders;
            },
            'order.passenger.deck_number': (order) => {
                const { orders } = this.props;

                const numberSort = (a, b) => {
                    let result = 0;

                    const deckA = a.seat_type && a.seat_type.deck;
                    const deckB = b.seat_type && b.seat_type.deck;

                    // if decks are not blank
                    if (deckA || deckB) {
                        // apply different sort algorhytms depending on the value type
                        result = deckA - deckB;

                        return order === 'ASC' ? result : -result;
                    }

                    return result;
                };

                const sortedOrders = orders.sort((orderA, orderB) => {
                    // Sort passengers per order first
                    if (orderA.passengers.length > 1) {
                        orderA.passengers.sort((a, b) => numberSort(a, b));
                    }

                    // First element of the array is the lowest seat value already
                    const paxA =
                        orderA.passengers.length && orderA.passengers[0];
                    const paxB =
                        orderB.passengers.length && orderB.passengers[0];

                    return numberSort(paxA, paxB);
                });

                return sortedOrders;
            },
            'order.passengers.interconnection': (order) => {
                const { orders } = this.props;

                const icSort = (a, b) => {
                    const interconnectionInfoA =
                        a.passengers[0]?.interconnection_info || {};
                    const interconnectionInfoB =
                        b.passengers[0]?.interconnection_info || {};
                    const rideUidAFrom =
                        interconnectionInfoA.from?.ride_uid || '';
                    const rideUidBFrom =
                        interconnectionInfoB.from?.ride_uid || '';
                    const rideUidATo = interconnectionInfoA.to?.ride_uid || '';
                    const rideUidBTo = interconnectionInfoB.to?.ride_uid || '';

                    if (
                        Object.keys(interconnectionInfoA).length !==
                        Object.keys(interconnectionInfoB).length
                    ) {
                        return order === 'ASC'
                            ? Object.keys(interconnectionInfoB).length -
                                  Object.keys(interconnectionInfoA).length
                            : Object.keys(interconnectionInfoA).length -
                                  Object.keys(interconnectionInfoB).length;
                    }

                    const compareFrom =
                        rideUidAFrom.localeCompare(rideUidBFrom);
                    return order === 'ASC'
                        ? -(compareFrom || rideUidATo.localeCompare(rideUidBTo))
                        : compareFrom || rideUidATo.localeCompare(rideUidBTo);
                };

                const sortedOrders = orders.sort(icSort);

                return sortedOrders;
            },
            'order.created_at': (order) => {
                const { orders } = this.props;
                const sortedOrders = orders.sort((orderA, orderB) => {
                    const dateA = new Date(orderA.created_at);
                    const dateB = new Date(orderB.created_at);

                    const result = dateA - dateB;
                    return order === 'DESC' ? result : -result;
                });
                return sortedOrders;
            },
        };

        static propTypes = {
            orders: PropTypes.array,
        };

        static defaultProps = {
            orders: [],
        };

        state = {
            activeSort: {
                method: '',
                order: 'ASC',
            },
        };

        /**
         * sorting handler that will pass with props,
         * sets state with sorted orders
         * @method sortBy
         * @param {string} sortMethod - sorting method corresponding to data property sort by
         * @param {string} order - order for sorting DESC|ASC
         */
        sortBy = (sortMethod, order) => {
            this.setState({
                activeSort: { method: sortMethod, order },
            });
        };

        render() {
            const { activeSort } = this.state;
            let { orders } = this.props;
            if (activeSort.method) {
                orders = this.sortMethods[activeSort.method](activeSort.order);
            }
            return (
                <Component
                    {...this.props}
                    orders={orders}
                    sortBy={this.sortBy}
                    activeSort={activeSort}
                />
            );
        }
    };
}
