import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    Legend,
    Fieldset,
    FormRow,
    Checkbox,
    Button,
    Heading,
} from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';
import { DEFAULT_FILTERS } from './default-filters-list';

const filtersCategories = () => {
    const categories = {};
    Object.keys(DEFAULT_FILTERS).forEach((key) => {
        categories[key] = false;
    });

    return categories;
};
function LogsFilters(props) {
    const { onChange, onSelectCategory, filters, clearFilters } = props;
    const [selectedCatsFilters, setSelectedCatsFilters] = useState(
        filtersCategories()
    );
    const isFilterChecked = (property, value) => {
        return filters[property]
            ? filters[property].indexOf(value) !== -1
            : false;
    };
    const translate = useContext(TranslateContext);
    const isCategoryFilterChecked = (category) => {
        return selectedCatsFilters[category];
    };
    const handleSelectCategory = (category) => {
        const newCats = {
            ...selectedCatsFilters,
            ...{ [category]: !selectedCatsFilters[category] },
        };
        const select = !isCategoryFilterChecked(category);
        setSelectedCatsFilters(newCats);
        onSelectCategory(select, DEFAULT_FILTERS[category]);
    };
    const handleClearFilters = () => {
        setSelectedCatsFilters(filtersCategories());
        clearFilters();
    };

    return (
        <form className="filters-set" name="filters">
            <Heading size={3} sectionHeader>
                {translate('logs-filters.heading')}
            </Heading>
            <FormRow>
                <Heading size={4} sectionHeader>
                    {translate('logs-filters.action-types')}
                </Heading>
                {Object.keys(DEFAULT_FILTERS).map((key, idx) => {
                    const title = (
                        <Legend className="filters-set__category">
                            <Checkbox
                                label={translate(
                                    `logs-filters.${key.replace(/_/g, '-')}`
                                )}
                                value=""
                                onChange={() => handleSelectCategory(key)}
                                id={`select-all-${key}`}
                                checked={isCategoryFilterChecked(key)}
                                small
                            />
                        </Legend>
                    );
                    return (
                        <Accordion title={title} key={`${key}_${idx}`}>
                            <Fieldset>
                                {Object.values(DEFAULT_FILTERS[key]).map(
                                    (filter, idx) => (
                                        <Checkbox
                                            label={translate(
                                                `logs-filters.${filter.replace(
                                                    /_/g,
                                                    '-'
                                                )}`
                                            )}
                                            key={`${key}_${filter}_${idx}`}
                                            name="action"
                                            value={filter}
                                            onChange={onChange}
                                            id={filter}
                                            checked={isFilterChecked(
                                                'action',
                                                filter
                                            )}
                                            small
                                        />
                                    )
                                )}
                            </Fieldset>
                        </Accordion>
                    );
                })}
            </FormRow>
            <FormRow last>
                <Button
                    type="button"
                    onClick={handleClearFilters}
                    appearance="secondary"
                >
                    {translate('logs-filters.clear-filters')}
                </Button>
            </FormRow>
        </form>
    );
}

LogsFilters.propTypes = {
    filters: PropTypes.shape({
        action: PropTypes.array,
    }),
    onChange: PropTypes.func.isRequired,
    onSelectCategory: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
};

LogsFilters.defaultProps = {
    filters: {
        action: [],
    },
};

export default LogsFilters;
