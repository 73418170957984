import moment from 'moment/moment';
import classNames from 'classnames';
import {
    Button,
    FormRow,
    Checkbox,
    Heading,
    Text,
    Input,
    Spinner,
    Tooltip,
} from '@flixbus/honeycomb-react';
import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { addTime, formatDate } from '../../../util/date';
import { calculateEta } from '../../../util/calculateEta';
import { TranslateContext } from '../../System/Translations';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const TIME_FORMAT = 'HH:mm';

export default function HadForm({
    id,
    had,
    arrivalDelay,
    departureDelay,
    departureText,
    stopUuid,
    allToNextStopsIds,
    extendDelay,
    removeDelaysToTheRest,
    removeDelay,
    setStopDelay,
    applyStopDelay,
    hadSavePending,
    getPrevStopDelay,
    isDisabledHAD,
    allToNextSegments,
    setAllToNextSegments,
}) {
    const translate = useContext(TranslateContext);
    const tooltipContent = () => (
        <Text extraClasses="flix-has-text-centered flix-space-flush-bottom">
            {translate('had.delay_overlap_tooltip')}
        </Text>
    );

    const stop = had.form.stops[stopUuid];

    const addToTime = useCallback((delay, time) => {
        return addTime(delay, 'minutes', TIME_FORMAT, time, true);
    }, []);
    const getDifferenceInMinutes = useCallback((dateOne, dateTwo) => {
        const date1 = formatDate.parseZone(dateOne, DATE_FORMAT, true);
        const date2 = formatDate.parseZone(dateTwo, DATE_FORMAT, true);

        const date1Momented = moment(date1);
        const date2Momented = moment(date2);

        return date2Momented.diff(date1Momented, 'minutes');
    }, []);
    const getMinutesFromValue = useCallback((date, value) => {
        let isNextDay = false;
        const formatted = formatDate.parseZone(date, 'YYYY-MM-DD', true);
        const stop = formatDate.parseZone(date, 'YYYY-MM-DD HH:mm', true);
        const time = formatDate.parseZone(date, 'HH:mm', true);

        if (time > value) {
            isNextDay = true;
        }

        const newDate = formatDate.parseZone(
            formatted + ' ' + value,
            'YYYY-MM-DD HH:mm',
            true
        );

        const stopMomented = moment(stop);
        const timeAndDateMomented = isNextDay
            ? moment(newDate).add(1, 'days')
            : moment(newDate);

        return {
            value: timeAndDateMomented.diff(stopMomented, 'minutes'),
            time: formatDate.parseZone(value, 'HH:mm', true),
            isNextDay,
        };
    }, []);

    const arrDelay = Math.round(arrivalDelay / 60);
    const arrDelayInMinutes =
        stop.arrival &&
        getDifferenceInMinutes(
            stop.arrival,
            calculateEta(stop.arrival, arrivalDelay)
        );
    const arrDelayInTime = stop.arrival && addToTime(arrDelay, stop.arrival);

    const depDelay = Math.round(departureDelay / 60);
    const depDelayInMinutes =
        stop.departure &&
        getDifferenceInMinutes(
            stop.departure,
            calculateEta(stop.departure, departureDelay)
        );
    const depDelayInTime =
        stop.departure && addToTime(depDelay, stop.departure);

    const [arrivalDelayMin, setArrivalDelayMin] = useState(arrDelayInMinutes);
    const [departureDelayMin, setDepartureDelayMin] =
        useState(depDelayInMinutes);

    const [arrivalDelayTimeText, setArrivalDelayTimeText] =
        useState(arrDelayInTime);
    const [departureDelayTimeText, setDepartureDelayTimeText] =
        useState(depDelayInTime);

    const [, setArrivalDelayOnNextDay] = useState(false);
    const [isDepartureDelayOnNextDay, setDepartureDelayOnNextDay] =
        useState(false);

    const isArrivalValid = () => {
        if (
            (!getPrevStopDelay()?.departureDelay &&
                !getPrevStopDelay()?.item?.departure_delay) ||
            isDisabledHAD
        ) {
            return true;
        }

        const { item, departureDelay } = getPrevStopDelay();
        const prevStopDepartureDateAndDelay = moment(
            calculateEta(item.departure, departureDelay)
        );
        const stopArrivalDateAndDelay = moment(
            calculateEta(stop.arrival, arrivalDelayMin * 60)
        );

        return prevStopDepartureDateAndDelay < stopArrivalDateAndDelay;
    };
    const isDepartureValid = () => {
        if (!stop.arrival || !stop.departure || isDisabledHAD) return true;

        if (departureDelayTimeText >= arrivalDelayTimeText) {
            return true;
        } else if (
            departureDelayTimeText < arrivalDelayTimeText &&
            isDepartureDelayOnNextDay
        ) {
            return true;
        } else {
            return false;
        }
    };

    const onArrivalChange = (value) => {
        setArrivalDelayTimeText(value);

        let newArrivalDelayMin = getMinutesFromValue(stop.arrival, value);

        setArrivalDelayMin(Math.round(newArrivalDelayMin.value));
        setArrivalDelayOnNextDay(newArrivalDelayMin.isNextDay);
        setStopDelay(newArrivalDelayMin.value * 60, departureDelayMin * 60);
    };
    const onArrivalMinChange = (value) => {
        if (value >= 0) {
            const stopArrivalDateAndDelay = moment(
                calculateEta(stop.arrival, value * 60)
            );
            const time = formatDate.parseZone(
                stopArrivalDateAndDelay,
                'HH:mm',
                true
            );

            onArrivalChange(time);
        }
    };
    const onDepartureChange = (value) => {
        setDepartureDelayTimeText(value);

        let newDepartureDelayMin = getMinutesFromValue(stop.departure, value);

        if (departureText > value && !newDepartureDelayMin.isNextDay) {
            newDepartureDelayMin.value = 0;
        }

        setDepartureDelayMin(Math.round(newDepartureDelayMin.value));
        setDepartureDelayOnNextDay(newDepartureDelayMin.isNextDay);
        setStopDelay(arrivalDelayMin * 60, newDepartureDelayMin.value * 60);
    };
    const onDepartureMinChange = (value) => {
        if (value >= 0) {
            const stopDepartureDateAndDelay = moment(
                calculateEta(stop.departure, value * 60)
            );
            const time = formatDate.parseZone(
                stopDepartureDateAndDelay,
                'HH:mm',
                true
            );

            onDepartureChange(time);
        }
    };

    const toggleAllToNextSegments = () => {
        const nextValue = !allToNextSegments;
        setAllToNextSegments(nextValue, id);
    };

    const onAddHandler = () => {
        if (allToNextSegments) {
            extendDelay(id);
        }

        applyStopDelay();

        if (allToNextSegments) {
            toggleAllToNextSegments();
        }

        had.setOpen(false);
    };
    const onRemoveHandler = () => {
        if (allToNextSegments) {
            removeDelaysToTheRest(allToNextStopsIds);
        } else {
            removeDelay(id);
        }

        applyStopDelay(true);

        if (allToNextSegments) {
            toggleAllToNextSegments();
        }

        had.setOpen(false);
    };

    const hadFormCss = classNames('timeline-station-had-form', {
        'timeline-station-had-form--disabled': isDisabledHAD || hadSavePending,
    });

    useEffect(() => {
        const arrivalDelayInMinutes =
            arrivalDelay &&
            getDifferenceInMinutes(
                stop.arrival,
                calculateEta(stop.arrival, arrivalDelay)
            );

        setArrivalDelayMin(arrivalDelayInMinutes);
    }, [arrivalDelay, stop.arrival, addToTime, getDifferenceInMinutes]);

    useEffect(() => {
        const departureDelayInMinutes =
            departureDelay &&
            getDifferenceInMinutes(
                stop.departure,
                calculateEta(stop.departure, departureDelay)
            );

        setDepartureDelayMin(departureDelayInMinutes);
    }, [departureDelay, stop.departure, id, addToTime, getDifferenceInMinutes]);

    return (
        <>
            {had.isOpen && (
                <div className={hadFormCss}>
                    <Heading
                        size={4}
                        flushSpace
                        extraClasses="timeline-station-had-form__title"
                    >
                        <span>{translate('had.manual-delays')}</span>
                        <Icon
                            InlineIcon={IconClose}
                            onClick={() => {
                                had.setOpen(false);
                            }}
                        />
                    </Heading>
                    <div className="flix-space-xs-bottom">
                        {stop.arrival && (
                            <FormRow
                                last
                                extraClasses="flix-space-xs-bottom timeline-station-had-form__input-wrapper"
                            >
                                <Input
                                    label={translate('had.arr-in-time')}
                                    type="time"
                                    step="60"
                                    id={`${stopUuid}-arr-mins`}
                                    value={arrivalDelayTimeText}
                                    onChange={({ target }) => {
                                        onArrivalChange(target.value);
                                    }}
                                    disabled={isDisabledHAD || hadSavePending}
                                    extraClasses={classNames(
                                        'timeline-station-had-form__input',
                                        {
                                            'timeline-station-had-form__input--warning':
                                                !isArrivalValid(),
                                        }
                                    )}
                                />
                                <Input
                                    label={translate('had.arr-in-min')}
                                    type="number"
                                    step="1"
                                    id={`${stopUuid}-arr-mins`}
                                    value={arrivalDelayMin}
                                    onChange={({ target }) => {
                                        onArrivalMinChange(target.value);
                                    }}
                                    disabled={isDisabledHAD || hadSavePending}
                                    extraClasses={classNames(
                                        'timeline-station-had-form__min-input',
                                        {
                                            'timeline-station-had-form__min-input--warning':
                                                !isArrivalValid(),
                                        }
                                    )}
                                />
                            </FormRow>
                        )}
                        {!isArrivalValid() && (
                            <span className="timeline-station-had-form__desc">
                                {translate('had.delay_conflict')}
                            </span>
                        )}
                    </div>
                    <div>
                        {stop.departure && (
                            <FormRow
                                last
                                extraClasses="timeline-station-had-form__input-wrapper"
                            >
                                <Input
                                    label={translate('had.dep-in-time')}
                                    type="time"
                                    step="60"
                                    id={`${stopUuid}-dep-time`}
                                    value={departureDelayTimeText}
                                    onChange={({ target }) => {
                                        onDepartureChange(target.value);
                                    }}
                                    disabled={isDisabledHAD || hadSavePending}
                                    extraClasses={classNames(
                                        'timeline-station-had-form__input',
                                        {
                                            'timeline-station-had-form__input--warning':
                                                !isDepartureValid(),
                                        }
                                    )}
                                />
                                <Input
                                    label={translate('had.dep-in-min')}
                                    type="number"
                                    step="1"
                                    id={`${stopUuid}-dep-mins`}
                                    value={departureDelayMin}
                                    onChange={({ target }) => {
                                        onDepartureMinChange(target.value);
                                    }}
                                    disabled={isDisabledHAD || hadSavePending}
                                    extraClasses={classNames(
                                        'timeline-station-had-form__min-input',
                                        {
                                            'timeline-station-had-form__min-input--warning':
                                                !isDepartureValid(),
                                        }
                                    )}
                                />
                            </FormRow>
                        )}
                        {!isDepartureValid() && (
                            <span className="timeline-station-had-form__desc">
                                {translate('had.delay_conflict')}
                            </span>
                        )}
                    </div>
                    <FormRow last className="timeline-station-had-form__action">
                        <div className="timeline-station-had-form__bulk">
                            <Checkbox
                                label={translate('segment.all_to_next_segment')}
                                id={`${stopUuid}-next-segment`}
                                value=""
                                checked={Number(allToNextSegments)}
                                onChange={toggleAllToNextSegments}
                                disabled={isDisabledHAD || hadSavePending}
                                small
                            />
                        </div>
                        <div className="timeline-station-had-form__cta">
                            {hadSavePending ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Spinner size="sm" />
                                </div>
                            ) : (
                                <>
                                    <Button
                                        display="block"
                                        onClick={onRemoveHandler}
                                        disabled={isDisabledHAD}
                                    >
                                        {translate('had.remove')}
                                    </Button>
                                    {!isDepartureValid() ||
                                    !isArrivalValid() ? (
                                        <Tooltip
                                            content={tooltipContent}
                                            position="top"
                                            smartPosition
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                marginLeft: '12px',
                                            }}
                                            id={`had.add-tooltip-${id}`}
                                        >
                                            <Button display="block" disabled>
                                                {translate('had.add')}
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            appearance={
                                                hadSavePending
                                                    ? null
                                                    : 'tertiary'
                                            }
                                            display="block"
                                            onClick={onAddHandler}
                                            disabled={isDisabledHAD}
                                        >
                                            {translate('had.add')}
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </FormRow>
                </div>
            )}
        </>
    );
}
