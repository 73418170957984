import React from 'react';
import PropTypes from 'prop-types';
import {
    Popup,
    PopupSection,
    Button,
    Checkbox,
    Text,
    FormRow,
    Box,
} from '@flixbus/honeycomb-react';
import TranslateContext from './../../System/Translations/context';
import cancellationReasons from './cancellationReasons';
import ReasonsSelector from './ReasonsSelector';

export default class CancelConfirm extends React.Component {
    static contextType = TranslateContext;

    static propTypes = {
        rideMeta: PropTypes.shape({
            from: PropTypes.shape({
                short_name: PropTypes.string.isRequired,
            }),
            to: PropTypes.shape({
                short_name: PropTypes.string.isRequired,
            }),
            ride: PropTypes.shape({
                is_cancelled: PropTypes.bool,
            }).isRequired,
            cancellation_reason: PropTypes.shape({
                reasonText: PropTypes.string.isRequired,
                reasonId: PropTypes.number.isRequired,
            }),
        }).isRequired,
        onSubmit: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        const rideMeta = props.rideMeta;
        const shoulPrintPaxList = rideMeta.should_print_pax_list;

        this.state = {
            // Disallow passengers notification for TR rides
            notifyPassengers: !shoulPrintPaxList,
            notifyFeatureDisabled: shoulPrintPaxList, // process.env.REACT_APP_ENV !== 'sandbox',
            reasonId: null,
            reasonText: '',
        };
    }

    submitCancellation = () => {
        const { onSubmit } = this.props;
        const { notifyPassengers, reasonId, reasonText } = this.state;
        onSubmit(notifyPassengers, reasonId, reasonText);
    };

    toggleNotifyPassengers = () => {
        const { notifyPassengers } = this.state;
        this.setState({
            notifyPassengers: !notifyPassengers,
        });
    };

    showNotifyPassengersCheckbox = () => {
        const { rideMeta } = this.props;
        const { notifyFeatureDisabled } = this.state;

        return !rideMeta.ride.is_cancelled && !notifyFeatureDisabled;
    };

    getNotifyPassengersCheckbox = () => {
        const { notifyPassengers } = this.state;
        const translate = this.context;

        return this.showNotifyPassengersCheckbox() ? (
            <Checkbox
                id="confirm-notify-pax"
                label={translate('toolbar.cancel_ride.notify_passengers')}
                checked={notifyPassengers}
                onChange={this.toggleNotifyPassengers}
                value="notify"
            />
        ) : null;
    };

    getActionTitle = () => {
        const { rideMeta } = this.props;
        const translate = this.context;

        return !rideMeta.ride.is_cancelled
            ? translate('toolbar.cancel_ride.action_cancel')
            : translate('toolbar.cancel_ride.action_uncancel');
    };

    getConfirmMessage = () => {
        const { rideMeta } = this.props;
        const translate = this.context;

        return !rideMeta.ride.is_cancelled
            ? translate('toolbar.cancel_ride.confirm_msg_cancel')
            : translate('toolbar.cancel_ride.confirm_msg_uncancel');
    };

    onReasonSelect = (value) => {
        this.setState({
            reasonId: value,
            reasonText: cancellationReasons[value],
        });
    };

    showCancelationReason = () => {
        const { rideMeta } = this.props;
        const { cancellation_reason } = rideMeta;
        const translate = this.context;

        if (cancellation_reason) {
            return (
                <Box
                    appearance="dimmed"
                    small
                    extraClasses="comment-content__control"
                >
                    {translate('ride-cancellation.reasons.with-reason')}
                    {cancellation_reason.reasonText && (
                        <span>
                            :{' '}
                            <strong>
                                {translate(
                                    `ride-cancellation.reasons.${cancellation_reason.reasonText}`
                                )}
                            </strong>
                        </span>
                    )}
                </Box>
            );
        }

        return null;
    };

    render() {
        const { rideMeta, onClose } = this.props;
        const actionTitle = this.getActionTitle();
        const notifyPassengersCheckbox = this.getNotifyPassengersCheckbox();
        const confirmMessage = this.getConfirmMessage();
        const translate = this.context;

        return (
            <React.Fragment>
                <Popup active onOverlayClick={onClose}>
                    <PopupSection type="title">
                        {rideMeta.from.short_name} &rarr;
                        {rideMeta.to.short_name}
                    </PopupSection>
                    <PopupSection type="content">
                        <Text extraClasses="comment-content__control">
                            {confirmMessage}
                        </Text>
                        {this.showCancelationReason()}
                        {!rideMeta.ride.is_cancelled && (
                            <FormRow>
                                <ReasonsSelector
                                    onSelect={this.onReasonSelect}
                                />
                            </FormRow>
                        )}
                        {notifyPassengersCheckbox}
                    </PopupSection>
                    <PopupSection type="actions">
                        <Button appearance="tertiary" onClick={onClose}>
                            {translate('toolbar.cancel_ride.action_close')}
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={this.submitCancellation}
                            disabled={
                                !this.state.reasonId &&
                                rideMeta.ride.is_cancelled === false
                            }
                        >
                            {actionTitle}
                        </Button>
                    </PopupSection>
                </Popup>
            </React.Fragment>
        );
    }
}
