import React from 'react';
import PropTypes from 'prop-types';
import { Fineprint } from '@flixbus/honeycomb-react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './CkEditor.scss';

export default function DraftEditor({
    label,
    content,
    error,
    onChange,
    disabled,
    placeholder,
    currentLang,
}) {
    return (
        <div className={error ? 'cn-template-has-error' : ''}>
            {/* use raw styles for label
                created feature request for standalone label
                https://jira.mfb.io/browse/HIPP-237
            */}
            <div>
                <label htmlFor="message">{label}</label>
                <CKEditor
                    id="message"
                    editor={ClassicEditor}
                    data={content}
                    config={{
                        toolbar: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            'blockQuote',
                            'undo',
                            'redo',
                        ],
                        language: currentLang,
                        placeholder,
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        if (typeof onChange === 'function') {
                            onChange({ ...event, data }, editor);
                        }
                    }}
                    disabled={disabled}
                />
                {error && (
                    <Fineprint className="cn-template-error">
                        {typeof error === 'string' ? error : ''}
                    </Fineprint>
                )}
            </div>
        </div>
    );
}

DraftEditor.propTypes = {
    content: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

DraftEditor.defaultProps = {
    content: '',
    label: 'Label',
    error: null,
    onChange: () => {},
    disable: false,
    placeholder: '',
};
