import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';

/**
 * @TODO create reuseable shared component
 */
export default function withNotificationsLogApi(Component) {
    return class WithNotificationsLogApi extends React.Component {
        static propTypes = {
            match: PropTypes.object,
            rideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        };

        static defaultProps = {
            match: undefined,
        };

        state = {
            notificationsLog: [],
            loading: true,
        };

        componentDidMount() {
            this.fetchNotificationsLog();
        }

        UNSAFE_componentWillReceiveProps(newProps) {
            /**
             * Check if rideId have changed
             * to fetch data for new ride
             * (e.g.) url params is changed
             */
            // get current props
            const { match, rideId } = this.props;
            // get new props map with non concurrent names
            const { match: newMatch, rideId: newRideId } = newProps;
            // provide compatibility with route match params
            const id = (match && match.params.rideId) || rideId;
            const newId = (newMatch && newMatch.params.rideId) || newRideId;
            if (id !== newId) {
                this.setState({ notificationsLog: [] }, () => {
                    this.fetchNotificationsLog();
                });
            }
        }

        componentWillUnmount() {
            api.cancel(this.requestUrl);
        }

        get requestUrl() {
            const { match, rideId } = this.props;
            // provide compatibility with route match params
            const id = (match && match.params.rideId) || rideId;

            return `/ride/${id}/notifications-log`;
        }

        fetchNotificationsLog() {
            this.setState({ loading: true }, () => {
                api.get(this.requestUrl)
                    .then(response =>
                        this.setState({
                            notificationsLog: response,
                            loading: false,
                        }))
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            this.setState({ loading: false });
                        }
                    });
            });
        }
        render() {
            const { notificationsLog, loading } = this.state;
            if (notificationsLog.length > 0) {
                return <Component {...this.props} notificationsLog={notificationsLog} />;
            } else if (loading) {
                return 'Loading...';
            }
            return 'No notifications';
        }
    };
}
