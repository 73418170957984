import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Textarea, Button, FormRow, Text } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../System/Translations';

import './style.scss';
import useSaveComments from '../CommentsHooks/useSaveComments';
import { NotifyContext } from '../NotificationSystem/context';

export default function CommentsForm({ id, onCancel, onAddComment }) {
    const {
        comment,
        saving,
        error: saveError,
        saveComment,
    } = useSaveComments(id, 'passenger');
    const translate = useContext(TranslateContext);
    const notify = useContext(NotifyContext);

    const [content, setContent] = useState('');

    useEffect(() => {
        if (saveError) {
            notify({
                type: 'danger',
                message: translate('comments.error') + saveError.toString(),
            });
        }
    }, [saveError, notify, translate]);

    const onSave = useCallback(() => {
        saveComment(content);
    }, [saveComment, content]);

    useEffect(() => {
        if (comment && comment.content === content && saveError === null) {
            setContent('');
            onAddComment(comment);
        }
    }, [comment, onAddComment, saveError, content]);

    const disableButton = useCallback(() => {
        if (
            content.trim().length > 0 &&
            Boolean(id) === true &&
            saving === false
        ) {
            return false;
        }
        return true;
    }, [content, id, saving]);

    return (
        <div className="comments-form">
            <FormRow>
                <Textarea
                    id="message-input-field"
                    aria-label={translate(
                        'passengers.comments_form.input_placeholder'
                    )}
                    placeholder={translate(
                        'passengers.comments_form.input_placeholder'
                    )}
                    onChange={(e) => setContent(e.target.value)}
                    value={content}
                    disabled={!id}
                />
                {!id && (
                    <Text extraClasses="comments-form__warning" small>
                        {translate('passengers.comments_form.warning')}
                    </Text>
                )}
            </FormRow>
            <FormRow>
                <Button
                    appearance="primary"
                    disabled={disableButton()}
                    onClick={onSave}
                    loading={saving}
                >
                    {translate('passengers.comments_form.button_add')}
                </Button>
                <Button appearance="secondary" onClick={onCancel}>
                    {translate('passengers.comments_form.button_cancel')}
                </Button>
            </FormRow>
        </div>
    );
}
