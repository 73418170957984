import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DataGrid, { DataHeaderGroup, DataRow, DataCol } from '../UI/DataGrid';
import { Grid, GridCol } from '@flixbus/honeycomb-react';
import ActionLogItem from './components/ActionLogItem';
import LogsFilters from './components/LogsFilters';
import { TranslateContext } from '../System/Translations';
import './ActionLog.scss';

export default function ActionsLog(props) {
    const { actionsLog, printView } = props;
    const translate = useContext(TranslateContext);
    const printColSize = printView && 12;

    const uniqueActionsLog = [...new Map(actionsLog.map(item =>
        [item.id, item])).values()];

    return (
        <Grid>
            {!printView && (
                <GridCol size={12} xl={2} lg={2} md={3} sm={12}>
                    <LogsFilters {...props} />
                </GridCol>
            )}
            <GridCol
                size={12}
                xl={printColSize || 10}
                lg={printColSize || 10}
                md={printColSize || 9}
                sm={12}
            >
                <DataGrid>
                    <DataHeaderGroup>
                        <DataRow header>
                            <DataCol>{translate('action-log.date')}</DataCol>
                            <DataCol>
                                {translate('action-log.description')}
                            </DataCol>
                            <DataCol>{translate('action-log.user')}</DataCol>
                            <DataCol>
                                {translate('action-log.app-version')}
                            </DataCol>
                        </DataRow>
                    </DataHeaderGroup>
                    {uniqueActionsLog.map((logItem) => (
                        <ActionLogItem key={logItem.id} item={logItem} />
                    ))}
                </DataGrid>
            </GridCol>
        </Grid>
    );
}

ActionsLog.propTypes = {
    actionsLog: PropTypes.array,
    printView: PropTypes.bool,
};
ActionsLog.defaultProps = {
    actionsLog: [],
    printView: false,
};
