import React, { useContext, useState } from 'react';
import {
    Button,
    ChoiceWrapper,
    ChoiceWrapperItem,
    Heading,
    Infobox,
    Popup,
    PopupSection,
    Spinner,
} from '@flixbus/honeycomb-react';
import { useApi } from '../RideSearch/hooks';
import { TranslateContext } from '../System/Translations';

const RideStatusPopup = ({ isOpened, handleClose, rides }) => {
    const translate = useContext(TranslateContext);

    const [selectedStatus, setSelectedStatus] = useState(undefined);
    const [selectStatusResponse, selectStatus, reset] = useApi(
        'put',
        `bulk/ride/status`
    );

    const options = [
        {
            label: (
                <>
                    <Heading size={3} flushSpace>
                        {translate('ride-status.on_sale')}
                    </Heading>
                </>
            ),
            value: 'on_sale',
        },
        {
            label: (
                <>
                    <Heading size={3} flushSpace>
                        {translate('ride-status.hidden')}
                    </Heading>
                </>
            ),
            value: 'hidden',
        },
        {
            label: (
                <>
                    <Heading size={3} flushSpace>
                        {translate('ride-status.archived')}
                    </Heading>
                </>
            ),
            value: 'archived',
        },
    ];

    const handleConfirm = () => {
        selectStatus({ status: selectedStatus, rideIds: rides });
    };

    const onClose = () => {
        reset();
        handleClose();
    };

    return (
        <Popup active={isOpened}>
            <PopupSection>
                <h2>{translate('ride-status-change-action')}</h2>
            </PopupSection>

            {selectStatusResponse.pending && <Spinner size="lg" />}

            {!selectStatusResponse?.pending &&
                !selectStatusResponse?.error &&
                !selectedStatus?.data && (
                    <PopupSection>
                        <ChoiceWrapper
                            onChange={({ target }) =>
                                setSelectedStatus(target.value)
                            }
                            info={translate(
                                'ride-status-change-hint',
                                rides.length
                            )}
                        >
                            {options.map(({ label, value }, idx) => (
                                <ChoiceWrapperItem
                                    key={`${label}_${idx}`}
                                    id={value}
                                    value={value}
                                    name="rideStatus"
                                    label={label}
                                />
                            ))}
                        </ChoiceWrapper>
                    </PopupSection>
                )}

            <PopupSection>
                {selectStatusResponse?.data &&
                    selectStatusResponse.data?.result === 'OK' && (
                        <Infobox small appearance="success">
                            <p>{translate('ride-status.change-success')}</p>
                        </Infobox>
                    )}

                {(selectStatusResponse?.error ||
                    (selectStatusResponse?.data &&
                        selectStatusResponse.data?.result !== 'OK')) && (
                    <Infobox appearance="warning">
                        <p>{translate('ride-status.change-failure')}</p>
                        <p>
                            <b>{translate('send_sms.message.label')}: </b>
                            {selectStatusResponse?.data?.result
                                ? selectStatusResponse?.data?.result
                                : selectStatusResponse?.error?.message}
                        </p>
                    </Infobox>
                )}
            </PopupSection>

            <PopupSection>
                <Button appearance="tertiary" onClick={onClose}>
                    {translate('ride-status.change-close')}
                </Button>
                <Button
                    disabled={
                        !selectedStatus ||
                        selectStatusResponse?.pending ||
                        selectStatusResponse?.data ||
                        selectStatusResponse?.error
                    }
                    appearance="primary"
                    onClick={handleConfirm}
                >
                    {translate('ride-status.change-confirm')}
                </Button>
            </PopupSection>
        </Popup>
    );
};

export default RideStatusPopup;
