import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import CommentItem from './components/CommentItem';
import Translate from '../System/Translations';

export default class CommentsFeed extends React.Component {
    static propTypes = {
        comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    list = null;

    componentDidMount() {
        this.scrollBottom(this.list);
    }

    componentDidUpdate() {
        this.scrollBottom(this.list);
    }

    scrollBottom = (element) => {
        if (!element) {
            return;
        }
        element.scrollTo(0, element.scrollHeight);
    };

    render() {
        let comments = JSON.parse(JSON.stringify(this.props.comments));
        comments.reverse();
        let commentsCollection;
        if (comments.length > 0) {
            commentsCollection = comments.map((commentData) => (
                <CommentItem
                    key={commentData.createdAt}
                    commentData={commentData}
                />
            ));
        } else {
            commentsCollection = (
                <li className="no-comments-message">
                    <Translate code="passengers.comments_form.no_comments" />
                </li>
            );
        }

        return (
            <div className="comments-feed-container">
                <ul
                    className="comments-feed"
                    ref={(list) => {
                        this.list = list;
                    }}
                >
                    {commentsCollection}
                </ul>
            </div>
        );
    }
}
