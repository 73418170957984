import { useCallback, useEffect, useState } from 'react';

import { useFiltersMap } from '.';

const STORAGE_KEY = 'rs-custom-filters';

function getFiltersList(filtersMap = {}, storageList = []) {
    return Object.entries(filtersMap).reduce((acc, item) => {
        let res = [...acc];
        res.push({
            name: item[0],
            label: item[1],
            checked: storageList.includes(item[0]),
        });
        return res;
    }, []);
}

/**
 * Hook that save custom filters list and provide mapped list
 * @function useCustomFilters
 * @returns {array} - [filtersList, isCustomFilters, saveFilters]
 */
export default function useCustomFilters() {
    const filtersMap = useFiltersMap();
    const [customFilters, setCustomFilters] = useState(
        getFiltersList(filtersMap)
    );

    // generate filters map combine with selected filters
    useEffect(() => {
        const localStorageFilters = window.localStorage.getItem(STORAGE_KEY);
        const storedFilters = localStorageFilters
            ? JSON.parse(localStorageFilters)
            : Object.keys(filtersMap); // set all filters checked by default

        setCustomFilters(getFiltersList(filtersMap, storedFilters));
    }, [filtersMap]);

    const saveFilters = useCallback(
        (filters) => {
            try {
                window.localStorage.setItem(
                    STORAGE_KEY,
                    JSON.stringify(filters)
                );
            } catch (e) {
                console.error(e);
            }
            setCustomFilters(getFiltersList(filtersMap, filters));
        },
        [filtersMap, setCustomFilters]
    );

    return [
        customFilters,
        customFilters.filter((filter) => filter.checked === true).length > 0,
        saveFilters,
    ];
}
