import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, DataTable } from '@flixbus/honeycomb-react';
import LockCapacity from './../LockCapacity';
import useUserCan from '../../../auth/useUserCan';

export default function CapacityTable(props) {
    const {
        rideId,
        rideUuid,
        departureDate,
        rideStatus,
        timeline,
        fetchTimeline,
        translate,
        notify,
    } = props;

    const isUserCanLock = useUserCan('lock_all_seats');
    const seatLockEnabledRideStatuses = ['hidden', 'on_sale', 'archived'];
    const statusCanLockSeat = () => {
        return seatLockEnabledRideStatuses.includes(rideStatus);
    };

    const initialHeaders = {
        cells: [translate('capacity.station')],
        selectionInputProps: {
            'aria-label': translate('capacity.station'),
            id: `${rideId}_station`,
            value: translate('capacity.station'),
        },
    };
    const initialRows = [
        {
            cells: [translate('capacity.seats')],
            selectionInputProps: {
                'aria-label': translate('capacity.seats'),
                id: `${rideId}_seats`,
                value: translate('capacity.seats'),
            },
            total: null,
        },
        {
            cells: [translate('capacity.bikes')],
            selectionInputProps: {
                'aria-label': translate('capacity.bikes'),
                id: `${rideId}_bikes`,
                value: translate('capacity.bikes'),
            },
            total: null,
        },
        {
            cells: [translate('capacity.wheelchairs')],
            selectionInputProps: {
                'aria-label': translate('capacity.wheelchairs'),
                id: `${rideId}_wheelchairs`,
                value: translate('capacity.wheelchairs'),
            },
            total: null,
        },
    ];
    const initialData = {
        totalSeats: 0,
        seatsLocked: 0,
        totalBikes: 0,
        bikesLocked: 0,
        totalWheelchairs: 0,
        wheelchairsLocked: 0,
    };

    const processTable = useCallback(() => {
        const stations = [];
        const allSegments = [];

        // prepare headers
        const newHeaders = { ...initialHeaders };

        // prepare rows
        const newRows = [...initialRows];

        // prepare table data
        const data = { ...initialData };

        timeline.forEach((item) => {
            const stationCssClass = classNames('ride-capacity__station', {
                'ride-capacity__station--closed': item.status === 'closed',
            });

            // update headers and rows
            newHeaders.cells.push(
                <div title={item.name} className={stationCssClass}>
                    {item.code}
                </div>
            );
            newRows[0].cells.push(
                item.seats_occupancy ? (
                    <>
                        {Number(item.seats_occupancy.locked)}
                        <span className="ride-capacity__cell-value">
                            <small className="ride-capacity__cell-value--green">
                                {Number(item.seats_occupancy.free) -
                                    Number(item.seats_occupancy.locked)}
                            </small>

                            <small className="ride-capacity__cell-value--red">
                                {Number(item.seats_occupancy.used)}
                            </small>
                        </span>
                    </>
                ) : null
            );
            newRows[1].cells.push(
                item.bikes_occupancy ? (
                    <>
                        {Number(item.bikes_occupancy.locked)}
                        <span className="ride-capacity__cell-value">
                            <small className="ride-capacity__cell-value--green">
                                {Number(item.bikes_occupancy.free) -
                                    Number(item.bikes_occupancy.locked)}
                            </small>

                            <small className="ride-capacity__cell-value--red">
                                {Number(item.bikes_occupancy.used)}
                            </small>
                        </span>
                    </>
                ) : null
            );
            newRows[2].cells.push(
                item.wheelchairs_occupancy ? (
                    <>
                        {Number(item.wheelchairs_occupancy.locked)}
                        <span className="ride-capacity__cell-value">
                            <small className="ride-capacity__cell-value--green">
                                {Number(item.wheelchairs_occupancy.free) -
                                    Number(item.wheelchairs_occupancy.locked)}
                            </small>

                            <small className="ride-capacity__cell-value--red">
                                {Number(item.wheelchairs_occupancy.used)}
                            </small>
                        </span>
                    </>
                ) : null
            );

            // prepare table data
            data.seatsLocked += item.seats_occupancy
                ? Number(item.seats_occupancy.locked)
                : null;
            data.bikesLocked += item.bikes_occupancy
                ? Number(item.bikes_occupancy.locked)
                : null;
            data.wheelchairsLocked += item.wheelchairs_occupancy
                ? Number(item.wheelchairs_occupancy.locked)
                : null;
            data.totalSeats += item.seats_occupancy
                ? Number(item.seats_occupancy.total)
                : null;
            data.totalBikes += item.bikes_occupancy
                ? Number(item.bikes_occupancy.total)
                : null;
            data.totalWheelchairs += item.wheelchairs_occupancy
                ? Number(item.wheelchairs_occupancy.total)
                : null;

            // prepare stations & allSegments
            if (item.entry_type === 'stop') {
                stations.push({
                    value: item.code,
                    displayValue: `${item.name} (${item.code})`,
                });
            }
            if (item.entry_type === 'segment') {
                allSegments.push({
                    id: item.id,
                    seats_locked: item.seats_occupancy.locked,
                    bike_slots_locked: item.bikes_occupancy.locked,
                    wheelchairs_locked: item.wheelchairs_occupancy.locked,
                });
            }
        });

        return { newHeaders, newRows, stations, allSegments };
    }, [timeline, initialData, initialHeaders, initialRows]);

    const tableData = processTable();
    const segmentId =
        tableData?.allSegments?.length && tableData.allSegments[0].id;
    return (
        <div className="ride-capacity__ride">
            <Heading size={3}>
                {translate('capacity.ride')} {rideId} - {departureDate}
            </Heading>

            {isUserCanLock && statusCanLockSeat() && (
                <LockCapacity
                    segmentId={segmentId}
                    allSegments={tableData.allSegments}
                    onClose={() => {}}
                    fetchTimeline={fetchTimeline}
                    rideUuid={rideUuid}
                    stations={tableData.stations}
                    notify={notify}
                />
            )}

            <div className="ride-capacity__table-wrapper">
                {tableData.newHeaders && tableData.newRows && (
                    <DataTable
                        headers={tableData.newHeaders}
                        rows={tableData.newRows}
                    />
                )}
                <div className="ride-capacity__legend">
                    <small>{translate('capacity.legend.locked')} - </small>1
                    <span className="ride-capacity__cell-value">
                        <small className="ride-capacity__cell-value--green">
                            2 - {translate('capacity.legend.free')}
                        </small>
                        <small className="ride-capacity__cell-value--red">
                            3 - {translate('capacity.legend.occupied')}
                        </small>
                    </span>
                </div>
            </div>
        </div>
    );
}

CapacityTable.propTypes = {
    rideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    rideUuid: PropTypes.string.isRequired,
    departureDate: PropTypes.string.isRequired,
    rideStatus: PropTypes.string.isRequired,
    timeline: PropTypes.arrayOf(PropTypes.object).isRequired,
};
