import { ApiClient } from './Client';
import { getTokenScoped, MEGADRIVE_NEW_SCOPE } from '../auth';

const api = new ApiClient(null, {
    apiUrl: '/ops-mobile/megadrive/api/',
    getAuthToken: getTokenScoped(MEGADRIVE_NEW_SCOPE),
    headers: {'x-api-key': 'XIMeiT3oX3yvyAGs7TEXCOb2kZHQhL'}
});

export default api;
