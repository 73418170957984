import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListPicker } from '.';

export default function NumberInput(props) {
    const {
        id,
        value: propValue,
        label,
        placeholder,
        InlineIcon,
        onChange,
    } = props;
    const [value, setValue] = useState('');
    const [selected, setSelected] = useState(propValue);
    const [genOptions, setOptions] = useState([]);

    useEffect(() => {
        setSelected(propValue);
    }, [propValue, setSelected]);

    const onInput = (value) => {
        // prevent of duplication values
        if (!selected.includes(value)) {
            onChange(value);
        }
        setValue('');
    };

    useEffect(() => {
        setOptions([
            {
                title: String(value),
                value,
                subtitle: label,
            },
            {
                title: value + 0,
                value: value + 0,
                subtitle: label,
            },
            {
                title: value + 1,
                value: value + 1,
                subtitle: label,
            },
            {
                title: value + 2,
                value: value + 2,
                subtitle: label,
            },
            {
                title: value + 3,
                value: value + 3,
                subtitle: label,
            },
            {
                title: value + 4,
                value: value + 4,
                subtitle: label,
            },
        ]);
    }, [value, setOptions, label]);

    return (
        <ListPicker
            id={id}
            options={genOptions}
            label={label}
            placeholder={placeholder}
            InlineIcon={InlineIcon}
            value={value}
            onChange={onInput}
            onKeyUp={(e) => {
                // set value before onChange triggers to generate options with proper value
                setValue(e.target.value);
            }}
            autoComplete="off"
            type="text"
            shouldResetValue={() => false}
        />
    );
}
NumberInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    InlineIcon: PropTypes.func,
    onChange: PropTypes.func,
};
NumberInput.defaultProps = {
    id: '',
    value: [],
    label: '',
    placeholder: '',
    InlineIcon: () => {},
    onChange: () => {},
};
