import React from 'react';
import PropTypes from 'prop-types';

function isPrimitive(value) {
    return typeof value !== 'object' || value === null;
}

export default function withFiltering(propName) {
    return function withFilteringPropName(Component) {
        return class WithFiltering extends React.Component {
            static propTypes = {
                [propName]: PropTypes.array.isRequired,
                filters: PropTypes.object,
            };

            static defaultProps = {
                filters: {},
            };

            constructor(props) {
                super(props);
                const { [propName]: dataToFilter, filters } = this.props;
                this.state = {
                    filteredData: dataToFilter,
                    filters,
                };
            }

            onFilterChange = (e) => {
                const { name, value, checked, type } = e.target;
                const { filters } = this.state;
                /**
                 * if checkbox is used processed it as a multiple option
                 * @todo sort it out with less if...else statements
                 */
                if (type === 'checkbox') {
                    let { [name]: checkboxFilter } = filters;
                    if (Array.isArray(checkboxFilter)) {
                        if (checked) {
                            checkboxFilter.push(value);
                        } else {
                            checkboxFilter = checkboxFilter.filter(
                                (item) => item !== value
                            );
                        }
                    } else if (checked) {
                        checkboxFilter = [value];
                    }
                    if (checkboxFilter.length) {
                        this.setFilter({ [name]: checkboxFilter });
                    } else {
                        this.resetFilter(name);
                    }
                } else if (value === '') {
                    this.resetFilter(name);
                } else {
                    this.setFilter({ [name]: value });
                }
            };

            onSelectCategoryFilterChange = (setFilters, filtersList) => {
                const { filters } = this.state;
                let newAction = setFilters
                    ? []
                    : filters.action && [...filters.action];

                this.setState((state) => {
                    let newFilters = { ...state.filters };

                    if (filters?.action?.length > 0) {
                        filtersList.forEach((filter) => {
                            if (setFilters) {
                                if (!filters.action.includes(filter)) {
                                    newAction.push(filter);
                                }
                            } else {
                                if (newAction.includes(filter)) {
                                    const itemIndex = newAction.indexOf(filter);
                                    newAction.splice(itemIndex, 1);
                                }
                            }
                        });
                    }

                    if (setFilters) {
                        // if category selected (checked)
                        newFilters = {
                            ...state.filters,
                            ...{
                                action:
                                    filters?.action?.length > 0
                                        ? [...filters.action, ...newAction]
                                        : [...filtersList],
                            },
                        };
                    } else {
                        // if category unselected (unchecked)
                        newFilters =
                            newAction?.length > 0
                                ? {
                                    ...state.filters,
                                    ...{
                                        action: newAction,
                                    },
                                }
                                : {};
                    }

                    return { filters: newFilters };
                }, this.filter);
            };

            setFilter = (filter) => {
                this.setState((state) => {
                    const newFilters = { ...state.filters, ...filter };
                    return { filters: newFilters };
                }, this.filter);
            };

            resetFilter = (filter) => {
                this.setState((state) => {
                    const newFilters = { ...state.filters };
                    delete newFilters[filter];
                    return { filters: newFilters };
                }, this.filter);
            };

            clearFilters = () => {
                const { [propName]: dataToFilter } = this.props;
                this.setState({ filters: {}, filteredData: dataToFilter });
            };

            filter() {
                const { filters } = this.state;
                const { [propName]: dataToFilter } = this.props;
                if (Object.keys(filters).length > 0) {
                    const filteredData = dataToFilter.filter((entity) =>
                        Object.keys(filters).reduce((flag, filter) => {
                            if (flag === false) {
                                return false;
                            }
                            let filterValue = filters[filter];
                            let valueToFilter = entity[filter];

                            if (typeof valueToFilter === 'undefined') {
                                return flag;
                            }
                            // if some values gonna be null it will fail don't let it be
                            // filter is array and value is primitive
                            if (
                                Array.isArray(filterValue) &&
                                isPrimitive(valueToFilter)
                            ) {
                                return filterValue.some(
                                    (item) =>
                                        item.toString() ===
                                        valueToFilter.toString()
                                );
                            }
                            // both are primitive
                            if (
                                isPrimitive(filterValue) &&
                                isPrimitive(valueToFilter)
                            ) {
                                const matchExp = new RegExp(filterValue, 'gi');
                                return (
                                    valueToFilter
                                        .toString()
                                        .search(matchExp) !== -1
                                );
                            }
                            // filter is primitive, value is array
                            if (
                                isPrimitive(filterValue) &&
                                Array.isArray(valueToFilter)
                            ) {
                                return valueToFilter.some(
                                    (item) =>
                                        item.toString() ===
                                        filterValue.toString()
                                );
                            }
                            // both are array
                            if (
                                Array.isArray(filterValue) &&
                                Array.isArray(valueToFilter)
                            ) {
                                const searchRegExp = new RegExp(
                                    filterValue.join('|'),
                                    'gi'
                                );
                                return valueToFilter
                                    .join(',')
                                    .search(searchRegExp !== -1);
                            }
                            return flag;
                        }, true)
                    );
                    this.setState({ filteredData });
                } else {
                    this.setState({ filteredData: dataToFilter });
                }
            }

            render() {
                const { filters, filteredData } = this.state;
                const passPropNameData = { [propName]: filteredData };
                return (
                    <Component
                        {...this.props}
                        {...passPropNameData}
                        onChange={this.onFilterChange}
                        onSelectCategory={this.onSelectCategoryFilterChange}
                        filters={filters}
                        setFilter={this.setFilter}
                        resetFilter={this.resetFilter}
                        clearFilters={this.clearFilters}
                    />
                );
            }
        };
    };
}
