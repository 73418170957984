import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    GridCol,
    List,
    Heading,
    Spinner,
} from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../System/Translations';
import { TemplateItem, SearchField, LanguageSelector } from './components';
import useGetTemplates from '../hooks/useGetTemplatesHook';

import './Templates.css';
import PlatformSelector from './components/PlatformSelector';

const PLATFORMS = ['FLIX', 'GH'];
const DEFAULT_PLATFORM = PLATFORMS[0];

export default function Templates(props) {
    const { onSelect, onLangSwitch, currentLang, sendMode } = props;
    const [searchValue, setSearch] = useState('');
    const [templatesResponse, requestTemplates] = useGetTemplates();
    const { data: templates, loading } = templatesResponse;
    const translate = useContext(TranslateContext);
    const searchRegexp = new RegExp(searchValue, 'i');
    const [platform, setPlatform] = useState(DEFAULT_PLATFORM);

    /** @todo come up with proper naming */
    const emailMode = sendMode === 'e-mail';
    const channel = emailMode ? 'email' : 'sms';

    useEffect(() => {
        setSearch('');
        requestTemplates(currentLang);
    }, [requestTemplates, currentLang]);

    let filteredTemplates = [];

    const getTranslations = useCallback(() => {
        if (templates === null) {
            return [];
        }
        return templates[platform][channel][currentLang] || [];
    }, [templates, platform, channel, currentLang]);

    if (!loading && searchValue !== '') {
        filteredTemplates = getTranslations().filter((t) => {
            return (
                (t.subject && t.subject.search(searchRegexp) >= 0) ||
                t.content.search(searchRegexp) >= 0
            );
        });
    }

    const resultsHeading = filteredTemplates.length
        ? `${filteredTemplates.length} ${translate(
              'notifier.templates.results_found'
          )}`
        : translate('notifier.templates.no_results_found');

    return (
        <div className="templates-container">
            {loading && <Spinner size="md" />}
            <>
                <Grid>
                    <GridCol size={3}>
                        <PlatformSelector
                            platforms={PLATFORMS}
                            default={DEFAULT_PLATFORM}
                            onSelect={setPlatform}
                        />
                    </GridCol>
                    <GridCol size={5}>
                        <SearchField onSearch={setSearch} />
                    </GridCol>
                    <GridCol size={4}>
                        <LanguageSelector
                            onSwitch={onLangSwitch}
                            currentLang={currentLang}
                        />
                    </GridCol>
                </Grid>

                {filteredTemplates.length === 0 && searchValue !== '' && (
                    <Heading size={3}>{resultsHeading}</Heading>
                )}

                {filteredTemplates.length > 0 && (
                    <>
                        <Heading size={3}>{resultsHeading}</Heading>
                        <List>
                            {filteredTemplates.map(
                                ({ subject, content, uid, id }, idx) => {
                                    const key =
                                        content
                                            .substr(0, 20)
                                            .replace(/ /g, '') +
                                        '_' +
                                        idx;
                                    return (
                                        <li key={key}>
                                            <TemplateItem
                                                title={subject}
                                                content={content}
                                                uid={uid || id}
                                                onSelect={onSelect}
                                            />
                                        </li>
                                    );
                                }
                            )}
                        </List>
                    </>
                )}
            </>
        </div>
    );
}

Templates.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onLangSwitch: PropTypes.func.isRequired,
    currentLang: PropTypes.string.isRequired,
    sendMode: PropTypes.string.isRequired,
};
