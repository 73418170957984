import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function RideCancellation(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const action = item.parameters.action;
    const notifyPassengers = item.parameters.notify_passengers || false;
    let passengersNotified = '';

    if (action === 'cancelled') {
        if (notifyPassengers) {
            passengersNotified = '. ' + translate('ride-cancellation.passengers-were-notified');
        } else {
            passengersNotified = '. ' + translate('ride-cancellation.passengers-notification-skipped');
        }
    }
    
    return (
        <div>
            {translate('ride-cancellation.ride-was')} { action }{ passengersNotified }
        </div>
    );
}

RideCancellation.propTypes = {
    item: PropTypes.object.isRequired,
};
