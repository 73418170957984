import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Heading,
    NavHorizontal,
    NavItem,
    Grid,
    GridCol,
} from '@flixbus/honeycomb-react';
import withPaxSelection from '../ReservationsTable/hocs/withPaxSelection';
import { TranslateContext } from '../System/Translations';
import PageDialog, { PageDialogContent } from '../UI/PageDialog';
import PaxList from './PaxList';
import MessageField from './MessageField';
import Templates from './Templates';
import SendWhatsappTemplates from "./SendWhatsappTemplates";

function EmailNotifier(props) {
    const {
        orders,
        selectedPax,
        toggleDialog,
        toggleBatch,
        togglePax,
        removeBatch,
        rideUuid
    } = props;
    const translate = useContext(TranslateContext);
    const navigation = [
        {
            text: translate('notifier.dialog.e_mail'),
            key: 'e-mail',
            active: true,
            // eslint-disable-next-line
            onClick: () => tabChange('e-mail'),
        },
        {
            text: translate('notifier.dialog.push_and_sms'),
            key: 'sms',
            active: false,
            // eslint-disable-next-line
            onClick: () => tabChange('sms'),
        },
        {
            text: translate('notifier.dialog.whatsapp'),
            key: 'whatsapp',
            active: true,
            // eslint-disable-next-line
            onClick: () => tabChange('whatsapp'),
        }
    ];
    const [activeTab, setActiveTab] = useState('e-mail');
    const [template, selectTemplate] = useState({});
    const [currentLang, switchLang] = useState('en');

    const tabChange = (key) => {
        setActiveTab(key);
    };

    const closeDialog = () => {
        toggleDialog();

        // clear template on close
        selectTemplate('');
    };

    const getNavigation = () =>
        navigation.map((item) => ({ ...item, active: item.key === activeTab }));

    const getPlaceholders = (string) => {
        // find everything wrapped with "[[ ]]"
        const pattern = /\w+(?=\]\])/g;
        const uniques = new Set(string.match(pattern));
        const result = Array.from(uniques);

        return result;
    };

    const onSelectTemplate = (selected) => {
        const extractPlaceholders = getPlaceholders(selected.title).concat(
            getPlaceholders(selected.content)
        );
        const allPlaceholders = Array.from(new Set(extractPlaceholders));

        selectTemplate({ ...selected, placeholders: allPlaceholders });
    };

    return (
        <React.Fragment>
            <PageDialog onClose={closeDialog}>
                <PageDialogContent>
                    <Heading size={2}>
                        {translate('notifier.email.dialog.title')}
                    </Heading>
                    <NavHorizontal
                        aria-label="navigation-tabs"
                        content={getNavigation()}
                        extraClasses="navigation-tabs"
                    >
                        {getNavigation().map((item) => {
                            const { text, key, ...rest } = item;
                            return (
                                <NavItem key={key} {...rest}>
                                    {text}
                                </NavItem>
                            );
                        })}
                    </NavHorizontal>
                    <Grid>
                        <GridCol size={6} md={5} sm={12}>
                            {activeTab === 'whatsapp' ? (
                                <SendWhatsappTemplates rideUuid={rideUuid} selectedPax={selectedPax} />
                            ) : (
                                <>
                                    <Templates
                                        onSelect={onSelectTemplate}
                                        onLangSwitch={switchLang}
                                        currentLang={currentLang}
                                        sendMode={activeTab}
                                    />
                                    <MessageField
                                        orders={orders}
                                        paxList={selectedPax}
                                        onCancel={closeDialog}
                                        content={template.content}
                                        title={template.title}
                                        uid={template.uid}
                                        placeholders={template.placeholders}
                                        sendMode={activeTab}
                                        currentLang={currentLang}
                                    />
                                </>
                            )}
                        </GridCol>
                        <GridCol size={6} md={7} sm={12}>
                            <Grid>
                                <GridCol push={1} size={11}>
                                    <PaxList
                                        showMode={activeTab}
                                        orders={orders}
                                        selectedPax={selectedPax}
                                        currentLang={currentLang}
                                        toggleBatch={toggleBatch}
                                        togglePax={togglePax}
                                        removeBatch={removeBatch}
                                    />
                                </GridCol>
                            </Grid>
                        </GridCol>
                    </Grid>
                </PageDialogContent>
            </PageDialog>
        </React.Fragment>
    );
}
EmailNotifier.propTypes = {
    orders: PropTypes.array,
    selectedPax: PropTypes.array,
    toggleDialog: PropTypes.func.isRequired,
    toggleBatch: PropTypes.func.isRequired,
    togglePax: PropTypes.func.isRequired,
    removeBatch: PropTypes.func.isRequired,
};
EmailNotifier.defaultProps = { orders: [], selectedPax: [] };

export default withPaxSelection(EmailNotifier);
