import Rollbar from 'rollbar';
import { getUser } from '../../../auth';

const rollbar = new Rollbar({
    enabled: process.env.REACT_APP_ENV === undefined && process.env.NODE_ENV === 'production',
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN || null,
    captureUncaught: true,
    captureUnhandledRejections: false,
    addErrorContext: true,
    payload: {
        environment: 'production[prod]',
        person: {
            name: getUser() && getUser().profile && getUser().profile.name,
        },
    },
    transform: (payload) => {
        try {
            payload.body.trace.exception.class = `[Rideviewer] ${payload.body.trace.exception.class}`;
        } catch {
            console.log('payload.body.trace.exception.message, not changed');
        }
        return payload;
    },
});

export default rollbar;
