import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function SeatLockLog(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const fromStopName = item.parameters.fromStop;
    const toStopName = item.parameters.toStop;
    const seatsNumber = item.parameters.seatsNumber;
    const bikesNumber = item.parameters.bikesNumber;
    const wheelchairsNumber = item.parameters.wheelchairsNumber;

    if (item.parameters.action === 'LOCK') {
        return <div>{translate('seat-lock-log.lock', {
            fromStopName,
            toStopName,
            seatsNumber: seatsNumber.toString(),
            bikesNumber: bikesNumber.toString(),
            wheelchairsNumber: wheelchairsNumber.toString(),
        })}</div>;
    }

    if (item.parameters.action === 'UNLOCK') {
        return <div>{translate('seat-lock-log.unlock', {
            fromStopName,
            toStopName,
            seatsNumber: seatsNumber.toString(),
            bikesNumber: bikesNumber.toString(),
            wheelchairsNumber: wheelchairsNumber.toString(),
        })}</div>;
    }
}
SeatLockLog.propTypes = {
    item: PropTypes.object.isRequired,
};
