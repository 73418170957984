const MASS_ASSIGNMENT = 'MASS_ASSIGNMENT';
const LOCK_ALL_CAPACITY = 'LOCK_ALL_CAPACITY';
const UNLOCK_ALL_CAPACITY = 'UNLOCK_ALL_CAPACITY';
const EXPORT_CSV = 'EXPORT_CSV';
const LOCK_CANCEL_TOKEN = 'LOCK_CANCEL_TOKEN';
const UNLOCK_CANCEL_TOKEN = 'UNLOCK_CANCEL_TOKEN';
const CHANGE_STATUS = 'CHANGE_STATUS';
const ADD_REMOVE_TAGS = 'ADD_REMOVE_TAGS';
const BATCH_STATION_STATUS = 'BATCH_STATION_STATUS';
const MARK_AS_BOOSTER = 'MARK_AS_BOOSTER';
const CAPACITY_LOCKING = 'CAPACITY_LOCKING';

export {
    MASS_ASSIGNMENT,
    LOCK_ALL_CAPACITY,
    UNLOCK_ALL_CAPACITY,
    EXPORT_CSV,
    LOCK_CANCEL_TOKEN,
    UNLOCK_CANCEL_TOKEN,
    CHANGE_STATUS,
    ADD_REMOVE_TAGS,
    BATCH_STATION_STATUS,
    MARK_AS_BOOSTER,
    CAPACITY_LOCKING
};
