import { compose } from 'recompose';
import withAppConfig from './hocs/withAppConfig';
import withWhoIsOnPage from './hocs/withWhoIsOnPage';
import withMsalAuth from './hocs/withMaslAuth';
import withNotificationSystem from '../NotificationSystem/hocs/withNotificationSystem';
import withNotifyProvider from '../NotificationSystem/context/hocs/withNotifyProvider';
import withNotifyConsumer from '../NotificationSystem/context/hocs/withNotifyConsumer';
import withTimezoneHelperProvider from '../System/TimezoneHelper/hocs/withTimezoneHelperProvider';
import { withPageTitleProvider } from '../System/PageTitle';
import { withRollbarProvider } from '../System/Rollbar';
import { withTranslateProvider, withTranslateConsumer } from '../System/Translations';
import withRideMetaProvider from './RideMeta/hocs/withRideMetaProvider';
import App from './App';

export default compose(
    withRollbarProvider,
    withTranslateProvider,
    withTranslateConsumer,
    withPageTitleProvider,
    withTimezoneHelperProvider,
    withNotificationSystem,
    withNotifyProvider,
    withNotifyConsumer,
    withMsalAuth,
    withAppConfig,
    withWhoIsOnPage,
    withRideMetaProvider,
)(App);
