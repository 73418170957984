import React from 'react';
import PropTypes from 'prop-types';
import WsSubscriber from '../../ws/WsSubscriber';
import WsPublisher from '../../ws/WsPublisher';
import api from '../../api/Client';
import { getUser } from '../../auth';
import { DispatcherContext } from '../System/Dispatcher';

const STATE_VIEWING = 'viewing_page';
const STATE_LEFT_PAGE = 'left_page';

function getCurrentUserInfo(state = STATE_VIEWING) {
    const cachedUser = getUser();
    if (cachedUser === null) {
        return null;
    }
    return {
        userId: cachedUser?.userName,
        userName: cachedUser?.profile.name,
        state,
    };
}

export default function withWhoIsOnPageWsSubscriber(Component) {
    return class WithWhoIsOnPageWsSubscriber extends React.Component {
        static propTypes = {
            match: PropTypes.shape(),
        };

        static defaultProps = {
            match: null,
        };

        static contextType = DispatcherContext;

        state = {
            whoIsOnPage: [],
            rideUUID: '',
        };

        componentDidMount() {
            const dispatcher = this.context;
            dispatcher.on('ride-meta.uuid', this.subscribe);
        }

        componentWillUnmount() {
            this.onPageLeft();
            WsSubscriber().unsubscribe(this.topic, this.onWhoIsOnPageChange);
            window.removeEventListener('beforeunload', this.onPageLeft);

            const dispatcher = this.context;
            dispatcher.off('ride-meta.uuid', this.subscribe);
        }

        subscribe = (dispatcher) => {
            const currentUserInfo = getCurrentUserInfo();
            if (currentUserInfo === null) {
                return;
            }
            this.setState({ rideUUID: dispatcher.payload }, () => {
                WsSubscriber().subscribe(this.topic, this.onWhoIsOnPageChange);
                WsPublisher().publish(this.topic, [currentUserInfo]);
                window.addEventListener('beforeunload', this.onPageLeft);
            });
        };

        onWhoIsOnPageChange = (data) => {
            const { whoIsOnPage } = this.state;
            const currentUserInfo = getCurrentUserInfo();
            const viewerInfo = data[0];

            if (currentUserInfo === null) {
                return;
            }

            if (viewerInfo.userId === currentUserInfo.userId) {
                return;
            }

            if (viewerInfo.state === STATE_LEFT_PAGE) {
                this.setState({
                    whoIsOnPage: whoIsOnPage.filter(
                        (item) => item.userId !== viewerInfo.userId
                    ),
                });

                return;
            }

            const isNewViewer = whoIsOnPage.reduce((currentValue, item) => {
                if (item.userId === viewerInfo.userId && currentValue) {
                    return false;
                }

                return currentValue;
            }, true);

            if (isNewViewer) {
                whoIsOnPage.push(viewerInfo);
                this.setState({ whoIsOnPage });

                WsPublisher().publish(this.topic, [currentUserInfo]);
            }
        };

        onPageLeft = () => {
            const currentUserInfo = getCurrentUserInfo(STATE_LEFT_PAGE);

            WsPublisher().publish(this.topic, [currentUserInfo]);
        };

        get topic() {
            const { match, id } = this.props;
            const { rideUUID } = this.state;
            const rideId = rideUUID || id || match.params.id;
            const { config: CONFIG } = api;
            // to have valid topic have to remove '-' from uuid
            return `${CONFIG.wamp.topic.who_is_on_page}.ride.${rideId.replace(
                /-/g,
                ''
            )}`;
        }

        render() {
            const { whoIsOnPage } = this.state;
            return <Component {...this.props} whoIsOnPage={whoIsOnPage} />;
        }
    };
}
