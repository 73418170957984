import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@flixbus/honeycomb-react';
import { Widget } from '@ops/rhinegold-widget';
import { getTokenScoped, FIREFLY_SCOPE } from '../../auth';

export default class TrainAssignmentWidget extends React.Component {
    static propTypes = {
        rideUuid: PropTypes.string.isRequired,
        translate: PropTypes.func,
    };

    render() {
        return (
            <Box extraClasses={'flix-space-flush-bottom'}>
                <Widget
                    getAccessToken={getTokenScoped(FIREFLY_SCOPE)}
                    language={this.props.translate.language}
                    rideUuid={this.props.rideUuid}
                />
            </Box>
        );
    }
}
