import React, { useContext } from 'react';
import { TranslateContext } from '../../../System/Translations';

export default function RideConceptChange() {
    const translate = useContext(TranslateContext);

    return (
        <div>
            {translate('ride-concept-change.log-message')}
        </div>
    );
}
