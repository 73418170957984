import moment from 'moment/moment';
import 'moment-timezone';
import 'moment-duration-format';

const DEFAULT_FORMAT = 'MMMM Do YYYY, HH:mm:ss';
export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

/**
 * returns formated date/time with given or default pattern
 * @func parseDate
 * @param {string} value - any valid time format (moment.js. time format)
 * @param {string} format - format pattern (valid moment.js patterns)
 * @param {boolean} preserveTZ - if true will parse date with given timezone, not clients one
 * @returns {string} - formated date/time string according given pattern
 */
function parseDate(value, format = DEFAULT_FORMAT, preserveTZ = false) {
    // set parser if we need to preserve TZ or not
    const dater = preserveTZ ? moment.parseZone : moment;
    return dater(value).isValid() ? dater(value).format(format) : value;
}

/**
 * returns formated date/time with given or default pattern
 * @func formatDate
 * @param {string} value - any valid time format
 * @param {string} format - format pattern (valid moment.js patterns)
 * @returns {string} - formated date/time string according given pattern
 */
export function formatDate(value, format) {
    return parseDate(value, format);
}

/**
 * returns formated date/time with given or default pattern
 * and actual time zone passed with the value
 * @method parseZone
 * @param {string} value - any valid time format
 * @param {string} format - format pattern (valid moment.js patterns)
 * @returns {string} - formated date/time string according given pattern with provided timezone
 */
formatDate.parseZone = function parseZone(value, format) {
    return parseDate(value, format, true);
};

formatDate.tz = function tz(value, timezone, format = moment.defaultFormat) {
    if (moment.tz) {
        return moment(value).tz(timezone).format(format);
    }
    return parseDate(value, format, true);
};

/**
 * returns formated date/time with given or default pattern
 * from Unix timestamp (seconds since the Unix Epoch)
 * @method unix
 * @param {string|number} value - Unix timestamp
 * @param {string} format - format pattern (valid moment.js patterns)
 * @returns {string} - formated date/time string according given pattern with provided timezone
 */
formatDate.unix = function unix(value, format) {
    return parseDate(moment.unix(value), format);
};

/**
 * check is date1 before date2
 * @param {string} date1 - any moment valid time format
 * @param {string} date2 - any moment valid time format
 * @returns {boolean}
 */
export function isBefore(date1, date2) {
    return moment(date1).isBefore(date2);
}

/**
 * check if two dates is a same day
 * @func compareDay
 * @param {string} date1 - any moment valid time format
 * @param {string} date2 - any moment valid time format to compare with
 * @param {boolean} preserveTZ - if true will parse date with given timezone, not clients one
 * @returns {boolean}
 */
function compareDay(date1, date2, preserveTZ = false) {
    // set parser if we need to preserve TZ or not
    const dater = preserveTZ ? moment.parseZone : moment;
    return dater(date1).isSame(date2, 'day');
}

/**
 * check if two dates is a same day (with clients time zone)
 * @func isSameDay
 * @param {string} date1 - any moment valid time format
 * @param {string} date2 - any moment valid time format to compare with
 * @returns {boolean}
 */
export function isSameDay(date1, date2) {
    return compareDay(date1, date2);
}

/**
 * check if two dates is a same day (with given time zone)
 * @method parseZone
 * @param {string} date1 - any moment valid time format
 * @param {string} date2 - any moment valid time format to compare with
 * @returns {boolean}
 */
isSameDay.parseZone = function parseZone(date1, date2) {
    return compareDay(date1, date2, true);
};

/**
 * function that returns the duration of a difference between two dates
 * with a given measurement
 * @func timeDiff
 * @param {string} date1 - Start date
 * @param {string} date2 - End date to get the duration of a difference
 * @param {string} unit - The supported measurements are years, months, weeks, days, hours, minutes, and seconds.
 * @returns {number}
 */
export function timeDiff(date1, date2, unit) {
    const a = moment(date1);
    const b = moment(date2);
    return a.diff(b, unit);
}
/**
 * function that returns the duration of a difference between two dates
 * with a given measurement
 * @func timeDiff
 * @param {string} value - any valid time format (moment.js. time format)
 * @param {string} units - The supported measurements are years, months, weeks, days, hours, minutes, and seconds.
 * @param {string} format - format pattern (valid moment.js patterns)
 * @returns {number}
 */
export function timeDuration(value, units, format) {
    return moment.duration(value, units).format(format);
}

/**
 * adding time to the current time
 * @func addTime
 * @param {string|number} delta - amount to add
 * @param {string} unit - the supported measurements are years, months, weeks, days, hours, minutes, seconds and milliseconds.
 * @param {string} format - format pattern (valid moment.js patterns)
 * @param {string} time - start time
 * @param {bool} preserveTZ - get tome zone from the time or client (false->client, true->time)
 * @returns {string} - formated time with addition
 */
export function addTime(
    delta,
    unit = 'ms',
    format = DEFAULT_FORMAT,
    time,
    preserveTZ = false
) {
    const dater = preserveTZ ? moment.parseZone : moment;
    const now = time ? dater(time) : dater();
    return now.add(delta, unit).format(format);
}

/**
 * calculates timezone difference
 * @func timezoneDiff
 * @param {string} time - time to compare with local time, moment valid time format
 * @return {number|string} - timezone offset
 */
export function getTimezoneDiff(time) {
    const local = moment().utcOffset();
    const remote = moment.parseZone(time).utcOffset();
    const timezoneOffsetValue = (local - remote) / 60;
    return timezoneOffsetValue > 0
        ? `+${timezoneOffsetValue}`
        : timezoneOffsetValue;
}

/**
 * provides uts offset for provided date and time zone
 * @func getUtcOffset
 * @param {string} time - moment valid time format
 */
export function getUtcOffset(time) {
    const timeSet = moment.parseZone(time);
    return timeSet.utcOffset() / 60;
}

/**
 * provides uts offset for provided date in local time zone
 * @func getUtcOffset
 * @param {string} time - moment valid time format
 */
export function getUtcOffsetLocal(time) {
    const timeSet = moment(time);
    return timeSet.utcOffset() / 60;
}

/**
 * provides uts offset text for local or provided date
 * @func getUtcOffsetText
 * @param {string} time - moment valid time format
 */
export function getUtcOffsetText(time) {
    const utc = getUtcOffset(time);
    return utc !== null ? ` (UTC ${utc > 0 ? '+' : ''}${utc})` : utc;
}

/**
 * provides the moment obj for provided hh:mm
 * @func getTimeFromHm
 * @param {string} time - moment valid time format
 */
export function getTimeFromFormat(time, format) {
    const newTime = moment(time, format);
    return newTime;
}

/**
 * provides the moment obj for provided YYYY-MM-DD and hh:mm
 * @func getTimeFromHm
 * @param {string} date - moment valid date format
 * @param {string} time - moment valid time format (in hh:mm)
 */
export function getCombinedFomattedDateWithTime(date, format, time) {
    const formattedDate = moment(date).format(format);
    return moment(formattedDate + ' ' + time);
}

/**
 * provides browser timezone
 * @func getBrowserTimezone
 */
export function getBrowserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * format minutes to the human readable value 'Hours h minutes min'
 * @func formatMinutes
 * @param {string} min - minutes to be formated
 * @return {array} - [hours, minutes]
 */
export function formatMinutes(min) {
    const hours = Math.floor(min / 60);
    const minutes = Math.ceil(min % 60);
    return [hours, minutes];
}

export function relativeTime(hours, minutes, locale = 'en') {
    if (isNaN(hours) || isNaN(minutes)) {
        return null;
    }
    const rtl = new Intl.RelativeTimeFormat(locale, {
        numeric: 'auto',
        style: 'short',
    });
    let hParts = [],
        mParts = [];

    if (minutes === 0 && hours === 0) {
        return rtl.format(0, 'minute');
    }

    if (hours !== 0 && minutes !== 0) {
        hParts = rtl.formatToParts(hours, 'hour');
        mParts = rtl.formatToParts(minutes, 'minute');
    }

    if (hParts.length && mParts.length) {
        const leadingLiteral =
            hParts[0].type === 'literal' ? hParts[0].value : '';
        const middleLiteral =
            hParts.at(-1).type === 'literal'
                ? hParts.at(-1).value.replace(/\s(\w*)$/, '')
                : '';
        const trailingLiteral =
            mParts.at(-1).type === 'literal' ? mParts.at(-1).value : '';
        const hoursValue =
            hParts[0].type === 'literal' ? hParts[1].value : hParts[0].value;
        const minutesValue =
            mParts[0].type === 'literal' ? mParts[1].value : mParts[0].value;
        return `${leadingLiteral}${hoursValue}${middleLiteral} ${minutesValue}${trailingLiteral}`;
    }
    if (hours !== 0) {
        return rtl.format(hours, 'hour');
    }
    if (minutes !== 0) {
        return rtl.format(minutes, 'minute');
    }
}

/**
 * date time formatting
 * @func tripDaysDiff
 * @params {date, date} Date
 * @return  {string} - number of days e.g +1
 */
export const tripDaysDiff = (dateString1, dateString2) => {
    // omit time of the day
    const formattedDate1 = dateString1.split('T')[0];
    const formattedDate2 = dateString2.split('T')[0];

    // make date object
    const date1 = new Date(formattedDate1);
    const date2 = new Date(formattedDate2);

    // compare dates
    const timeDifference = date2.getTime() - date1.getTime();
    const diffInDays = timeDifference / (1000 * 60 * 60 * 24);

    return diffInDays > 0 ? `+${Math.floor(diffInDays)}` : '';
};
