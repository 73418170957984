import { withStateHandlers } from 'recompose';

/**
 * Enhancer with handlers to control state
 * for form visibility
 */

export default withStateHandlers(
    { isFormOpen: false },
    {
        openForm: () => () => ({ isFormOpen: true }),
        closeForm: () => () => ({ isFormOpen: false }),
    },
);
