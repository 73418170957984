import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../util/date';
import { TranslateContext } from '../../../System/Translations';

export default function DeferredNotificationQueued(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    return (
        <div>
            {translate('deferred-notification-queued.notification-sending-queued-for-station')} 
            <strong>{ item.parameters.station.name }</strong> {translate('deferred-notification-queued.notification-to-send')}
            {translate('deferred-notification-queued.notification-at')} <strong>{ formatDate(item.parameters.send_at) } </strong>
            - { item.parameters.incoming_passengers } {translate('deferred-notification-queued.passenger')}
            {translate('deferred-notification-queued.notification-boarding')}
        </div>
    );
}

DeferredNotificationQueued.propTypes = {
    item: PropTypes.object.isRequired,
};
