import React, {
    useReducer,
    useContext,
    useEffect,
    useState,
    useRef,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router';
import { Button, Grid, GridCol, FormRow } from '@flixbus/honeycomb-react';
import {
    IconBus,
    IconRideTransfer,
    IconStation,
    IconTime,
    IconBuses,
    IconCheckmarkStrong,
    Icon,
    IconCalendar,
    IconTimeSolid,
    IconStationSolid,
    IconWheel,
    IconPartner,
    IconLabel,
    IconLabelSolid,
    IconArrowUp,
    IconArrowDown,
    IconDocument,
    IconTimelineAlt,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../System/Translations';
import {
    useCustomFilters,
    useFiltersMap,
    useLists,
    useDefaults,
} from '../hooks';
import SearchAutocomplete from './SearchAutocomplete';
import formReducer, { useActionCreator } from './SearchFormReducer';
import * as CONST from './formMap';
import FormBuilder from '../FiltersBuilder/FormBuilder';
import FormDataCloud from './FormDataCloud';

const ICON_MAP = {
    [CONST.LINE]: IconBus,
    [CONST.TAGS]: IconLabel,
    [CONST.EXCLUDE_TAGS]: IconLabelSolid,
    [CONST.TRIP_NUMBER]: IconBuses,
    [CONST.STOP_PASSING_THROUGH]: IconRideTransfer,
    [CONST.PLATE_NUMBER]: IconWheel,
    [CONST.STOP_ARRIVAL]: IconStationSolid,
    [CONST.STOP_DEPARTURE]: IconStation,
    [CONST.DATE]: IconCalendar,
    [CONST.ARRTIME]: IconTimeSolid,
    [CONST.DEPTIME]: IconTime,
    [CONST.STATUSES]: IconCheckmarkStrong,
    [CONST.PARTNERS]: IconPartner,
    [CONST.WEEKDAYS]: IconCalendar,
    [CONST.CONCESSION_OWNERS]: IconDocument,
    [CONST.TIMEZONE]: IconTimelineAlt,
    [CONST.BUS_NUMBER]: IconBus,
    [CONST.BUS_IDS]: IconBus,
};

function searchToFormData(search) {
    const rawQuery = search.match(/\?s=(.*)/);
    if (rawQuery === null) {
        return {};
    }
    try {
        const query = window.atob(rawQuery[1]);
        const formData = JSON.parse(query);

        if (Object.keys(formData).length > 0) {
            return formData;
        }
    } catch (e) {
        return {};
    }

    return {};
}

const STORAGE_FILTERS_EXPANDED_KEY = 'rv-storage-filters-expanded';

export default function SearchForm(props) {
    const { onSubmit, loading } = props;
    const translate = useContext(TranslateContext);
    const { pathname, search } = useLocation();
    const history = useHistory();
    const [formData, dispatchForm] = useReducer(formReducer, {});
    const [addAction, deleteAction, resetAction, batchAction] =
        useActionCreator(dispatchForm);
    const [customFilters, isCustom, saveCustomFilters] = useCustomFilters();

    const [isReady, setReady] = useState(false);
    const [isFromUrl, setIsFromUrl] = useState(false);
    const [toggleFiltersPane, setToggleFiltersPane] = useState(() => {
        const localStorageValue = window.localStorage.getItem(
            STORAGE_FILTERS_EXPANDED_KEY
        );
        const storageValue = localStorageValue
            ? JSON.parse(localStorageValue)
            : false;

        return storageValue || isCustom;
    });
    const filtersMap = useFiltersMap();
    const [lists, getLists] = useLists();
    const [setDefaults] = useDefaults(customFilters, formData);
    const initLoad = useRef(true);
    const today = new Date();
    const todayMonth =
        today.getMonth() >= 9
            ? today.getMonth() + 1
            : `0${today.getMonth() + 1}`;
    const todayString = `${today.getDate()}.${todayMonth}.${today.getFullYear()}`;

    const submitForm = useCallback(() => {
        onSubmit(formData);
    }, [formData, onSubmit]);

    useEffect(() => {
        if (lists.data && isReady === false) {
            setReady(true);
        }
    }, [lists.data, isReady, setReady]);

    // initial set formData from url search query
    useEffect(() => {
        const formFromSearch = searchToFormData(search);
        const setForm = batchAction();
        if (isReady && Object.keys(formFromSearch).length && !isFromUrl) {
            setForm(formFromSearch);
        }
        if (isReady) {
            setIsFromUrl(true);
        }
    }, [isReady, search, batchAction, isFromUrl]);

    // set default values for filters
    useEffect(() => {
        if (isFromUrl && isReady) {
            setDefaults({
                [CONST.STATUSES]: [addAction(CONST.STATUSES, true), 'on_sale'],
                [CONST.DATE]: [addAction(CONST.DATE), todayString],
            });
        }
    }, [addAction, isReady, isFromUrl, setDefaults, todayString]);

    // Hit the search on page load if filters are set in the URL
    useEffect(() => {
        const filtersCount = Object.keys(formData).length;
        if (initLoad.current && isReady && filtersCount) {
            initLoad.current = false;
            const isDefaultStatus =
                formData[CONST.STATUSES]?.length === 1 &&
                formData[CONST.STATUSES].includes('on_sale');
            const isToday = formData[CONST.DATE]
                ?.split(',')
                ?.includes(todayString);
            if (filtersCount === 2 && isDefaultStatus && isToday) {
                return;
            }
            submitForm();
        }
    }, [isReady, formData, todayString, submitForm]);

    // Set url search query to persist formData
    useEffect(() => {
        if (Object.keys(formData).length > 0) {
            try {
                history.push({
                    pathname,
                    search: `?s=${window.btoa(JSON.stringify(formData))}`,
                });
            } catch (e) {}
        } else if (isReady) {
            // do not perform this action before form data will be set
            // it sets after stops and lines will be loaded
            history.push({
                pathname,
                search: '',
            });
        }
    }, [formData, history, pathname, isReady]);

    useEffect(() => {
        const options = {
            params: {},
        };
        if (translate.language !== 'en') {
            options.params.lang = translate.language;
        }
        getLists(options);
    }, [getLists, translate.language]);

    const buttonLabel = loading
        ? translate('ride-search.form.button-search-submitting')
        : translate('ride-search.form.button-search');

    function setAction(action) {
        return (value) => {
            dispatchForm({ type: action, value });
        };
    }

    function onFormSubmit(e) {
        e.preventDefault();
        onSubmit(formData);
    }

    /**
     *
     * @TODO
     * Come up with options (tags) generation
     * put it in separate file with single source
     */
    function getLineOptions() {
        if (lists.data) {
            const { lines } = lists.data;
            return lines.map((line) => ({
                title: `${line.code}-${line.title}`,
                icon: <Icon InlineIcon={ICON_MAP[CONST.LINE]} />,
                value: line.uuid,
                type: CONST.LINE,
                subtitle: filtersMap[CONST.LINE],
                search: `${line.code}`,
                key: line.uuid,
                add: addAction(CONST.LINE, true),
            }));
        }
        return [];
    }

    function getTagsOptions() {
        if (lists.data) {
            const { tags } = lists.data;
            return tags.map((tag) => ({
                title: tag.name,
                icon: <Icon InlineIcon={ICON_MAP[CONST.TAGS]} />,
                value: tag.name,
                type: CONST.TAGS,
                subtitle: filtersMap[CONST.TAGS],
                search: tag.id.toString(),
                key: tag.id,
                add: addAction(CONST.TAGS, true),
            }));
        }
        return [];
    }

    function getConcessionOwnersNamesOptions() {
        if (lists.data) {
            const { concessionOwners } = lists.data;
            return concessionOwners.map((name) => ({
                title: `${name.title}`,
                icon: <Icon InlineIcon={ICON_MAP[CONST.CONCESSION_OWNERS]} />,
                value: name.title,
                type: CONST.CONCESSION_OWNERS,
                subtitle: filtersMap[CONST.CONCESSION_OWNERS],
                search: `${name.title.toString()}`,
                key: name.id,
                add: addAction(CONST.CONCESSION_OWNERS, true),
            }));
        }
        return [];
    }

    function getStopsOptions() {
        if (lists.data) {
            const { stops } = lists.data;
            return stops.map((stop) => ({
                title: `${stop.code}-${stop.name}`,
                icon: <Icon InlineIcon={ICON_MAP[CONST.STOP_DEPARTURE]} />,
                value: stop.uuid,
                type: CONST.STOP_DEPARTURE,
                subtitle: filtersMap[CONST.STOP_DEPARTURE],
                search: `${stop.code}-${stop.name}`,
                key: stop.uuid,
                add: addAction(CONST.STOP_DEPARTURE),
            }));
        }
        return [];
    }

    function getPlateOption() {
        return {
            title: '',
            value: '',
            icon: <Icon InlineIcon={ICON_MAP[CONST.PLATE_NUMBER]} />,
            subtitle: 'Plate number',
            key: 'plate-number',
            type: filtersMap[CONST.PLATE_NUMBER],
            add: addAction(CONST.PLATE_NUMBER),
        };
    }

    function formBuilderFilters() {
        return customFilters.reduce((ff, filter) => {
            const res = [...ff];
            if (filter.checked) {
                res.push(filter.name);
            }
            return res;
        }, []);
    }
    /**
     *
     * @todo
     * Match status values with labels for tags
     */
    function getTags() {
        if (lists.data === null) {
            return [];
        }
        return Object.entries(formData).reduce((cloudTags, entry) => {
            const [key, value] = entry;
            const { stops, lines, partners, tags } = lists.data;
            let result = [...cloudTags];
            if (key === CONST.LINE) {
                return result.concat(
                    lines.reduce((acc, line) => {
                        let val = [...acc];
                        if (value.includes(line.uuid)) {
                            val.push({
                                title: (
                                    <span>
                                        <Icon InlineIcon={ICON_MAP[key]} />
                                        {line.code}-{line.title}
                                    </span>
                                ),
                                value: line.uuid,
                                type: key,
                                subtitle: 'Line number',
                                key: line.uuid,
                                delete: deleteAction(key),
                            });
                        }

                        return val;
                    }, [])
                );
            }
            if (key === CONST.TAGS || key === CONST.EXCLUDE_TAGS) {
                return result.concat(
                    tags.reduce((acc, tag) => {
                        let val = [...acc];
                        // convert type to have bcw compatibility with old entrypoint
                        if (value.includes(String(tag.id))) {
                            val.push({
                                title: (
                                    <span>
                                        <Icon InlineIcon={ICON_MAP[key]} />
                                        {tag.name}
                                    </span>
                                ),
                                value: String(tag.id),
                                type: key,
                                subtitle:
                                    key === CONST.EXCLUDE_TAGS
                                        ? translate(
                                              'ride-search.tag-exclude-input-label'
                                          )
                                        : translate(
                                              'ride-search.tag-input-label'
                                          ),
                                key: String(tag.id),
                                delete: deleteAction(key),
                            });
                        }

                        return val;
                    }, [])
                );
            }
            if (
                key === CONST.STOP_ARRIVAL ||
                key === CONST.STOP_DEPARTURE ||
                key === CONST.STOP_PASSING_THROUGH
            ) {
                return result.concat(
                    stops.reduce((acc, stop) => {
                        let val = [...acc];
                        if (value === stop.uuid) {
                            val.push({
                                title: (
                                    <span>
                                        <Icon InlineIcon={ICON_MAP[key]} />
                                        {stop.code}-{stop.name}
                                    </span>
                                ),
                                value: stop.uuid,
                                type: key,
                                subtitle: 'Departure station',
                                key: stop.uuid,
                                delete: deleteAction(key),
                            });
                        }
                        return val;
                    }, [])
                );
            }
            if (key === CONST.PARTNERS) {
                return result.concat(
                    partners.reduce((acc, partner) => {
                        let val = [...acc];
                        if (value.includes(partner.uuid)) {
                            val.push({
                                title: (
                                    <span>
                                        <Icon InlineIcon={ICON_MAP[key]} />
                                        {partner.shortName}
                                    </span>
                                ),
                                value: partner.uuid,
                                type: key,
                                key: partner.uuid,
                                delete: deleteAction(key),
                            });
                        }

                        return val;
                    }, [])
                );
            }
            // skip live rides or exclude assigned buses filter from adding to the form data cloud
            if (
                key === CONST.LIVE_RIDES ||
                key === CONST.EXCLUDE_ASSIGNED_BUSES
            ) {
                return result;
            }
            if (Array.isArray(value)) {
                return result.concat(
                    value.map((item) => ({
                        title: (
                            <span>
                                <Icon InlineIcon={ICON_MAP[key]} /> {item}{' '}
                            </span>
                        ),
                        value: item,
                        type: key,
                        key: `${item}-${key}`,
                        delete: deleteAction(key),
                    }))
                );
            }

            return result.concat({
                title: (
                    <span>
                        {ICON_MAP[key] && <Icon InlineIcon={ICON_MAP[key]} />}{' '}
                        {value}
                    </span>
                ),
                value,
                type: key,
                key: `${value}-${key}`,
                delete: deleteAction(key),
            });
        }, []);
    }

    function filtersPaneToggle() {
        setToggleFiltersPane(!toggleFiltersPane);

        try {
            window.localStorage.setItem(
                STORAGE_FILTERS_EXPANDED_KEY,
                !toggleFiltersPane
            );
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <form onSubmit={onFormSubmit}>
            <FormRow last>
                <Grid>
                    <GridCol>
                        <FormDataCloud tags={getTags()} reset={resetAction()} />
                    </GridCol>
                </Grid>
            </FormRow>

            <div className="ride-search__main-panel">
                <div className="ride-search__multiple-input">
                    <FormRow last>
                        <SearchAutocomplete
                            lines={getLineOptions()}
                            stops={getStopsOptions()}
                            plate={getPlateOption()}
                            tags={getTagsOptions()}
                            concessionOwners={getConcessionOwnersNamesOptions()}
                            onChange={setAction(CONST.MULTIPLE)}
                            add={addAction}
                            delete={deleteAction}
                        />
                    </FormRow>
                </div>
                <div className="ride-search__filter-button flix-has-text-centered">
                    <FormRow last>
                        &nbsp;
                        <Button onClick={filtersPaneToggle} link>
                            {translate('logs-filters.heading')}
                            <Icon
                                appearance="primary"
                                InlineIcon={
                                    toggleFiltersPane
                                        ? IconArrowUp
                                        : IconArrowDown
                                }
                            />
                        </Button>
                    </FormRow>
                </div>
                <div className="ride-search__search-button ride-search-text--right">
                    <FormRow last>
                        <Button
                            appearance="primary"
                            disabled={!Object.keys(formData).length || loading}
                            onClick={submitForm}
                            loading={loading}
                            type="button"
                        >
                            {buttonLabel}
                        </Button>
                    </FormRow>
                </div>
            </div>

            {toggleFiltersPane && (
                <div className="ride-search__filters-pane">
                    <FormBuilder
                        lists={lists}
                        customFilters={formBuilderFilters()}
                        panelCustomFilters={customFilters}
                        saveCustomFilters={saveCustomFilters}
                        setAction={setAction}
                        formData={formData}
                        actions={[addAction, deleteAction, resetAction]}
                    />
                </div>
            )}
        </form>
    );
}

SearchForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};
SearchForm.defaultProps = { loading: false };
