import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';
import { Link } from '@flixbus/honeycomb-react';
import api from '../../../../api/Client';

export default function Rebookr2RebookingFromRide(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);
    const BACKEND_HOST = api?.config?.backend_host?.replace(/\/$/, '');
    const { source_order_id, new_order_id, ride } = item.parameters;

    return (
        <div>
            {translate('rebookr2-rebooking.passengers-from-order')}&nbsp;
            <Link
                href={`${BACKEND_HOST}/reports/orders/${source_order_id}/view`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {source_order_id}
            </Link>
            &nbsp;
            {translate('rebookr2-rebooking.passengers-were-rebooked-from')}
            &nbsp;
            <Link href={`/ride-view/${ride.id}/timeline`} target="_blank">
                {ride.title}
            </Link>
            {new_order_id ? (
                <>
                    {translate('rebookr2-rebooking.passengers-to-new-order')}
                    &nbsp;
                    <Link
                        href={`${BACKEND_HOST}/reports/orders/${new_order_id}/view`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {new_order_id}
                    </Link>
                    &nbsp;
                </>
            ) : null}
        </div>
    );
}

Rebookr2RebookingFromRide.propTypes = {
    item: PropTypes.object.isRequired,
};
