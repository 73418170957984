import Autobahn from 'autobahn';
import api from '../api/Client';

class WsConnection {
    constructor() {
        this.unreachableHandlers = [];
        this.lostHandlers = [];
        this.errorHandlers = [];
        this.readyHandlers = [];
        this.subscribers = [];
        this.currentSession = this.createConnection()
            .open()
            .catch((e) => {
                console.log('WS: create connection fail', e);
            });
    }

    createConnection() {
        const { config: CONFIG } = api;
        if (this.currentConnection && this.currentConnection.isOpen) {
            this.currentConnection.close();
        }

        this.currentConnection = new Autobahn.Connection({
            url: CONFIG.wamp.url,
            realm: CONFIG.wamp.realm,
            authmethods: ['token'],
            onchallenge() {
                return CONFIG.wamp.token;
            },
        });

        return this;
    }

    open() {
        return new Promise((resolve, reject) => {
            this.currentConnection.onopen = (session, details) => {
                this.currentSession = session;
                this.readyHandlers.forEach((readyHandler) => readyHandler(session, details));
                resolve(session);
            };

            this.currentConnection.onclose = (reason, details) => {
                if (reason === 'unreachable') {
                    this.unreachableHandlers.forEach((unreachableHandler) => unreachableHandler(details));
                } else if (reason === 'lost') {
                    this.lostHandlers.forEach((lostHandler) => lostHandler(details));
                } else {
                    this.errorHandlers.forEach((errorHandler) => errorHandler(details));
                }
                reject(details);
            };

            this.currentConnection.open();
        });
    }

    getSession() {
        return new Promise((resolve) => resolve(this.currentSession));
    }

    onReady(callback) {
        this.readyHandlers.push(callback);
        return this;
    }

    onUnreachable(callback) {
        this.unreachableHandlers.push(callback);
        return this;
    }

    onLost(callback) {
        this.lostHandlers.push(callback);
        return this;
    }

    onError(callback) {
        this.errorHandlers.push(callback);
        return this;
    }
}

let instance;

function getInstance() {
    if (instance) {
        return instance;
    }

    instance = new WsConnection();

    return instance;
}

export default getInstance;
