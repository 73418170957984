import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner as Loader } from '@flixbus/honeycomb-react';

export default function Spinner(props) {
    const { message, className, size, ...restProps } = props;
    const cssClassName = classNames('ride-viewer-spinner-box', {
        [className]: className,
    });
    return (
        <div {...restProps} className={cssClassName}>
            <Loader size={size} />
            {message && <p>{message}</p>}
        </div>
    );
}

Spinner.propTypes = {
    message: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
};

Spinner.defaultProps = {
    message: '',
    className: '',
    size: 'xxl',
};
