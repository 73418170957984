import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@flixbus/honeycomb-react';
import { Icon, IconClose, IconOffer } from '@flixbus/honeycomb-icons-react';
import useUserCan from '../../auth/useUserCan';
import RideTag from './compounds/RideTag';
import AddTag from './compounds/AddTag';
import RemoveTagPromt from './compounds/RemoveTagPromt';
import { TranslateContext } from '../System/Translations';

import './style.scss';

export default class RideTags extends Component {
    static propTypes = {
        tags: PropTypes.array,
        removeRideTag: PropTypes.func.isRequired,
        addRideTags: PropTypes.func.isRequired,
        tagsGroup: PropTypes.array,
        getTagsGroup: PropTypes.func,
    };

    static defaultProps = {
        tags: [],
        tagsGroup: [],
        getTagsGroup: () => {},
    };

    constructor() {
        super();
        this.state = {
            showAddTags: false,
            showRemoveTag: false,
            tagToRemove: null,
        };
    }

    showTagsModal = () => {
        this.setState({
            showAddTags: true,
        });
    };

    hideTagsModal = () => {
        this.setState({
            showAddTags: false,
        });
    };

    showRemoveTagModal = (tag) => {
        this.setState({
            showRemoveTag: true,
            tagToRemove: tag,
        });
    };

    hideRemoveTagModal = () => {
        this.setState({
            showRemoveTag: false,
            tagToRemove: null,
        });
    };

    static contextType = TranslateContext;

    render() {
        const translate = this.context;
        const {
            // eslint-disable-next-line react/prop-types
            tags,
            addRideTags,
            removeRideTag,
            tagsGroup,
            getTagsGroup,
        } = this.props;
        const isUserCanAddTag = useUserCan('adding_tag');
        const action = (item) => {
            if (!isUserCanAddTag) {
                return undefined;
            }
            if (item.isRideViewerReadonly) {
                return undefined;
            }
            return this.showRemoveTagModal(item);
        };
        const renderedTags = tags.reduce((acc, item) => {
            acc.push(
                // eslint-disable-next-line max-len
                <RideTag
                    key={item.id}
                    name={item.name}
                    color={item.color}
                    InlineIcon={IconClose}
                    action={() => {
                        action(item);
                    }}
                />
            );

            return acc;
        }, []);
        const skipTagIds = tags.reduce((acc, item) => {
            acc.push(item.id);
            return acc;
        }, []);
        const { showAddTags, tagToRemove, showRemoveTag } = this.state;
        return (
            <Box extraClasses={renderedTags.length ? null : 'hide-on-print'}>
                {isUserCanAddTag ? (
                    <div className="ride-tags-heading hide-on-print">
                        <button
                            className="add-button"
                            onClick={this.showTagsModal}
                            type="button"
                        >
                            <Icon InlineIcon={IconOffer} />
                            {translate('tag.add-tag')}
                        </button>
                    </div>
                ) : null}
                {renderedTags}
                {showAddTags ? (
                    <AddTag
                        addRideTags={addRideTags}
                        skipTagIds={skipTagIds}
                        tagsGroup={tagsGroup}
                        onClose={this.hideTagsModal}
                        getTagsGroup={getTagsGroup}
                    />
                ) : null}
                {showRemoveTag ? (
                    <RemoveTagPromt
                        translate={translate}
                        onClose={this.hideRemoveTagModal}
                        tagToRemove={tagToRemove}
                        onConfirm={() => {
                            removeRideTag(tagToRemove.id);
                            this.hideRemoveTagModal();
                        }}
                    />
                ) : null}
            </Box>
        );
    }
}
