import React from 'react';
import PropTypes from 'prop-types';
import RideMetaContext from '../context';

export default function withRideMetaProvider(Component) {
    // eslint-disable-next-line react/prefer-stateless-function
    return class WithRideMetaProvider extends React.Component {
        // eslint-disable-next-line react/sort-comp
        setRideMeta = (meta) => {
            this.setState({
                rideMeta: meta,
            });
        };

        state = {
            rideMeta: {},
        };

        render() {
            const { rideMeta } = this.state;
            return (
                // eslint-disable-next-line react/jsx-no-undef
                <RideMetaContext.Provider value={{ rideMeta, setRideMeta: this.setRideMeta }}>
                    <Component {...this.props} />
                </RideMetaContext.Provider>
            );
        }
    };
}
withRideMetaProvider.propTypes = {
    ride: PropTypes.func.isRequired,
};
