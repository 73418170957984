import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    AutocompleteOptions,
    Text,
    Input,
} from '@flixbus/honeycomb-react';
import { Icon, IconMagnifier } from '@flixbus/honeycomb-icons-react';
import { autocompleteProcessor } from '../helpers';

/**
 *
 * @todo
 * Use common List picker component
 */
export default function SearchAutocomplete(props) {
    const { lines, stops, plate, tags, concessionOwners } = props;

    // Search autocomplete
    const [dataSearch, setDataSearch] = useState([]);
    const [innerValue, setInnerValue] = useState('');
    const AutocompleteData = [
        ...lines.slice(0, 5),
        ...stops.slice(0, 5),
        ...tags.slice(0, 5),
        ...concessionOwners.slice(0, 5),
    ];

    function getPlateNumberOption(value) {
        return { ...plate, title: value, value };
    }
    const filterAutocompleteData = (searchQuery, arrayToFilter) =>
        new Promise((resolve) => {
            const res = autocompleteProcessor(
                searchQuery,
                'search',
                arrayToFilter
            );

            resolve(res);
        });

    // narrows down suggested options list based on user input
    const filterOptionsList = async (e) => {
        const fLines = await filterAutocompleteData(e.target.value, lines);
        const fStops = await filterAutocompleteData(e.target.value, stops);
        const fTags = await filterAutocompleteData(e.target.value, tags);
        const fConcessionOwners = await filterAutocompleteData(
            e.target.value,
            concessionOwners
        );
        let plateNumber = [];
        if (e.target.value !== '') {
            plateNumber = getPlateNumberOption(e.target.value);
        }
        const dataSearch = []
            .concat(
                fLines.slice(0, 10),
                fStops.slice(0, 10),
                fTags.slice(0, 10),
                fConcessionOwners.slice(0, 10)
            )
            .sort((a, b) => a.index - b.index);
        setDataSearch([].concat(plateNumber, dataSearch));
    };

    // pushes selected option value to the state
    const handleItemSelect = (selectedItem) => {
        const { value, add: addValue } = selectedItem;
        addValue(value);
        setInnerValue('');
        setDataSearch([]); // hiding the options list
    };

    function renderOption(option) {
        return (
            <>
                <Text className="ride-search-suggestions-option">
                    <span className="ride-search-suggestions-option__title">
                        {option.icon} {option.title}
                    </span>
                    {option.subtitle}
                </Text>
            </>
        );
    }

    return (
        <div className="ride-search-custom-autocomplete">
            <Autocomplete
                id="composite-search"
                options={dataSearch === null ? null : dataSearch}
                onDebounce={filterOptionsList}
                onSelect={handleItemSelect}
                value={innerValue}
                onChange={(e) => {
                    setInnerValue(e.target.value);
                }}
            >
                <Input
                    iconLeft={
                        <Icon appearance="primary" InlineIcon={IconMagnifier} />
                    }
                    id="composite-search-input"
                    aria-label="Search Ride"
                    type="search"
                    autoComplete="off"
                    onFocus={async (e) => {
                        if (e.target.value !== '') {
                            await filterOptionsList({ ...e }); // copy object to prevent  that target getting null

                            return;
                        }
                        setDataSearch(AutocompleteData);
                    }} // allows option list to be shown on focus
                />
                <AutocompleteOptions
                    optionsToDisplay={5}
                    label="composite-search-options"
                    renderOption={renderOption}
                />
            </Autocomplete>
        </div>
    );
}

SearchAutocomplete.propTypes = {
    lines: PropTypes.array,
    stops: PropTypes.array,
    plate: PropTypes.object,
    tags: PropTypes.array,
    concessionOwners: PropTypes.array,
};
SearchAutocomplete.defaultProps = {
    lines: [],
    stops: [],
    plate: {},
    tags: [],
    concessionOwners: [],
};
