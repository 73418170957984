import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../System/Translations';

export default function StationClosed(props) {
    const { item } = props;
    const translate = useContext(TranslateContext);

    return (
        <div>
        {translate('station-closed.station-is-closed')} <strong>{ item.parameters.station.name }</strong>.
        {translate('station-closed.station-id')} <strong>{ item.parameters.station.id }</strong>
        </div>);
}

StationClosed.propTypes = {
    item: PropTypes.object.isRequired,
};
